import {lag} from '@possible/generated/proto'
import Log from 'src/lib/loggingUtil'

export const californiaSingleAPR = 459
export const californiaInstallmentAPR = 178
export const floridaFees = 5.0
export const alaskaFees = 5.0
const louisianaFees = 10.0
const louisianaMaxInterest = 45
const daysInWeek = 7
const daysInYear = 365.0
const defaultPaymentIntervalWeeks = 2
const defaultWeekDuration = 8
const ohioMaximumSubjectToFeeAmount = 300

export type PaymentInfoType = {
  loan_amount: number
  fees: number
  interest: number
  interest_and_fees: number
  total_owed: number
  payment_amt: number
}

function getInterest(
  apr: number,
  amount: number,
  numberOfPayments: number,
  collectionWeekInterval: number,
  maxInterest?: number,
): number {
  const daily = apr / 100.0 / daysInYear
  const perPayment = amount / numberOfPayments
  let total = 0
  const numberOfDaysBetweenCollection = daysInWeek * collectionWeekInterval
  for (let i = 0; i < numberOfPayments; i++) {
    const a = amount * numberOfDaysBetweenCollection * daily
    total += a
    amount -= perPayment
    if (amount <= 0) {
      break
    }
  }
  if (maxInterest) {
    total = total > maxInterest ? maxInterest : total
  }
  return total
}

function complexRateValidation(complexRate: lag.LoanTerms.IComplexRate) {
  return (
    typeof complexRate.amountMin !== undefined &&
    typeof complexRate.amountMax !== undefined &&
    typeof complexRate.rate !== undefined
  )
}

function getComplexInterest(terms: lag.ILoanTerms, loanAmount: number): number {
  const {complexRate} = terms
  let interestAmount = 0,
    startAmount = loanAmount

  if (stateHasComplexInterestRate(terms) && complexRate) {
    let rateIndex = complexRate.length - 1
    while (startAmount > 0 && rateIndex >= 0 && complexRateValidation(complexRate[rateIndex])) {
      const {amountMin, amountMax, rate} = complexRate[rateIndex]
      if (startAmount > amountMin! && startAmount <= amountMax!) {
        const amount = startAmount - amountMin!
        interestAmount = interestAmount + amount * rate!
        startAmount = startAmount - amount
      }
      rateIndex -= 1
    }
  }
  return interestAmount
}

function stateHasComplexInterestRate(terms: lag.ILoanTerms): boolean {
  return !!terms?.complexRate?.length
}

export function getEstimatedAPR(terms: lag.ILoanTerms, loanAmount: number): number | undefined {
  // Following below APR calculation
  //  ((fees + interest) / loan amount) / number of days in loan term) * 365 * 100
  if (stateHasComplexInterestRate(terms)) {
    const interestByAmount = getComplexInterest(terms, loanAmount) / loanAmount
    const durationWeeks = terms.loanDurationWeeks ?? defaultWeekDuration
    const numberOfDaysLoanTerm = durationWeeks * daysInWeek
    const apr = (interestByAmount / numberOfDaysLoanTerm) * daysInYear * 100

    return Number(apr.toFixed(2))
  } else {
    return terms.estimatedAPR
  }
}

//this function calculates the interest, fees, and payments for states that have a fixed fee (vs rate based fee) and optionally capped interest
function CalcFeesInterestPaymentsWithFixedFeeAndCappedInterest(
  terms: lag.ILoanTerms,
  loan_amount: number,
  fixedFee: number,
  defaultWeeks: number,
  maxInterest?: number,
): PaymentInfoType {
  const fees = fixedFee
  const numberOfWeeks = terms.loanDurationWeeks ?? defaultWeeks
  const paymentInterval = terms.collectionWeekInterval ?? defaultPaymentIntervalWeeks
  const numberOfPayments = numberOfWeeks / paymentInterval

  const interest = getInterest(
    terms.estimatedAPR ?? 0,
    loan_amount,
    numberOfPayments,
    paymentInterval,
    maxInterest,
  )

  const interest_and_fees = interest + fees
  const total_owed = loan_amount + interest_and_fees
  const payment_amt = total_owed / numberOfPayments

  return {
    loan_amount,
    fees,
    interest,
    interest_and_fees,
    total_owed,
    payment_amt,
  }
}

export function CalcFeesInterestSinglePaymentCalifornia(
  terms: lag.ILoanTerms,
  loan_amount: number,
): PaymentInfoType {
  const fees = 0
  const interest = loan_amount * (terms.rate ?? 0)
  const interest_and_fees = interest + fees
  const total_owed = loan_amount + interest_and_fees
  const payment_amt = total_owed

  return {
    loan_amount,
    fees,
    interest,
    interest_and_fees,
    total_owed,
    payment_amt,
  }
}

export const StatesWithFixedFeeAndCappedInterest = new Set(['FL', 'LA', 'AK'])

const stateHasFixedFeeAndCappedInterest = (stateAbv: string): boolean =>
  StatesWithFixedFeeAndCappedInterest.has(stateAbv)

type FixedFeeAndDuration = {
  stateFee: number
  duration: number
  maxInterest?: number
}

function getFixedFee(stateAbv: string): FixedFeeAndDuration {
  switch (stateAbv) {
    case 'FL':
      return {
        stateFee: floridaFees,
        duration: defaultWeekDuration,
      }
    case 'LA':
      return {
        stateFee: louisianaFees,
        duration: defaultWeekDuration,
        maxInterest: louisianaMaxInterest,
      }
    case 'AK':
      return {
        stateFee: alaskaFees,
        duration: defaultWeekDuration,
      }
    default:
      return {
        stateFee: 0,
        duration: defaultWeekDuration,
      }
  }
}

function calculateCappedFeeAndServiceChargeForIowa(loanAmount: number): number {
  const feeAmount = loanAmount * 0.1
  return feeAmount > 30 ? 30 : feeAmount
}

const ohioLoanAmount = (loanAmount) => Math.min(loanAmount, ohioMaximumSubjectToFeeAmount)

const loanAmountSubjectToFee = (stateAbv, loanAmount): number =>
  stateAbv === 'OH' ? ohioLoanAmount(loanAmount) : loanAmount

export function CalcFeesInterestPayments(
  terms: lag.ILoanTerms,
  loan_amount: number,
  stateAbv: string,
  discount?: number,
): PaymentInfoType {
  Log.log('CalcFeesInterestPayments: ', terms)
  let rate = terms.rate ?? 0
  const durationWeeks = terms.loanDurationWeeks ?? defaultWeekDuration

  /* for California we are calculating the payments for installment plan */
  const paymentIntervalWeeks = terms.collectionWeekInterval ?? defaultPaymentIntervalWeeks
  const payment_count = stateAbv === 'CA' ? 4 : durationWeeks / paymentIntervalWeeks

  if (stateAbv === 'OH') {
    //The OH rate is per month
    rate = (rate * durationWeeks) / 4
  } else if (stateHasFixedFeeAndCappedInterest(stateAbv)) {
    const {stateFee, duration, maxInterest} = getFixedFee(stateAbv)
    return CalcFeesInterestPaymentsWithFixedFeeAndCappedInterest(
      terms,
      loan_amount,
      stateFee,
      duration,
      maxInterest,
    )
  }

  if (discount && discount > 0) {
    rate = rate * (1 - discount)
  }

  const fees = (): number => {
    if (stateHasComplexInterestRate(terms)) {
      return getComplexInterest(terms, loan_amount)
    } else if (stateAbv === 'IA') {
      return parseFloat((calculateCappedFeeAndServiceChargeForIowa(loan_amount) * 2).toFixed(2))
    } else {
      return parseFloat((loanAmountSubjectToFee(stateAbv, loan_amount) * rate).toFixed(2))
    }
  }
  let interest = 0

  if (terms.lenderApr && terms.lenderApr !== 0) {
    interest = getInterest(terms.lenderApr, loan_amount, payment_count, paymentIntervalWeeks)
  }

  const calculatedFees = fees()
  const interest_and_fees = calculatedFees + interest
  const total_owed = interest_and_fees + loan_amount
  const payment_amt = total_owed / payment_count
  return {
    loan_amount,
    fees: calculatedFees,
    interest,
    interest_and_fees,
    total_owed,
    payment_amt,
  }
}
