import React, {ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Money from 'src/assets/illustrations/Money.svg'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'

export type Props = {
  expirationDate: string
  onAcceptOffer: () => Promise<void>
  title: string
}

export const LoanProcessingApprovedTemplate = ({
  expirationDate,
  onAcceptOffer,
  title,
}: Props): ReactNode => {
  const {t} = useTranslation(['Onboarding', 'Common'])

  const [isBusy, setIsBusy] = useState(false)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_approved_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: t('Common:AcceptThisOffer'),
      testID: 'LoanProcessingApprovedTemplateAcceptOffer',
      onPress: async () => {
        setIsBusy(true)
        TrackAppEvent(AppEvents.Name.loan_approved_completed, AppEvents.Category.Activation)
        await onAcceptOffer()
        setIsBusy(false)
      },
      loading: isBusy,
      disabled: isBusy,
    },
  }

  return (
    <Page variant="generic" title={title} smallTopGap buttonProps={buttonProps}>
      <Box fill gap="medium">
        <PFText variant="p_lg">
          {t('OnboardingLoanProcessingApprovedBody', {
            expirationDate,
          })}
        </PFText>
        <Box padding="enormous">
          <PFSvgContain svg={Money} width="100%" />
        </Box>
      </Box>
    </Page>
  )
}
