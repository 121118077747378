import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import React from 'react'

import {AchPaymentMethod, LinkedAccount} from 'src/cassandra'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {DebitCardPaymentMethodLink} from 'src/products/card/PaymentMethods/PaymentMethodLink/DebitCardPaymentMethodLink'
import {PaymentMethodLinkBase} from 'src/products/card/PaymentMethods/PaymentMethodLink/PaymentMethodLinkBase'
import {PaymentAccount, PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {CardActionListItemProps} from 'src/products/card/components/molecules/CardActionListItem'

type PaymentMethodLinkProps = Partial<CardActionListItemProps> & {
  flow?: PaymentFlow
  paymentMethod: PaymentAccount
}

export const PaymentMethodLink = ({
  flow,
  paymentMethod,
  ...props
}: PaymentMethodLinkProps): JSX.Element | null => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const handleOnPress = (): void => {
    TrackAppEvent(CardEvents.card_payment_method_link_cta, AppEvents.Category.Card)
    navigation.navigate('CardViewPaymentMethods', {flow})
  }

  switch (paymentMethod?.__typename) {
    case 'LinkedAccount':
      return (
        <PaymentMethodLinkBase<LinkedAccount>
          paymentMethod={paymentMethod}
          onPress={handleOnPress}
          {...props}
        />
      )
    case 'AchPaymentMethod':
      return (
        <PaymentMethodLinkBase<AchPaymentMethod>
          paymentMethod={paymentMethod}
          onPress={handleOnPress}
          {...props}
        />
      )
    case 'DebitCardPaymentMethod':
      return (
        <DebitCardPaymentMethodLink
          paymentMethod={paymentMethod}
          onPress={handleOnPress}
          {...props}
        />
      )
    default:
      return null
  }
}
