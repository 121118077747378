import React from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonAction} from 'src/designSystem/types'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  ReschedulePaymentCalendar,
  ReschedulePaymentCalendarProps,
} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePaymentCalendar/ReschedulePaymentCalendar'
import {
  ReschedulePaymentAlertOverlay,
  ReschedulePaymentAlertOverlayProps,
} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePaymentAlertOverlay/ReschedulePaymentAlertOverlay'
import {
  ReschedulePaymentErrorOverlay,
  ReschedulePaymentErrorOverlayProps,
} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePaymentErrorOverlay/ReschedulePaymentErrorOverlay'

export type ReschedulePaymentTemplateProps = {
  alertOverlayProps: ReschedulePaymentAlertOverlayProps
  finalAllowedDateText: string // .format('MMM Do')
  hasPaymentBeenRescheduled: boolean
  isDisabled: boolean
  isLoading: boolean
  onContinue: NonNullable<ButtonAction>
  originalPaymentDateText: string // .format('MMM Do')
  paymentAmount: string
  rescheduledPaymentDateText?: string // .format('MMM Do')
} & ReschedulePaymentCalendarProps &
  ReschedulePaymentErrorOverlayProps

const ReschedulePaymentTemplate = (props: ReschedulePaymentTemplateProps): JSX.Element => {
  const {
    alertOverlayProps,
    finalAllowedDateText,
    hasPaymentBeenRescheduled,
    isDisabled,
    isLoading,
    onContinue,
    originalPaymentDateText,
    paymentAmount,
    rescheduledPaymentDateText,
    markedDates,
    maxDate,
    minDate,
    onDayPress: handleOnDayPress,
    selectedDate,
    showReschedulePaymentErrorOverlay,
    onContactUs: handleOnContactUs,
  } = props

  const {t} = useTranslation('Reschedule')

  const description = hasPaymentBeenRescheduled
    ? t('InitiallyScheduledAndRescheduled', {
        originalDate: originalPaymentDateText,
        rescheduledDate: rescheduledPaymentDateText,
      })
    : t('ChooseWhenYouWantToMakeYourPayment', {amount: paymentAmount})

  return (
    <Page
      title={t('RescheduleYourPayment')}
      barStyle={'light-content'}
      testID="ReschedulePaymentTemplate"
      variant="generic"
      smallTopGap
      buttonProps={{
        type: 'singleButton',
        primary: {
          testID: 'Save-Date-Button',
          text: t('SaveDate'),
          onPress: onContinue,
          loading: isLoading,
          disabled: isLoading || isDisabled,
        },
      }}
    >
      <Box marginBottom={'small'} gap={'small'}>
        <PFText variant={'p'}>{description}</PFText>
        <ReschedulePaymentCalendar
          markedDates={markedDates}
          maxDate={maxDate}
          minDate={minDate}
          onDayPress={handleOnDayPress}
          selectedDate={selectedDate}
        />
        <Alert
          title={t('ReschedulingNote')}
          description={
            <PFText variant={'p_sm'} testID="End-Payment-Date">
              {t('YourPaymentMustBeScheduledBefore')}
              <PFText variant={'p_sm_semibold'}>{finalAllowedDateText}</PFText>
            </PFText>
          }
          level={'info'}
        />
      </Box>
      <ReschedulePaymentAlertOverlay {...alertOverlayProps} />
      <ReschedulePaymentErrorOverlay
        showReschedulePaymentErrorOverlay={showReschedulePaymentErrorOverlay}
        onContactUs={handleOnContactUs}
      />
    </Page>
  )
}

export {ReschedulePaymentTemplate}
