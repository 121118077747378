import React, {useCallback} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {StyleSheet, View} from 'react-native'
import {useFocusEffect} from '@react-navigation/native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {WorkflowsReadyType} from 'src/workflows/types'
import {useProductHubRedirect} from 'src/products/general/ProductHub/useProductHubRedirect'
import {WorkflowDebugPanel} from 'src/products/general/Workflows/WorkflowDebugPanel'
import {WorkflowLoader} from 'src/products/general/Workflows/WorkflowLoader'
import AppEvents from 'src/lib/Analytics/app_events'
import {CardProductHub} from 'src/products/general/ProductHub/CardProductHub'
import {LoanProductHub} from 'src/products/general/ProductHub/LoanProductHub'
import {getCardOfferInfo, getLoanOfferInfo} from 'src/products/general/ProductHub/ProductHub.utils'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {useIsInCardsOnboarding} from 'src/products/general/ProductHub/UseIsInCardsOnboarding/useIsInCardsOnboarding'
import {useSelectDefaultOffer} from 'src/workflows/hooks/useSelectDefaultOffer'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {ProductHubProductsDocument} from 'src/products/general/ProductHub/ProductHub.gqls'
import {contentMaxWidth, mediumGap} from 'src/designSystem/layout'
import {ProductHubTileProvider} from 'src/products/general/ProductHub/ProductHubTileProvider'
import {navigateToDeeplinkIfNecessary} from 'src/lib/singular/utils'

type ProductHubProps = StackScreenProps<MainStackParamList, 'ProductHub'>
type ProductHubReadyProps = WorkflowsReadyType & ProductHubProps

export const ProductHubReady: React.FC<ProductHubReadyProps> = (props) => {
  const {
    navigation,
    route,
    selectedOffer,
    applications: allApplications,
    offers: allOffers,
    ineligibleOffers,
    refetch,
  } = props
  const {
    data,
    loading: isAccountStatusLoading,
    refetch: refetchAccountStatusQuery,
  } = useCassandraQuery(ProductHubProductsDocument)
  const latestCardAccountStatus = data?.me?.cardAccounts?.active?.status
  const latestLoanAggregateStatus = data?.me?.loans?.latestActionableLoan?.aggregateStatus
  const {loading: isLoadingSelectDefaultOffer} = useSelectDefaultOffer()
  const {isLoading: isLoadingProductHubRedirect, redirectRoute} = useProductHubRedirect({
    selectedOffer,
    latestCardAccountStatus,
    latestLoanAggregateStatus,
    isAccountStatusLoading,
    allApplications,
    allOffers,
    isSelectDefaultOfferLoading: isLoadingSelectDefaultOffer,
  })

  useFocusEffect(
    useCallback(() => {
      navigateToDeeplinkIfNecessary()
    }, []),
  )
  // More temporary code until we figure out what the Product Hub is supposed to do
  // While onboarding for a new product, don't display offers for other products
  const {isInCardsOnboarding, isLoading: isInOnboardingLoading} = useIsInCardsOnboarding()

  if (isLoadingSelectDefaultOffer || isLoadingProductHubRedirect || isInOnboardingLoading) {
    return <Spinner />
  }

  // If there is only 1 active offer, useProductHubRedirect will direct the user into OfferApplicationWorkflow
  // Otherwise display the active offers
  if (redirectRoute) {
    navigation.reset(redirectRoute)
    return
  }

  // If Application is pending, display the ApplicationProcessing screen only
  const handleRefetchAccountStatusQuery = async (): Promise<void> => {
    await refetchAccountStatusQuery()
    void (await refetch())
  }

  return (
    <ProductHubTileProvider>
      <View style={styles.containerView}>
        <CardProductHub
          cardAccountStatus={latestCardAccountStatus}
          navigation={navigation}
          route={route}
          cardOfferInfo={getCardOfferInfo(allOffers)}
          onUnderwritingComplete={handleRefetchAccountStatusQuery}
          ineligibleOffers={ineligibleOffers}
        />
        {!isInCardsOnboarding ? (
          <LoanProductHub
            loanOfferInfo={getLoanOfferInfo(allOffers)}
            onUnderwritingComplete={handleRefetchAccountStatusQuery}
            navigation={navigation}
          />
        ) : null}
      </View>
      <WorkflowDebugPanel title="Product Hub" />
    </ProductHubTileProvider>
  )
}

type OnReadyType = (workflowsData: WorkflowsReadyType) => JSX.Element
export const ProductHub: React.FC<ProductHubProps> = ({navigation, route}) => {
  const handleOnReady: OnReadyType = (workflowsData) => {
    return <ProductHubReady {...workflowsData} navigation={navigation} route={route} />
  }
  return (
    <WorkflowLoader
      errorEvent={AppEvents.Name.product_hub_workflow_error}
      errorContactUsEvent={AppEvents.Name.product_hub_workflow_error_contact_us_pressed}
      onReady={handleOnReady}
    />
  )
}

const styles = StyleSheet.create({
  containerView: {
    alignSelf: 'center',
    flex: 1,
    maxWidth: contentMaxWidth,
    width: '100%',
    gap: mediumGap,
  },
})
