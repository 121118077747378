import React from 'react'
import converter from 'number-to-words'
import {TFunction} from 'i18next'
import {StackNavigationProp} from '@react-navigation/stack'
import {Trans} from 'react-i18next'

import TexasLoanInfo from 'src/products/loans/LoanSelection/TexasLoanInfo'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import {
  CalcFeesInterestPayments,
  CalcFeesInterestSinglePaymentCalifornia,
  floridaFees,
  alaskaFees,
  PaymentInfoType,
} from 'src/products/loans/LoanSelection/FeeCalc'
import OhioLoanInfo from 'src/products/loans/LoanSelection/OhioLoanInfo'
import CaliforniaLoanInfo from 'src/products/loans/LoanSelection/CaliforniaLoanInfo'
import AlaskaLoanInfo from 'src/products/loans/LoanSelection/AlaskaLoanInfo'
import AlabamaLoanInfo from 'src/products/loans/LoanSelection/AlabamaLoanInfo'
import IowaLoanInfo from 'src/products/loans/LoanSelection/IowaLoanInfo'
import LoanInfoWithVerificationFee from 'src/products/loans/LoanSelection/LoanInfoWithVerificationFee'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {infoCapsuleText as infoCapsuleTextColor} from 'src/designSystem/semanticColors'
import LouisianaLoanInfo from 'src/products/loans/LoanSelection/LouisianaLoanInfo'
import Log from 'src/lib/loggingUtil'
import {lag} from '@possible/generated/proto'
import {
  LoanAmountSelectionContentStateLoanInfoCapsule,
  LoanAmountSelectionContentLoanTermsFields,
} from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanAmountSelectionContent.types'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {PFInfoCapsuleVariant} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import {WorkflowsStackParams} from 'src/workflows/types'

export type StateLoanInfoCapsulePropsType = {
  t: TFunction
  paymentInformation: PaymentInfoType
  loanState: string
  loanTerms: LoanAmountSelectionContentLoanTermsFields
  navigation: StackNavigationProp<WorkflowsStackParams, 'LoanAmountSelection'>
}

const isPrimaryTx = (loanTitle: string | undefined): boolean => loanTitle === 'TX_Primary'

/**
 * Get state-specific loan info for the state loan info UI capsule.
 */
export const geStateLoanInfoCapsule = (
  props: StateLoanInfoCapsulePropsType,
): LoanAmountSelectionContentStateLoanInfoCapsule => {
  const {t, paymentInformation, loanState, loanTerms, navigation} = props
  let variant: LoanAmountSelectionContentStateLoanInfoCapsule['variant']
  let text: LoanAmountSelectionContentStateLoanInfoCapsule['text']
  let element: LoanAmountSelectionContentStateLoanInfoCapsule['element']
  let onPress: LoanAmountSelectionContentStateLoanInfoCapsule['onPress']
  const paymentCount = (): number => {
    if (!loanTerms?.loanDurationWeeks || !loanTerms?.collectionWeekInterval) {
      Log.error('LoanSelector, paymentCount: loan terms are not valid')
      return 0
    }
    return loanTerms?.loanDurationWeeks / loanTerms?.collectionWeekInterval
  }

  const numberOfPayments = isStateCA(loanState) ? 4 : paymentCount()
  const amount = paymentInformation.payment_amt

  const infoCapsuleText = `${t('InfoText', {
    numPayments: converter.toWords(numberOfPayments),
    amount: amount.toFixed(2),
  })}`

  const MoreInfoContent = (
    <Box direction={'row'} gap={'tiny'} align={'center'}>
      <PFText variant={'p_sm'} color={infoCapsuleTextColor}>
        {infoCapsuleText}
      </PFText>
      <SvgIcon name={'arrowRight'} colorVariant={'inactive'} />
    </Box>
  )

  switch (loanState) {
    case 'TX':
      {
        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(
            <TexasLoanInfo paymentInfo={paymentInformation} navigation={navigation} />,
            true,
          )
        if (isPrimaryTx(loanTerms.title)) {
          variant = PFInfoCapsuleVariant.Element
          element = MoreInfoContent
          onPress = handleOnTapInfoCapsule
        } else {
          variant = PFInfoCapsuleVariant.Text
          text = infoCapsuleText
        }
      }
      break
    case 'OH':
      {
        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(
            <OhioLoanInfo paymentInfo={paymentInformation} navigation={navigation} />,
            true,
          )
        variant = PFInfoCapsuleVariant.Element
        element = MoreInfoContent
        onPress = handleOnTapInfoCapsule
      }
      break
    case 'FL':
      {
        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(
            <LoanInfoWithVerificationFee
              paymentInfo={paymentInformation}
              fee={floridaFees}
              navigation={navigation}
            />,
            true,
          )
        variant = PFInfoCapsuleVariant.Element
        element = MoreInfoContent
        onPress = handleOnTapInfoCapsule
      }
      break
    case 'CA':
      {
        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(<CaliforniaLoanInfo navigation={navigation} />, true)
        const paymentAmt = (amount / 4).toFixed(2)
        const numPayments = converter.toWords(numberOfPayments)
        const californiaInfoCapsuleElement = (
          <Trans i18nKey="CAInfoText">
            <Box direction={'row'} marginRight={1} flex>
              <PFText variant={'p_sm'} color={infoCapsuleTextColor}>
                You will have the free option to upgrade to {numPayments} payments of
                <PFText variant={'p_sm_semibold'} color={infoCapsuleTextColor}>
                  {' '}
                  ${paymentAmt}{' '}
                </PFText>
                (payments include estimated fees).
                <Box paddingLeft={'tiny'}>
                  <SvgIcon name={'arrowRight'} colorVariant={'inactive'} />
                </Box>
              </PFText>
            </Box>
          </Trans>
        )

        variant = PFInfoCapsuleVariant.Element
        element = californiaInfoCapsuleElement
        onPress = handleOnTapInfoCapsule
      }
      break
    case 'LA':
      {
        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(
            <LouisianaLoanInfo paymentInfo={paymentInformation} navigation={navigation} />,
            true,
          )
        variant = PFInfoCapsuleVariant.Element
        element = MoreInfoContent
        onPress = handleOnTapInfoCapsule
      }
      break
    case 'AK':
      {
        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(
            <AlaskaLoanInfo
              paymentInfo={paymentInformation}
              fee={alaskaFees}
              navigation={navigation}
            />,
            true,
          )
        variant = PFInfoCapsuleVariant.Element
        element = MoreInfoContent
        onPress = handleOnTapInfoCapsule
      }
      break
    case 'IA':
      {
        const modifiedPaymentInfo = paymentInformation
        modifiedPaymentInfo.fees /= 2

        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(
            <IowaLoanInfo paymentInfo={modifiedPaymentInfo} navigation={navigation} />,
            true,
          )
        variant = PFInfoCapsuleVariant.Element
        element = MoreInfoContent
        onPress = handleOnTapInfoCapsule
      }
      break
    case 'AL':
      {
        const handleOnTapInfoCapsule = (): void =>
          ShowLightbox(
            <AlabamaLoanInfo paymentInfo={paymentInformation} navigation={navigation} />,
            true,
          )
        variant = PFInfoCapsuleVariant.Element
        element = MoreInfoContent
        onPress = handleOnTapInfoCapsule
      }
      break
    default: {
      variant = PFInfoCapsuleVariant.Text
      text = infoCapsuleText
    }
  }

  return {
    variant,
    text,
    element,
    onPress,
  }
}

/**
 * Get the initial selected loan amount. This may come from loan terms.
 */
export const getInitialLoanAmount = (args: {
  loanTerms?: LoanAmountSelectionContentLoanTermsFields
  showPrequalAmount?: boolean
  prequalAmount?: number
  selectedLoanAmount: number
}): number | undefined => {
  const {loanTerms, showPrequalAmount, prequalAmount, selectedLoanAmount} = args
  const defaultAmount = showPrequalAmount ? prequalAmount : loanTerms?.defaultAmount
  let amt: number | undefined
  if (selectedLoanAmount) {
    amt = selectedLoanAmount
  }
  if (!amt) {
    amt = defaultAmount
  }
  return amt
}

/**
 * Given a loan amount, apply the loan terms min/max amounts to ensure it's within the valid range.
 */
export const applyLoanTermsMinMaxToAmount = (
  amt: number,
  loanTerms?: lag.ILoanTerms,
  maximumAmount?: number,
): number => {
  if (loanTerms?.minimumAmount) {
    if (amt < loanTerms?.minimumAmount) {
      amt = loanTerms?.minimumAmount
    } else if (maximumAmount && amt > maximumAmount) {
      amt = maximumAmount
    }
  }

  return amt
}

const isStateCA = (state): boolean => state === 'CA'

/**
 * Get info about payment amount and fees given the selected loan amount
 * and loan terms.
 */
export const getPaymentInfoFeesAndTotal = (
  loanTerms: lag.ILoanTerms,
  selectedLoanAmount: number,
  loanUsStateAbv: string,
): PaymentInfoType =>
  isStateCA(loanUsStateAbv)
    ? CalcFeesInterestSinglePaymentCalifornia(loanTerms, selectedLoanAmount)
    : CalcFeesInterestPayments(loanTerms, selectedLoanAmount, loanUsStateAbv)

// This constant should never be used, but is needed here to force the getMaxLoanAmountFromLoanTerms to return a number
// and never undefined, which then stops us from having to allow undefined values all the way down to the
// 3rd party slider that we use in SelectAmountSlider
const defaultMaxLoanAmount = 100

export const getMaxLoanAmountFromLoanTerms = (
  repeatLoan: boolean,
  loanTerms?: lag.ILoanTerms,
): number => {
  const maxAmount: number | undefined =
    repeatLoan && loanTerms?.maximumAmountRepeatLoan
      ? loanTerms?.maximumAmountRepeatLoan
      : loanTerms?.maximumAmount
  return maxAmount ?? defaultMaxLoanAmount
}

export const getLoanDurationWeeks = (args: {
  loanTermsDurationWeeks: number
  loanUsStateAbv: string
}): number => {
  const {loanTermsDurationWeeks, loanUsStateAbv} = args
  return loanTermsDurationWeeks || (isStateCA(loanUsStateAbv) ? 2 : 8)
}
