import React, {FC} from 'react'
import {StyleSheet} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import LoanLength from 'src/products/loans/SelectAmount/SelectAmountTile/LoanLength'
import SelectAmountTileHeader from 'src/products/loans/SelectAmount/SelectAmountTile/SelectAmountTileHeader'
import {SelectAmountSlider} from 'src/products/loans/SelectAmount/SelectAmountTile/SelectAmountSlider'

type SelectAmountTileProps = {
  nextAmount: number
  onSlidingComplete: () => void
  onValueChange: (loanAmount: number) => void
  step: number | undefined
  loanDuration: number
  minimumAmount: number
  maximumAmount: number
  prequalificationAmount?: number
  requestLimit?: number
}

const SelectAmountTile: FC<SelectAmountTileProps> = (props) => {
  const {
    nextAmount,
    onSlidingComplete,
    onValueChange,
    step,
    minimumAmount,
    prequalificationAmount,
    requestLimit,
    loanDuration,
  } = props

  let {maximumAmount} = props

  // TODO:  [ENG-5683] The screen goes blank if max and min are exactly the same upon sliding
  // design will come up with a better solution. marking this spot
  // also check the onValueChange below as it is flooring the selection.
  const bufferAmount = 0.0001

  if (maximumAmount && maximumAmount === minimumAmount) {
    maximumAmount = maximumAmount + bufferAmount
  }

  return (
    <BasicTile styles={styles.tile}>
      <Box gap={'medium'} paddingTop={'medium'} paddingBottom={'large'}>
        <SelectAmountTileHeader
          nextAmount={nextAmount}
          prequalificationAmount={prequalificationAmount}
          requestLimit={requestLimit}
        />
        <Box paddingHorizontal={'small'}>
          <SelectAmountSlider
            nextAmount={nextAmount}
            onSlidingComplete={(): void => onSlidingComplete()}
            onValueChange={(value): void => onValueChange(Math.floor(value))}
            step={step}
            minimumAmount={minimumAmount}
            maximumAmount={maximumAmount}
            prequalificationAmount={prequalificationAmount}
          />
        </Box>
      </Box>
      <LoanLength loanDuration={loanDuration} />
    </BasicTile>
  )
}

export {SelectAmountTile}

const styles = StyleSheet.create({
  tile: {
    overflow: 'visible',
  },
})
