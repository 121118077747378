import {LoanPaymentMethod} from '@possible/cassandra/src/types/types.mobile.generated'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {
  AcceptAgreementsContinueResult,
  AgreementType,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreements.types'
import {
  AcceptAgreementsSetLoanDisbursementMethodDocument,
  AcceptAgreementsUpdateLoanActivationAgreementsDocument,
} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useSaveAcceptAgreementsResult/useSaveAcceptAgreementsResult.gqls'

export type UseSaveAcceptedAgreementsResultReturn = {
  onSaveAcceptAgreementsResult: (args: {
    loanId: string
    agreementsResult: AcceptAgreementsContinueResult
  }) => Promise<void>
  isSubmittingResult: boolean
}
/**
 * Hook to save the the results of a user accepting agreements on any of the AcceptAgreements screen.
 * If the user has selected a loan disbursement method via the AutoPay agreement that will also be updated.
 */
export const useSaveAcceptAgreementsResult = (): UseSaveAcceptedAgreementsResultReturn => {
  const [submitAcceptAgreementsForLoan, {loading: isSubmittingAcceptAgreements}] =
    useCassandraMutation(AcceptAgreementsUpdateLoanActivationAgreementsDocument)
  const [submitSetDisbursementMethodForLoan, {loading: isSubmittingSetDisbursementMethod}] =
    useCassandraMutation(AcceptAgreementsSetLoanDisbursementMethodDocument)

  /**
   * Record which agreements a user accepted given result in the AcceptAgreementsContinueResult.
   */
  const onSaveAcceptAgreementsResult = async ({
    loanId,
    agreementsResult,
  }: {
    loanId: string
    agreementsResult: AcceptAgreementsContinueResult
  }): Promise<void> => {
    // track selected events after user pressed the continue button but before any API is called
    if (agreementsResult.didAcceptAutoPayAgreement === true) {
      TrackAppEvent(
        AppEvents.Name.accept_agreements_accept_ach_selected,
        AppEvents.Category.Activation,
      )
    } else if (agreementsResult.didAcceptAutoPayAgreement === false) {
      TrackAppEvent(
        AppEvents.Name.accept_agreements_not_accept_ach_selected,
        AppEvents.Category.Activation,
      )
    }
    if (agreementsResult.didAcceptLoanAgreement === true) {
      TrackAppEvent(
        AppEvents.Name.accept_agreements_accept_loan_agreement_selected,
        AppEvents.Category.Activation,
      )
    }
    // If user selected check as payment method we need to update the loan disbursement method
    const loanDisbursementMethod: LoanPaymentMethod | undefined =
      agreementsResult.setDisbursementMethodToCheck ? LoanPaymentMethod.Check : undefined

    const submitPromises: [
      ReturnType<typeof submitAcceptAgreementsForLoan>,
      ReturnType<typeof submitSetDisbursementMethodForLoan> | undefined,
    ] = [
      submitAcceptAgreementsForLoan({
        variables: {
          loanId,
          acceptAutoPayAgreement: agreementsResult.didAcceptAutoPayAgreement,
          acceptLoanAgreement: agreementsResult.didAcceptLoanAgreement,
          acceptArbitrationAgreement: agreementsResult.stateSpecificAgreements?.find(
            (agreement) => agreement.agreementType === AgreementType.ArbitrationAgreement,
          )?.didUserAccept,
          acceptCreditServiceAgreement: agreementsResult.stateSpecificAgreements?.find(
            (agreement) => agreement.agreementType === AgreementType.CreditServicesAgreement,
          )?.didUserAccept,
          acceptCreditServiceDisclosure: agreementsResult.stateSpecificAgreements?.find(
            (agreement) =>
              agreement.agreementType === AgreementType.CreditServicesDisclosureStatement,
          )?.didUserAccept,
          acceptStatutoryNotice: agreementsResult.stateSpecificAgreements?.find(
            (agreement) => agreement.agreementType === AgreementType.StatutoryNotice,
          )?.didUserAccept,
          acceptInstallmentPlanDisclosure: agreementsResult.stateSpecificAgreements?.find(
            (agreement) =>
              agreement.agreementType === AgreementType.AcceptInstallmentPlanDisclosure,
          )?.didUserAccept,
        },
      }),
      loanDisbursementMethod
        ? submitSetDisbursementMethodForLoan({
            variables: {
              loanId,
              disbursementMethod: LoanPaymentMethod.Check,
            },
          })
        : undefined,
    ]
    try {
      const res = await Promise.all(submitPromises)
      if (res[0].errors || res[1]?.errors) {
        throw res[0].errors?.[0] ?? res[1]?.errors?.[0]
      }
      if (
        !res[0].data?.loanActivationUpdateRequirements.success ||
        (loanDisbursementMethod && !res[1]?.data?.loanSetDisbursementMethod.success)
      ) {
        throw new Error('Failed to update loan activation requirements')
      }
      const completedEventArgs = {
        ...agreementsResult,
        stateSpecificAgreements: agreementsResult.stateSpecificAgreements?.map(
          (agreementsResult) => {
            return {
              id: agreementsResult.id,
              agreementType: agreementsResult.agreementType,
              documentTypeToDownload: agreementsResult.documentTypeToDownload,
              didUserAccept: agreementsResult.didUserAccept,
            }
          },
        ),
      }
      // track completed event once it's actually completed
      TrackAppEvent(
        AppEvents.Name.accept_agreements_completed,
        AppEvents.Category.Activation,
        completedEventArgs,
      )
    } catch (e) {
      logOfferActivationErrorAndShowException(
        e,
        'useSaveAcceptAgreementsResult onSaveAcceptAgreementsResult() failed to submit',
      )
      throw e
    }
  }
  return {
    onSaveAcceptAgreementsResult,
    isSubmittingResult: isSubmittingAcceptAgreements || isSubmittingSetDisbursementMethod,
  }
}
