import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {ReschedulePaymentAlertOverlayContentProps} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePayment.utils'

export type ReschedulePaymentAlertOverlayProps = {
  isVisible: boolean
  reschedulePaymentAlertContentProps: ReschedulePaymentAlertOverlayContentProps
  onConfirmSelectedRescheduleDate: () => void
  onConfirmDeclineRescheduleDate: () => void
}

const ReschedulePaymentAlertOverlay: FC<ReschedulePaymentAlertOverlayProps> = (props) => {
  const {
    isVisible,
    reschedulePaymentAlertContentProps,
    onConfirmSelectedRescheduleDate,
    onConfirmDeclineRescheduleDate,
  } = props
  const {t} = useTranslation(['Reschedule', 'Common'])

  const okayButtonText =
    reschedulePaymentAlertContentProps?.primaryButtonText || t('Common:Continue')

  const handleOnConfirmSelectedRescheduleDate = (): void => {
    TrackAppEvent(
      AppEvents.Name.reschedule_alert_continue_selected,
      AppEvents.Category.ManageActiveLoan,
    )
    onConfirmSelectedRescheduleDate()
  }

  const handleOnDismiss = (): void => {
    TrackAppEvent(
      AppEvents.Name.reschedule_alert_nevermind_selected,
      AppEvents.Category.ManageActiveLoan,
    )
    onConfirmDeclineRescheduleDate()
  }

  return (
    <OverlaySimple
      visible={isVisible}
      title={reschedulePaymentAlertContentProps?.title}
      testID={'Reschedule-Payment-Alert-Overlay'}
      okayButtonText={okayButtonText}
      onOkay={handleOnConfirmSelectedRescheduleDate}
      dismissButtonText={reschedulePaymentAlertContentProps?.secondaryButtonText}
      onDismiss={handleOnDismiss}
      text={reschedulePaymentAlertContentProps?.text}
    />
  )
}

export {ReschedulePaymentAlertOverlay}
