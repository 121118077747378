import React, {FC, ReactNode, useCallback, useEffect} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {View} from 'react-native'

import {
  LinkedAccountOwnerType,
  LinkedAccountOwnershipValidationType,
} from '@possible/cassandra/src/types/types.mobile.generated'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  accountsNeedsReview,
  typeOfValidationError,
} from 'src/products/MCU/AccountManagementV2/AccountManagement.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {LinkedAccountOwnershipSummarySubset} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {openContactUsForm} from 'src/lib/contactUs'

type OwnershipStatusInfoModalProps = {
  visible: boolean
  onOkay: () => void
  ownershipSummary?: LinkedAccountOwnershipSummarySubset
  onAddNewAccount?: () => void
  testID?: string
}

const OwnershipStatusInfoModal: FC<OwnershipStatusInfoModalProps> = (props) => {
  const {
    visible: isVisible,
    onOkay: handleOnOkay,
    ownershipSummary,
    onAddNewAccount,
    testID,
  } = props
  const navigation: StackNavigationProp<MainStackParamList> = useNavigation()
  const {t} = useTranslation(['AccountOwnership', 'Common'])
  const contactUsButton = t('ContactUs')
  const contactUsLink = t('Common:ContactUsLowercase')

  const hasNameValidationError = typeOfValidationError(
    ownershipSummary?.validationErrors,
    LinkedAccountOwnershipValidationType.Name,
  )

  const hasStateValidationError = typeOfValidationError(
    ownershipSummary?.validationErrors,
    LinkedAccountOwnershipValidationType.State,
  )

  const doesAccountNeedReview = accountsNeedsReview(ownershipSummary?.validationErrors)

  const isBusinessAccount =
    ownershipSummary?.ownerType === LinkedAccountOwnerType.Association ||
    ownershipSummary?.ownerType === LinkedAccountOwnerType.Trust

  const isJointAccount = ownershipSummary?.ownerType === LinkedAccountOwnerType.Joint

  const ownershipAmplitudeEvent = useCallback((): void => {
    sendAnalyticEvent(ampli.bankOwnershipFail.bind(ampli))

    if (hasNameValidationError && doesAccountNeedReview) {
      sendAnalyticEvent(ampli.bankOwnershipNameChallenge.bind(ampli))
    } else if (hasNameValidationError && !hasStateValidationError) {
      sendAnalyticEvent(ampli.bankOwnershipNameFail.bind(ampli))
    } else if (isBusinessAccount) {
      sendAnalyticEvent(ampli.bankOwnershipNonPersonalAccount.bind(ampli))
    } else if (hasStateValidationError && !hasNameValidationError) {
      sendAnalyticEvent(ampli.bankOwnershipAddressFail.bind(ampli))
    } else if (hasNameValidationError || hasStateValidationError) {
      sendAnalyticEvent(ampli.bankOwnershipNameAndAddressFail.bind(ampli))
    } else if (isJointAccount) {
      sendAnalyticEvent(ampli.bankOwnershipJointAccount.bind(ampli))
    }
  }, [
    doesAccountNeedReview,
    hasNameValidationError,
    hasStateValidationError,
    isBusinessAccount,
    isJointAccount,
  ])

  useEffect(() => {
    ownershipAmplitudeEvent()
  }, [ownershipAmplitudeEvent])

  const title = (): string => {
    if (hasNameValidationError && doesAccountNeedReview) {
      return t('YourAccountIsUnderReview')
    } else if (hasStateValidationError || hasNameValidationError) {
      return t('WeNeedToVerifySomeInformation')
    } else if (isBusinessAccount) {
      return t('PleaseLinkANewAccount')
    } else if (isJointAccount) {
      return t('NeedToVerifyJointAccount')
    } else {
      return t('YourAccountIsUnderReview')
    }
  }

  const onContactUs = (navigation: StackNavigationProp<MainStackParamList>): void => {
    sendAnalyticEvent(ampli.bankOwnershipContactUsClicked.bind(ampli))
    handleOnOkay()
    openContactUsForm(navigation)
  }

  const getModalContentForError = (): ReactNode => {
    if (hasNameValidationError && doesAccountNeedReview) {
      return null
    } else if (hasStateValidationError && !hasNameValidationError) {
      return (
        <Box marginBottom={'small'}>
          <PFText variant={'p'}>{t('NeedToVerifyAddress')}</PFText>
        </Box>
      )
    } else if (hasNameValidationError && !hasStateValidationError) {
      return (
        <>
          <Box marginBottom={'small'}>
            <PFText variant={'p'}>{t('NeedToVerifyNameOrLinkNewAccount')}</PFText>
          </Box>
          <Box>
            <PFText variant={'p'}>
              {t('FeelFreeTo')}
              <SvgLink
                onPress={(): void => onContactUs(navigation)}
                linkText={contactUsLink}
                linkType={'inline'}
              />
              {t('ForMoreAssistance')}
            </PFText>
          </Box>
        </>
      )
    } else if (hasNameValidationError && hasStateValidationError) {
      return (
        <Box marginBottom={'small'}>
          <PFText variant={'p'}>{t('NeedToVerifyNameAndAddress')}</PFText>
        </Box>
      )
    } else if (isBusinessAccount) {
      return (
        <>
          <Box marginBottom={'small'}>
            <PFText variant={'p'}>{t('PossibleOnlySupportsPersonallAccounts')}</PFText>
          </Box>
          <Box>
            <PFText variant={'p'}>
              {t('FeelFreeTo')}
              <SvgLink
                onPress={(): void => onContactUs(navigation)}
                linkText={contactUsLink}
                linkType={'inline'}
              />
              {t('ForMoreAssistance')}
            </PFText>
          </Box>
        </>
      )
    } else if (isJointAccount) {
      return (
        <Box marginBottom={'small'}>
          <PFText variant={'p'}>{t('PossibleSupportsSharedBankAccountsButMustVerify')}</PFText>
        </Box>
      )
    }
  }

  const getOkButtonText = (): string => {
    if (hasNameValidationError && doesAccountNeedReview) {
      return contactUsButton
    } else if (isBusinessAccount || (hasNameValidationError && !hasStateValidationError)) {
      return t('LinkANewAccount')
    } else if (hasStateValidationError && !hasNameValidationError) {
      return contactUsButton
    } else if (hasNameValidationError && hasStateValidationError) {
      return contactUsButton
    } else {
      return contactUsButton
    }
  }

  const handleGetOkButtonAction = (): void => {
    if (
      isBusinessAccount ||
      (hasNameValidationError && !hasStateValidationError && !doesAccountNeedReview)
    ) {
      if (onAddNewAccount) {
        onAddNewAccount()
        handleOnOkay()
      }
    } else {
      return onContactUs(navigation)
    }
  }

  return (
    <OverlaySimple
      visible={isVisible}
      title={title()}
      titleSize="h2"
      titleAlign="left"
      okayButtonText={getOkButtonText()}
      okayButtonSize="large"
      hideOnOkay={true}
      onOkay={handleGetOkButtonAction}
      padding="medium"
      testID={testID ?? 'Ownership-Status-Info-Modal'}
      onDismiss={handleOnOkay}
      dismissButtonText={t('Common:OkThanks')}
    >
      <View>{getModalContentForError()}</View>
    </OverlaySimple>
  )
}

export {OwnershipStatusInfoModal}
