import React, {FC} from 'react'
import {Pressable} from 'react-native'
import {SvgProps} from 'react-native-svg'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {
  disbursementMethodSelectedColor,
  unselectedCTABackground,
  disbursementBorderColor,
} from 'src/designSystem/semanticColors'
import {NamedColors} from 'src/designSystem/colors'

type DisbursementCTAProps = {
  title: string
  description: string
  illustration?: React.FC<SvgProps>
  feeDescription: string
  onPress: () => void
  isHighlighted: boolean
  isSelected: boolean
}

/**
 * Displays a disbursement method option CTA for the user to select.
 */
const LoanDisbursementMethodOptionCTA: FC<DisbursementCTAProps> = (props) => {
  const {
    title,
    description,
    illustration,
    feeDescription,
    onPress: handleOnPress,
    isSelected,
    isHighlighted,
  } = props

  const getCTABackgroundColor = (): NamedColors => {
    if (isHighlighted || isSelected) {
      return disbursementMethodSelectedColor
    } else {
      return unselectedCTABackground
    }
  }

  return (
    <Pressable
      onPress={handleOnPress}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        flex: 1,
        margin: isSelected ? 4 : 10,
        borderWidth: isSelected ? 6 : 0,
        borderColor: disbursementBorderColor,
        borderRadius: 5,
        paddingRight: '2.5%',
        paddingLeft: '2.5%',
        paddingVertical: 24,
        backgroundColor: getCTABackgroundColor(),
      }}
    >
      <PFText variant={'p_lg_semibold'} color={'white'} textAlign={'center'}>
        {title}
      </PFText>
      <Box marginTop="small" />
      <PFText variant={'p'} color={'white'} textAlign={'center'}>
        {description}
      </PFText>
      {illustration ? (
        <Box alignSelf="center" marginTop="small">
          <PFSvgContain height={32} svg={illustration} />
        </Box>
      ) : null}
      <Box
        // eslint-disable-next-line react-native/no-inline-styles
        boxStyle={{flex: 1}}
      />
      <Box marginTop="small" />
      <PFText
        variant={'p_lg'}
        color={isSelected || isHighlighted ? 'success' : 'white'}
        textAlign={'center'}
      >
        {feeDescription}
      </PFText>
    </Pressable>
  )
}

export {LoanDisbursementMethodOptionCTA}
