import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  CardAgreementType,
  InstallmentPayment,
} from '@possible/cassandra/src/types/types.mobile.generated'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import PFDescriptor from 'src/designSystem/components/atoms/PFDescriptor/PFDescriptor'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {CardAcceptAgreement} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptAgreement'
import {PaymentMethodLink} from 'src/products/card/PaymentMethods/PaymentMethodLink/PaymentMethodLink'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {formatDate, friendlyDateWithDOW, utcDate} from 'src/lib/utils/date'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {ButtonAction} from 'src/designSystem/types'

type CardPayOverTimeDetailsTemplateProps = {
  cardBalance: string
  ctaText: string
  isLoading?: boolean
  onPressPrimary: ButtonAction
  // We're accessing depricated fields because the backend currently returns null for the new ones.
  paymentSchedule: Pick<InstallmentPayment, 'amount' | 'executeAt' | 'id'>[]
  // Need a future refactor to address the need of these props */
  defaultPaymentMethod: Parameters<typeof PaymentMethodLink>[0]['paymentMethod']
  isAutopayEnabled: boolean
}

const CardPayOverTimeDetailsTemplate = ({
  cardBalance,
  ctaText,
  defaultPaymentMethod,
  isAutopayEnabled,
  isLoading,
  onPressPrimary,
  paymentSchedule,
}: CardPayOverTimeDetailsTemplateProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_over_time_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardPayOverTimeDetails', 'CardPaymentAgreement'])

  const [isAuthorized, setAuthorized] = useState(false)
  const [isDebitAuthorizationAccepted, setIsDebitAuthorizationAccepted] = useState(false)

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: ctaText,
          disabled:
            isLoading || !isAuthorized || (!isAutopayEnabled && !isDebitAuthorizationAccepted),
          onPress: onPressPrimary,
          loading: isLoading,
        },
      }}
      variant={'generic'}
      title={t('PlanDetails')}
      testID={'CardPayOverTimeDetailsTemplate'}
      smallTopGap
    >
      <Box gap={'medium'} marginBottom={'small'}>
        <Box align={'center'}>
          <PFText testID={'CardPayOverTimeDetailsTemplate-Card-Balance'} variant={'d2'}>
            {cardBalance}
          </PFText>
          <PFText
            variant={'p'}
            color={'textDisabled'}
            textProps={{style: {textTransform: 'uppercase'}}}
          >
            {t('CardBalance')}
          </PFText>
        </Box>
        <Box direction={'row'} gap={'little'}>
          <PFDescriptor name={'charged-fees'} size={'enormous'} />
          <PFText variant={'p_sm'} textProps={{style: {flex: 1}}}>
            {t('YourMembershipFee')}
          </PFText>
        </Box>
        <BasicTile padding={'small'}>
          <Box gap={'small'}>
            <PFText variant={'label_md'}>{t('PaymentMethod')}</PFText>
            <PaymentMethodLink paymentMethod={defaultPaymentMethod} />
            <PFText variant={'label_md'}>Schedule</PFText>
            <Box gap={'small'}>
              {paymentSchedule?.map((item) => {
                return (
                  <Box key={item.executeAt} direction={'row'}>
                    <PFText variant={'p'} textProps={{style: {flex: 1}}}>
                      {formatDate(item.executeAt, friendlyDateWithDOW, utcDate)}
                    </PFText>
                    <PFText variant={'p_semibold'}>{convertToDollarAmt(item.amount)}</PFText>
                  </Box>
                )
              })}
            </Box>
            <PFText variant={'p_sm'}>{t('IncludesYourMembershipFee')}</PFText>
          </Box>
        </BasicTile>
        <PFCheckbox
          checked={isAuthorized}
          testID={'CardPayOverTimeDetailsTemplate-Plan-Details-Checkbox'}
          onPress={(checked): void => setAuthorized(checked)}
        >
          {t('IAuthorize')}
        </PFCheckbox>
        {!isAutopayEnabled ? (
          <CardAcceptAgreement
            checked={isDebitAuthorizationAccepted}
            setChecked={setIsDebitAuthorizationAccepted}
            cardAgreementType={CardAgreementType.DebitAuthorizationAgreement}
            agreementName={t('CardPaymentAgreement:DebitAuthorizationAgreement')}
          />
        ) : null}
      </Box>
    </Page>
  )
}

export {CardPayOverTimeDetailsTemplate}
