import React from 'react'
import {useTranslation} from 'react-i18next'
import {CompositeScreenProps} from '@react-navigation/native'
import type {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {CardAdhocPaymentStack} from 'src/products/card/AdhocPayment/CardAdhocPaymentStack'
import {useCardAdhocPayment} from 'src/products/card/AdhocPayment/useCardAdhocPayment/useCardAdhocPayment'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import CalendarCash from 'src/assets/illustrations/CalendarCash.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {formatDate, humanReadableDateDayMonth2} from 'src/lib/utils/date'
import {NamedColors} from 'src/designSystem/colors'

type CardAdhocPaymentSuccessProps = CompositeScreenProps<
  StackScreenProps<CardAdhocPaymentStack, 'CardAdhocPaymentSuccess'>,
  StackScreenProps<MainStackParamList>
>

const CardAdhocPaymentSuccess = ({navigation}: CardAdhocPaymentSuccessProps): JSX.Element => {
  const {paymentState} = useCardAdhocPayment()
  usePageViewedAnalytics({
    eventName: CardEvents.card_adhoc_payment_success_screen_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardAdhocPaymentSuccess', 'Common'])

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:OK'),
          onPress: () => navigation.navigate('CardDashboard'),
        },
      }}
      variant={'generic'}
      title={t('YourPaymentWasSuccessful')}
      testID="Payment-Successful-Page-Id"
      smallTopGap
    >
      <>
        <Box align={'center'} paddingVertical={'enormous'}>
          <PFSvgContain height={141} svg={CalendarCash} />
        </Box>
        <PFText
          textAlign={'center'}
          variant={'d2'}
          testID="Payment-Amount-Id"
        >{`${convertToDollarAmt(paymentState.paymentSpecification.amount)}`}</PFText>
        <Box align={'center'} gap={'tiny'} paddingTop={'small'}>
          <PFText variant={'p_lg'} color={NamedColors.SILVER}>
            {t('WillBePaidOn')}
          </PFText>
          <PFText variant={'h3'} testID="Payment-Date-Id">
            {formatDate(paymentState.paymentSpecification.executeDate, humanReadableDateDayMonth2)}
          </PFText>
        </Box>
      </>
    </Page>
  )
}

export {CardAdhocPaymentSuccess}
