import React, {FC, ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {RadioButtonList} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonList'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {DidNotAcceptAgreementModal} from 'src/products/MCU/AccountManagementV2/Modals/DidNotAcceptAgreementModal'
import HeaderSpacer from 'src/designSystem/components/atoms/HeaderSpacer/HeaderSpacer'

enum OptionIds {
  Accept = 'accept',
  NoAccept = 'no_accept',
}

type Props = {
  accountMask: string
  onAccept: () => void | Promise<void>
  onRefuse: () => void
  onACHAgreementPressed: () => Promise<void>
  isLoadingOnContinue: boolean
}

export const AcceptAgreementsV2Template: FC<Props> = (props) => {
  const {
    accountMask,
    onAccept: handleOnAccept,
    onRefuse: handleOnRefuse,
    onACHAgreementPressed: handleACHAgreementPressed,
    isLoadingOnContinue,
  } = props

  const {t} = useTranslation(['LoanApproved', 'AccountManagement', 'Common'])
  const [showDidNotAcceptAgreementModal, setShowDidNotAcceptAgreementModal] =
    useState<boolean>(false)

  const [selectedOption, setSelectedOption] = useState<OptionIds>()

  const description: ReactElement = (
    <PFText variant="p">
      {t('LoanCommonText1')} <PFText variant="p_semibold">{t('LoanCommonText2')}</PFText>
    </PFText>
  )

  const acceptText = (
    <PFText variant="p" testID="AcceptAgreements-Accept-Option-Text">
      {t('AccountManagement:IAuthorizePossible', {mask: accountMask})}
      <SvgLink
        linkType="inline"
        onPress={handleACHAgreementPressed}
        linkText={t('ElectronicPaymentAuthorization')}
      />
      {t('AccountManagement:IsValidAndWillRemain')}
    </PFText>
  )

  const options = [
    {
      id: OptionIds.Accept,
      text: acceptText,
    },
    {
      id: OptionIds.NoAccept,
      text: <PFText variant="p">{t('IDoNotAccept')}</PFText>,
    },
  ]

  const handleOnDidNotAcceptAgreementClose = (result: 'try_again' | 'ok_thanks'): void => {
    if (result === 'try_again') {
      setSelectedOption(OptionIds.NoAccept)
      setShowDidNotAcceptAgreementModal(false)
    } else if (result === 'ok_thanks') {
      handleOnRefuse()
      setShowDidNotAcceptAgreementModal(false)
    }
  }

  const isOptionId = (test: string): test is OptionIds => {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    return Object.values(OptionIds).indexOf(test as OptionIds) !== -1
  }

  const handleOnSelect = (optionId: string): void => {
    setShowDidNotAcceptAgreementModal(false)
    if (isOptionId(optionId) && optionId === OptionIds.Accept) {
      setSelectedOption(OptionIds.Accept)
      TrackAppEvent(
        AppEvents.Name.accept_agreements_accept_ach_selected,
        AppEvents.Category.Activation,
      )
    }
    if (isOptionId(optionId) && optionId === OptionIds.NoAccept) {
      setSelectedOption(OptionIds.NoAccept)
      setShowDidNotAcceptAgreementModal(true)
    }
  }

  return (
    <>
      <Page
        variant="generic"
        title={
          <>
            {/*
            the default page header spacer doesnt look right when this page is embedded in a ShowLightbox modal
            for URAs so we add it manually here instead and set noHeaderSpacer={true}. see ENG-17521
          */}
            <HeaderSpacer />
            <PFText>{t('AcceptAgreements')}</PFText>
          </>
        }
        description={description}
        buttonProps={{
          type: 'singleButton',
          primary: {
            text: t('Common:AgreeAndContinue'),
            onPress: handleOnAccept,
            disabled: selectedOption !== OptionIds.Accept || isLoadingOnContinue,
            loading: isLoadingOnContinue,
            testID: 'accept_agreements_button',
          },
        }}
        smallTopGap
        noHeaderSpacer
      >
        <Box gap="large" marginTop="small">
          <RadioButtonList
            options={options}
            onPress={handleOnSelect}
            selectedOption={selectedOption?.toString() || ''}
          />
        </Box>
      </Page>
      <DidNotAcceptAgreementModal
        visible={showDidNotAcceptAgreementModal}
        onCTA={handleOnDidNotAcceptAgreementClose}
      />
    </>
  )
}
