import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import LoanSummaryRow from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanSummary/LoanSummaryRow'
import {loanSummaryLegalTextColor} from 'src/designSystem/semanticColors'

type LoanSummaryProps = {
  amountBorrowed: number
  totalFees: number
  totalAmount: number
}

const LoanSummary: FC<LoanSummaryProps> = (props) => {
  const {amountBorrowed, totalFees, totalAmount} = props
  const {t} = useTranslation('LoanSelection')

  const sectionBoxProps: BoxProps = {
    paddingHorizontal: 'little',
    gap: 'tiny',
  }

  return (
    <Box paddingVertical={'little'}>
      <Box {...sectionBoxProps}>
        <LoanSummaryRow label={t('AmountBorrowed')} value={`$${amountBorrowed.toFixed(2)}`} />
        <LoanSummaryRow label={t('EstimatedFees')} value={`+$${totalFees.toFixed(2)}`} />
      </Box>
      <Box paddingBottom={'small'} paddingHorizontal={'little'}>
        <PFText variant={'p_sm'} color={loanSummaryLegalTextColor}>
          {t('FinalFeeStatement')}
        </PFText>
      </Box>
      <Separator />
      <Box {...sectionBoxProps} paddingTop={'little'}>
        <LoanSummaryRow
          label={t('PaybackAmount')}
          value={`$${totalAmount.toFixed(2)}`}
          bold={true}
        />
      </Box>
    </Box>
  )
}

export default LoanSummary
