import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

export type ReschedulePaymentErrorOverlayProps = {
  showReschedulePaymentErrorOverlay: boolean
  onContactUs: () => void
}

const ReschedulePaymentErrorOverlay: FC<ReschedulePaymentErrorOverlayProps> = (props) => {
  const {showReschedulePaymentErrorOverlay, onContactUs: handleOnContactUs} = props
  const {t} = useTranslation(['Reschedule', 'Common'])

  return (
    <OverlaySimple
      testID={'Reschedule-Payment-Error-Overlay'}
      visible={showReschedulePaymentErrorOverlay}
      okayButtonText={t('Common:Continue')}
      title={t('OopsWeAreUnableToSave')}
      content={
        <Box gap={'small'} marginBottom={'little'}>
          <PFText variant={'p'}>{t('HmmWeAreHavingTroubleSaving')}</PFText>
          <PFText variant={'p'}>
            <Trans i18nKey="ForAssistance" t={t}>
              Please{' '}
              <SvgLink
                testID={'Contact-Us-Link'}
                onPress={handleOnContactUs}
                linkType={'inline'}
                linkText={t('Common:ContactUsLowercase')}
              />{' '}
              for further assistance!
            </Trans>
          </PFText>
        </Box>
      }
    />
  )
}

export {ReschedulePaymentErrorOverlay}
