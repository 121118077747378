import {MeQuery} from '../schemas/consumer'
import {
  AwardsProcessReferralLinkDocument,
  CardAccountActivateInstallmentPlanDocument,
  CardAccountAgreementsDownloadDocument,
  CardAccountEnableAutomaticPaymentsDocument,
  CompleteUraDocument,
  ExpireUraDocument,
  GetUserNextAvailablePaymentDateDocument,
  LoanDocumentsDownloadDocument,
  PendingUserRequestedActionsDocument,
  Query,
  SetAppPushNotificationTokenDocument,
  User,
} from '../types/consumer'
import {
  BindingType,
  CardStaticAgreementType,
  FundingDirection,
  GetLoanFundingStatusDocument,
  GetStaticCardAgreementsDocument,
  MeActiveCardIdDocument,
  LoanDocumentType,
  LoanGetDocumentDocument,
  GetUserEnvDocument,
  PartnerLinkUserWithOfferDocument,
  PartnerStoreProductAttributionDocument,
  ProcessorNetwork,
  UserDocumentType,
  GetUserDocumentsCollectionDocument,
  Scalars,
  LoanPrequalification,
  PrequalificationStatusDocument,
  UserDocumentGenerateUploadUrlDocument,
  UserDocumentCreateDocument,
  CreateUserDocumentInput,
} from '../types/types.mobile.generated'
import {ApplyMutation, ApplyQuery} from '../utils/operations'
import {_applyQuery} from '../utils'
import {DonkeyUiBranchesInput, GetDonkeyUiBranchesDocument} from '../types/types.public.generated'

export const GetMe = async (): Promise<User> => {
  return (await _applyQuery<Pick<Query, 'me' | '__typename'>>(MeQuery)).me
}

export const GetPendingUserRequestedActions = async () => {
  return (await ApplyQuery(PendingUserRequestedActionsDocument)).data.me.userRequestedActions
    .pending
}

export const partnerLinkUserWithOffer = async (offerId: string) => {
  return (await ApplyMutation(PartnerLinkUserWithOfferDocument, {offerId}))
    ?.partnerLinkUserWithOffer
}

export const partnerStoreProductAttribution = async (offerId: string, entityId: string) => {
  return (await ApplyMutation(PartnerStoreProductAttributionDocument, {offerId, entityId}))
    ?.partnerStoreProductAttribution
}

export type ApplyForCardAccountParam = {
  cardOfferId?: string
}

export const cardAccountAgreementsDownload = (
  cardAccountId: string,
  cardAccountAgreementId: string[],
) => {
  return ApplyMutation(CardAccountAgreementsDownloadDocument, {
    cardAccountId,
    cardAccountAgreementId,
  })
}

export const completeUra = (uraId: string) => {
  return ApplyMutation(CompleteUraDocument, {
    uraId,
  })
}

export const expireUra = (uraId: string) => {
  return ApplyMutation(ExpireUraDocument, {
    uraId,
  })
}

export const getUserEnvMethod = async () => {
  return ApplyMutation(GetUserEnvDocument)
}

export const getLoanFundingStatus = async (loanId: string) => {
  return ApplyMutation(GetLoanFundingStatusDocument, {loanId})
}

export const getStaticCardAgreements = async (agreementTypes: CardStaticAgreementType[]) => {
  return ApplyMutation(GetStaticCardAgreementsDocument, {agreementTypes})
}

export const processReferral = async (referringUserId: string, referralCode: string) => {
  return ApplyMutation(AwardsProcessReferralLinkDocument, {referringUserId, referralCode})
}

export const cardAccountEnableAutomaticPayments = (
  cardAccountId: string,
  cardPaymentMethodId: string,
) => {
  return ApplyMutation(CardAccountEnableAutomaticPaymentsDocument, {
    cardAccountId,
    cardPaymentMethodId,
  })
}

export const setAppPushNotificationToken = (token: string, bindingType: BindingType) => {
  return ApplyMutation(SetAppPushNotificationTokenDocument, {
    token,
    bindingType,
  })
}

export const getUserNextAvailablePaymentDate = (
  network: ProcessorNetwork,
  direction: FundingDirection,
  desiredSettlementTime: any,
) => {
  return ApplyMutation(GetUserNextAvailablePaymentDateDocument, {
    network,
    direction,
    desiredSettlementTime,
  })
}

export const cardAccountActivateInstallmentPlan = (
  cardAccountId: string,
  cardAccountInstallmentPlanId: string,
) => {
  return ApplyMutation(CardAccountActivateInstallmentPlanDocument, {
    cardAccountId,
    cardAccountInstallmentPlanId,
  })
}

export const hasActiveCard = async (): Promise<boolean> => {
  const data = await ApplyMutation(MeActiveCardIdDocument)
  return (
    !!data?.me?.cardAccounts?.active?.id &&
    data?.me?.cardAccounts?.active?.status?.__typename === 'ActiveCardAccountStatus'
  )
}

export const getLoanDocument = (loanId: string, type: LoanDocumentType) => {
  return ApplyMutation(LoanGetDocumentDocument, {
    loanId,
    type,
  })
}

export const loanDocumentsDownload = async (loanId: string) => {
  return (
    await ApplyMutation(LoanDocumentsDownloadDocument, {
      loanId,
    })
  )?.loanDocumentsDownload?.documents
}

export const getUserDocumentColletion = async (types: UserDocumentType) => {
  return (await ApplyQuery(GetUserDocumentsCollectionDocument, {types: types})).data.me.documents
}

export const getUserPrequalificationStatus = async (
  date: Scalars['DateTime']['input'],
): Promise<LoanPrequalification> => {
  return (await ApplyQuery(PrequalificationStatusDocument, {assessmentDate: date})).data
    .loanGetPrequalification
}

export const getUserDocumentGenerateUploadUrl = async (filename: string) => {
  return (await ApplyMutation(UserDocumentGenerateUploadUrlDocument, {filename}))
    ?.userDocumentGenerateUploadUrl
}

export const getUserDocumentWithPresignedUrl = async (input: CreateUserDocumentInput) => {
  return (await ApplyMutation(UserDocumentCreateDocument, {input}))?.userDocumentCreate
}

export const getDonkeyUiBranches = async (input: DonkeyUiBranchesInput) => {
  return (await ApplyQuery(GetDonkeyUiBranchesDocument, {input}, true)).data?.getDonkeyUiBranches
}
