import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Linking} from 'react-native'

import {CardAccountStatusCode} from 'src/cassandra'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {
  FaqQuestion,
  PayOverTimeFAQQuestionTwo,
} from 'src/products/card/PayOverTime/PayOverTimeFAQ/PayOverTimeFAQ'
import {useCards} from 'src/products/card/hooks/useCards'
import {useIsBalancePaidOff} from 'src/products/card/hooks/useIsBalancePaidOff'

type Props = StackScreenProps<MainStackParamList, 'CardHowDelinquencyAffectMe'>

const CardHowDelinquencyAffectMe: FC<Props> = (props) => {
  const {navigation} = props
  const {activeCardStatus} = useCards()
  const cardDeactivated = activeCardStatus?.code === CardAccountStatusCode.Deactivated
  const {t} = useTranslation('CardHowDelinquencyAffectMe')
  const isBalancePaidOff = useIsBalancePaidOff()

  usePageViewedAnalytics({
    eventName: CardEvents.card_delinquency_explained_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  return (
    <Page
      buttonProps={{
        type: 'doubleButton',
        primary: {
          text: t('Make a Payment'),
          disabled: isBalancePaidOff,
          onPress: (): void => {
            navigation.navigate('CardAdhocPayment', {screen: 'CardAdhocPaymentAmountEntry'})
          },
          testID: 'primaryButton',
        },
        secondary: {
          buttonText: t('BackToDashboard'),
          onPress: (): void => {
            navigation.navigate('CardDashboard')
          },
        },
      }}
      variant={'generic'}
      title={t('HowWillDelinquencyAffectMe')}
      testID="Delinquency-Info-Page-Id"
      smallTopGap={true}
    >
      <Box gap={'small'} marginBottom={'small'}>
        {!cardDeactivated ? (
          <FaqQuestion title={t('TitleOne')}>
            <PFText variant="p">{t('TextOne')}</PFText>
          </FaqQuestion>
        ) : null}
        <PayOverTimeFAQQuestionTwo />
        <FaqQuestion key="title3" title={t('TitleThree')}>
          <PFText variant="p">
            <Trans i18nKey={'TextThree'} t={t}>
              {
                'Pay off your balance as soon as you can — because the longer your account is delinquent, the worse impact it will have on your credit.\n\nDepending on your situation, you may be able to make a smaller payment first. Contact us at '
              }
              <SvgLink
                linkType={'inline'}
                onPress={(): void => void Linking.openURL('tel:18559615354')}
                linkText={t('SupportPhone')}
              />
              {' or '}
              <SvgLink
                linkType={'inline'}
                onPress={(): void => void Linking.openURL('mailto:help@possiblefinance.com')}
                linkText={t('SupportMail')}
              />
              {' to find out more.'}
            </Trans>
          </PFText>
        </FaqQuestion>
      </Box>
    </Page>
  )
}

export default CardHowDelinquencyAffectMe
