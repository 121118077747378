import React, {FC} from 'react'

import {PaymentReviewTilaAndLoanAgreementCAGQLContainer} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCAGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents from 'src/lib/Analytics/app_events'

export type PaymentReviewTilaAndLoanAgreementCAWorkflowContainerProps =
  WorkflowPreReqFulfillScreenProps

const PaymentReviewTilaAndLoanAgreementCAWorkflowContainer: FC<
  PaymentReviewTilaAndLoanAgreementCAWorkflowContainerProps
> = (props) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.review_payment_schedule_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })
  usePageViewedAnalytics({
    eventName: AppEvents.Name.tila_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })
  usePageViewedAnalytics({
    eventName: AppEvents.Name.final_loan_acceptance_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled?.()
  }

  return <PaymentReviewTilaAndLoanAgreementCAGQLContainer onContinue={handleOnContinue} />
}

export {PaymentReviewTilaAndLoanAgreementCAWorkflowContainer}
