import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import {SelectAmountTile} from 'src/products/loans/SelectAmount/SelectAmountTile/SelectAmountTile'

type SelectAmountProps = {
  nextAmount: number
  onSlidingComplete: () => void
  onValueChange: (loanAmount: number) => void
  step: number | undefined
  minimumAmount: number
  maximumAmount: number
  loanDuration: number
  prequalificationAmount?: number
  lowerBlock?: JSX.Element
  requestLimit?: number
  testID?: string
}

const SelectAmount: FC<SelectAmountProps> = (props) => {
  const {
    nextAmount,
    onSlidingComplete,
    onValueChange,
    step,
    minimumAmount,
    maximumAmount,
    prequalificationAmount,
    lowerBlock,
    requestLimit,
    loanDuration,
    testID = 'select-amount',
  } = props

  const {t} = useTranslation('LoanSelection')

  const showPrequalInfo =
    prequalificationAmount && prequalificationAmount > 0 && nextAmount > prequalificationAmount

  return (
    <Box gap={'large'} testID={testID}>
      <SelectAmountTile
        nextAmount={nextAmount}
        onSlidingComplete={(): void => onSlidingComplete()}
        onValueChange={(value): void => onValueChange(value)}
        step={step}
        minimumAmount={minimumAmount}
        maximumAmount={maximumAmount}
        prequalificationAmount={prequalificationAmount}
        requestLimit={requestLimit}
        loanDuration={loanDuration}
      />
      {showPrequalInfo ? (
        <Alert
          level="info"
          title={t('PreQualNoteTitle')}
          description={t('PreQualNoteDescription')}
        />
      ) : null}
      {lowerBlock}
    </Box>
  )
}

export {SelectAmount}
