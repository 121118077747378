import {ApolloProvider} from '@apollo/client'
import React, {FC} from 'react'

import {useCassandraClient} from '@possible/cassandra'

export const UseCassandra: FC<React.PropsWithChildren> = (props) => {
  const {children} = props

  const [apolloClient] = useCassandraClient()

  if (!apolloClient) {
    return <>{children}</>
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
