import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {SegmentedButton} from 'src/designSystem/components/atoms/SegmentedButton/SegmentedButton'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {PayOverTimeHeaderTile} from 'src/products/card/PayOverTime/CardPayOverTimeSchedule/CardPayOverTimeScheduleTemplate/PayOverTimeHeaderTile/PayOverTimeHeaderTile'
import {PayOverTimeInstallmentTile} from 'src/products/card/PayOverTime/CardPayOverTimeSchedule/CardPayOverTimeScheduleTemplate/PayOverTimeInstallmentTile/PayOverTimeInstallmentTile'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {PendingPaymentHelpOverlay} from 'src/products/card/Dashboard/CardDashboardHeader/CardDashboardBalanceLedger/PendingPaymentHelpOverlay'
import {CardEventProperties} from 'src/lib/Analytics/eventProperties'
import {ButtonAction} from 'src/designSystem/types'
import {CardPayOverTimeScheduleQueryQuery} from 'src/products/card/PayOverTime/CardPayOverTimeSchedule/CardPayOverTimeScheduleQuery.gqls'

type CardPayOverTimeScheduleTemplateProps = {
  installments: NonNullable<
    NonNullable<
      CardPayOverTimeScheduleQueryQuery['me']['cardAccounts']['active']
    >['installmentPlans']['current']
  >['installments']
  onPressPrimary: ButtonAction
  pendingAmount: string
  remainingAmount: string
  totalDue: string
  totalPaid: string
  totalOverdueAmount: string
}

const CardPayOverTimeScheduleTemplate = ({
  installments,
  onPressPrimary: handleOnPressPrimary,
  pendingAmount,
  remainingAmount,
  totalDue,
  totalOverdueAmount,
  totalPaid,
}: CardPayOverTimeScheduleTemplateProps): JSX.Element => {
  const {t} = useTranslation('CardPayOverTimeScheduleTemplate')
  const [activeTab, setActiveTab] = useState<'remaining' | 'paid'>('remaining')
  const [isPendingPaymentOverlayVisible, setIsPendingPaymentOverlayVisible] = React.useState(false)

  const numberOfInstallments = installments?.length ?? 0
  const installmentsPaid = installments?.filter(({paidOff}) => paidOff) ?? []
  const numberOfInstallmentsPaid = installmentsPaid.length
  const installmentsRemaining = installments?.filter(({paidOff}) => !paidOff) ?? []
  const numberOfInstallmentsRemaining = installmentsRemaining.length

  const handleOnPressPendingPayment = (trackingLocation: string) => (): void => {
    setIsPendingPaymentOverlayVisible((prevState) => !prevState)
    TrackAppEvent(CardEvents.card_pending_transaction_help_clicked, AppEvents.Category.Card, {
      location: trackingLocation,
    })
  }

  const handleOnDismiss = (): void => {
    setIsPendingPaymentOverlayVisible((prevState) => !prevState)
  }

  const handleOnPressSegmentedButtonRemainingInstallments = (): void => {
    setActiveTab('remaining')
    TrackAppEvent(CardEvents.card_pot_detail_page_installments_filtered, AppEvents.Category.Card, {
      filteredBy:
        CardEventProperties.card_pot_detail_page_installments_filters.installments_remaining,
    })
  }

  const handleOnPressSegmentedButtonPaidInstallments = (): void => {
    setActiveTab('paid')
    TrackAppEvent(CardEvents.card_pot_detail_page_installments_filtered, AppEvents.Category.Card, {
      filteredBy: CardEventProperties.card_pot_detail_page_installments_filters.installments_paid,
    })
  }

  return (
    <>
      <Page
        buttonProps={{
          type: 'singleButton',
          primary: {
            text: t('MakePayment'),
            onPress: handleOnPressPrimary,
          },
        }}
        variant={'generic'}
        title={t('PayOverTimeDetails')}
        smallTopGap={true}
      >
        <PayOverTimeHeaderTile
          pendingAmount={pendingAmount}
          remainingAmount={remainingAmount}
          totalDue={totalDue}
          totalOverdueAmount={totalOverdueAmount}
          totalPaid={totalPaid}
          onPressPendingPayment={handleOnPressPendingPayment(
            CardEventProperties.card_pending_payment_help_locations.card_pot_detail_header,
          )}
        />
        <Box justify="center" align="center" gap={'small'} padding={'medium'}>
          <PFText variant="p">
            {numberOfInstallmentsRemaining === numberOfInstallments
              ? t('InstallmentsRemaining', {count: numberOfInstallmentsRemaining})
              : t('InstallmentsPaid', {
                  count: numberOfInstallmentsPaid,
                  total: numberOfInstallments,
                })}
          </PFText>

          {numberOfInstallmentsRemaining <= 3 ? (
            <Box width={'85%'}>
              <SegmentedButton
                buttons={[
                  {
                    isActive: activeTab === 'remaining',
                    onPress: handleOnPressSegmentedButtonRemainingInstallments,
                    title: t('Remaining'),
                  },
                  {
                    isActive: activeTab === 'paid',
                    onPress: handleOnPressSegmentedButtonPaidInstallments,
                    title: t('Paid'),
                  },
                ]}
              />
            </Box>
          ) : null}
        </Box>
        <Box gap={'medium'} paddingBottom={'medium'}>
          {(activeTab === 'paid' ? installmentsPaid : installmentsRemaining).map(
            ({id, ...installment}) => (
              <PayOverTimeInstallmentTile
                key={id || ''}
                {...installment}
                onPressPendingPayment={handleOnPressPendingPayment(
                  CardEventProperties.card_pending_payment_help_locations
                    .card_pot_detail_page_installment,
                )}
              />
            ),
          )}
        </Box>
      </Page>
      <PendingPaymentHelpOverlay
        isVisible={isPendingPaymentOverlayVisible}
        onDismiss={handleOnDismiss}
      >
        <Box paddingTop={'small'}>
          <PFText variant={'p_lg'}>{t('POTPendingPaymentHelpOverlayText')}</PFText>
        </Box>
      </PendingPaymentHelpOverlay>
    </>
  )
}

export {CardPayOverTimeScheduleTemplate}
