import React, {ReactElement} from 'react'

import {AcceptLoanAndStateAgreementsGQLContainer} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreensBase/AcceptLoanAndStateAgreementsBase/AcceptLoanAndStateAgreementsGQLContainer'
import {AcceptLoanAndStateAgreementsHITemplate} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/AcceptAgreementsScreens/AcceptLoanAndStateAgreementsHI/AcceptLoanAndStateAgreementsHITemplate'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import AppEvents from 'src/lib/Analytics/app_events'

export type AcceptLoanAndStateAgreementsHIWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to use the AcceptLoanAndStateAgreementsHI screen within Workflows.
 */
const AcceptLoanAndStateAgreementsHIWorkflowContainer: React.FC<
  AcceptLoanAndStateAgreementsHIWorkflowContainerProps
> = (props: AcceptLoanAndStateAgreementsHIWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props
  const handleOnAcceptAndContinue = async (): Promise<void> => {
    await onPreReqFulfilled()
  }
  usePageViewedAnalytics({
    eventName: AppEvents.Name.accept_agreements_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })
  return (
    <AcceptLoanAndStateAgreementsGQLContainer
      testID="AcceptLoanAndStateAgreementsHIWorkflowContainer"
      onAcceptAndContinue={handleOnAcceptAndContinue}
      render={(innerProps): ReactElement => (
        <AcceptLoanAndStateAgreementsHITemplate {...innerProps} />
      )}
    />
  )
}

export {AcceptLoanAndStateAgreementsHIWorkflowContainer}
