import React from 'react'
import {DeepNonNullable} from 'utility-types'

import {StateCodes} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  useCassandraLazyQuery,
  useCassandraMutation,
  useCassandraQuery,
} from '@possible/cassandra/src/utils/hooks'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  LoanAmountSelectionSetLoanAmountNewDocument,
  LoanAmountSelectionNewDocument,
  LoanAmountSelectionNewQuery,
} from 'src/products/loans/LoanAmountSelectionNew/LoanAmountSelectionNew.gqls'
import {
  LoanAmountSelectionTermsDocument,
  LoanAmountSelectionTermsQuery,
} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelection.gqls'
import {
  logOfferApplicationError,
  logOfferApplicationErrorAndShowException,
} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {LoanSelectionCompleted, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {
  LoanAmountSelectionTemplate,
  LoanAmountSelectionTemplateProps,
} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionTemplate'
import {LoanAmountSelectionBaseProps} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelection.types'
import {OpenWebPage} from 'src/navigation/NavHelper'
import {possibleStateLicensesURI} from 'src/navigation/WebLinks'

const isUndefined = (val: unknown): boolean => typeof val === 'undefined'

const LoanTermsRequiredTypeGuard = (
  loanTerms:
    | NonNullable<LoanAmountSelectionTermsQuery['getPrimaryLoanTypeByState']>['terms']
    | undefined,
): loanTerms is DeepNonNullable<LoanAmountSelectionTermsQuery>['getPrimaryLoanTypeByState']['terms'] => {
  return !(
    isUndefined(loanTerms?.rate) ||
    isUndefined(loanTerms?.loanDurationWeeks) ||
    isUndefined(loanTerms?.collectionWeekInterval) ||
    isUndefined(loanTerms?.estimatedApr) ||
    isUndefined(loanTerms?.maximumAmountRepeatLoan) ||
    isUndefined(loanTerms?.maximumAmount) ||
    isUndefined(loanTerms?.minimumAmount) ||
    isUndefined(loanTerms?.defaultAmount) ||
    isUndefined(loanTerms?.step) ||
    isUndefined(loanTerms?.title) ||
    isUndefined(loanTerms?.lenderApr)
  )
}

function isStateCode(value?: string | null): value is StateCodes {
  if (!value) {
    return false
  }
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return Object.values(StateCodes).includes(value as StateCodes)
}

function stringToStateCode(value?: string | null): StateCodes | undefined {
  const upperCaseValue = value?.toUpperCase()
  if (isStateCode(upperCaseValue)) {
    return upperCaseValue
  }
  return undefined
}

const selectUserState = (data: LoanAmountSelectionNewQuery): StateCodes | undefined => {
  return stringToStateCode(
    // Must check profile value first, and use || instead of ?? since the state could be an empty string
    data.me.profile?.home?.address?.state || data.me.onboarding?.loan?.stateSelected,
  )
}

export type LoanAmountSelectionGQLContainerProps = Pick<
  LoanAmountSelectionTemplateProps,
  'navigation'
> &
  LoanAmountSelectionBaseProps

const LoanAmountSelectionGQLContainer: React.FC<LoanAmountSelectionGQLContainerProps> = (props) => {
  const {navigation, onComplete} = props

  const [hasStateCodeError, setHasStateCodeError] = React.useState<boolean>(false)
  // Gets today's date just once on component mount
  const assessmentDate = React.useRef(new Date().toISOString()).current
  const {
    selectedData: queryData,
    loading: isQueryLoading,
    error: queryError,
  } = useCassandraQuery(
    LoanAmountSelectionNewDocument,
    {
      variables: {assessmentDate},
      fetchPolicy: 'network-only',
      onError: (e) => {
        logOfferApplicationError(
          e,
          'LoanAmountSelectionGQLContainer: LoanAmountSelectionNew query failed',
        )
      },
      onCompleted: (data) => {
        const stateCode = selectUserState(data)
        if (!stateCode) {
          logOfferApplicationError(
            new Error('LoanAmountSelectionGQLContainer: stateCode not found'),
          )
          setHasStateCodeError(true)
          return
        }
        setHasStateCodeError(false)
        void getTerms({
          variables: {
            state: stateCode,
          },
        })
      },
    },
    (data) => {
      const prequalifiedAmount = data.loanGetPrequalification.amount.split('.')[0]
      return {
        userStateCode: selectUserState(data),
        prequalifiedAmount: prequalifiedAmount === '0' ? undefined : prequalifiedAmount,
        isRepeatLoan: data.me.loans.all && data.me.loans.all.length > 0,
      }
    },
  )

  const [getTerms, {selectedData: termsData, loading: isLoadingTerms, error: termsError}] =
    useCassandraLazyQuery(
      LoanAmountSelectionTermsDocument,
      {
        fetchPolicy: 'cache-first',
        onCompleted: (data) => {
          const terms = data.getPrimaryLoanTypeByState?.terms
          if (!terms) {
            logOfferApplicationError(new Error('LoanAmountSelectionGQLContainer: terms not found'))
          }
        },
      },
      (data) => {
        const terms = data.getPrimaryLoanTypeByState?.terms
        if (!LoanTermsRequiredTypeGuard(terms)) {
          return undefined
        }
        return {
          collectionWeekInterval: terms.collectionWeekInterval,
          defaultAmount: Number(terms.defaultAmount.split('.')[0]),
          estimatedApr: Number(terms.estimatedApr),
          lenderApr: Number(terms.lenderApr),
          loanDurationWeeks: terms.loanDurationWeeks,
          maximumAmount: Number(terms.maximumAmount.split('.')[0]),
          maximumAmountRepeatLoan: Number(terms.maximumAmountRepeatLoan),
          minimumAmount: Number(terms.minimumAmount.split('.')[0]),
          rate: Number(terms.rate),
          step: terms.step,
          title: terms.title,
        }
      },
    )

  const [submitLoanAmountMutation] = useCassandraMutation(
    LoanAmountSelectionSetLoanAmountNewDocument,
    {
      onError: (error) => {
        logOfferApplicationErrorAndShowException(
          error,
          'LoanAmountSelectionContainer: Error submitting loan amount.',
        )
      },
    },
  )

  const handleOnViewStateFeeSchedules = (): void => {
    OpenWebPage({
      uri: possibleStateLicensesURI(),
    })
  }

  /**
   * After the user selects amount and presses the "Continue" button we save it to
   * `me.onboarding.loans.amountSelected` and move on with `onComplete()`.
   */
  const handleOnContinueAfterAmountSelected = async (args: {
    selectedLoanAmount: number
  }): Promise<void> => {
    try {
      const {selectedLoanAmount} = args
      TrackAppEvent(AppEvents.Name.loan_amount_selected, AppEvents.Category.Application, {
        value: selectedLoanAmount,
        loanTermsMinAmount: termsData?.minimumAmount,
        loanTermsMaxAmount: termsData?.maximumAmount,
      })

      LoanSelectionCompleted()

      const loanAmountSubmissionResponse = await submitLoanAmountMutation({
        variables: {amount: selectedLoanAmount.toString()},
      })

      if (loanAmountSubmissionResponse?.errors?.length) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw loanAmountSubmissionResponse.errors[0] // this is a carry over from the original implemented.
      }

      await onComplete()
    } catch (e) {
      logOfferApplicationError(e, 'LoanAmountSelection onContinueAfterAmountSelected error')
      throw e
    }
  }

  return (
    <BaseTemplate
      testID="LoanAmountSelectionGQLContainer"
      isLoading={isQueryLoading || isLoadingTerms || !queryData?.userStateCode || !termsData}
      isError={!!queryError || hasStateCodeError || !!termsError}
    >
      <LoanAmountSelectionTemplate
        navigation={navigation}
        loanUsStateAbv={queryData?.userStateCode ?? ''} // default value will never be used because of `isLoading` above.
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        loanTerms={termsData as NonNullable<typeof termsData>} // `termsData` will never `undefined` because of `isLoading` above.
        prequalificationAmount={
          queryData?.prequalifiedAmount ? parseInt(queryData.prequalifiedAmount) : undefined
        }
        isRepeatLoan={!!queryData?.isRepeatLoan}
        onViewStateFeeSchedules={handleOnViewStateFeeSchedules}
        onContinueAfterAmountSelected={handleOnContinueAfterAmountSelected}
      />
    </BaseTemplate>
  )
}

export {LoanAmountSelectionGQLContainer}
