import React, {FC} from 'react'

import {Consumer} from 'src/cassandra'
import Analytics from 'src/lib/Analytics'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {CardAgreementViewerTemplate} from 'src/products/card/Application/CardAgreementViewer/CardAgreementViewerTemplate/CardAgreementViewerTemplate'
import {useEnsureIsNewCardAccount} from 'src/products/card/Application/useEnsureIsNewCardAccount'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType/useGetCohortUserType'
import {useGetSelectedCardOfferId} from 'src/products/card/Application/useGetSelectedCardOfferId'
import {
  logOfferApplicationError,
  logOfferApplicationErrorAndShowException,
} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {useUpdateCardUserType} from 'src/products/loans/Landing/useUpdateCardUserType'
import {useUserProperty} from 'src/api/lib/UserProperties/useUserProperty'
import {useIsInCardBureauFlow} from 'src/products/card/Application/useIsInCardBureauFlow'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {CardAccountIdQueryDocument} from 'src/products/card/Common/CardAccountIdQuery.gqls'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

type CardAgreementViewerGQLContainerProps = {
  onComplete: (() => void | Promise<void>) | undefined
}
export const CardAgreementViewerGQLContainer: FC<CardAgreementViewerGQLContainerProps> = ({
  onComplete,
}) => {
  const [applyForCard, {loading: isSubmitting, called: wasCalled, error}] =
    Consumer.hooks.useCardAccountSubmitApplicationMutation({
      onError: (e) => {
        logOfferApplicationError(e, 'CardAgreementViewerGQLContainer query failed')
      },
    })

  const cardOfferId = useGetSelectedCardOfferId()
  const cohortUserType = useGetCohortUserType()
  const {isLoading: isLoading, updateCardUserType} = useUpdateCardUserType()
  const {value: cardUserProperties} = useUserProperty('cardApplicationGroup')
  const {selectedData: cardAccountId} = useCassandraQuery(
    CardAccountIdQueryDocument,
    {},
    (data) => data?.me?.cardAccounts?.active?.id,
  )

  usePageViewedAnalytics({
    eventName: CardEvents.accept_cardholder_agreement_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {setPreviousAccount, clearPreviousAccount} = useEnsureIsNewCardAccount()

  const {isInCardBureauFlow, isLoading: isLoadingInCardBureauFlow} = useIsInCardBureauFlow()

  const handleOnAgree = async (): Promise<void> => {
    try {
      TrackAppEvent(CardEvents.card_application_submitted, AppEvents.Category.Card, undefined, true)
      Analytics.CardApplicationSubmitted()

      if (!cardOfferId) {
        throw new Error('Card offer ID is not available')
      }

      // Ensure user is updated with the correct userType
      await updateCardUserType()

      // Submit the application
      const {errors} = await applyForCard({
        variables: {
          cardOfferId,
          miscData: {
            cohortPhase: cardUserProperties?.cohortPhase,
            cohortUserType,
          },
        },
      })

      if (errors?.length) {
        throw new Error(errors[0]?.message)
      }

      setPreviousAccount(cardAccountId)
      await onComplete?.()
    } catch (e) {
      clearPreviousAccount()
      logOfferApplicationErrorAndShowException(e, 'card application submit error')
    }
  }

  return (
    <BaseTemplate isLoading={isLoadingInCardBureauFlow}>
      <CardAgreementViewerTemplate
        onPress={handleOnAgree}
        isSubmitting={isSubmitting || isLoading}
        called={wasCalled && !error}
        isInCardBureauFlow={isInCardBureauFlow}
      />
    </BaseTemplate>
  )
}
