import {createSelector} from 'reselect'

import {
  AchPaymentMethod,
  Address,
  Card,
  CardAccount,
  CardAccountOfferInfo,
  CardPaymentMethodType,
  CardStatusCode,
  CardType,
  DebitCardPaymentMethod,
  LinkedAccount,
  LinkedAccountStatusCode,
  Maybe,
  User,
  UserProfile,
} from '@possible/cassandra/src/types/consumer'

import {PfReduxState} from 'src/reducers/types'

export const getUserProfile = (state: PfReduxState): Maybe<UserProfile> =>
  state.cassandra.user.me?.profile ?? null

export const getUserFullName = createSelector(getUserProfile, (userProfile: Maybe<UserProfile>) =>
  [
    userProfile?.name?.firstName,
    userProfile?.name?.middleName,
    userProfile?.name?.lastName,
    userProfile?.name?.suffix,
  ]
    .filter((i) => !!i)
    .join(' '),
)

export const getUserFullHomeAddress = (state: PfReduxState): Maybe<Address> =>
  state.cassandra.user.me?.profile?.home?.address ?? null

export const getUserFullMailingAddress = (state: PfReduxState): Maybe<Address> =>
  state.cassandra.user.me?.profile?.mailing?.address ?? null

export const getUserSSNMask = (state: PfReduxState): Maybe<string> =>
  state.cassandra.user.me?.identification?.ssn?.mask ?? null

export const getCardAccountOfferInfo = (state: PfReduxState): Maybe<CardAccountOfferInfo> => {
  return state.cassandra?.user?.me?.cardAccounts?.offerInfo ?? null
}

export const getActiveAccount = (state: PfReduxState): Maybe<CardAccount> =>
  state.cassandra.user.me?.cardAccounts?.active ?? null

export const getActiveAccountId = createSelector(
  getActiveAccount,
  (activeAccount: Maybe<CardAccount>): Maybe<string> => activeAccount?.id ?? null,
)

export const getUser = (state: PfReduxState): Maybe<User> => state.cassandra.user.me ?? null

const createGetCardSelector = (cardType: CardType, cardStatus?: CardStatusCode) => {
  return createSelector<PfReduxState, Maybe<CardAccount>, Maybe<Card>>(
    getActiveAccount,
    (activeAccount) => {
      const cards = activeAccount?.cards?.issued ?? []
      const cardFound = cards.find(
        (card) => (!cardStatus || card.status?.code === cardStatus) && card?.type === cardType,
      )
      return cardFound ?? null
    },
  )
}

export const getPendingPhysicalCard = createGetCardSelector(
  CardType.PhysicalCard,
  CardStatusCode.Pending,
)

/**
 * Return the physical card if it is active, otherwise return virtual card
 */
export const getUsableVirtualOrPhysicalCard = createSelector(
  createGetCardSelector(CardType.PhysicalCard, CardStatusCode.Active),
  createGetCardSelector(CardType.PhysicalVirtualCard),
  (activePhysicalCard?: Maybe<Card>, virtualCard?: Maybe<Card>) => {
    return activePhysicalCard || virtualCard
  },
)

const getPaymentMethodTypeFactory = (paymentMethodType: string) => {
  return createSelector<PfReduxState, Maybe<CardAccount>, CardPaymentMethodType[]>(
    getActiveAccount,
    (activeAccount) => {
      const allAccounts = activeAccount?.paymentMethods?.all ?? []
      return allAccounts?.filter((account) => account?.__typename === paymentMethodType)
    },
  )
}

export const getAchPaymentMethods = getPaymentMethodTypeFactory('AchPaymentMethod') as (
  state: PfReduxState,
) => AchPaymentMethod[]

export const getDebitPaymentMethods = getPaymentMethodTypeFactory('DebitCardPaymentMethod') as (
  state: PfReduxState,
) => DebitCardPaymentMethod[]

const linkedAccountStatuses: (LinkedAccountStatusCode | undefined | null)[] = [
  LinkedAccountStatusCode.LinkedInUse,
]
export const getAllPlaidLinkedBankAccounts = (state: PfReduxState): LinkedAccount[] => {
  const allAccounts = state.cassandra.user.me?.bankAccounts?.all ?? []
  return allAccounts.filter((account) => linkedAccountStatuses.includes(account?.status))
}

export const getShouldEnrollInCards = (state: PfReduxState): boolean =>
  state.lib.card?.shouldEnrollInCards

export const getPreviousCardAccountId = (state: PfReduxState): string | undefined =>
  state.lib.card?.previousCardAccountId
