import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import BlackCalendarIcon from 'src/assets/illustrations/BlackCalendar.svg'
import VisaAndMaster from 'src/assets/illustrations/VisaAndMaster.svg'
import VisaAndMasterGray from 'src/assets/illustrations/VisaAndMasterGray.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {transferMethodsType} from 'src/lib/loans/consts'
import {LoanDisbursementMethodOptionCTA} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodOptionCTA'

export type LoanDisbursementMethodSelectionTemplateProps = {
  isInstantDebitDisabled?: boolean
  onSelectDebitCard: () => void
  onSelectAch: () => void
  onContinue: () => void | Promise<void>
  isSubmitting: boolean
  isContinueBtnDisabled: boolean
  selectedMethod: Extract<transferMethodsType, 'ach' | 'interchange'> | undefined
}

const LoanDisbursementMethodSelectionTemplate: FC<LoanDisbursementMethodSelectionTemplateProps> = (
  props,
) => {
  const {
    isInstantDebitDisabled,
    isContinueBtnDisabled,
    isSubmitting,
    onSelectDebitCard,
    onSelectAch,
    onContinue,
    selectedMethod,
  } = props

  const {t} = useTranslation(['DisbursementMethodSelection', 'Common'])

  const handleOnPressDebitCard = (): void => {
    if (isInstantDebitDisabled) {
      return
    }
    onSelectDebitCard()
  }

  const handleOnPressACH = (): void => {
    onSelectAch()
  }

  /**
   * Determine the lower info block that is displayed below the disbursement method CTA options.
   */
  let lowerBlockInfo: JSX.Element | null = null
  const whatHappensWhyRepaymentDue = (
    <Box direction="row" gap="little">
      <PFSvg SvgUri={BlackCalendarIcon} svgProps={{width: 32, height: 32}} />
      <Box marginHorizontal="little" boxStyle={styles.whatHappensWhenRepaymentDueContainer}>
        <PFText variant="p_semibold">
          {t('WhatHappens')} <PFText variant="p">{t('AllOfYourPayments')}</PFText>
        </PFText>
      </Box>
    </Box>
  )

  if (selectedMethod === 'interchange') {
    lowerBlockInfo = (
      <Box gap={'small'}>
        <PFText variant={'p'} color={'primary'}>
          {t('AccessToMoney')}
        </PFText>
        {whatHappensWhyRepaymentDue}
      </Box>
    )
  } else if (selectedMethod === 'ach') {
    lowerBlockInfo = (
      <Box gap={'small'}>
        <PFText variant={'p'} color={'primary'}>
          {t('MoneyWithinOneToTwoBusinessDays')}
        </PFText>
        {whatHappensWhyRepaymentDue}
      </Box>
    )
  }

  return (
    <Page
      variant={'generic'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: () => onContinue(),
          disabled: isContinueBtnDisabled || isSubmitting,
          loading: isSubmitting,
        },
      }}
      title={t('Title')}
      smallTopGap
    >
      <Box gap="small">
        <Box direction={'row'}>
          <LoanDisbursementMethodOptionCTA
            title={t('DebitCard')}
            description={t('SupportedTypes')}
            illustration={
              selectedMethod === undefined || selectedMethod === 'interchange'
                ? VisaAndMaster
                : VisaAndMasterGray
            }
            feeDescription={t('InstantTransfer')}
            onPress={handleOnPressDebitCard}
            isSelected={selectedMethod === 'interchange'}
            // when no option is selected yet we highlight both options
            isHighlighted={selectedMethod === undefined}
          />
          <LoanDisbursementMethodOptionCTA
            title={t('DirectDeposit')}
            description={t('LinkYourBankAccount')}
            feeDescription={t('ACHDepositTime')}
            onPress={handleOnPressACH}
            isSelected={selectedMethod === 'ach'}
            // when no option is selected yet we highlight both options
            isHighlighted={selectedMethod === undefined}
          />
        </Box>
        {isInstantDebitDisabled ? (
          <Alert
            level="warning"
            title={t('InstantDebitUnavailableTitle')}
            description={t('InstantDebitUnavailableDescription')}
          />
        ) : null}
        {lowerBlockInfo}
      </Box>
    </Page>
  )
}

export {LoanDisbursementMethodSelectionTemplate}

const styles = StyleSheet.create({
  whatHappensWhenRepaymentDueContainer: {
    flex: 1,
  },
})
