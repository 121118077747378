import React, {useCallback, useContext} from 'react'
import {CompositeScreenProps, useFocusEffect} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'

import AppEvents, {ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'
import {AdhocPaymentConfirmContainer} from 'src/products/general/AdhocPayment/AdhocPaymentConfirm/AdhocPaymentConfirmContainer'
import {PaymentConfirmInfoCapsule} from 'src/products/loans/AdhocPayment/LoanPayConfirm/PaymentConfirmInfoCapsule/PaymentConfirmInfoCapsule'
import {LoanPayContext, LoanPaySetterContext} from 'src/products/loans/AdhocPayment/LoanPayProvider'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {
  getLoanPaymentOptionLabel,
  loanAdhocPaymentLogError,
  trackLoansAdhocScreenRenderFailure,
} from 'src/products/loans/AdhocPayment/LoanPay.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  getSelectedLoanPaymentMethod,
  goToPaymentMethods,
  submitLoanPaymentAndNavigate,
  trackChangePaymentMethodCompleted,
} from 'src/products/loans/AdhocPayment/LoanPayConfirm/LoanPayConfirm.utils'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  LoanPayConfirmDocument,
  LoanPayConfirmMakeCustomPaymentDocument,
} from 'src/products/loans/AdhocPayment/LoanPayConfirm/LoanPayConfirm.gqls'
import {PaymentMethodLinkSubsets} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/PaymentMethodLink.types'
import {
  LoanPayConfirmAch,
  LoanPayConfirmDebitCard,
} from 'src/products/loans/AdhocPayment/LoanPayConfirm/LoanPayConfirm.types'

type LoanPayConfirmProps = Pick<
  CompositeScreenProps<
    StackScreenProps<LoanPayNavigatorStack, 'LoanPayConfirm'>,
    StackScreenProps<MainStackParamList>
  >,
  'navigation' | 'route'
>

/**
 * "Confirm payment" screen for adhoc loan payments. Displays the payment amount + payment method
 * and allows the user to submit the payment.
 */
const LoanPayConfirm = ({navigation, route}: LoanPayConfirmProps): JSX.Element => {
  const queryResult = useCassandraQuery(
    LoanPayConfirmDocument,
    {
      // data should be cached from previous page already
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
    (data) => {
      const eligiblePaymentMethods: (LoanPayConfirmAch | LoanPayConfirmDebitCard)[] = []
      let amountOutstanding: string | undefined = undefined
      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ActiveLoanAggregateStatus'
      ) {
        for (const paymentMethod of data.me.paymentMethods.loanEligible ?? []) {
          if (
            paymentMethod.__typename === 'AchPaymentMethod' ||
            paymentMethod.__typename === 'DebitCardPaymentMethod'
          ) {
            eligiblePaymentMethods.push(paymentMethod)
          }
        }
        amountOutstanding = data.me.loans.latestActionableLoan.aggregateStatus.amountOutstanding
      }
      return {
        paymentMethods: eligiblePaymentMethods,
        amountOutstanding,
        loanId: data.me.loans.latestActionableLoan?.id,
      }
    },
  )
  const {refetch} = queryResult
  const [submitPayment, submitPaymentResult] = useCassandraMutation(
    LoanPayConfirmMakeCustomPaymentDocument,
  )
  // the payment option that the user has selected (full balance, missed installment, etc)
  // is stored in the context
  const {selectedPaymentOption, selectedPaymentMethodId, newSelectedPaymentMethodId} =
    useContext(LoanPayContext)
  const {setSelectedPaymentMethodId, setNewSelectedPaymentMethodId} =
    useContext(LoanPaySetterContext)

  const {selectedPaymentMethod} = getSelectedLoanPaymentMethod({
    selectedPaymentMethodId,
    paymentMethods: queryResult.selectedData?.paymentMethods ?? [],
  })

  // queryResult.loading will be true on first fetch
  // if we're re-fetching after a new payment method was added then selectedPaymentMethod will be temporarily undefined
  const showLoadingSpinner = queryResult.loading && !selectedPaymentMethod

  const routeParamNewSelectedPaymentInstrumentId = route.params?.newSelectedPaymentInstrumentId
  useFocusEffect(
    useCallback(() => {
      // Refetch the data after adding a new payment method and track if found
      async function refetchNewPaymentMethod(): Promise<void> {
        try {
          const response = await refetch()

          let newPaymentMethod: LoanPayConfirmAch | LoanPayConfirmDebitCard | undefined
          for (const method of response?.data?.me.paymentMethods.loanEligible ?? []) {
            if (
              (method.__typename === 'AchPaymentMethod' &&
                method.bankingPaymentInstrumentId === routeParamNewSelectedPaymentInstrumentId) ||
              (method.__typename === 'DebitCardPaymentMethod' &&
                method.id === routeParamNewSelectedPaymentInstrumentId)
            ) {
              newPaymentMethod = method
              break
            }
          }

          if (newPaymentMethod) {
            trackChangePaymentMethodCompleted({newPaymentMethod})
          } else {
            loanAdhocPaymentLogError(
              new Error(
                `LoanPayConfirm refetchNewPaymentMethod() failed to find new payment method with id="${routeParamNewSelectedPaymentInstrumentId}"`,
              ),
            )
          }
        } catch (e) {
          loanAdhocPaymentLogError(
            e instanceof Error ? e : new Error(String(e)),
            'LoanPayConfirm failed to re-fetch data after adding a new account',
          )
        }
      }

      if (routeParamNewSelectedPaymentInstrumentId) {
        setNewSelectedPaymentMethodId(routeParamNewSelectedPaymentInstrumentId)
        setSelectedPaymentMethodId(routeParamNewSelectedPaymentInstrumentId)

        void refetchNewPaymentMethod()

        navigation.setParams({newSelectedPaymentInstrumentId: undefined})
      } else if (!selectedPaymentMethodId && selectedPaymentMethod) {
        setSelectedPaymentMethodId(selectedPaymentMethod.id)
      }
    }, [
      routeParamNewSelectedPaymentInstrumentId,
      navigation,
      setNewSelectedPaymentMethodId,
      setSelectedPaymentMethodId,
      refetch,
      selectedPaymentMethod,
      selectedPaymentMethodId,
    ]),
  )

  const pageViewedAnalytics = {
    eventName: ManageActiveLoanEvents.loans_adhoc_payment_review_screen_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
    isLoading: queryResult.loading,
  }

  const paymentMethodLinkAnalyticEvent = (): void => {
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_payment_method_link_cta,
      AppEvents.Category.ManageActiveLoan,
    )
  }

  /**
   * Send user to the "Payment methods" screen when they tap on their payment method.
   */
  const handleOnPressViewPaymentMethods = (): void => {
    goToPaymentMethods({
      navigation,
    })
  }

  /**
   * Track event before going back to amount selection screen.
   */
  const handleOnPressPaymentAmount = (): void => {
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_payment_review_edit_amount_selected,
      AppEvents.Category.ManageActiveLoan,
    )
    navigation.goBack()
  }

  /**
   * Submit the payment when the CTA button is pressed.
   */
  const handleOnPressSubmitPayment = async (): Promise<void> => {
    if (
      !selectedPaymentMethod?.bankingPaymentInstrumentId ||
      !queryResult.selectedData?.loanId ||
      !selectedPaymentOption
    ) {
      loanAdhocPaymentLogError(
        new Error(
          `LoanPayConfirm handleOnPressSubmitPayment() called but was unable to submit due to missing data bankingPaymentInstrumentId="${selectedPaymentMethod?.bankingPaymentInstrumentId}" loanId="${queryResult.selectedData?.loanId}" selectedLoanCustomPaymentOption="${selectedPaymentOption?.intention}"`,
        ),
      )
      return
    }

    await submitLoanPaymentAndNavigate({
      loanId: queryResult.selectedData.loanId,
      paymentInstrumentId: selectedPaymentMethod.bankingPaymentInstrumentId,
      paymentOptionIntention: selectedPaymentOption.intention,
      paymentMethod: selectedPaymentMethod,
      submitPayment,
      navigation,
    })
  }

  const paymentAmount = selectedPaymentOption?.amount ?? '0'
  const paymentsCount = selectedPaymentOption?.installmentCount.toString() ?? '1'

  /**
   * Log error when render fails and hits the ErrorBoundary.
   */
  const handleOnErrorBoundary = (e: Error): void => {
    trackLoansAdhocScreenRenderFailure(e, 'LoanPayConfirm')
  }

  const selectedPaymentMethodWithNewProperty: PaymentMethodLinkSubsets | undefined =
    selectedPaymentMethod
      ? {
          ...selectedPaymentMethod,
          // add a client-only property to indicate if this was just added
          isPaymentMethodNew: newSelectedPaymentMethodId === selectedPaymentMethod.id,
        }
      : undefined

  return (
    <BaseTemplate isLoading={showLoadingSpinner} onErrorBoundary={handleOnErrorBoundary}>
      <AdhocPaymentConfirmContainer
        totalOwed={queryResult.selectedData?.amountOutstanding}
        isLoading={showLoadingSpinner}
        isSubmittingPayment={submitPaymentResult.loading}
        onPressPaymentAmount={(): void => handleOnPressPaymentAmount()}
        onPressSubmitPayment={handleOnPressSubmitPayment}
        onPressViewPaymentMethods={handleOnPressViewPaymentMethods}
        pageViewedAnalytics={pageViewedAnalytics}
        paymentMethodLinkAnalyticEvent={paymentMethodLinkAnalyticEvent}
        paymentAmount={paymentAmount}
        selectedButtonLabel={
          selectedPaymentOption ? getLoanPaymentOptionLabel(selectedPaymentOption) : undefined
        }
        selectedPaymentMethod={selectedPaymentMethodWithNewProperty}
        infoCapsuleElement={
          <PaymentConfirmInfoCapsule paymentAmount={paymentAmount} paymentsCount={paymentsCount} />
        }
        testID="LoanPayConfirm"
        onErrorBoundary={handleOnErrorBoundary}
      />
    </BaseTemplate>
  )
}

export {LoanPayConfirm}
