import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import AutoPay from 'src/assets/icons/AutoPay.svg'
import {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import {EntryTile} from 'src/designSystem/components/molecules/EntryTile/EntryTile'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'

type Props = {
  hasPendingPhysicalCard: boolean
}

export const SetupAutopayTile: FC<Props> = ({hasPendingPhysicalCard}) => {
  const {t} = useTranslation('SetupAutopayTile')
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const onClick = (): void => {
    TrackAppEvent(CardEvents.card_dashboard_enroll_autopay_tile_cta, AppEvents.Category.Card, {
      hasPhysicalCard: !hasPendingPhysicalCard,
    })

    if (navigation) {
      PushPage(navigation, 'HowDoesAutopayWork')
    }
  }

  return (
    <EntryTile
      svg={AutoPay}
      svgWidth={hasPendingPhysicalCard ? 46 : 80}
      svgHeight={hasPendingPhysicalCard ? 32 : 80}
      header={hasPendingPhysicalCard ? t('GetInstantAccess') : t('EnrollInAutoPay')}
      body={hasPendingPhysicalCard ? t('SetUpAutoPay') : t('BuildCreditHistory')}
      bodyStyle={boxStyles.body}
      linkText={t('EnrollNow')}
      onLinkPress={onClick}
    />
  )
}

type boxStylesType = {
  [prop: string]: BoxProps
}
const boxStyles: boxStylesType = {
  body: {
    marginLeft: 'little',
  },
}
