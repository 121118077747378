import {Consumer} from '@possible/cassandra'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {UpdateLastLoan} from 'src/api/actions/loans/loanActions'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfDispatch} from 'src/store/utils'
import {getIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {LoanApproved, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {EmitRedirectionEvent} from 'src/lib/utils/events'
import {CancelSurveyTemplate} from 'src/products/loans/LoanApprovedActivation/CancelSurvey/CancelSurveyTemplate'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CancelSurveyDocument} from 'src/products/loans/LoanApprovedActivation/CancelSurvey/CancelSurvey.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type Props = StackScreenProps<MainStackParamList, 'CancelSurvey'>

export const CancelSurveyNavPageStateContainer: React.FC<Props> = (props) => {
  const {navigation} = props

  const {t} = useTranslation('CancelLoan')
  const {
    selectedData,
    loading: isLoading,
    error: queryError,
  } = useCassandraQuery(
    CancelSurveyDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (from) => {
      return {
        loanId: from.me.loans.latestActionableLoan?.id,
      }
    },
  )
  const dispatch = usePfDispatch()
  const shouldRedirectLoanApplyAcceptToWeb = getIsFeatureFlagEnabled(
    'loan-application-handoff-to-web-on-android',
  )
  const [isOnContinueLoading, setIsOnContinueLoading] = React.useState(false)
  const [loanCancel] = Consumer.hooks.useLoanCancelMutation({
    onError: (e) => {
      Log.error(e, 'Failed to cancel loan')
    },
  })

  useEffect(() => {
    void dispatch(UserStateRefresh())
  }, [dispatch])

  const handleOnContinue = async (value: string, other?: string): Promise<void> => {
    setIsOnContinueLoading(true)
    try {
      if (selectedData?.loanId) {
        await loanCancel({
          variables: {
            loanId: selectedData.loanId,
            reason: other && other !== '' ? other : value,
            reasonCodes: [],
          },
        })
      } else {
        Log.warn(`Trying to cancel loan, but couldn't find it.`)
      }

      const didRefresh = await dispatch(UserStateRefresh())
      if (!didRefresh) {
        throw new Error(t('FailedToLoadError'))
      }

      await dispatch(UpdateLastLoan())
      navigation.pop()
    } catch (e) {
      Log.log(e)
      ShowException(e)
    } finally {
      setIsOnContinueLoading(false)
    }
  }

  const handleRedirectAcceptLoan = (): void => {
    navigation.pop()

    LoanApproved()

    TrackAppEvent(AppEvents.Name.loan_approved_completed, AppEvents.Category.Activation)

    if (shouldRedirectLoanApplyAcceptToWeb) {
      EmitRedirectionEvent('workflows')
    } else {
      navigation.navigate('ApplicationActivationWorkflow', {screen: 'Loading'})
    }
  }

  return (
    <BaseTemplate
      testID="CancelSurveyNavPageStateContainer"
      isLoading={isLoading && !selectedData?.loanId}
      isError={!!queryError}
    >
      <CancelSurveyTemplate
        onContinue={handleOnContinue}
        onRedirectAcceptLoan={handleRedirectAcceptLoan}
        isOnContinueLoading={isOnContinueLoading}
      />
    </BaseTemplate>
  )
}
