import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {
  ConfirmBankAccountGQLContainer,
  ConfirmBankAccountGQLContainerProps,
} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountGQLContainer'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'

export type ConfirmBankAccountNavPageStateContainerProps = StackScreenProps<
  MainStackParamList,
  'ConfirmBankAccount'
>

/**
 * Container to integrate the ConfirmBankAccount into the NavPageState navigation system.
 */
export const ConfirmBankAccountNavPageStateContainer: React.FC<
  ConfirmBankAccountNavPageStateContainerProps
> = (props) => {
  const {navigation, route} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.confirm_bank_account_viewed,
    eventCategory: AppEvents.Category.Activation,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnSubmit: ConfirmBankAccountGQLContainerProps['onSubmit'] = async () => {
    TrackAppEvent(AppEvents.Name.confirm_bank_account_completed, AppEvents.Category.Activation, {
      navContainerType: 'NavPageState',
    })

    // if the user was sent here because of an ACH error (probably from LoanFinalAccept) we will
    // go back to the previous screen after entering account details
    if (route.params.shouldNavBackAfterSubmit) {
      navigation.goBack()
    } else {
      // this should no longer be reachable but we can't remove this path until we fully remove NavPageState
      // https://possible.atlassian.net/browse/ENG-21203
      throw new Error('ConfirmBankAccountNavPageStateContainer: shouldNavBackAfterSubmit is false')
    }
  }

  return <ConfirmBankAccountGQLContainer onSubmit={handleOnSubmit} />
}
