import React from 'react'
import {Platform, TextInput, TextInputProps, StyleSheet, View} from 'react-native'
import {debounce} from 'lodash'
import {DeepNonNullable} from 'utility-types'

import {DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {littleGap} from 'src/designSystem/layout'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {VariantsStyle} from 'src/designSystem/typography'

const debounceErrorTime = 1000

type LoanAmountInputProps = Pick<
  DeepNonNullable<TextInputProps>,
  'onChangeText' | 'onSubmitEditing'
> & {
  label: string
  loanAmount: string
  invalidAmountText: string
  isValidAmount: boolean
}

const LoanAmountInput: React.FC<LoanAmountInputProps> = (props) => {
  const {
    label,
    loanAmount,
    isValidAmount,
    invalidAmountText,
    onChangeText,
    onSubmitEditing: handleOnSubmitEditing,
  } = props

  const [isValidAmountDebounced, setIsValidAmountDebounced] = React.useState<boolean>(isValidAmount)

  const debouncedSetIsValidAmount = React.useMemo(
    () =>
      debounce((value: boolean) => {
        setIsValidAmountDebounced(value)
      }, debounceErrorTime),
    [],
  )

  React.useEffect(() => {
    if (isValidAmount) {
      // Immediately set valid amount when valid
      setIsValidAmountDebounced(isValidAmount)
    }
    debouncedSetIsValidAmount(isValidAmount)
  }, [isValidAmount, debouncedSetIsValidAmount])

  function handleOnChangeText(value: string): void {
    const valueWithoutDollarSign = value.replace('$', '')

    if (!valueWithoutDollarSign) {
      onChangeText('')
      return
    }

    // Remove leading zeros, ensure input is an integer
    const parsedValue = parseInt(valueWithoutDollarSign)
    if (isNaN(parsedValue)) {
      return
    }

    const newLoanAmount = parsedValue.toString()

    onChangeText(newLoanAmount)
  }

  const hitSlop = {
    top: VariantsStyle.p_semibold(NamedColors.SILVER).lineHeight, // Use `lineHeight` value from `label` to add hitSlop `top` offset. `top` doesn't seem to help on android 😢
    right: 0,
    bottom: 8,
    left: 0,
  }

  return (
    <>
      <View style={[styles.inputWrapper, !isValidAmountDebounced && styles.amountInputInvalid]}>
        <PFText variant={'p_semibold'} textAlign={'center'} color={NamedColors.SILVER}>
          {label}
        </PFText>
        <TextInput
          cursorColor={DefaultVariantsColor['textPrimary']}
          style={styles.amountInput}
          value={`$${loanAmount}`}
          onChangeText={handleOnChangeText}
          onSubmitEditing={handleOnSubmitEditing}
          hitSlop={hitSlop}
          maxLength={4}
          inputMode={'numeric'}
          testID={'LoanAmountInput-TextInput'}
          enterKeyHint={'done'}
        />
      </View>
      {isValidAmountDebounced ? null : (
        <View style={styles.validationLabel}>
          <SvgIcon name={'error'} colorVariant={'error'} isFilled />
          <PFText color={'error'} variant={'p_sm'}>
            {invalidAmountText}
          </PFText>
        </View>
      )}
    </>
  )
}

export {LoanAmountInput}
export type {LoanAmountInputProps}

const styles = StyleSheet.create({
  amountInputInvalid: {
    borderColor: NamedColors.VERMILLION,
    borderWidth: 2,
  },
  amountInput: {
    ...VariantsStyle.d2(DefaultVariantsColor['textPrimary']),
    textAlign: 'center',
    ...Platform.select({
      web: {
        outlineStyle: 'none',
      },
      android: {
        padding: 0, // android padding fix
      },
    }),
  },
  inputWrapper: {
    backgroundColor: NamedColors.WHITESMOKE,
    borderRadius: 10,
    paddingVertical: 8,
    width: '100%',
    ...Platform.select({
      android: {
        paddingBottom: 0, // android padding fix
      },
    }),
  },
  validationLabel: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: littleGap,
    padding: littleGap,
  },
})
