import React from 'react'

import {VerifyYourIdentityGQLContainer} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentityGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {MainStackParamList, MainStackScreenProps} from 'src/nav/MainStackParamsList'
import AppEvents from 'src/lib/Analytics/app_events'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

type Props = MainStackScreenProps<'VerifyYourIdentity'>

const VerifyYourIdentityRouteContainer: React.FC<Props> = (props) => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.personal_details_screen_viewed,
    eventCategory: AppEvents.Category.Application,
  })

  const {
    route: {
      params: {isEditing, onComplete},
    },
  } = props

  const navigation = useNavigation<StackNavigationProp<MainStackParamList, 'VerifyYourIdentity'>>()

  const handleOnComplete = (): void => {
    navigation.pop()
    void onComplete?.()
  }

  return <VerifyYourIdentityGQLContainer isEditing={isEditing} onComplete={handleOnComplete} />
}

export {VerifyYourIdentityRouteContainer}
