import React from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {BottomSheetOrOverlay} from 'src/designSystem/components/organisms/BottomSheetOrOverlay/BottomSheetOrOverlay'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'

type PendingTransactionsHelpOverlayProps = {
  isVisible: boolean
  onDismiss: () => void
}

const PendingTransactionsHelpOverlay = ({
  isVisible,
  onDismiss: handleOnDismiss,
}: PendingTransactionsHelpOverlayProps): JSX.Element => {
  const {t} = useTranslation(['CardDashboardHeader', 'Common'])
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  const handleOnPress = (): void => {
    handleOnDismiss()

    TrackAppEvent(CardEvents.card_pending_transaction_help_cta_clicked, AppEvents.Category.Card)

    navigation.navigate('CardTransactions')
  }

  return (
    <BottomSheetOrOverlay
      onDismiss={handleOnDismiss}
      dismissButtonText={t('Common:Close')}
      showCloseButton
      testID={'PendingTransactionsHelpOverlay'}
      title={t('CardDashboardHeader:PendingTransactions')}
      titleVariant={'h3'}
      visible={isVisible}
    >
      <PFText variant={'p_lg'}>{t('CardDashboardHeader:PendingTransactionsHelpText')}</PFText>

      <Button mode={'secondary'} onPress={handleOnPress} size={'large'} width={'100%'}>
        {t('CardDashboardHeader:PendingTransactionsHelpTextCta')}
      </Button>
    </BottomSheetOrOverlay>
  )
}

export {PendingTransactionsHelpOverlay}
