import {StackNavigationProp} from '@react-navigation/stack'
import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrySubmitReturnType} from 'src/lib/loans/useLoanSubmission/useLoanSubmission'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanApplicationSubmissionGQLContainer} from 'src/products/loans/LoanApplicationSubmission/LoanApplicationSubmissionGQLContainer'

type Props = {
  navigation: StackNavigationProp<MainStackParamList, 'LoanSubmission'>
}
/**
 * Container to integrate the LoanReapplicationSubmission screen into navigation
 * using NavPageState.
 */
const LoanReapplicationSubmissionNavPageStateContainer: React.FC<Props> = (props) => {
  const {navigation} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.completed_application_submission_screen_viewed,
    eventCategory: AppEvents.Category.Application,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnLoanApplicationSubmitted = (loan: TrySubmitReturnType): Promise<void> => {
    if (loan) {
      TrackAppEvent(AppEvents.Name.completed_application_submitted, AppEvents.Category.Application)

      // TODO: Go to ProductHub in long term -- ENG-20070
      navigation.reset({index: 0, routes: [{name: 'Dashboard'}]})
    }
    return Promise.resolve()
  }

  return (
    <LoanApplicationSubmissionGQLContainer
      onSubmitLoanApplication={handleOnLoanApplicationSubmitted}
    />
  )
}

export {LoanReapplicationSubmissionNavPageStateContainer}
