import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {LinkedAccount} from '@possible/cassandra/src/types/consumer'

import {PaymentAccount} from 'src/products/card/PaymentMethods/types'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PrimaryAccountDescription from 'src/products/general/components/molecules/PrimaryAccountDescription/PrimaryAccountDescription'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {PaymentMethodList} from 'src/products/card/PaymentMethods/CardViewPaymentMethods/PaymentMethodList'
import {AccountNotUsableInfoTile} from 'src/products/MCU/AccountManagementV2/AccountNotUsableInfoTile'
import {
  OnBankSelectedForPrimaryAccountSelection,
  OnContinueAfterPrimaryAccountSelected,
} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelection.types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

export type PrimaryAccountSelectionTemplateProps = {
  navigation: StackNavigationProp<MainStackParamList>
  onBankAccountSelected?: OnBankSelectedForPrimaryAccountSelection
  onContinueAfterPrimaryAccountSelected: OnContinueAfterPrimaryAccountSelected
  linkedAccountsValidForPrimary: LinkedAccount[]
  defaultPaymentMethod: PaymentAccount
  isLoading: boolean
}

/**
 * Template for the PrimaryAccountSelection screen. Allows a user to select a bank account from their list
 * of valid accounts to be their primary bank account.
 */
const PrimaryAccountSelectionTemplate: React.FC<PrimaryAccountSelectionTemplateProps> = (
  props: PrimaryAccountSelectionTemplateProps,
) => {
  const {
    navigation,
    onBankAccountSelected,
    onContinueAfterPrimaryAccountSelected,
    defaultPaymentMethod,
    linkedAccountsValidForPrimary,
    isLoading,
  } = props

  const {t} = useTranslation(['AccountManagement', 'Common'])
  const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentAccount>(() => {
    if (
      defaultPaymentMethod?.__typename === 'AchPaymentMethod' &&
      defaultPaymentMethod.account?.__typename === 'LinkedAccount'
    ) {
      return defaultPaymentMethod.account
    } else if (defaultPaymentMethod?.__typename === 'LinkedAccount') {
      return defaultPaymentMethod
    }
  })

  useEffect(() => {
    // disable continue btn if payment method isn't set
    if (!selectedPaymentMethod && !isContinueDisabled) {
      setIsContinueDisabled(true)
    }
  }, [selectedPaymentMethod, isContinueDisabled, setIsContinueDisabled])
  useEffect(() => {
    // disable continue btn if loading
    if (isLoading && !isContinueDisabled) {
      setIsContinueDisabled(true)
    }
  }, [isLoading, isContinueDisabled, setIsContinueDisabled])

  const shouldShowRadioButtons = linkedAccountsValidForPrimary.length > 0

  const handleSelectBank = (bankAccount: PaymentAccount): void => {
    setIsContinueDisabled(false)
    setSelectedPaymentMethod(bankAccount)
    onBankAccountSelected?.({bankAccount})
  }

  const onContinue = async (): Promise<void> => {
    setIsContinueDisabled(true)
    let accountMask: string | undefined = ''
    if (selectedPaymentMethod?.__typename === 'AchPaymentMethod') {
      accountMask = selectedPaymentMethod.account?.mask
    } else if (selectedPaymentMethod?.__typename === 'DebitCardPaymentMethod') {
      accountMask = selectedPaymentMethod.card.mask ?? selectedPaymentMethod.card.account?.mask
    } else if (selectedPaymentMethod?.__typename === 'LinkedAccount') {
      accountMask = selectedPaymentMethod.mask
    }

    await onContinueAfterPrimaryAccountSelected({
      accountId: selectedPaymentMethod?.id ?? '',
      accountMask,
    })
    setIsContinueDisabled(false)
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: onContinue,
          disabled: isContinueDisabled,
          testID: 'Continue-Button-Primary-Account-Selection',
        },
      }}
      variant={'generic'}
      title={t('ChoosePrimary')}
      smallTopGap
    >
      <Box>
        <PrimaryAccountDescription isChooseYourAccount={true} />
        <PFText variant={'h3'}>{t('Accounts')}</PFText>
        <Box marginTop={'small'}>
          <PaymentMethodList
            paymentMethods={linkedAccountsValidForPrimary}
            navigation={navigation}
            selectedPaymentAccount={selectedPaymentMethod}
            onPaymentAccountSelect={handleSelectBank}
            showRadio={shouldShowRadioButtons}
          />
        </Box>
        <AccountNotUsableInfoTile choosePrimaryAccountScreen />
      </Box>
    </Page>
  )
}

export {PrimaryAccountSelectionTemplate}
