import React, {FC, useCallback, useState} from 'react'
import {Image, View, StyleSheet, LayoutChangeEvent, ImageStyle} from 'react-native'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {ampli, RegistrationWaitlistedProperties} from 'src/lib/Analytics/ampli'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Group4731_WaitListComingSoon from 'src/assets/illustrations/Group4731_WaitListComingSoon.svg'
import Log from 'src/lib/loggingUtil'
import Group1715_OnTheWaitList from 'src/assets/illustrations/Group1715_OnTheWaitList.svg'
import {CardWaitListStatus} from 'src/lib/card/actions'
import {
  SetAmplitudeUserProperties,
  SetBrazeUserProperties,
  TrackAppEvent,
} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {stateAbvToFull} from 'src/lib/us_states'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {isDeviceWeb} from 'src/lib/utils/platform'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {RegisteredForOutOfStateWaitListPropertyName} from 'src/products/loans/StateSelector/UnsupportedStateWaitListConstants'
import {useGetCardWaitlistStatus} from 'src/products/loans/StateSelector/useGetCardWaitlistStatus'
import {
  useGetWaitlistVariant,
  WaitListVariant,
} from 'src/products/loans/StateSelector/UseGetWaitlistVariant/useGetWaitlistVariant'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {AddressQueryDocument} from 'src/products/loans/StateSelector/AddressQuery.gqls'

type UnsupportedStateWaitListProps = {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

export const UnsupportedStateWaitList: FC<UnsupportedStateWaitListProps> = (props) => {
  const {navigation} = props
  const {t} = useTranslation('UnsupportedStateWaitList')
  const {canAccessCards: isCardsUser} = useCanAccessCards()
  const getCardWaitListStatus = useGetCardWaitlistStatus()
  const [wasCtaInvoked, setWasCtaInvoked] = useState(false)
  const [isOnWaitList, setIsOnWaitList] = useState(false)
  const [imageWidth, setImageWidth] = useState<number>(150)
  const waitlistVariant = useGetWaitlistVariant()
  const cardWaitListStatus = isOnWaitList ? CardWaitListStatus.REGISTERED : getCardWaitListStatus

  const {selectedData: homeAddressState} = useCassandraQuery(AddressQueryDocument, {}, (data) => {
    return data?.me?.profile?.home?.address?.state
  })

  usePageViewedAnalytics({
    eventName: AppEvents.Name.out_of_state_wait_list_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })

  const onLayout = (event: LayoutChangeEvent): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setImageWidth(event.nativeEvent.layout.width)
  }

  const onPress = useCallback(async () => {
    if (wasCtaInvoked) return

    setWasCtaInvoked(true)

    try {
      await setUserProperty('card_wait_list', {registered_for_wait_list: true})
    } catch (e) {
      Log.warn('failed to add use to Card Wait List from Dashboard', e)
      return
    }

    SetAmplitudeUserProperties(RegisteredForOutOfStateWaitListPropertyName, 'true')
    TrackAppEvent(AppEvents.Name.out_of_state_wait_list_registered, AppEvents.Category.Card)
    SetBrazeUserProperties(RegisteredForOutOfStateWaitListPropertyName, true)

    const waitlistProps: RegistrationWaitlistedProperties = {
      waitlist_reason: 'us_state_ineligible',
    }
    try {
      ampli.registrationWaitlisted(waitlistProps)
    } catch (e) {
      Log.warn('failed to send registrationWaitlisted event to Ampli', e)
    }
    setIsOnWaitList(true)
  }, [wasCtaInvoked])

  const getPageDescription = (): string | undefined => {
    if (cardWaitListStatus === CardWaitListStatus.NOT_REGISTERED) {
      if (
        [
          WaitListVariant.CardEnabledSupportedState,
          WaitListVariant.CardEnabledUnsupportedState,
        ].includes(waitlistVariant)
      ) {
        return t('CardComingSoonDescription')
      } else if (waitlistVariant === WaitListVariant.CardEnabledQuotaReached) {
        return t('CardQuotaDescription')
      } else {
        return t('WaitListBody')
      }
    } else if (cardWaitListStatus === CardWaitListStatus.REGISTERED) {
      if (waitlistVariant === WaitListVariant.CardEnabledUnsupportedState) {
        return t('CardRegisteredPageDescription')
      } else if (waitlistVariant === WaitListVariant.CardEnabledSupportedState) {
        return t('WaitListRegisteredBodyCard')
      }
      return t('WaitListRegisteredBody')
    }
  }

  const getBodyImage = (): React.ReactNode => {
    if (waitlistVariant === WaitListVariant.CardEnabledSupportedState) {
      return (
        <Image
          style={styles.bodyImage}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          source={require('src/assets/images/CardWaitlist.png')}
          resizeMode={'contain'}
        />
      )
    } else if (waitlistVariant === WaitListVariant.Loan) {
      return <PFSvgContain width={206} svg={Group1715_OnTheWaitList} />
    }
  }

  const getPageTitle = (): string | undefined => {
    if (cardWaitListStatus === CardWaitListStatus.NOT_REGISTERED) {
      if (waitlistVariant === WaitListVariant.CardEnabledUnsupportedState) {
        return t('CardComingSoonTitle', {
          state: homeAddressState ? (stateAbvToFull.get(homeAddressState) ?? '') : '',
        })
      } else if (waitlistVariant === WaitListVariant.CardEnabledQuotaReached) {
        return t('CardQuotaTitle')
      }
      return t('ComingSoonTitle')
    } else if (cardWaitListStatus === CardWaitListStatus.REGISTERED) {
      if (waitlistVariant === WaitListVariant.CardEnabledUnsupportedState) {
        return t('CardRegisteredPageTitle')
      } else if (waitlistVariant === WaitListVariant.CardEnabledSupportedState) {
        return t('WaitListRegisteredTitleCard')
      }
      return t('WaitListRegisteredTitle')
    }
  }

  const getNotRegisteredCtaText = (): string => {
    if (isCardsUser) {
      if (waitlistVariant === WaitListVariant.CardEnabledQuotaReached) {
        return t('CardQuotaCTA')
      }
      return t('CardWaitListCTA')
    }
    return t('WaitListCTA')
  }

  const getNotRegisteredSubText = (): string => {
    if (isCardsUser) {
      if (waitlistVariant === WaitListVariant.CardEnabledQuotaReached) {
        return t('CardQuotaSubText')
      }
      return t('CardWaitListSubText')
    }

    return t('WaitListSubText')
  }

  const getUnregisteredImage = (): React.ReactNode => {
    if (
      [
        WaitListVariant.CardEnabledUnsupportedState,
        WaitListVariant.CardEnabledQuotaReached,
      ].includes(waitlistVariant)
    ) {
      const width: ImageStyle = {
        width: isDeviceWeb() ? imageWidth : '100%',
      }
      return (
        <Image
          style={[width, styles.unregisteredImage]}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          source={require('src/assets/images/CardWishlist.png')}
        />
      )
    }
    return <PFSvgContain width={206} svg={Group4731_WaitListComingSoon} />
  }

  const getPageContent = (): React.ReactNode => {
    if (cardWaitListStatus === CardWaitListStatus.NOT_REGISTERED) {
      return (
        <Box gap="small">
          <View style={styles.alignSelfCenter}>{getUnregisteredImage()}</View>
          <PFText variant={'p'}>{getNotRegisteredSubText()}</PFText>
        </Box>
      )
    } else if (cardWaitListStatus === CardWaitListStatus.REGISTERED) {
      return (
        <>
          <View style={styles.alignSelfCenter}>{getBodyImage()}</View>
        </>
      )
    }
  }

  const getPageButtonProps = (): ButtonLockupProps | undefined => {
    if (cardWaitListStatus === CardWaitListStatus.NOT_REGISTERED) {
      return {
        type: 'singleButton',
        primary: {
          text: getNotRegisteredCtaText(),
          onPress,
        },
      }
    } else if (cardWaitListStatus === CardWaitListStatus.REGISTERED) {
      return waitlistVariant === WaitListVariant.CardEnabledSupportedState
        ? {
            type: 'singleButton',
            primary: {
              text: t('Continue'),
              onPress: (): void => {
                navigation.navigate('ProductHub', {
                  isInNonInitialOnboardingFlow: true,
                })
              },
            },
          }
        : undefined
    }
  }

  if (cardWaitListStatus === CardWaitListStatus.NOT_SET) {
    return null
  }

  return (
    <Page
      title={getPageTitle()}
      smallTopGap={true}
      description={getPageDescription()}
      variant={'generic'}
      buttonProps={getPageButtonProps()}
    >
      <View onLayout={onLayout}>{getPageContent()}</View>
    </Page>
  )
}

const styles = StyleSheet.create({
  alignSelfCenter: {
    alignSelf: 'center',
  },
  bodyImage: {
    height: 314,
    width: 375,
  },
  unregisteredImage: {
    aspectRatio: 1,
    height: 'auto',
    resizeMode: 'cover',
  },
})
