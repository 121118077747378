import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents, {BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PrimaryAccountContent} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/PrimaryAccountContent'

type Props = StackScreenProps<MainStackParamList, 'SetAccountAsPrimary'>

export const SetAccountAsPrimary: FC<Props> = ({route, navigation}) => {
  const {accountMask, accountId, onChooseNewAccountComplete} = route.params
  const {t} = useTranslation(['AccountManagement', 'Common'])

  const [handleChooseNewAccountComplete, {isLoading: isLoadingChooseNewAccountComplete}] =
    usePromise(async () => onChooseNewAccountComplete?.(), {
      catch: (error) => {
        logAddPaymentMethodErrorAndShowException(
          error,
          `SetAccountAsPrimary: Error calling onChooseNewAccountComplete callback.`,
        )
      },
    })

  usePageViewedAnalytics({
    eventName: BankAccountManagementEvents.set_account_as_primary_page_viewed,
    eventCategory: AppEvents.Category.BankAccountManagement,
  })

  function sendUserToAcceptPrimaryAccountAgreement(): void {
    // After selecting a primary account, users must accept the ACH agreement
    navigation.replace('AcceptPrimaryAccountAgreement', {
      accountMask: accountMask ?? '',
      linkedAccountIdForPrimary: accountId,
      onAccept: async () => {
        // This callback function handles/logs it's own errors and shows snackbar
        // It is passed from the LinkUsableAccount URA
        const handleCompleteResponse = await handleChooseNewAccountComplete()
        if (handleCompleteResponse.caughtError) {
          return
        }
        navigation.goBack()
      },
    })
  }

  return (
    <Page
      title={
        <PFText textAlign="left" variant="h2">
          <Trans
            i18nKey="AccountManagement:WantToSetBankAccountEndingInXXXXAsPrimary"
            values={{accountMask}}
          >
            Want to set bank account
            <PFText variant="h2" color={NamedColors.PRODUCT_BLUE}>
              {' '}
              ending in {{accountMask}}{' '}
            </PFText>
            as primary?
          </Trans>
        </PFText>
      }
      variant="generic"
      smallTopGap
      buttonProps={{
        type: 'doubleButton',
        primary: {
          text: t('YesUpdateMyPrimary'),
          onPress: sendUserToAcceptPrimaryAccountAgreement,
          loading: isLoadingChooseNewAccountComplete,
          disabled: isLoadingChooseNewAccountComplete,
        },
        secondary: {
          buttonText: t('MaybeLater'),
          onPress: () => navigation.goBack(),
        },
      }}
    >
      <PrimaryAccountContent />
    </Page>
  )
}
