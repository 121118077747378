// Cannot be inside of ampli directory because it is removed when generate command is run
import {SerializableObject} from 'singular-react-native'
import {snakeCase} from 'lodash'

import Log from 'src/lib/loggingUtil'
import {AnalyticsDisabled} from 'src/lib/Analytics/Analytics.utils'
import {Braze} from 'src/lib/braze/braze'
import SingularSDK from 'src/lib/singular'
import {EventOptions, PromiseResult, Result} from 'src/lib/Analytics/ampli'

/**
When using this function you have to add `.bind(ampli)` to the end of the function name
Example without arguments:
    sendAnalyticEvent(ampli.bankOwnershipAddressFail.bind(ampli))

Example with arguments:
    sendAnalyticEvent(ampli.loansAdhocAmountEntryErrorViewed.bind(ampli), {
    eventArgs: {
      category: AppEvents.Category.ManageActiveLoan,
    },
  })

Without the `.bind()` it will through a @typescript-eslint/unbound-method error for the ampli function
Using `.bind()` allows this function to work without having to disable the rule everytime the function is used
 */

export const sendAnalyticEvent = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  EventFnType extends (properties: any, options?: EventOptions) => PromiseResult<Result>,
>(
  amplitudeEventFn: EventFnType,
  config?: {
    eventArgs: Parameters<EventFnType>[0]
    shouldLogEventToSingular?: boolean
  },
): void => {
  /**
In order for the event name sent to braze/singular to match the event name that is used in amplitude
it has to be transformed to be in snake_case and the "bound_" prefix has to be removed 
("bound_" is added because we have to use the .bind() method to make TS happy)

Example of amplitudeEventFn.name from ampli:
"bound_loansAdhocAmountEntryErrorViewed"

How eventName needs to look for amplitude/braze/singular:
"loans_adhoc_amount_entry_error_viewed"
*/

  const eventName = snakeCase(amplitudeEventFn.name).replace('bound_', '')
  if (AnalyticsDisabled()) return
  if (eventName === null) {
    Log.error(`sendAnalyticEvent: Log event name is null`)
    return
  }
  try {
    amplitudeEventFn(config?.eventArgs)
  } catch (e) {
    Log.warn(`failed to send ${eventName} event to Ampli`, e)
  }
  Log.info(`amplitude:  ${JSON.stringify({eventName, args: config?.eventArgs})}`)

  try {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    let brazeArgs = config?.eventArgs as object
    if (typeof config?.eventArgs === 'string') {
      brazeArgs = {value: config.eventArgs}
    }
    Braze?.logCustomEvent(eventName, brazeArgs)

    if (config?.shouldLogEventToSingular) {
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      SingularSDK.logEvent(eventName, config.eventArgs as SerializableObject)
    }
    Log.info(`braze: ${JSON.stringify({eventName, brazeArgs})}`)
  } catch (e) {
    Log.warn(`failed to log ${eventName} event to braze`, e)
  }
}
