import {
  LoanActivationPreRequisiteType,
  StateCodes,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyQuery} from '@possible/cassandra/src/utils/operations'
import {StackNavigationProp} from '@react-navigation/stack'
import React from 'react'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanActivationWorkflowUXConfig} from 'src/products/general/ApplicationActivationWorkflow/LoanApplicationActivationWorkflow/LoanActivationWorkflowUXConfig'
import {LoanApplicationActivationWorkflowDocument} from 'src/products/general/ApplicationActivationWorkflow/LoanApplicationActivationWorkflow/LoanApplicationActivationWorkflow.gqls'
import {useWFLogging} from 'src/workflows/logging'
import {WorkflowPreReqFulfillScreenProps, WorkflowsStackParams} from 'src/workflows/types'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'

/**
 * Handle when all prereqs are met for the loan activation workflow.
 */
export const handleOnAllLoanActivationPreReqsMet = async (args: {
  mainNavigation: StackNavigationProp<
    MainStackParamList,
    'ApplicationActivationWorkflow',
    undefined
  >
  workflowNavigation?: StackNavigationProp<WorkflowsStackParams>
  logger: ReturnType<typeof useWFLogging>
}): Promise<void> => {
  const {mainNavigation, workflowNavigation, logger} = args
  const result = await ApplyQuery(LoanApplicationActivationWorkflowDocument, undefined, undefined)
  logger.log(
    'handleOnAllLoanActivationPreReqsMet() got latest loan status result before navigating to Dashboard or UpgradeToInstallment',
    {result},
  )
  if (
    result.data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
    'ActiveLoanAggregateStatus'
  ) {
    if (result.data.me.loans.latestActionableLoan.aggregateStatus.multiPaymentUpgradeAvailable) {
      logger.log('Sending user to UpgradeToInstallment after all prereqs are met')
      mainNavigation.reset({
        routes: [
          {
            name: 'UpgradeToInstallment',
            params: {
              isFromWorkflowsActivation: true,
            },
          },
        ],
      })
    } else {
      logger.log('Sending user to Dashboard after all prereqs are met')
      mainNavigation.reset({
        routes: [{name: 'MoneyOnTheWay'}],
      })
    }
  } else {
    // the loan should be active at this point. if it's not something went wrong
    const error = new Error(
      'Loan not active after all prereqs met in LoanApplicationActivationWorkflow',
    )
    logOfferActivationErrorAndShowException(error)
    workflowNavigation?.navigate('PostApplicationActivationError', {
      error,
    })
  }
}

/**
 * Get the screen component for a route name given a LoanActivationWorkflowUXConfig.
 */
export const getScreenComponentFromUXConfig = (args: {
  uxConfig: LoanActivationWorkflowUXConfig
  routeName: keyof LoanActivationWorkflowUXConfig['routeNameToComponentMap']
  stateCode: StateCodes
  metPreReqs: LoanActivationPreRequisiteType[]
  unmetPreReqs: LoanActivationPreRequisiteType[]
  logger: ReturnType<typeof useWFLogging>
}): React.FC<WorkflowPreReqFulfillScreenProps> => {
  const {uxConfig, routeName, stateCode, metPreReqs, unmetPreReqs, logger} = args
  const ErrorNoScreenForRoute: React.FC = () => {
    return (
      <ErrorTemplate
        error={new Error(`No "${routeName}" screen found for stateCode="${stateCode}"`)}
      />
    )
  }
  const thisRouteNameToComponentMap = uxConfig.routeNameToComponentMap[routeName]
  if (!thisRouteNameToComponentMap) {
    return ErrorNoScreenForRoute
  }
  let thisRouteComponent: React.FC<WorkflowPreReqFulfillScreenProps> = ErrorNoScreenForRoute
  if ('type' in thisRouteNameToComponentMap) {
    if (thisRouteNameToComponentMap.type === 'dynamic') {
      // if this route is dynamic depending on met/unmet prereqs we call the mapper fn to get the component
      thisRouteComponent =
        thisRouteNameToComponentMap.routeToScreenMapperFn({
          metPreReqs,
          unmetPreReqs,
        }) ?? ErrorNoScreenForRoute
    }
  } else {
    // if this route is static we just use the component its set to
    thisRouteComponent = thisRouteNameToComponentMap
  }
  logger.debug(`got dynamic screen component for route`, {
    routeName,
    stateCode,
    componentName: thisRouteComponent.name ?? 'none found',
  })
  return thisRouteComponent
}
