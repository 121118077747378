import React, {FC, useCallback, useEffect, useRef} from 'react'
import {useFocusEffect} from '@react-navigation/native'

import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'

type Props = {
  token?: string
  zendeskUrl: string
}

export const ZendeskSSOFormWeb: FC<Props> = (props) => {
  const {token, zendeskUrl} = props

  const formRef = useRef<HTMLFormElement | null>(null)

  useFocusEffect(
    useCallback(() => {
      sendAnalyticEvent(ampli.helpCenterRedirectSelected.bind(ampli))
    }, []),
  )

  useEffect(() => {
    // This behaviour is to submit the form when the component is mounted on web
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [formRef, token])

  // @ts-expect-error testId isn't valid but we can use it in our react-native-web setup
  // eslint-disable-next-line react/no-unknown-property
  const input = <input type="hidden" name="jwt" value={token} testID="TokenInput" />

  return (
    // @ts-expect-error need to use testId
    // eslint-disable-next-line react/no-unknown-property
    <form method="post" ref={formRef} action={zendeskUrl} testID="ZendeskSSOFormWeb">
      {input}
    </form>
  )
}
