import React, {FC} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import StateLoanInfo from 'src/products/loans/LoanSelection/StateLoanInfo'
import {PaymentInfoType} from 'src/products/loans/LoanSelection/FeeCalc'
import {WorkflowsStackParams} from 'src/workflows/types'

type Props = {
  navigation: StackNavigationProp<WorkflowsStackParams, 'LoanAmountSelection'>
  paymentInfo: PaymentInfoType
}

const OhioLoanInfo: FC<Props> = ({navigation, paymentInfo}) => {
  const {t} = useTranslation('LoanSelection')
  return (
    <StateLoanInfo
      navigation={navigation}
      paymentInfo={paymentInfo}
      description={t('OHMainDescription')}
      feesTitle={t('MonthlyFee')}
    />
  )
}

export default OhioLoanInfo
