import React from 'react'
import {FetchResult} from '@apollo/client'

import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {VerifyYourIdentityTemplate} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentityTemplate'
import {
  UserModifyProfileDocument,
  UserModifyProfileMutation,
  UserModifyProfileMutationVariables,
} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.gqls'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import Log from 'src/lib/loggingUtil'
import {PersonalInformationDocument} from 'src/products/MCU/VerifyYourIdentity/operations/PersonalInformation.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {VerifyYourIdentityRouteProps} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.types'

const VerifyYourIdentityGQLContainer: React.FC<VerifyYourIdentityRouteProps> = (props) => {
  const {onComplete: handleOnComplete, isEditing = false} = props

  const {selectedData: personalInformationData, loading: isLoadingPersonalInfo} = useCassandraQuery(
    PersonalInformationDocument,
    {
      fetchPolicy: 'network-only',
    },
    (data) => ({
      firstName: data.me.profile?.name?.firstName ?? '',
      lastName: data.me.profile?.name?.lastName ?? '',
      birthday: data.me.profile?.birthDate ?? '',
      ssn: '',
    }),
  )

  const [submitUserPersonalInfo, {loading: isSubmittingPersonalInfo}] = useCassandraMutation<
    UserModifyProfileMutation,
    UserModifyProfileMutationVariables
  >(UserModifyProfileDocument, {
    onError: (error) => {
      Log.error(`${error.message} - VerifyYourIdentity Screen - userModifyProfile mutation`)
      throw new Error('Unable to submit personal info.')
    },
    onCompleted: () => {
      TrackAppEvent(AppEvents.Name.personal_details_completed, AppEvents.Category.Application)
      TrackAppEvent(AppEvents.Name.personal_details_ssn_completed, AppEvents.Category.Application)
    },
  })

  const handleSubmitUserPersonalInfo = async (options: {
    variables: {input: UserModifyProfileMutationVariables}
  }): Promise<FetchResult<UserModifyProfileMutation>> => {
    return submitUserPersonalInfo({
      variables: options.variables.input,
    })
  }

  return (
    <BaseTemplate isLoading={isLoadingPersonalInfo}>
      <VerifyYourIdentityTemplate
        {...props}
        isEditing={isEditing}
        onComplete={handleOnComplete}
        defaultValues={personalInformationData}
        onSubmitUserPersonalInfo={handleSubmitUserPersonalInfo}
        isSubmittingPersonalInfo={isSubmittingPersonalInfo}
      />
    </BaseTemplate>
  )
}

export {VerifyYourIdentityGQLContainer}
