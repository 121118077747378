import {cardAccountActivateInstallmentPlan} from 'src/cassandra'
import {formatDate, friendlyDateWithDOW, utcDate} from 'src/lib/utils/date'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {UseEnablePayOverTimeDocument} from 'src/products/card/PaymentMethods/useEnablePayOverTimeQuery.gqls'

export const useEnablePayOverTime = () => {
  const {selectedData: activeAccount} = useCassandraQuery(
    UseEnablePayOverTimeDocument,
    {fetchPolicy: 'cache-first'},
    (data) => data.me.cardAccounts.active,
  )

  const accountId = activeAccount?.id
  const potentialPlan = activeAccount?.installmentPlans.potential

  return async (): Promise<{amount: number; date: string}> => {
    const executeAt = potentialPlan?.payments?.[0]?.executeAt
    if (!accountId || !potentialPlan?.id || !executeAt) {
      throw new Error('Invalid accountId or plan.id')
    }

    TrackAppEvent(CardEvents.enable_pay_over_time_action, AppEvents.Category.Card)
    await cardAccountActivateInstallmentPlan(accountId, potentialPlan.id)

    return {
      amount: Number(potentialPlan?.payments?.[0]?.amount ?? 0),
      date: formatDate(executeAt, friendlyDateWithDOW, utcDate),
    }
  }
}
