import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'

import {CohortUserTpe} from 'src/cassandra'
import {allowedStates} from 'src/lib/us_states'
import {CohortUserTypeQueryDocument} from 'src/products/card/Application/useGetCohortUserType/CohortUserTypeQuery.gqls'

export const useGetCohortUserType = (): CohortUserTpe => {
  const {data} = useCassandraQuery(CohortUserTypeQueryDocument)

  const doesUserHaveCompletedLoan =
    data?.me?.loans?.latestActionableLoan?.aggregateStatus.__typename ===
    'ClosedLoanAggregateStatus'
  const isUserInLoanState = !!allowedStates().includes(
    data?.me?.profile?.home?.address?.state ?? '',
  )

  if (doesUserHaveCompletedLoan) {
    return CohortUserTpe.LoanGrad
  } else if (isUserInLoanState) {
    return CohortUserTpe.LoanState
  } else {
    return CohortUserTpe.NonLoanState
  }
}
