import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never}
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
  /**
   * A base64url binary encoded string (see: RFC 4648, https://datatracker.ietf.org/doc/html/rfc4648#section-5).
   *
   * Example value: "SSdtIGEgbGl0dGxlIHRlYXBvdA=="
   */
  Base64Binary: {input: string; output: string}
  /**
   * A zoned date with no time information.
   *
   * Example value: "1983-10-01"
   */
  Date: {input: string; output: string}
  /**
   * A date with zoned date time information.
   *
   * Example value: "1983-10-01T23:59:00.000-10:00[Pacific/Honolulu]"
   */
  DateTime: {input: string; output: string}
  /**
   * An arbitrary precision signed decimal number. This value is serialized as a string to prevent rounding errors.
   *
   * Example value: "29.99"
   */
  Decimal: {input: string; output: string}
  /**
   * A local date with no time information.
   *
   * Example value: "1983-10-01"
   */
  LocalDate: {input: string; output: string}
  /**
   * A UUID
   *
   * Example value: "00000fed-ac32-9641-0000-000000000000"
   */
  UUID: {input: string; output: string}
  /**
   * A time zone id.
   *
   * Example value: America/Los_Angeles
   */
  ZoneId: {input: string; output: string}
}

/** Contains balance details of a banking account. */
export type AccountBalance = {
  __typename?: 'AccountBalance'
  /** Linked account ID */
  accountId: Scalars['String']['output']
  /** Account mask */
  accountMask: Scalars['String']['output']
  /** Account name */
  accountName: Scalars['String']['output']
  /** The amount of funds available to be withdrawn from the account */
  available: Scalars['Decimal']['output']
  /** Date this balance was fetched from the aggregator */
  cachedDateTime: Scalars['DateTime']['output']
  /** Total amount of funds in the account */
  current: Scalars['Decimal']['output']
  /** Name of the financial institution */
  institutionName: Scalars['String']['output']
  /** Credit limit for credit-type accounts */
  limit: Scalars['Decimal']['output']
}

/** Filters applied to account lookup */
export type AccountLookupFilter = {
  onlyAchEligible?: InputMaybe<Scalars['Boolean']['input']>
}

export type AchData = {
  __typename?: 'AchData'
  fileId?: Maybe<Scalars['String']['output']>
  traceNumber?: Maybe<Scalars['String']['output']>
}

/** Payment method for an ACH authorization. */
export type AchPaymentMethod = PaymentMethod & {
  __typename?: 'AchPaymentMethod'
  /** Information about the underlying account when it is supported by Plaid or another aggregator's data. */
  account?: Maybe<LinkedAccount>
  /** List of all payments networks available for this payment method */
  availableNetworks: Array<ProcessorNetwork>
  /** Information about the underlying account even when it is a manually added unlinked account */
  bankAccount: BankAccount
  /** Banking payment instrument id (this is a global ID for this payment method relevant to our banking subsystem) */
  bankingPaymentInstrumentId: Scalars['ID']['output']
  /** Canonical payment method type category. This value is always `ACH`. */
  code: PaymentMethodTypeCode
  /** Timestamp tracking the payment instrument creation. */
  createdAt: Scalars['DateTime']['output']
  /** Indicates the payment directions for which this is a default method */
  defaultStatus: Array<DefaultPaymentMethodCode>
  /**
   * The fundingSource for the payment processor
   * @deprecated In case we have multiple funding sources for the same network its returning only the first one
   */
  fundingSource?: Maybe<FundingSource>
  /**
   * Id for the fundingSource for the payment processor
   * @deprecated In case we have multiple funding sources for the same network its returning only the first one
   */
  fundingSourceId?: Maybe<Scalars['UUID']['output']>
  /** Unique synthetic identifier for this ACH authorization. */
  id: Scalars['ID']['output']
  /** The last 4 digits of the ach account number */
  mask?: Maybe<Scalars['String']['output']>
  /** Gets the next feasible payment date for a payment method */
  nextPaymentDate: NextPaymentDate
  /** The first digit of the ach account number */
  prefix?: Maybe<Scalars['String']['output']>
  /** ACH routing number - 9 digits */
  routingNumber?: Maybe<Scalars['String']['output']>
  /** Captures the payment methods current status. */
  status: PaymentMethodStatus
  /** Timestamp tracking the payment instrument last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Payment method for an ACH authorization. */
export type AchPaymentMethodNextPaymentDateArgs = {
  desiredSettlementTime: Scalars['DateTime']['input']
}

/** Input object for adding a new ACH payment method */
export type AchPaymentMethodInput = {
  /** ACH account number - more than 4 digits */
  accountNumber: Scalars['String']['input']
  /**
   * The linked account reference to be associated with the new payment method
   * If null, then no linked account will be associated to it
   */
  linkedAccountId?: InputMaybe<Scalars['ID']['input']>
  /** ACH routing number - 9 digits */
  routingNumber: Scalars['String']['input']
}

/** Interface shared by all ACTIVE card account restriction types. */
export type ActiveCardAccountRestriction = {
  /** Code for this restrictive sub-state. */
  code?: Maybe<ActiveCardAccountRestrictionCode>
  /** Indicates when the card account transitioned into this restrictive sub-state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
}

/** Enumerates the canonical set of non-plain ACTIVE sub-states for a card account. */
export enum ActiveCardAccountRestrictionCode {
  /** Indicates the user has voluntarily restricted use of their card account. */
  Locked = 'LOCKED',
  /** Indicates the system has restricted use of the user's card account. */
  Suspended = 'SUSPENDED',
}

/** All potential ACTIVE card account restriction types. */
export type ActiveCardAccountRestrictions =
  | CardAccountLockedRestriction
  | CardAccountSuspendedRestriction

/** Details for a card account in the ACTIVE state. */
export type ActiveCardAccountStatus = BaseCardAccountStatus & {
  __typename?: 'ActiveCardAccountStatus'
  /**
   * Dollar amount the account is overdue
   * @deprecated Moved to OverduePaymentInfo object
   */
  amountOverdue?: Maybe<Scalars['Decimal']['output']>
  /** Indicates the frequency of auto-pay for this account */
  autopayFrequency: PaymentFrequency
  /** Defines how the card account calculates auto-pay payment amounts */
  autopayModel: AutopayModel
  /** Card account balance */
  balance: CardAccountBalance
  /** Card account overdue status */
  cardAccountOverdueStatus?: Maybe<CardAccountOverdueStatus>
  /** Returns true if the card chargedOffStatus is anything other than NONE */
  chargedOffStatus?: Maybe<ChargedOffCardStatus>
  /** Canonical card account status code; the value is always ACTIVE. */
  code: CardAccountStatusCode
  /** Days until card account is delinquent */
  daysUntilDelinquent?: Maybe<Scalars['Int']['output']>
  /** Number of days the account it delinquent */
  delinquentNumberOfDays?: Maybe<Scalars['Int']['output']>
  /** Indicates when the card account transitioned into the ACTIVE state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Overdue installment info */
  overdueInstallmentInfo?: Maybe<Array<Maybe<OverDuePaymentInfo>>>
  /** Overdue autopay information for the account */
  overduePaymentInfo?: Maybe<OverDuePaymentInfo>
  /** Overdue statement information for the account */
  overdueStatementInfo?: Maybe<OverDuePaymentInfo>
  /** Identifies the restriction, if any, applied to this card account. */
  restriction?: Maybe<ActiveCardAccountRestrictions>
  /** Card account subStatus code. */
  subStatus: CardAccountSubStatus
}

/** Interface shared by all ACTIVE card restriction types. */
export type ActiveCardRestriction = {
  /** Code for this restrictive sub-state. */
  code: ActiveCardRestrictionCode
  /** Indicates when the card transitioned into this restrictive sub-state. */
  occurredAt: Scalars['DateTime']['output']
}

/** Enumerates the canonical set of non-plain ACTIVE sub-states for a card manifestation. */
export enum ActiveCardRestrictionCode {
  /** Indicates the system has restricted use of the user's card manifestation. */
  Suspended = 'SUSPENDED',
}

/** All potential ACTIVE card restriction types. */
export type ActiveCardRestrictions = CardSuspendedRestriction

/** Details for a card in the ACTIVE state. */
export type ActiveCardStatus = BaseCardStatus & {
  __typename?: 'ActiveCardStatus'
  /** Canonical card status code; the value is always ACTIVE. */
  code: CardStatusCode
  /** Indicates when the card transitioned into the ACTIVE state. */
  occurredAt: Scalars['DateTime']['output']
  /**
   * Identifies the restriction, if any, applied to this card manifestation.
   * @deprecated Field no longer supported
   */
  restriction?: Maybe<ActiveCardRestrictions>
}

/** Details for installment plans in the ACTIVE state. */
export type ActiveInstallmentPlanStatus = BaseInstallmentPlanStatus & {
  __typename?: 'ActiveInstallmentPlanStatus'
  /** Canonical installment plan status code; the value is always ACTIVE. */
  code?: Maybe<InstallmentPlanStatusCode>
  /** Indicates the number of reminaing installment payments. */
  installmentsRemaining?: Maybe<Scalars['Int']['output']>
}

export type ActiveLoanAggregateStatus = LoanAggregateStatusType & {
  __typename?: 'ActiveLoanAggregateStatus'
  actions: LoanActiveStatusActionCollection
  alerts: LoanActiveStatusAlertCollection
  amountBorrowed: Scalars['Decimal']['output']
  amountOutstanding: Scalars['Decimal']['output']
  amountPaidOff: Scalars['Decimal']['output']
  amountProcessing: Scalars['Decimal']['output']
  code: LoanAggregateStatusCode
  conversion: LoanConversion
  /**
   * The first future date where there may be a credit impact ff a payment is rescheduled to this date or later.
   * This could be null if all payments are already in default.
   */
  creditImpactDate?: Maybe<Scalars['Date']['output']>
  customPaymentOptions: LoanCustomPaymentOptionCollection
  disbursement: LoanDisbursement
  /** @deprecated Moved to UserLoanCollection */
  loanCount: Scalars['Int']['output']
  multiPaymentUpgradeAvailable: Scalars['Boolean']['output']
  payments: LoanPaymentCollection
  /** Return weekend or holiday dates from the current date to two weeks past the current loan termination date */
  unavailablePaymentDates?: Maybe<LoanPaymentDateCollection>
}

/** Represents a loan in the ACTIVE  state. */
export type ActiveLoanStatus = BaseLoanStatus & {
  __typename?: 'ActiveLoanStatus'
  /** Indicates the loan amount disbursed to the borrower. */
  amountBorrowed?: Maybe<Scalars['Decimal']['output']>
  /** Indicates the remaining balance owed by the borrower. */
  amountOutstanding?: Maybe<Scalars['Decimal']['output']>
  /** Canonical loan status code; the value is always ACTIVE. */
  code: LoanStatusCode
  /** Indicates when the funding settled in the users account. */
  fundingSettledAt?: Maybe<Scalars['DateTime']['output']>
  /** Indicates when the loan transitioned into the ACTIVE state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

export type AddPaymentMethodResponse = {
  __typename?: 'AddPaymentMethodResponse'
  /** The persisted type. It can be null in case of an error */
  paymentMethod?: Maybe<PaymentMethod>
  /** Success or a failure reason */
  result: AddPaymentMethodResult
}

/** Result object wrapping the enumerated result type */
export type AddPaymentMethodResult = {
  __typename?: 'AddPaymentMethodResult'
  type: AddPaymentMethodResultType
}

/**
 * Enumerated result type for the AddPaymentMethod.
 * It will be SUCCESS if everything went well. The other values are all business validation errors
 */
export enum AddPaymentMethodResultType {
  AlreadyExists = 'ALREADY_EXISTS',
  BinMatch = 'BIN_MATCH',
  ExpiredCard = 'EXPIRED_CARD',
  InvalidAccountNumber = 'INVALID_ACCOUNT_NUMBER',
  InvalidCardType = 'INVALID_CARD_TYPE',
  InvalidExpirationDate = 'INVALID_EXPIRATION_DATE',
  InvalidMask = 'INVALID_MASK',
  InvalidRoutingNumber = 'INVALID_ROUTING_NUMBER',
  Successful = 'SUCCESSFUL',
}

/** Mailing address information. */
export type Address = Verified & {
  __typename?: 'Address'
  /** City name. */
  city?: Maybe<Scalars['String']['output']>
  /** Country name. */
  country?: Maybe<Scalars['String']['output']>
  /**
   * County name.
   * @deprecated We do not support setting county.
   */
  county?: Maybe<Scalars['String']['output']>
  /** Timestamp tracking the address's creation. */
  createdAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Full mailing address.
   * @deprecated This is gone. Remove after next FE release.
   */
  full?: Maybe<Scalars['String']['output']>
  /** Postal code. */
  postalCode?: Maybe<Scalars['String']['output']>
  /** State name or abbreviation. */
  state?: Maybe<Scalars['String']['output']>
  /** Line 1 of the street address. */
  street1?: Maybe<Scalars['String']['output']>
  /** Optional, line 2 of the street address. */
  street2?: Maybe<Scalars['String']['output']>
  /** Timestamp tracking the address's last modification. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /** Indicates whether the mailing address was verified. */
  verified: Scalars['Boolean']['output']
}

/** Canonical list of address types. This is used to specify which address to update. */
export enum AddressType {
  /** User's home address */
  Home = 'HOME',
  /** User's mailing address */
  Mailing = 'MAILING',
}

export enum AdverseReasonCode {
  BadCashFlow = 'BAD_CASH_FLOW',
  BalanceTooLow = 'BALANCE_TOO_LOW',
  CannotVerifyIncome = 'CANNOT_VERIFY_INCOME',
  HighReturnedChecks = 'HIGH_RETURNED_CHECKS',
  IncomeTooLow = 'INCOME_TOO_LOW',
  IncomeUnpredictable = 'INCOME_UNPREDICTABLE',
  LimitedPfHistory = 'LIMITED_PF_HISTORY',
  NotEnoughHistory = 'NOT_ENOUGH_HISTORY',
  Other = 'OTHER',
  PoorPfPerformance = 'POOR_PF_PERFORMANCE',
  RiskyIncomeSource = 'RISKY_INCOME_SOURCE',
  SdbFailure = 'SDB_FAILURE',
  StackedLoans = 'STACKED_LOANS',
  TooManyInquiries = 'TOO_MANY_INQUIRIES',
}

/** A list of codes indicating why a loan had adverse action taken */
export type AdverseReasonCodeCollection = {
  __typename?: 'AdverseReasonCodeCollection'
  codes: Array<Maybe<AdverseReasonCode>>
}

export enum AggregatorType {
  Finicity = 'FINICITY',
  Mocked = 'MOCKED',
  Plaid = 'PLAID',
  Possible = 'POSSIBLE',
  Unrecognized = 'UNRECOGNIZED',
  Yodlee = 'YODLEE',
}

/** The address components supplied for a user's credit product application; such as a credit card account. */
export type ApplicantPhysicalAddressComponentsInput = {
  /** City name. */
  city: Scalars['String']['input']
  /**
   * Country name.
   * @deprecated We do not support setting country.
   */
  country?: InputMaybe<Scalars['String']['input']>
  /**
   * County name.
   * @deprecated We do not support setting county.
   */
  county?: InputMaybe<Scalars['String']['input']>
  /** Postal code. */
  postalCode: Scalars['String']['input']
  /** State code */
  state: Scalars['String']['input']
  /** Line 1 of the street address. */
  street1: Scalars['String']['input']
  /** Optional, line 2 of the street address. */
  street2?: InputMaybe<Scalars['String']['input']>
}

/** The address supplied (single full String) for a user's credit product application; such as a credit card account. */
export type ApplicantPhysicalAddressInput = {
  /** Full mailing address. */
  full: Scalars['String']['input']
}

/** Enumeration of Channel type used to enable a user for card */
export enum ApplicationChannel {
  None = 'NONE',
  PaidAds = 'PAID_ADS',
}

/** Details for a card account in the APPROVED state. */
export type ApprovedCardAccountStatus = BaseCardAccountStatus & {
  __typename?: 'ApprovedCardAccountStatus'
  /** Canonical card account status code; the value is always APPROVED. */
  code: CardAccountStatusCode
  /** Indicates when the card account transitioned into the APPROVED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Card account subStatus code. */
  subStatus: CardAccountSubStatus
}

export type ApprovedLoanAggregateStatus = LoanAggregateStatusType & {
  __typename?: 'ApprovedLoanAggregateStatus'
  /** Collection of pre requisites for the loan activation workflow */
  activationPreReqs: LoanActivationPreRequisiteCollection
  amountApproved: Scalars['Decimal']['output']
  amountRequested: Scalars['Decimal']['output']
  code: LoanAggregateStatusCode
  conversion: LoanConversion
  /** List of codes indicating the reasons a loan is approved for less than requested */
  counterOfferCodes: AdverseReasonCodeCollection
  expirationDateTime: Scalars['DateTime']['output']
  multiPaymentUpgradeAvailable: Scalars['Boolean']['output']
  /** List of payments of the approved loan */
  payments: LoanPaymentCollection
}

/** Represents a loan in the APPROVED state. */
export type ApprovedLoanStatus = BaseLoanStatus & {
  __typename?: 'ApprovedLoanStatus'
  /** The maximum amount allowed for the user. Will be the same as amountBorrowed if the loan is accepted. */
  amountApproved: Scalars['Decimal']['output']
  /** Canonical loan status code; the value is always APPROVED. */
  code: LoanStatusCode
  /** @deprecated use counterOfferReasonCodes instead */
  counterOfferCodes?: Maybe<StatusReasonCodeCollection>
  /** List of codes indicating the reasons a loan is approved for less than requested */
  counterOfferReasonCodes: AdverseReasonCodeCollection
  /** Indicates when the loan transitioned into the APPROVED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

export type AuditColumn = {
  __typename?: 'AuditColumn'
  column: Scalars['String']['output']
  newValue?: Maybe<Scalars['String']['output']>
  oldValue?: Maybe<Scalars['String']['output']>
}

export type AuditRow = {
  __typename?: 'AuditRow'
  afterRawForm: Scalars['String']['output']
  beforeRawForm: Scalars['String']['output']
  column: Array<Maybe<AuditColumn>>
  id?: Maybe<Scalars['String']['output']>
  table?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type AuditTrail = {
  __typename?: 'AuditTrail'
  delta: Array<Maybe<AuditRow>>
}

/** Captures information about a payment schedule submitted by the user. */
export type AutomaticPaymentSchedule = {
  __typename?: 'AutomaticPaymentSchedule'
  /**
   * The payment amount that is currently expected. Card usage between now and the payment processing date may cause
   * this amount to adjust.
   */
  currentExpectedAmount?: Maybe<Scalars['Decimal']['output']>
  /** Unique synthetic identifier for this payment schedule. ID may be null when inquiring potential payments */
  id?: Maybe<Scalars['ID']['output']>
  /** Represents the original due date of this payment */
  originalScheduleDate: Scalars['LocalDate']['output']
  /** List of dates allowed for rescheduling this payment */
  paymentAllowedRescheduleDates: CardPaymentAllowedRescheduleDatesCollection
  /**
   * The date the payment will get submitted to the payment processor.
   * It should be equal to originalScheduleDate if no reschedule operations were made in this payment
   * Equal to rescheduleDate otherwise
   */
  paymentDate: Scalars['DateTime']['output']
  /**
   * Object wrapping information for the payment execution
   * Execution means the moment the payment should be staged and the balance cut off to submit it to banking
   */
  paymentExecution: PaymentExecutionInfo
  /** The card payment method that to be used to fund the payment. */
  paymentMethod?: Maybe<CardPaymentMethodType>
  /** New date of the payment after one or many reschedule operations */
  rescheduleDate?: Maybe<Scalars['LocalDate']['output']>
}

/** Enumeration representing how the auto-pay amount should be calculated */
export enum AutopayModel {
  /** Uses the real-time full balance amount (ledger) */
  FullBalance = 'FULL_BALANCE',
  /** Uses the minimum payment amount for the statement cycle */
  MinimumPayment = 'MINIMUM_PAYMENT',
  /** Uses the full amount for the statement cycle */
  StatementBalance = 'STATEMENT_BALANCE',
}

export enum BalanceType {
  Available = 'AVAILABLE',
  Ledger = 'LEDGER',
  MinimumDue = 'MINIMUM_DUE',
  PastDue = 'PAST_DUE',
  Statement = 'STATEMENT',
}

export type BankAccount = {
  __typename?: 'BankAccount'
  /** The friendlyName associated with account / payment instrument */
  friendlyName: Scalars['String']['output']
  /** Information about the institution that issues this account */
  institution?: Maybe<Institution>
  /** The last 4 digits of the account number */
  mask: Scalars['String']['output']
}

/** Contains details about a transaction from a banking account. */
export type BankingAccountTransaction = {
  __typename?: 'BankingAccountTransaction'
  /** Possible accountId */
  accountId: Scalars['String']['output']
  /** Dollar amount of this transaction as returned by the aggregator */
  amount: Scalars['Decimal']['output']
  /** Dollar amount of this transaction: positive values when money moves out of the account; negative values when money moves in. */
  amountDirectional: Scalars['Decimal']['output']
  /** Array of categories */
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Date this transaction was done */
  date: Scalars['DateTime']['output']
  /** Transaction description */
  description: Scalars['String']['output']
  /** Indicates if the transaction is pending */
  pending: Scalars['Boolean']['output']
  /** Indicates if the transaction was removed when syncing with the aggregator */
  removed: Scalars['Boolean']['output']
}

/** A paginated card account transactions view. */
export type BankingAccountTransactionPage = Paginated & {
  __typename?: 'BankingAccountTransactionPage'
  /** Metadata that can be used to fetch the next page. */
  cursor: PaginationCursor
  /** The transactions contained by this page. */
  items: Array<BankingAccountTransaction>
}

/** Bankruptcy codes */
export enum BankruptcyCode {
  BankruptcyChapter_12_13ConvertedToChapter_7 = 'BANKRUPTCY_CHAPTER_12_13_CONVERTED_TO_CHAPTER_7',
  BankruptcyDischargedChapter_7 = 'BANKRUPTCY_DISCHARGED_CHAPTER_7',
  BankruptcyDischargedChapter_11 = 'BANKRUPTCY_DISCHARGED_CHAPTER_11',
  BankruptcyDismissedChapter_7 = 'BANKRUPTCY_DISMISSED_CHAPTER_7',
  BankruptcyDismissedChapter_11 = 'BANKRUPTCY_DISMISSED_CHAPTER_11',
  BankruptcyDismissedChapter_12 = 'BANKRUPTCY_DISMISSED_CHAPTER_12',
  BankruptcyDismissedChapter_13 = 'BANKRUPTCY_DISMISSED_CHAPTER_13',
  BankruptcyFiledChapter_7 = 'BANKRUPTCY_FILED_CHAPTER_7',
  BankruptcyFiledChapter_11 = 'BANKRUPTCY_FILED_CHAPTER_11',
  BankruptcyFiledChapter_12 = 'BANKRUPTCY_FILED_CHAPTER_12',
  BankruptcyFiledChapter_13 = 'BANKRUPTCY_FILED_CHAPTER_13',
  BankruptcyWithdrawnChapter_7 = 'BANKRUPTCY_WITHDRAWN_CHAPTER_7',
  BankruptcyWithdrawnChapter_11 = 'BANKRUPTCY_WITHDRAWN_CHAPTER_11',
  BankruptcyWithdrawnChapter_12 = 'BANKRUPTCY_WITHDRAWN_CHAPTER_12',
  BankruptcyWithdrawnChapter_13 = 'BANKRUPTCY_WITHDRAWN_CHAPTER_13',
  ReaffirmationOfDebtChapter_7 = 'REAFFIRMATION_OF_DEBT_CHAPTER_7',
  ReaffirmationOfDebtChapter_11 = 'REAFFIRMATION_OF_DEBT_CHAPTER_11',
  ReaffirmationOfDebtRescindedChapter_7 = 'REAFFIRMATION_OF_DEBT_RESCINDED_CHAPTER_7',
}

/** Captures information common to all card account states. */
export type BaseCardAccountStatus = {
  /** Canonical card account status code. */
  code: CardAccountStatusCode
  /** Indicates when the card account transitioned into this state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Card account subStatus code. */
  subStatus: CardAccountSubStatus
}

export type BaseCardStatus = {
  /** Canonical card status code. */
  code: CardStatusCode
  /** Indicates when the card transitioned into this state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
}

/** Captures information common to all installment plan states. */
export type BaseInstallmentPlanStatus = {
  /** Canonical card account status code. */
  code?: Maybe<InstallmentPlanStatusCode>
}

/** Captures information common to all loan states. */
export type BaseLoanStatus = {
  /** Canonical loan status code. */
  code: LoanStatusCode
  /**
   * Indicates when the loan transitioned into this state. @TODO: for re-entrant states, like ACTIVE, which transition
   * is recorded?
   */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Canonical loan substatus code. */
  substatusCode: LoanSubstatusCode
}

/** Captures information common to all user states. */
export type BaseUserStatus = {
  /** Canonical user status code. */
  code: UserStatusCode
}

export enum BindingType {
  Fcm = 'FCM',
}

/** Captures information about the borrower. */
export type BorrowerInfo = {
  __typename?: 'BorrowerInfo'
  /** Method borrower elects for receiving loan funds. */
  disbursementMethod?: Maybe<LoanPaymentMethod>
  /** Monthly income information of user at application time. */
  monthlyIncome?: Maybe<MonthlyIncomeInfo>
  /** Method borrower agrees to use for loan repayment. */
  paymentMethod?: Maybe<LoanPaymentMethod>
  /** User that applied for this loan. */
  user?: Maybe<User>
}

/** Funding auth cancellability status */
export enum CancellableStatus {
  /** Certified cancellable */
  Cancellable = 'CANCELLABLE',
  /** Certified not cancellable */
  NotCancellable = 'NOT_CANCELLABLE',
  /** We don't know if it can be cancelled */
  Unknown = 'UNKNOWN',
}

/** Details for a card account in the CANCELLED state. */
export type CancelledCardAccountStatus = BaseCardAccountStatus & {
  __typename?: 'CancelledCardAccountStatus'
  /** Canonical card account status code; the value is always CANCELLED. */
  code: CardAccountStatusCode
  /** Indicates when the card account transitioned into the CANCELLED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Card account subStatus code. */
  subStatus: CardAccountSubStatus
}

/** Represents a loan in the CANCELLED state. */
export type CancelledLoanStatus = BaseLoanStatus & {
  __typename?: 'CancelledLoanStatus'
  /** Canonical loan status code; the value is always CANCELLED. */
  code: LoanStatusCode
  /** Indicates when the loan transitioned into the CANCELLED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

export type Card = {
  __typename?: 'Card'
  /** Timestamp tracking the card creation. */
  createdAt: Scalars['DateTime']['output']
  /**
   * The encrypted credit card expiry.
   * @deprecated Removed for PCI compliance. Will be removed soon.
   */
  encryptedExpiry?: Maybe<Scalars['Base64Binary']['output']>
  /**
   * The encrypted full credit card number.
   * @deprecated Removed for PCI compliance. Will be removed soon.
   */
  encryptedNumber?: Maybe<Scalars['Base64Binary']['output']>
  /** Card unique id in the card vendors system. */
  externalId?: Maybe<Scalars['String']['output']>
  /** Card unique id. */
  id?: Maybe<Scalars['String']['output']>
  /** The last 4 numbers of the full credit card number. */
  mask?: Maybe<Scalars['String']['output']>
  /** Card status and status dependent information. */
  status?: Maybe<CardStatuses>
  /** Indicates how this card manifests. */
  type?: Maybe<CardType>
  /** Timestamp tracking the card last modification. */
  updatedAt: Scalars['DateTime']['output']
}

export type CardAccount = {
  __typename?: 'CardAccount'
  /** Collection containing card account agreements. */
  agreements: CardAccountAgreementCollection
  /** Flag for user's autopay status. */
  autopayEnabled: Scalars['Boolean']['output']
  /**
   * Card account balance
   * @deprecated This field is status dependent and has been moved to the ACTIVE, EXPIRED, and DEACTIVATED state types
   */
  balance?: Maybe<CardAccountBalance>
  /** Collection containing historical card account balance entries. */
  balances: CardBalanceEntryCollection
  /** Details about this card account type */
  cardAccountType?: Maybe<CardAccountType>
  /** Network carrying this cardAccount. */
  cardNetwork: CardNetwork
  /** Collection of usable or previously-usable instances of this cardAccount. */
  cards: CardCollection
  /** Timestamp tracking the loan's creation. */
  createdAt: Scalars['DateTime']['output']
  /** Indicates the credit limit for this card account. */
  creditLimit: Scalars['Decimal']['output']
  /** Details about this card account fees */
  fees?: Maybe<CardAccountFees>
  /** Card account's unique id. */
  id: Scalars['ID']['output']
  /** Collection containing card account installment plan information. */
  installmentPlans: CardInstallmentPlanCollection
  /** Indicates if the user has enabled installment payment. */
  installmentsEnabled: Scalars['Boolean']['output']
  /** Indicates the date when the installment payment was enabled. */
  installmentsEnabledAt?: Maybe<Scalars['DateTime']['output']>
  /** Any miscellanous informaton this card account */
  miscData?: Maybe<CardAccountMiscData>
  /** Notes attached to this card account. */
  notes?: Maybe<UserNoteCollection>
  /** Payment method registered by the user and associated with this card account. */
  paymentMethods: CardPaymentMethodCollection
  /** Collection containing card payment information. */
  payments: CardPaymentCollection
  /** Refunds attached to this card account. */
  refunds?: Maybe<CardAccountRefundCollection>
  /** Collection containing current and historical card account statements. */
  statements: CardAccountStatementCollection
  /** Card status and status dependent information. */
  status: CardAccountStatuses
  /** Indicates if this card account is configured to support minimum payments */
  supportsMinPay: Scalars['Boolean']['output']
  /** Collection containing pending and settled historical card account transactions. */
  transactions: CardAccountTransactionCollection
  /** Timestamp tracking the loan's last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Contains information about whether the user accepted the corresponding agreements document. */
export type CardAccountAgreement = ChangeTracked & {
  __typename?: 'CardAccountAgreement'
  /** Timestamp tracking the card account agreement's creation. */
  createdAt: Scalars['DateTime']['output']
  /** Card account agreement's unique id. */
  id: Scalars['ID']['output']
  /** Indicates the user's response to these card account agreement terms. */
  status: CardAgreementStatus
  /** Indicates which type of agreement document this instance pertains to. */
  type: CardAgreementType
  /** Timestamp tracking the card account agreement's last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Collection of card account agreements relevant to given card account. */
export type CardAccountAgreementCollection = {
  __typename?: 'CardAccountAgreementCollection'
  /**
   * Retrieves the card account agreements matching the specified status codes. All card account agreements get returned
   * when `statuses` is either empty or not specified.
   */
  all: Array<CardAccountAgreement>
}

/** Collection of card account agreements relevant to given card account. */
export type CardAccountAgreementCollectionAllArgs = {
  statuses?: InputMaybe<Array<CardAgreementStatus>>
}

/** Contains information about agreement document and its corresponding download URL (for generated PDFs). */
export type CardAccountAgreementWithDownloadUrl = {
  __typename?: 'CardAccountAgreementWithDownloadUrl'
  /** Contains details of the agreements document. */
  agreement: CardAccountAgreement
  /** Contains a pre-signed url of the agreements document. */
  download: PresignedUrl
}

export type CardAccountApplicationExtraFlagInput = {
  key: Scalars['String']['input']
  value: Scalars['Boolean']['input']
}

/** A requirements to apply for a card account */
export type CardAccountApplicationPreRequisite = {
  __typename?: 'CardAccountApplicationPreRequisite'
  /** A name for the pre-requisite */
  name?: Maybe<CardAccountApplicationPreRequisiteName>
  /** pre-requisite a mininum requirement (optional = false) or not (optional = true) */
  optional?: Maybe<Scalars['Boolean']['output']>
  /** pre-requisite status (missing, required, invalid etc) */
  status?: Maybe<CardAccountApplicationPreRequisiteStatus>
}

export enum CardAccountApplicationPreRequisiteName {
  BankDataLinks = 'BANK_DATA_LINKS',
  BankDataLinksShowingPayroll = 'BANK_DATA_LINKS_SHOWING_PAYROLL',
  BasicPiiDob = 'BASIC_PII_DOB',
  BasicPiiFullName = 'BASIC_PII_FULL_NAME',
  BasicPiiMailingAddress = 'BASIC_PII_MAILING_ADDRESS',
  BasicPiiPrimaryResidenceAddress = 'BASIC_PII_PRIMARY_RESIDENCE_ADDRESS',
  BasicPiiSsn = 'BASIC_PII_SSN',
}

export enum CardAccountApplicationPreRequisiteStatus {
  Invalid = 'INVALID',
  Missing = 'MISSING',
  Submitted = 'SUBMITTED',
  SubmittedInReview = 'SUBMITTED_IN_REVIEW',
  Valid = 'VALID',
}

/** Holds all the requirements to apply for a card account */
export type CardAccountApplicationPreRequisitesCollection = {
  __typename?: 'CardAccountApplicationPreRequisitesCollection'
  all: Array<CardAccountApplicationPreRequisite>
}

/** Type to represent the card account audit entry */
export type CardAccountAuditEntry = {
  __typename?: 'CardAccountAuditEntry'
  /** Timestamp tracking the loan's acceptance date. */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>
  /** Timestamp tracking the loan's approved date. */
  approvedAt?: Maybe<Scalars['DateTime']['output']>
  /** Max credit limit possible */
  balanceLimit?: Maybe<Scalars['Decimal']['output']>
  /** Timestamp tracking the loan's cancelled date. */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>
  /** cardAccountId associated with audit entry */
  cardAccountId?: Maybe<Scalars['ID']['output']>
  /** Entry type for audit entry, signifies the type of change made for this entry */
  cardId?: Maybe<Scalars['ID']['output']>
  /** Timestamp tracking the loan's creation. */
  createdAt: Scalars['DateTime']['output']
  /** Timestamp tracking the loan's deactivated date. */
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>
  /** Reason card account was deactivated. */
  deactivationReason?: Maybe<DeactivatedCardAccountReasonCode>
  /** Note to explain what the entry type means */
  entryNote: Scalars['String']['output']
  entryType: CardAccountAuditEntryType
  /** Timestamp tracking the loan's expired date. */
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  /** CardAccountAuditEntry's unique id. */
  id: Scalars['ID']['output']
  /** Timestamp tracking the loan's lock expiration date. */
  lockExpiresAt?: Maybe<Scalars['DateTime']['output']>
  /** Timestamp tracking the loan's locked date. */
  lockedAt?: Maybe<Scalars['DateTime']['output']>
  /** Overdue status of account */
  overdueStatus?: Maybe<CardAccountOverdueStatus>
  /** Previous overdue status of account */
  previousOverdueStatus?: Maybe<CardAccountOverdueStatus>
  /** Previous Card status. */
  previousStatus?: Maybe<CardAccountStatusCode>
  /** Previous Card sub status. */
  previousSubStatus?: Maybe<CardAccountSubStatus>
  /** Timestamp tracking the loan's rejection date. */
  rejectedAt?: Maybe<Scalars['DateTime']['output']>
  /** list of rejection reason codes */
  rejectionReasonCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Card status. */
  status?: Maybe<CardAccountStatusCode>
  /** Card sub status. */
  subStatus?: Maybe<CardAccountSubStatus>
  /** Timestamp tracking the loan's suspended date. */
  suspendedAt?: Maybe<Scalars['DateTime']['output']>
  /** Reason Card account was suspended. */
  suspensionReason?: Maybe<CardAccountSuspensionReasonCode>
  /** Response id from Underwriting. */
  underwritingResponseId?: Maybe<Scalars['ID']['output']>
  /** Timestamp tracking the loan's last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Enum of card account audit entry types. */
export enum CardAccountAuditEntryType {
  CardAccountActivePayLaterComplete = 'CARD_ACCOUNT_ACTIVE_PAY_LATER_COMPLETE',
  CardAccountActiveSuspended = 'CARD_ACCOUNT_ACTIVE_SUSPENDED',
  CardAccountActiveUnsuspended = 'CARD_ACCOUNT_ACTIVE_UNSUSPENDED',
  CardAccountApproved = 'CARD_ACCOUNT_APPROVED',
  CardAccountApprovedAccepted = 'CARD_ACCOUNT_APPROVED_ACCEPTED',
  CardAccountApprovedExpired = 'CARD_ACCOUNT_APPROVED_EXPIRED',
  CardAccountApprovedRejected = 'CARD_ACCOUNT_APPROVED_REJECTED',
  CardAccountBillPaymentMethodCreated = 'CARD_ACCOUNT_BILL_PAYMENT_METHOD_CREATED',
  CardAccountBillPaymentMethodUpdated = 'CARD_ACCOUNT_BILL_PAYMENT_METHOD_UPDATED',
  CardAccountBorrowingBaseUpdated = 'CARD_ACCOUNT_BORROWING_BASE_UPDATED',
  CardAccountCohortDataUpdated = 'CARD_ACCOUNT_COHORT_DATA_UPDATED',
  CardAccountCreditLimitAdjusted = 'CARD_ACCOUNT_CREDIT_LIMIT_ADJUSTED',
  CardAccountCreditReportingIdBackfill = 'CARD_ACCOUNT_CREDIT_REPORTING_ID_BACKFILL',
  CardAccountDeactivated = 'CARD_ACCOUNT_DEACTIVATED',
  CardAccountDeactivatedWithBalance = 'CARD_ACCOUNT_DEACTIVATED_WITH_BALANCE',
  CardAccountExpiredCooloffPeriodUpdated = 'CARD_ACCOUNT_EXPIRED_COOLOFF_PERIOD_UPDATED',
  CardAccountLedgerUpdated = 'CARD_ACCOUNT_LEDGER_UPDATED',
  CardAccountMlaOfacVerify = 'CARD_ACCOUNT_MLA_OFAC_VERIFY',
  CardAccountOverdueAmountChange = 'CARD_ACCOUNT_OVERDUE_AMOUNT_CHANGE',
  CardAccountOverdueStatusChange = 'CARD_ACCOUNT_OVERDUE_STATUS_CHANGE',
  CardAccountPanDecodeRequest = 'CARD_ACCOUNT_PAN_DECODE_REQUEST',
  CardAccountPaymentAmountChange = 'CARD_ACCOUNT_PAYMENT_AMOUNT_CHANGE',
  CardAccountPaymentDateUpdated = 'CARD_ACCOUNT_PAYMENT_DATE_UPDATED',
  CardAccountPaymentExecutionDateUpdated = 'CARD_ACCOUNT_PAYMENT_EXECUTION_DATE_UPDATED',
  CardAccountPaymentInit = 'CARD_ACCOUNT_PAYMENT_INIT',
  CardAccountPaymentRescheduled = 'CARD_ACCOUNT_PAYMENT_RESCHEDULED',
  CardAccountPaymentStaged = 'CARD_ACCOUNT_PAYMENT_STAGED',
  CardAccountPaymentStatusChange = 'CARD_ACCOUNT_PAYMENT_STATUS_CHANGE',
  CardAccountPaymentUpcomingNotify = 'CARD_ACCOUNT_PAYMENT_UPCOMING_NOTIFY',
  CardAccountPending = 'CARD_ACCOUNT_PENDING',
  CardAccountPendingCancelled = 'CARD_ACCOUNT_PENDING_CANCELLED',
  CardAccountPendingExpired = 'CARD_ACCOUNT_PENDING_EXPIRED',
  CardAccountPiiVerify = 'CARD_ACCOUNT_PII_VERIFY',
  CardAccountReactivated = 'CARD_ACCOUNT_REACTIVATED',
  CardAccountRejected = 'CARD_ACCOUNT_REJECTED',
  CardAccountRejectedCooloffPeriodUpdated = 'CARD_ACCOUNT_REJECTED_COOLOFF_PERIOD_UPDATED',
  CardAccountStatementGeneratedDownloadUrl = 'CARD_ACCOUNT_STATEMENT_GENERATED_DOWNLOAD_URL',
  CardBillAutopayDisabled = 'CARD_BILL_AUTOPAY_DISABLED',
  CardBillAutopayEnabled = 'CARD_BILL_AUTOPAY_ENABLED',
  CardBillAutopaySetModel = 'CARD_BILL_AUTOPAY_SET_MODEL',
  CardBillInstallmentsDisabled = 'CARD_BILL_INSTALLMENTS_DISABLED',
  CardBillInstallmentsEnabled = 'CARD_BILL_INSTALLMENTS_ENABLED',
  CardResetPaymentSchedule = 'CARD_RESET_PAYMENT_SCHEDULE',
  Note = 'NOTE',
  Unknown = 'UNKNOWN',
}

/**
 * Captures card account balance details.
 * Balance amounts can not be available at the moment of a query so a "null" value is expected here.
 */
export type CardAccountBalance = {
  __typename?: 'CardAccountBalance'
  /** Available credit balance at the point in time represented by this statement. */
  availableBalance?: Maybe<Scalars['Decimal']['output']>
  /**
   * The amount spent at this point in time, which includes both the processed and non-processed transactions.
   * This would give an estimate about how much money is remaining to be spent (prior to tips, fees, etc).
   */
  availableSpent?: Maybe<Scalars['Decimal']['output']>
  /** Credit balance at the point in time represented by this statement based on the ledger. */
  ledgerBalance?: Maybe<Scalars['Decimal']['output']>
  /**
   * The actual amount spent at this point in time based on the ledger. This total is calculated by only the posted
   * transactions, and excludes the pre-authorized transactions (transactions that are currently being processed).
   */
  ledgerSpent?: Maybe<Scalars['Decimal']['output']>
  /**
   * Determines the amount the user must pay back at that point in time. This number is primarily used for manual
   * payments as there may be other payments in flight (such as auto-pay). Use this value as the ceiling for manual
   * payments, as anything above this amount will be rejected.
   */
  ledgerSpentAfterPayments?: Maybe<Scalars['Decimal']['output']>
  /**
   * Indicates the current minimum payment amount due. This is the minimum a user needs to pay in a statement cycle
   * to avoid being overdue
   */
  minimumPaymentDue?: Maybe<Scalars['Decimal']['output']>
  /**
   * Determines the minimum payment due amount at that point in time. This number is primarily used for manual
   * payments or to indicate the amount of the next auto-pay when the account is set to consider a minimum payment
   * programs as there may be other payments in flight (such as auto-pay).
   */
  minimumPaymentDueAfterPayments?: Maybe<Scalars['Decimal']['output']>
  /** Indicates the past due statement amount. This is the amount to be paid to make the account current (not delinquent) again */
  pastDue?: Maybe<Scalars['Decimal']['output']>
  /**
   * Determines the past due amount at that point in time. This number is primarily used for manual payments since
   * there may be other payments in flight (such as auto-pay).
   */
  pastDueAfterPayments?: Maybe<Scalars['Decimal']['output']>
  /** Indicates the current statement amount. This is the full amount from a statement cycle */
  statement?: Maybe<Scalars['Decimal']['output']>
  /**
   * Determines the statement amount at that point in time. This number is primarily used for manual
   * payments or to indicate the amount of the next auto-pay when the account is set
   * to consider a statement balance amounts model as there may be other payments in flight (such as auto-pay).
   */
  statementAfterPayments?: Maybe<Scalars['Decimal']['output']>
  /** The date the balances were updated to the specified values. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

/** Collection of card account associated with the user. */
export type CardAccountCollection = {
  __typename?: 'CardAccountCollection'
  /** Supplies the user's active card account, if they have one. */
  active?: Maybe<CardAccount>
  /**
   * Returns all the requirements to apply for a card account
   * @deprecated No longer in use on FE, but hammering gateway ENG-11546.
   */
  applicationPreRequisites: CardAccountApplicationPreRequisitesCollection
  /** Returns information on the user's card account offer */
  offerInfo: CardAccountOfferInfo
  /**
   * Lists this user's card account offers.
   * @deprecated This field is replaced by offerInfo to provide additional information.
   */
  offers: Array<CardAccountOffer>
}

/** Information regarding fees associated with the card account. */
export type CardAccountFees = {
  __typename?: 'CardAccountFees'
  /** Collection of potential fee schedule */
  potential?: Maybe<Array<Maybe<FeeSchedule>>>
}

/** Describes a card account install plan and its payment schedule. */
export type CardAccountInstallmentPlan = {
  __typename?: 'CardAccountInstallmentPlan'
  /**
   * Amount pending for installment plan
   * @deprecated renamed to pendingAmount to match grpc/backend
   */
  amountPending?: Maybe<Scalars['Decimal']['output']>
  /**
   * Amount remaining for installment plan
   * @deprecated renamed to amountRemaining to match grpc/backend
   */
  amountRemaining?: Maybe<Scalars['Decimal']['output']>
  /** Indicates the frequency used to schedule the payments for this plan. */
  frequency?: Maybe<PaymentFrequency>
  /**
   * Unique identifier for this installment plan
   * It is being used to be provided as groupId (cardAccountInstallmentPlanId) in cardAccountActivateInstallmentPlan mutation
   */
  id?: Maybe<Scalars['ID']['output']>
  /** List of installments for this plan. */
  installments?: Maybe<Array<InstallmentPayment>>
  /** number of installments past their due date */
  numberOfOverdueInstallments?: Maybe<Scalars['Int']['output']>
  /**
   * Old named list of payments for this plan. Please do not use this anymore!
   * @deprecated updating name to installments to match grpc and backend naming conventions
   */
  payments?: Maybe<Array<InstallmentPayment>>
  pendingAmount?: Maybe<Scalars['Decimal']['output']>
  remainingAmount?: Maybe<Scalars['Decimal']['output']>
  /** Card status and status dependent information. */
  status: InstallmentPlanStatuses
  /** The total due for the installment plan */
  totalDue?: Maybe<Scalars['Decimal']['output']>
  /** The total amount of money due of overdue installments */
  totalOverdueAmount?: Maybe<Scalars['Decimal']['output']>
  /** Total amount paid for installment plan */
  totalPaid?: Maybe<Scalars['Decimal']['output']>
}

/** Details for the LOCKED restriction applied to an ACTIVE card account. */
export type CardAccountLockedRestriction = ActiveCardAccountRestriction & {
  __typename?: 'CardAccountLockedRestriction'
  /** Code for this restrictive sub-state. This field's value is always LOCKED. */
  code: ActiveCardAccountRestrictionCode
  /** Indicates when the lock will expire on this card account. */
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  /** Indicates when the card account transitioned into this restrictive sub-state. */
  occurredAt: Scalars['DateTime']['output']
}

/** Information holding any miscellaneous details of a  card account. */
export type CardAccountMiscData = {
  __typename?: 'CardAccountMiscData'
  /** Cohort phase when the user applied for the card account */
  cohortPhase?: Maybe<CohortPhase>
  /** Cohort user type when the user applied for the card account */
  cohortUserType?: Maybe<CohortUserTpe>
}

export type CardAccountMiscDataInput = {
  /** Cohort phase during card application */
  cohortPhase?: InputMaybe<CohortPhase>
  /** Cohort user type during card application */
  cohortUserType?: InputMaybe<CohortUserTpe>
}

/** Card account offer information. */
export type CardAccountOffer = {
  __typename?: 'CardAccountOffer'
  /** Max balance offered for the card account. */
  balanceLimit: Scalars['Decimal']['output']
  /** A friendly product name */
  displayName: Scalars['String']['output']
  /** Card account offer's unique id. */
  id: Scalars['ID']['output']
  /** Monthly membership fee (if any) */
  monthlyMembershipFees: Scalars['Decimal']['output']
  /** Network carrying the card account. */
  network: CardNetwork
  /** All pre-requisites for this offer */
  preReqs: ProductPreRequisiteCollection
}

export enum CardAccountOfferAvailabilityStatus {
  IneligibleLoanStatus = 'INELIGIBLE_LOAN_STATUS',
  IneligibleLocation = 'INELIGIBLE_LOCATION',
  IneligibleOtherProductsStatus = 'INELIGIBLE_OTHER_PRODUCTS_STATUS',
  IneligibleProductStatus = 'INELIGIBLE_PRODUCT_STATUS',
  NoOfferAvailable = 'NO_OFFER_AVAILABLE',
  OfferAvailable = 'OFFER_AVAILABLE',
  QuotaReached = 'QUOTA_REACHED',
  UserNotEligible = 'USER_NOT_ELIGIBLE',
}

/** Details of the card offer available to the user */
export type CardAccountOfferInfo = {
  __typename?: 'CardAccountOfferInfo'
  /** Product category */
  category: ProductCategory
  /** offer availability status (available, notAvailable, InEligibelUser,InEligibleLoanStatus etc) */
  offerAvailabilityStatus: CardAccountOfferAvailabilityStatus
  /** Lists this user's card account offers. */
  offers: Array<CardAccountOffer>
}

export enum CardAccountOverdueStatus {
  /** Card Account missed statement due date */
  Delinquent = 'DELINQUENT',
  /** Card Account has been delinquent over 29 days */
  DelinquentThirtyDaysOrGreater = 'DELINQUENT_THIRTY_DAYS_OR_GREATER',
  /** Card account is current */
  None = 'NONE',
  /** Card Account missed last autopay or pay over time payment */
  Overdue = 'OVERDUE',
}

/** Structure to hold a the token required to decode (client-side) the PAN, cvv and expiry */
export type CardAccountPanDecodeAuthToken = {
  __typename?: 'CardAccountPanDecodeAuthToken'
  /** Auth token dataholder for i2c issuer processor */
  i2cAuthToken?: Maybe<CardAccountPanDecodeI2CAuthToken>
}

/** Structure to hold a the token required to decode (client-side) the PAN, cvv and expiry (i2c specific) */
export type CardAccountPanDecodeI2CAuthToken = {
  __typename?: 'CardAccountPanDecodeI2CAuthToken'
  /** Auth token for i2c issuer processor */
  authToken: Scalars['String']['output']
  /** i2c issuer processor card reference number */
  cardReferenceNumber: Scalars['String']['output']
}

export type CardAccountPanDecodedData = {
  __typename?: 'CardAccountPanDecodedData'
  /** Card cvv in clear text */
  cvv: Scalars['String']['output']
  /** Card expiry in clear text */
  expiry: Scalars['String']['output']
  /** Card number in clear text */
  number: Scalars['String']['output']
}

/** A refund representation for a card account */
export type CardAccountRefund = {
  __typename?: 'CardAccountRefund'
  /** Amount to be refunded in the banking account */
  bankingAmount?: Maybe<Scalars['Decimal']['output']>
  /** Banking Funding Authorization object */
  bankingFundingAuth?: Maybe<FundingAuthorization>
  /** The payment instrument id from banking selected to issue a banking refund */
  bankingInstrumentId?: Maybe<Scalars['UUID']['output']>
  /** The card account id associated with the refund */
  cardAccountId: Scalars['UUID']['output']
  /** Timestamp tracking the card account refund's creation. */
  createdAt: Scalars['DateTime']['output']
  /** Representation of the responsible for the refund record creation */
  createdBy: NamedEntityDescriptor
  /** Unique identification for a refund in the system */
  id: Scalars['ID']['output']
  /** Amount to be credited in the issuer processor (i2c) */
  issuerProcessorAmount?: Maybe<Scalars['Decimal']['output']>
  /** Notes for this refund. Expected to include the reason why we are refunding this customer and the jira ticket number */
  notes: Scalars['String']['output']
  /** Typed reason for a refund */
  reason: RefundReason
  /** Reason the refund was rejected */
  rejectReason?: Maybe<Scalars['String']['output']>
  /** Current status of the refund process */
  status: RefundStatus
  /** Issuer processor (i2c) transaction id */
  transactionId?: Maybe<Scalars['String']['output']>
  /** The type of the refund. It also means where the refund needs to happen (banking accounts, i2c, ...) */
  type: RefundType
  /** Timestamp tracking the card account refund's last modification. */
  updatedAt: Scalars['DateTime']['output']
  /** Representation of the responsible for the last update on the refund record */
  updatedBy: NamedEntityDescriptor
}

/** Collection containing all refunds for the underlying card account. */
export type CardAccountRefundCollection = {
  __typename?: 'CardAccountRefundCollection'
  /** List of refund representations */
  all: Array<CardAccountRefund>
}

/** Input to perform changes to the actual payment scheduled date */
export type CardAccountReschedulePaymentInput = {
  /** The card account id associated with the payment */
  cardAccountId: Scalars['UUID']['input']
  /** The new date selected by an agent */
  newPaymentDate: Scalars['LocalDate']['input']
  /** The id of the referenced payment schedule entity */
  paymentScheduleId: Scalars['UUID']['input']
  /** The reason for rescheduling this payment */
  reason: CardPaymentRescheduleReason
  /** Free text for notes */
  reasonNotes?: InputMaybe<Scalars['String']['input']>
  /**
   * Research purpose. Whether the user asked for multiple payments reschedules
   * Default is false
   */
  requestedMultipleReschedules: Scalars['Boolean']['input']
}

/** Input to create and execute an ADHOC payment for a card account */
export type CardAccountSchedulePaymentInput = {
  /** The card account id to create a payment for */
  cardAccountId: Scalars['UUID']['input']
  /** The id of the banking payment instrument id to use in the ADHOC payment */
  paymentInstrumentId: Scalars['UUID']['input']
  /** Details for this new payment (amount, execute_at, etc..) */
  paymentSpecification: PaymentSpecificationInput
  /** The processor network (ACH, debit card, etc..) to use for this new payment */
  processorNetwork: ProcessorNetwork
}

/** Statements capture balance information and transaction at the end of a given window of time (i.e. month of January). */
export type CardAccountStatement = {
  __typename?: 'CardAccountStatement'
  /** The statement balance amount */
  amount: Scalars['Decimal']['output']
  /**
   * Available credit balance at the point in time represented by this statement.
   * @deprecated Use balance object instead.
   */
  availableCredit?: Maybe<Scalars['Decimal']['output']>
  /**
   * The statement balance based as of the startDate.
   * @deprecated Use amount instead
   */
  balance?: Maybe<Scalars['Decimal']['output']>
  /** Specifies the last day to pay the statement before getting delinquent */
  dueDate: Scalars['LocalDate']['output']
  /** Specifies this statements end date */
  endDate: Scalars['LocalDate']['output']
  /** Statements unique identifier */
  id: Scalars['ID']['output']
  /** The statement balance minimum payment amount */
  minimumPaymentAmount: Scalars['Decimal']['output']
  /**
   * Payment due date for this statement.
   * @deprecated Use dueDate instead
   */
  paymentDueDate?: Maybe<Scalars['LocalDate']['output']>
  /** The statements PDF presigned url to allow users to view document from within the app. */
  presignedUrl: Scalars['String']['output']
  /** The statement balance amount that is remaining to be paid */
  remainingAmount: Scalars['Decimal']['output']
  /** The statement balance minimum payment amount that is remaining to be paid */
  remainingMinimumPaymentAmount: Scalars['Decimal']['output']
  /** Specifies this statements start date. */
  startDate: Scalars['LocalDate']['output']
  /** Specifies when the statement was generated, normally one day after statement end_date */
  statementDate: Scalars['LocalDate']['output']
}

/** Collection containing all statements for the underlying card account. */
export type CardAccountStatementCollection = {
  __typename?: 'CardAccountStatementCollection'
  /**
   * Statement covering the current active statement window. This statement will change throughout the window (month)
   * and once the month ends it will eventually become the `mostRecent` statement.
   */
  active?: Maybe<CardAccountStatement>
  /**
   * Provides a paginated view of all historical statements; reverse chronologically sorted. When no `pageSelector` is
   * supplied all pending transactions for the underlying statement are returned.
   */
  historical: CardAccountStatementPage
  /** Statement covering the most recently completed statement window. */
  mostRecent?: Maybe<CardAccountStatement>
}

/** Collection containing all statements for the underlying card account. */
export type CardAccountStatementCollectionHistoricalArgs = {
  dateRange?: InputMaybe<DateRangeInput>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** A paginated card account statement view. */
export type CardAccountStatementPage = Paginated & {
  __typename?: 'CardAccountStatementPage'
  /** Metadata that can be used to fetch the next page. */
  cursor: PaginationCursor
  /** The statements contained by this page. */
  statements: Array<CardAccountStatement>
}

/** Enumerates the canonical set of states for a card account. */
export enum CardAccountStatusCode {
  /**
   * Indicates that the card account is active.
   *
   * Successor states: CANCELLED, DEACTIVATED
   */
  Active = 'ACTIVE',
  /**
   * Indicates that the card account application has been approved.
   *
   * Successor states: ACTIVE, CANCELLED, EXPIRED
   */
  Approved = 'APPROVED',
  /** Indicates that the card account was cancelled by the user. This is a terminal state. */
  Cancelled = 'CANCELLED',
  /** Indicates that the card account has been deactivated. This is a terminal state. */
  Deactivated = 'DEACTIVATED',
  /** Indicates that the card account was not accepted by the user in time following approval. This is a terminal state. */
  Expired = 'EXPIRED',
  /**
   * Indicates that the card account is awaiting the outcome of a review.
   *
   * Successor states: APROVED, REJECTED
   */
  Pending = 'PENDING',
  /** Indicates that the card account application was rejected. This is a terminal state. */
  Rejected = 'REJECTED',
}

/** Represents all potential card account states. */
export type CardAccountStatuses =
  | ActiveCardAccountStatus
  | ApprovedCardAccountStatus
  | CancelledCardAccountStatus
  | DeactivatedCardAccountStatus
  | ExpiredCardAccountStatus
  | PendingCardAccountStatus
  | RejectedCardAccountStatus

export enum CardAccountSubStatus {
  /** Card is available for use */
  Active = 'ACTIVE',
  /** Card is available for use but locked by user */
  ActiveLocked = 'ACTIVE_LOCKED',
  /** Card is suspended/not available and the user is on a payment plan to recover */
  ActiveSuspended = 'ACTIVE_SUSPENDED',
  /** Card is pending activation */
  Approved = 'APPROVED',
  /** Card was approved and then cancelled by user */
  CancelledApproved = 'CANCELLED_APPROVED',
  /** Card was pending approval and then cancelled */
  CancelledPending = 'CANCELLED_PENDING',
  /** Card has been deactivated (closed). No balance left to be paid */
  Deactivated = 'DEACTIVATED',
  /** Card has been deactivated (closed) but still have balance left to be paid */
  DeactivatedWithBalance = 'DEACTIVATED_WITH_BALANCE',
  /** Card was not accepted in due time */
  ExpiredApproved = 'EXPIRED_APPROVED',
  /** Card was pending approval for too long */
  ExpiredPending = 'EXPIRED_PENDING',
  /** Card is pending approval. */
  Pending = 'PENDING',
  /** Card is has been rejected. */
  Rejected = 'REJECTED',
}

export type CardAccountSubmitRefundInput = {
  /** Amount to be refunded in the banking account */
  bankingAmount?: InputMaybe<Scalars['Decimal']['input']>
  /** The banking payment instrument id to be used to credit the refund */
  bankingInstrumentId?: InputMaybe<Scalars['UUID']['input']>
  /** The card account id associated with the refund */
  cardAccountId: Scalars['UUID']['input']
  /** Amount to be credited in the issuer processor (i2c) */
  issuerProcessorAmount?: InputMaybe<Scalars['Decimal']['input']>
  /** Notes for this refund. Expected to include the reason why we are refunding this customer and the jira ticket number */
  notes: Scalars['String']['input']
  /** Typed reason for a refund */
  reason: RefundReason
  /** The type of the refund. It also means where the refund needs to happen (banking accounts, i2c, ...) */
  type: RefundType
}

/** Details for the SUSPENDED restriction applied to an ACTIVE card account. */
export type CardAccountSuspendedRestriction = ActiveCardAccountRestriction & {
  __typename?: 'CardAccountSuspendedRestriction'
  /** Code for this restrictive sub-state. This field's value is always SUSPENDED. */
  code: ActiveCardAccountRestrictionCode
  /** Indicates when the card account transitioned into this restrictive sub-state. */
  occurredAt: Scalars['DateTime']['output']
  /** Human readable reason for the card account's transition to this restrictive sub-state. */
  reason?: Maybe<CardAccountSuspensionReasonCode>
}

/** Enumerates the canonical set of card account suspension reasons. */
export enum CardAccountSuspensionReasonCode {
  /** The card account was suspended because fraud was reported against the card account account. */
  Fraud = 'FRAUD',
  /** The card account was suspended because of insufficient funds during a payment. */
  Nsf = 'NSF',
  /** The card account was suspended because the user has defaulted on their balance repayment. */
  PaymentDefaults = 'PAYMENT_DEFAULTS',
  /** The card account was suspended because the user has arranged a payment plan to pay down their balance over time. */
  PayLaterAgreement = 'PAY_LATER_AGREEMENT',
}

export type CardAccountToggleAutopayInput = {
  /** The card account id */
  cardAccountId: Scalars['UUID']['input']
  /** Sets if the operation is to enable or disable autopay */
  enabled: Scalars['Boolean']['input']
  /** Autopay calculation model. Required if enabling autopay */
  model?: InputMaybe<AutopayModel>
}

/** Contains details about a transaction that occured in the underlying statement window. */
export type CardAccountTransaction = {
  __typename?: 'CardAccountTransaction'
  /** Merchant that accepted this transaction */
  acceptor?: Maybe<CardMerchant>
  /** Dollar amount of this transaction */
  amount: Scalars['Decimal']['output']
  /** Additional amount of this transaction */
  amountAdditional?: Maybe<Scalars['Decimal']['output']>
  /** Authorization code for this transaction */
  authorizationCode?: Maybe<Scalars['String']['output']>
  /** Remaining balance available for this card */
  availableBalance?: Maybe<Scalars['Decimal']['output']>
  /** Description of this transaction */
  description?: Maybe<Scalars['String']['output']>
  /** Dispute associated with this transaction */
  dispute?: Maybe<CardTransactionDispute>
  /** Ledger balance for this card */
  ledgerBalance?: Maybe<Scalars['Decimal']['output']>
  /** Merchant used for this transaction */
  merchant?: Maybe<CardMerchant>
  /** Status of the transaction */
  status?: Maybe<CardTransactionStatus>
  /** Time this transaction executed */
  transactionDatetime: Scalars['DateTime']['output']
  /** Time this transaction expires */
  transactionExpiryDatetime?: Maybe<Scalars['DateTime']['output']>
  /** This transaction's id */
  transactionId: Scalars['ID']['output']
  /** Time this transaction was posted */
  transactionPostedDatetime?: Maybe<Scalars['DateTime']['output']>
}

/** Collection containing all transactions for the underlying card account. */
export type CardAccountTransactionCollection = {
  __typename?: 'CardAccountTransactionCollection'
  /**
   * Provides a paginated view of all pending and settled transactions; reverse chronologically sorted. When no `pageSelector` is
   * supplied all pending transactions for the underlying statement are returned.
   * @deprecated replaced by complete (below)
   */
  all: CardAccountTransactionPage
  /**
   * Provides a paginated view of all transactions; reverse chronologically sorted. When no `pageSelector` is
   * supplied page 1 of max 1000 items is returned
   */
  complete: CardAccountTransactionPage
  /**
   * Provides a paginated view of all pending transactions; reverse chronologically sorted. When no `pageSelector` is
   * supplied all pending transactions for the underlying statement are returned.
   */
  pending: CardAccountTransactionPage
  /**
   * Provides a paginated view of all settled transactions; reverse chronologically sorted. When no `pageSelector` is
   * supplied all pending transactions for the underlying statement are returned.
   */
  settled: CardAccountTransactionPage
}

/** Collection containing all transactions for the underlying card account. */
export type CardAccountTransactionCollectionAllArgs = {
  dateRange?: InputMaybe<DateRangeInput>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** Collection containing all transactions for the underlying card account. */
export type CardAccountTransactionCollectionCompleteArgs = {
  options?: InputMaybe<CardTransactionsFilteringOptions>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** Collection containing all transactions for the underlying card account. */
export type CardAccountTransactionCollectionPendingArgs = {
  dateRange?: InputMaybe<DateRangeInput>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** Collection containing all transactions for the underlying card account. */
export type CardAccountTransactionCollectionSettledArgs = {
  dateRange?: InputMaybe<DateRangeInput>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** A paginated card account transactions view. */
export type CardAccountTransactionPage = Paginated & {
  __typename?: 'CardAccountTransactionPage'
  /** Card account id for these transactions */
  cardAccountId: Scalars['ID']['output']
  /** Metadata that can be used to fetch the next page. */
  cursor: PaginationCursor
  /** End date for these transactions */
  endDate?: Maybe<Scalars['LocalDate']['output']>
  /** The transactions contained by this page. */
  items: Array<CardAccountTransaction>
  /** Start date for these transactions */
  startDate?: Maybe<Scalars['LocalDate']['output']>
}

export type CardAccountType = {
  __typename?: 'CardAccountType'
  /** Network carrying this cardAccount. */
  cardNetwork: CardNetwork
  /** Card account type id */
  id: Scalars['UUID']['output']
  /** Max credit limit possible */
  maxBalanceLimit: Scalars['Decimal']['output']
  /** Min credit limit possible */
  minBalanceLimit: Scalars['Decimal']['output']
  /** Minimum Payment calculation object */
  minimumPaymentCalculation: MinimumPaymentCalculation
  /** Monthly membership fee (if any) */
  monthlyMembershipFees: Scalars['Decimal']['output']
  /** Card Type (Program) name */
  name: Scalars['String']['output']
  /** Reflects the id of the program in the issuer processor system (i2c) */
  programId: Scalars['String']['output']
}

export enum CardAccountWorkflowState {
  Approved = 'APPROVED',
  BankDataprep = 'BANK_DATAPREP',
  ManualReview = 'MANUAL_REVIEW',
  Snooze = 'SNOOZE',
  UraWait = 'URA_WAIT',
}

export type CardAccountWorkflowStatistics = {
  __typename?: 'CardAccountWorkflowStatistics'
  count: Scalars['Int']['output']
  oldestManualReviewCardAccountId?: Maybe<Scalars['UUID']['output']>
  oldestManualReviewQueuedAt?: Maybe<Scalars['DateTime']['output']>
  state: CardAccountWorkflowState
  subState?: Maybe<Scalars['String']['output']>
}

/** Identifies the current status of a card account agreement document. */
export enum CardAgreementStatus {
  /** The user has agreed to the card account agreement terms. */
  Agreed = 'AGREED',
  /** The user has neither agreed-to nor rejected the card account agreement terms. */
  Pending = 'PENDING',
  /** The user has rejected the card account agreement terms. */
  Rejected = 'REJECTED',
}

/** Labels designated to each agreement document type. */
export enum CardAgreementType {
  /** Beta testing agreement document */
  BetaTestingAgreement = 'BETA_TESTING_AGREEMENT',
  /** Label for the debit authorization agreements document. */
  DebitAuthorizationAgreement = 'DEBIT_AUTHORIZATION_AGREEMENT',
  /** Label for the electronic repayments agreements document. */
  ElectronicRepayments = 'ELECTRONIC_REPAYMENTS',
  /** Label for the installment payments agreements document. */
  InstallmentAgreement = 'INSTALLMENT_AGREEMENT',
  /** Label for the terms and conditions agreements document. */
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
}

export type CardBalanceEntry = {
  __typename?: 'CardBalanceEntry'
  /** Associated transaction Id for balance entry */
  associatedTransactionId?: Maybe<Scalars['ID']['output']>
  /** Balance amount for balance entry */
  balanceAmount: Scalars['Decimal']['output']
  /** Balance datetime for balance entry */
  balanceDateTime: Scalars['DateTime']['output']
  /** Balance type for balance entry */
  balanceType: BalanceType
}

export type CardBalanceEntryCollection = {
  __typename?: 'CardBalanceEntryCollection'
  /** Provides a paginated response including available balances sorted from most to least recent */
  available?: Maybe<CardBalancePage>
  /** External ids associated with the account */
  externalIds: Array<Maybe<Scalars['String']['output']>>
  /** Provides a paginated response including ledger balances sorted from most to least recent */
  ledger?: Maybe<CardBalancePage>
  /** Provides a paginated response including minimum due balances sorted from most to least recent */
  minimumDue?: Maybe<CardBalancePage>
  /** Provides a paginated response including past due balances sorted from most to least recent */
  pastDue?: Maybe<CardBalancePage>
  /** Provides a paginated response including statement balances sorted from most to least recent */
  statement?: Maybe<CardBalancePage>
}

export type CardBalanceEntryCollectionAvailableArgs = {
  options?: InputMaybe<CardBalanceFilterOptions>
  pageSelector?: InputMaybe<PageSelectorInput>
}

export type CardBalanceEntryCollectionLedgerArgs = {
  options?: InputMaybe<CardBalanceFilterOptions>
  pageSelector?: InputMaybe<PageSelectorInput>
}

export type CardBalanceEntryCollectionMinimumDueArgs = {
  options?: InputMaybe<CardBalanceFilterOptions>
  pageSelector?: InputMaybe<PageSelectorInput>
}

export type CardBalanceEntryCollectionPastDueArgs = {
  options?: InputMaybe<CardBalanceFilterOptions>
  pageSelector?: InputMaybe<PageSelectorInput>
}

export type CardBalanceEntryCollectionStatementArgs = {
  options?: InputMaybe<CardBalanceFilterOptions>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** Filters for card balance entries */
export type CardBalanceFilterOptions = {
  /** External id to pull balance entries for. */
  externalId: Scalars['String']['input']
}

/** A paginated card balances. */
export type CardBalancePage = Paginated & {
  __typename?: 'CardBalancePage'
  /** Metadata that can be used to fetch the next page. */
  cursor: PaginationCursor
  /** Card external id for these balance entries */
  externalId: Scalars['String']['output']
  /** The transactions contained by this page. */
  items: Array<CardBalanceEntry>
}

/** Collection of cards held under the given card account. */
export type CardCollection = {
  __typename?: 'CardCollection'
  /**
   * Retrieves the issued cards matching the specified states. All issued cards get return when `states`
   * is either empty or not specified.
   */
  issued: Array<Card>
}

/** Collection of cards held under the given card account. */
export type CardCollectionIssuedArgs = {
  states?: InputMaybe<Array<InputMaybe<CardStatusCode>>>
}

/** Canonical set of card deactivation reasons */
export enum CardDeactivationReasonCode {
  /** Indicates that the card was deactivated because it was reported lost. */
  Lost = 'LOST',
  /** Indicates that the card was deactivate because a replacement card was issued. */
  Reissued = 'REISSUED',
  /** Indicates that the card was deactivated because it was reported stolen. */
  Stolen = 'STOLEN',
}

/** Collection of installment plans associated with the given card account. */
export type CardInstallmentPlanCollection = {
  __typename?: 'CardInstallmentPlanCollection'
  /** Gets the current installment plan if one exists. If no plan exists this member is null. */
  current?: Maybe<CardAccountInstallmentPlan>
  /** Flag to signify if user is eligible for installments. */
  eligibleForInstallments: Scalars['Boolean']['output']
  /**
   * Gets a potential installment plan for the user. When an installment plan is active there is not potential
   * new installment plan; so this member will be null.
   */
  potential?: Maybe<CardAccountInstallmentPlan>
}

export type CardManualReviewAction = {
  __typename?: 'CardManualReviewAction'
  cardAccount?: Maybe<CardAccount>
  manualReviewTask?: Maybe<CardManualReviewTask>
  manualReviewTaskHistory?: Maybe<Array<CardManualReviewTaskHistory>>
  userFullName?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['ID']['output']>
}

export enum CardManualReviewReasonCodes {
  BankingDataPrepException = 'BANKING_DATA_PREP_EXCEPTION',
  EnablerUnderwritingException = 'ENABLER_UNDERWRITING_EXCEPTION',
  FraudChecksException = 'FRAUD_CHECKS_EXCEPTION',
  KycChecksException = 'KYC_CHECKS_EXCEPTION',
  Other = 'OTHER',
  PaymentMethodsMissingOrInvalid = 'PAYMENT_METHODS_MISSING_OR_INVALID',
  PaymentPayovertimeEligibility = 'PAYMENT_PAYOVERTIME_ELIGIBILITY',
}

export type CardManualReviewTask = {
  __typename?: 'CardManualReviewTask'
  assigneeId?: Maybe<Scalars['ID']['output']>
  cardAccount?: Maybe<CardAccount>
  cardAccountId?: Maybe<Scalars['ID']['output']>
  reviewReasonCode?: Maybe<CardManualReviewReasonCodes>
  reviewReasonMessage?: Maybe<Scalars['String']['output']>
  taskId?: Maybe<Scalars['String']['output']>
}

export type CardManualReviewTaskHistory = {
  __typename?: 'CardManualReviewTaskHistory'
  assignedAt?: Maybe<Scalars['DateTime']['output']>
  assigneeId?: Maybe<Scalars['ID']['output']>
  cardAccountId?: Maybe<Scalars['ID']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  reviewId?: Maybe<Scalars['ID']['output']>
  reviewReasonCode?: Maybe<CardManualReviewReasonCodes>
  reviewReasonMessage?: Maybe<Scalars['String']['output']>
  taskId?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CardMerchant = {
  __typename?: 'CardMerchant'
  /** Code for this merchant's category */
  categoryCode?: Maybe<Scalars['String']['output']>
  /** Merchant's location city */
  city?: Maybe<Scalars['String']['output']>
  /** Merchant's location country */
  country?: Maybe<Scalars['String']['output']>
  /** This merchant's id */
  id: Scalars['ID']['output']
  /** Type of device for this merchant */
  merchantDeviceType?: Maybe<CardMerchantDeviceType>
  /** Name of this merchant */
  name?: Maybe<Scalars['String']['output']>
  /** Merchant's location state */
  state?: Maybe<Scalars['String']['output']>
}

export enum CardMerchantDeviceType {
  Api = 'API',
  Atm = 'ATM',
  Irv = 'IRV',
  Pos = 'POS',
  Web = 'WEB',
}

/** Enumeration of potential network operators for the cardAccount. */
export enum CardNetwork {
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
}

/** Represents a collection of the eligible dates a payment can be rescheduled to */
export type CardPaymentAllowedRescheduleDatesCollection = {
  __typename?: 'CardPaymentAllowedRescheduleDatesCollection'
  /**
   * List of CardPaymentRescheduleDate object containing a date and useful information for the process
   * If the date is not allowed to be selected, than it shouldn't be on this list (for example, weekends and Bank Holidays)
   */
  allowed: Array<CardPaymentRescheduleDate>
  /** The referenced statement due date used to calculate the allowed dates collection */
  statementDueDate?: Maybe<Scalars['LocalDate']['output']>
}

/** Collection of payments associated with the given card account. */
export type CardPaymentCollection = {
  __typename?: 'CardPaymentCollection'
  /** Collection of all payments on this card account */
  all?: Maybe<Array<Payment>>
  /** Collection of payments that have been processed. */
  completed?: Maybe<Array<Payment>>
  /** Collection of payments that have not been processed. */
  pending?: Maybe<Array<Payment>>
  /** Provides information regarding payment periods and due dates */
  periods?: Maybe<PaymentPeriodCollection>
  /**
   * Collection of potential automatic payment schedule details.
   * Allows the user to see the automatic payment schedule before committing to it.
   */
  potential?: Maybe<PotentialAutomaticPaymentCollection>
  /**
   * Collection of upcoming automatic payment schedule details.
   * Number of payments must be between 2 and 12.
   */
  schedule?: Maybe<Array<AutomaticPaymentSchedule>>
}

/** Collection of payments associated with the given card account. */
export type CardPaymentCollectionAllArgs = {
  dateRange?: InputMaybe<DateRangeInput>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** Collection of payments associated with the given card account. */
export type CardPaymentCollectionCompletedArgs = {
  dateRange?: InputMaybe<DateRangeInput>
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** Collection of payments associated with the given card account. */
export type CardPaymentCollectionPendingArgs = {
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** Collection of payments associated with the given card account. */
export type CardPaymentCollectionScheduleArgs = {
  numberOfPayments?: InputMaybe<Scalars['Int']['input']>
}

/** Canonical set of payment date statuses */
export enum CardPaymentDateStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

/**
 * Collection of card payment methods that the user has registered to their account and linked with the given card
 * account.
 */
export type CardPaymentMethodCollection = {
  __typename?: 'CardPaymentMethodCollection'
  /** All payment methods that have been associated with the given card account. */
  all?: Maybe<Array<CardPaymentMethodType>>
  /**
   * The payment method, associated with the given card account, that the user selected to be the default payment
   * method.
   */
  default?: Maybe<CardPaymentMethodType>
  /**
   * The sole money-order payment method associated with the card account. This payment method is used exclusively by
   * customer support.
   */
  moneyOrder?: Maybe<CheckPaymentMethod>
}

/**
 * Collection of card payment methods that the user has registered to their account and linked with the given card
 * account.
 */
export type CardPaymentMethodCollectionAllArgs = {
  typeCodeFilter?: InputMaybe<Array<PaymentMethodTypeCode>>
}

/** All payment methods that can be linked to a card account. */
export type CardPaymentMethodType = AchPaymentMethod | CheckPaymentMethod | DebitCardPaymentMethod

export type CardPaymentPeriod = {
  __typename?: 'CardPaymentPeriod'
  /** Ending date and time for the period */
  endDateTime: Scalars['DateTime']['output']
  /** Due date and time for the period */
  paymentDueAt: Scalars['DateTime']['output']
  /** Starting date and time for the period */
  startDateTime: Scalars['DateTime']['output']
}

/** Object wrapping a date and useful information for the payment rescheduling process */
export type CardPaymentRescheduleDate = {
  __typename?: 'CardPaymentRescheduleDate'
  /** The date object (with no time) */
  date: Scalars['LocalDate']['output']
  /** Possible restriction to the date or NO_RESTRICTION */
  restriction: CardPaymentRescheduleDateRestriction
}

/** Enumeration for possible restrictions for a date to reschedule a payment */
export enum CardPaymentRescheduleDateRestriction {
  /** The date can bring an impact on the account status (current -> risk of DQ) */
  CloseToStatementDueDateAccountCurrentDqRisk = 'CLOSE_TO_STATEMENT_DUE_DATE_ACCOUNT_CURRENT_DQ_RISK',
  /** The date can bring an impact on the account status (DQ -> risk of 30 days past due) */
  CloseToStatementDueDateAccountDq_30DpdRisk = 'CLOSE_TO_STATEMENT_DUE_DATE_ACCOUNT_DQ_30DPD_RISK',
  /** The date can bring an impact on the account status (30 DPD DQ -> risk of 60 days past due) */
  CloseToStatementDueDateAccountDq_60DpdRisk = 'CLOSE_TO_STATEMENT_DUE_DATE_ACCOUNT_DQ_60DPD_RISK',
  /** The date can bring an impact on the account status (60 DPD DQ -> risk of 90 days past due) */
  CloseToStatementDueDateAccountDq_90DpdRisk = 'CLOSE_TO_STATEMENT_DUE_DATE_ACCOUNT_DQ_90DPD_RISK',
  /** The date can bring an impact on the account status (90 DPD DQ -> risk of 120 days past due) */
  CloseToStatementDueDateAccountDq_120DpdRisk = 'CLOSE_TO_STATEMENT_DUE_DATE_ACCOUNT_DQ_120DPD_RISK',
  /** The date can bring an impact on the account status (120 DPD DQ -> risk of 150 days past due) */
  CloseToStatementDueDateAccountDq_150DpdRisk = 'CLOSE_TO_STATEMENT_DUE_DATE_ACCOUNT_DQ_150DPD_RISK',
  /** The date can bring an impact on the account status (150 DPD DQ -> risk of account closure) */
  CloseToStatementDueDateAccountDqChargeOffRisk = 'CLOSE_TO_STATEMENT_DUE_DATE_ACCOUNT_DQ_CHARGE_OFF_RISK',
  /** The date is allowed to reschedule and there is no restriction */
  NoRestriction = 'NO_RESTRICTION',
}

/** Enumeration for the possible reasons for rescheduling a payment */
export enum CardPaymentRescheduleReason {
  /** Financial emergency */
  FinancialEmergency = 'FINANCIAL_EMERGENCY',
  /** Use when customer is not able to specify any reason */
  NotSpecified = 'NOT_SPECIFIED',
  /** Any other reason - may require a note to be also included */
  Other = 'OTHER',
  /** Calculated payroll does not match the user needs */
  PayrollMisalignment = 'PAYROLL_MISALIGNMENT',
}

export enum CardPaymentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  InProgressAchTransit = 'IN_PROGRESS_ACH_TRANSIT',
  Pending = 'PENDING',
}

/** Enum to represent card payment types */
export enum CardPaymentType {
  AutoPay = 'AUTO_PAY',
  Custom = 'CUSTOM',
  PayOverTime = 'PAY_OVER_TIME',
}

export type CardStaticAgreement = {
  __typename?: 'CardStaticAgreement'
  /** The type of this agreement document */
  agreementType: CardStaticAgreementType
  /** pre-signed url for this agreement document */
  presignedUrl: Scalars['String']['output']
}

/**
 * Enumerates static agreement document types for agreements that are shared by
 * all users
 */
export enum CardStaticAgreementType {
  /** Generic card beta testing agreement */
  BetaTestingAgreement = 'BETA_TESTING_AGREEMENT',
  /** Generic card agreement document */
  CardAgreement = 'CARD_AGREEMENT',
  /** Generic Possible terms of service document */
  TermsOfService = 'TERMS_OF_SERVICE',
}

/** Enumerates the canonical set of states for a card manifestation. */
export enum CardStatusCode {
  /**
   * Indicates that the card is active.
   *
   * Successor states: DEACTIVATED
   */
  Active = 'ACTIVE',
  /** Indicates that the card has been deactivated. This is a terminal state. */
  Deactivated = 'DEACTIVATED',
  /**
   * Indicates that the card has not been activated yet.
   *
   * Successor states: ACTIVE, DEACTIVATED
   */
  Pending = 'PENDING',
  /**
   * Indicates that the card is SUSPENDED.
   *
   * Successor states: SUSPENDED
   */
  Suspended = 'SUSPENDED',
}

/** Represents all potential card states. */
export type CardStatuses =
  | ActiveCardStatus
  | DeactivatedCardStatus
  | PendingCardStatus
  | SuspendedCardStatus

/** Details for the SUSPENDED restriction applied to an ACTIVE card manifestation. */
export type CardSuspendedRestriction = ActiveCardRestriction & {
  __typename?: 'CardSuspendedRestriction'
  /** Code for this restrictive sub-state. This field's value is always SUSPENDED. */
  code: ActiveCardRestrictionCode
  /** Indicates when the card transitioned into this restrictive sub-state. */
  occurredAt: Scalars['DateTime']['output']
  /** Indicates why the card was suspsended, a null value indciates the reason was not specified. */
  reason?: Maybe<CardSuspensionReasonCode>
}

/** Canonical set of card suspension reasons */
export enum CardSuspensionReasonCode {
  /** Indicates that the card was suspended because fraud was dectected or reported on this account. */
  Fraud = 'FRAUD',
  /** Indicates that the card was suspended because a payment for this account failed. */
  PaymentFailed = 'PAYMENT_FAILED',
  /** Indicates that the card was suspended because a user has entered in to a POT agreement */
  PayLaterAgreement = 'PAY_LATER_AGREEMENT',
}

export type CardTransactionDispute = {
  __typename?: 'CardTransactionDispute'
  /** Dollar amount of this dispute */
  amount: Scalars['Decimal']['output']
  /** Time that this dispute was initiated */
  dateTime: Scalars['DateTime']['output']
  /** Current status of this dispute */
  status: CardTransactionDisputeStatus
  /** Type of dispute */
  type: CardTransactionDisputeType
}

export enum CardTransactionDisputeStatus {
  Approved = 'APPROVED',
  CardHolderWin = 'CARD_HOLDER_WIN',
  InProgress = 'IN_PROGRESS',
  MerchantWin = 'MERCHANT_WIN',
  Rejected = 'REJECTED',
}

export enum CardTransactionDisputeType {
  Payment = 'PAYMENT',
  Transaction = 'TRANSACTION',
}

export enum CardTransactionStatus {
  Pending = 'PENDING',
  Posted = 'POSTED',
}

/** Filters that may be applied to card transactions */
export type CardTransactionsFilteringOptions = {
  /** Date range filter */
  dateRange: DateRangeInput
  /**
   * Returns all card account related transactions including declined transactions, fees etc.
   * Posted vs. Authorized transactions are also returned individually vs. rolled up
   */
  includeEverything: Scalars['Boolean']['input']
}

/** Indicates the real-world of this card manifestation. */
export enum CardType {
  /** The describes a physical card. */
  PhysicalCard = 'PHYSICAL_CARD',
  /** The describes virtualizated physical card (i.e. a physical card stored in an app wallet). */
  PhysicalVirtualCard = 'PHYSICAL_VIRTUAL_CARD',
}

/** Affixes change timestamp metadata onto some underlying object; examples include users and loans. */
export type ChangeTracked = {
  /** Timestamp tracking the underlying object's creation. */
  createdAt: Scalars['DateTime']['output']
  /** Timestamp tracking the underlying object's last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Charged off status of a card */
export enum ChargedOffCardStatus {
  /** Charged off at an accelerated rate (earlier than normal) //TODO confirm this */
  Accelerated = 'ACCELERATED',
  /** Charged off due to to Bankruptcy */
  Bankruptcy = 'BANKRUPTCY',
  /** Charged off due to charge off program rules */
  Contractual = 'CONTRACTUAL',
  /** Card is not charged off */
  None = 'NONE',
}

/** Represents a loan in the CHARGEDOFF state. */
export type ChargedOffLoanStatus = BaseLoanStatus & {
  __typename?: 'ChargedOffLoanStatus'
  /** Indicates the loan amount disbursed to the borrower. */
  amountBorrowed?: Maybe<Scalars['Decimal']['output']>
  /** The amount of principal remaining at the time of charge off */
  amountChargedOff: Scalars['Decimal']['output']
  /** Indicates the remaining balance owed by the borrower. */
  amountOutstanding?: Maybe<Scalars['Decimal']['output']>
  /** Canonical loan status code; the value is always CHARGEDOFF. */
  code: LoanStatusCode
  /** Indicates when the loan transitioned into the CHARGEDOFF state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * The time the loan transitioned to the collections substatus.
   * Null if that has not occurred
   */
  sentToCollectionsAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

/** Payment method for a check */
export type CheckPaymentMethod = PaymentMethod & {
  __typename?: 'CheckPaymentMethod'
  /** List of all payments networks available for this payment method */
  availableNetworks: Array<ProcessorNetwork>
  /** Banking payment instrument id (this is a global ID for this payment method relevant to our banking subsystem) */
  bankingPaymentInstrumentId: Scalars['ID']['output']
  /** Canonical payment method type category. This value is always `CHECK`. */
  code: PaymentMethodTypeCode
  /** Timestamp tracking the payment instrument creation. */
  createdAt: Scalars['DateTime']['output']
  /** Indicates the payment directions for which this is a default method */
  defaultStatus: Array<DefaultPaymentMethodCode>
  /**
   * The fundingSource for the payment processor
   * @deprecated In case we have multiple funding sources for the same network its returning only the first one
   */
  fundingSource?: Maybe<FundingSource>
  /**
   * Id for the fundingSource for the payment processor
   * @deprecated In case we have multiple funding sources for the same network its returning only the first one
   */
  fundingSourceId?: Maybe<Scalars['UUID']['output']>
  /** Unique synthetic identifier for this check. */
  id: Scalars['ID']['output']
  /** The last digits of the payment instrument */
  mask?: Maybe<Scalars['String']['output']>
  /** Gets the next feasible payment date for a payment method */
  nextPaymentDate: NextPaymentDate
  /** The first digits of the payment instrument */
  prefix?: Maybe<Scalars['String']['output']>
  /** Captures the payment methods current status. */
  status: PaymentMethodStatus
  /** Timestamp tracking the payment instrument last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Payment method for a check */
export type CheckPaymentMethodNextPaymentDateArgs = {
  desiredSettlementTime: Scalars['DateTime']['input']
}

export type ClosedLoanAggregateStatus = LoanAggregateStatusType & {
  __typename?: 'ClosedLoanAggregateStatus'
  code: LoanAggregateStatusCode
  conversion: LoanConversion
  disbursement?: Maybe<LoanDisbursement>
  /**
   * How many loans, total, the user has applied for
   * @deprecated Moved to UserLoanCollection
   */
  loanCount: Scalars['Int']['output']
  /** Contains payments only if the most recent loan had valid payments */
  payments: LoanPaymentCollection
  prequalAmount?: Maybe<Scalars['Decimal']['output']>
  reapplyOn: Scalars['DateTime']['output']
  reason: LoanClosedReasonCode
}

/** Enumeration of cohort phases for the cardAccount. */
export enum CohortPhase {
  Crut = 'CRUT',
  Internal = 'INTERNAL',
  None = 'NONE',
  OnboardingExp1 = 'ONBOARDING_EXP1',
  Pga = 'PGA',
  PgaBuw = 'PGA_BUW',
  Phase1 = 'PHASE1',
  Phase2 = 'PHASE2',
  Phase3 = 'PHASE3',
}

/** Enumeration of cohort user type for the cardAccount. */
export enum CohortUserTpe {
  LoanGrad = 'LOAN_GRAD',
  LoanState = 'LOAN_STATE',
  None = 'NONE',
  NonLoanState = 'NON_LOAN_STATE',
}

/** Details of the communication sent to the User */
export type CommunicationAuditTrail = {
  __typename?: 'CommunicationAuditTrail'
  createdAt: Scalars['DateTime']['output']
  html?: Maybe<Scalars['String']['output']>
  templateId: Scalars['String']['output']
  text?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  type: Scalars['String']['output']
}

/** User communication preferences */
export type CommunicationPreferences = {
  __typename?: 'CommunicationPreferences'
  blacklisted?: Maybe<Scalars['Boolean']['output']>
  complaint?: Maybe<Scalars['Boolean']['output']>
  doNotContact?: Maybe<Scalars['Boolean']['output']>
  escalate?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  loginContact?: Maybe<Scalars['Boolean']['output']>
}

export type CommunicationTemplate = {
  __typename?: 'CommunicationTemplate'
  config?: Maybe<CommunicationTemplateConfiguration>
  description?: Maybe<Scalars['String']['output']>
  emailHtmlTemplate?: Maybe<Scalars['String']['output']>
  emailTextTemplate?: Maybe<Scalars['String']['output']>
  pushTemplate?: Maybe<Scalars['String']['output']>
  smsTemplate?: Maybe<Scalars['String']['output']>
  templateId: Scalars['String']['output']
  title: Scalars['String']['output']
  uraTemplate?: Maybe<Scalars['String']['output']>
  voiceTemplate?: Maybe<Scalars['String']['output']>
  zendeskTemplate?: Maybe<Scalars['String']['output']>
}

export type CommunicationTemplateConfiguration = {
  __typename?: 'CommunicationTemplateConfiguration'
  defaultTypes?: Maybe<Array<Maybe<MessageType>>>
  fromAddress?: Maybe<Scalars['String']['output']>
  noReply?: Maybe<Scalars['Boolean']['output']>
  titles?: Maybe<Array<Maybe<KeyValuePair>>>
  variables?: Maybe<Array<Maybe<TemplateVariable>>>
}

export type CommunicationTemplateConfigurationInput = {
  defaultTypes?: InputMaybe<Array<InputMaybe<MessageType>>>
  fromAddress?: InputMaybe<Scalars['String']['input']>
  noReply?: InputMaybe<Scalars['Boolean']['input']>
  titles?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>
  variables?: InputMaybe<Array<InputMaybe<TemplateVariableInput>>>
}

export type CommunicationTemplateInput = {
  config?: InputMaybe<CommunicationTemplateConfigurationInput>
  description: Scalars['String']['input']
  emailHtmlTemplate?: InputMaybe<Scalars['String']['input']>
  emailTextTemplate?: InputMaybe<Scalars['String']['input']>
  pushTemplate?: InputMaybe<Scalars['String']['input']>
  smsTemplate?: InputMaybe<Scalars['String']['input']>
  templateId: Scalars['String']['input']
  title: Scalars['String']['input']
  uraTemplate?: InputMaybe<Scalars['String']['input']>
  voiceTemplate?: InputMaybe<Scalars['String']['input']>
  zendeskTemplate?: InputMaybe<Scalars['String']['input']>
}

export type CommunicationTemplateResponse = {
  __typename?: 'CommunicationTemplateResponse'
  success: Scalars['Boolean']['output']
}

/** Basic information about the communication template */
export type CommunicationTemplateSummary = {
  __typename?: 'CommunicationTemplateSummary'
  description: Scalars['String']['output']
  templateId: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ComplexRate = {
  __typename?: 'ComplexRate'
  amountMax?: Maybe<Scalars['Decimal']['output']>
  amountMin?: Maybe<Scalars['Decimal']['output']>
  rate?: Maybe<Scalars['Decimal']['output']>
}

/** Canonical set of funding compliance codes */
export enum ComplianceCodes {
  /** Code used for Coastal bank associated loan funding transfers */
  Coastal = 'COASTAL',
  /** Code used for Special Purpose Vehicle buyback from Coastal */
  CoastalBuybackSpv = 'COASTAL_BUYBACK_SPV',
  /** Code used for Coastal bank associated credit card product funding transfers */
  CoastalCc = 'COASTAL_CC',
  /** Code used for all product funding transfers by default */
  Default = 'DEFAULT',
  /** Code used for the Special Purpose Vehicle */
  PossibleSpv = 'POSSIBLE_SPV',
  /** Code used for texas state loan funding transfers */
  UsTx = 'US_TX',
  /** Code used for washington state loan funding transfers */
  UsWa = 'US_WA',
}

export type ConversionOptionLoanPayment = {
  __typename?: 'ConversionOptionLoanPayment'
  amount: Scalars['Decimal']['output']
  id?: Maybe<Scalars['UUID']['output']>
  originalDate?: Maybe<Scalars['DateTime']['output']>
  rescheduledDate?: Maybe<Scalars['DateTime']['output']>
}

export type ConversionOptionLoanTerms = {
  __typename?: 'ConversionOptionLoanTerms'
  stateName?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  variantId?: Maybe<Scalars['UUID']['output']>
}

export type CreateUserDocumentInput = {
  /** Document is already uploaded and has a provided ID that should be used in this request */
  documentId: Scalars['UUID']['input']
  /** Original file name */
  fileName: Scalars['String']['input']
  /** Relates to loan (optional) */
  loanId?: InputMaybe<Scalars['UUID']['input']>
  /** Document sub type (optional) */
  subType?: InputMaybe<UserDocumentSubType>
  /** Document type */
  type: UserDocumentType
  /** User ID to correlate the doc */
  userId: Scalars['UUID']['input']
}

/** Input responsible for date range filter. */
export type DateRangeInput = {
  /** The upper date range; inclusive. */
  end?: InputMaybe<Scalars['LocalDate']['input']>
  /** The lower date range; inclusive. */
  start: Scalars['LocalDate']['input']
}

/** Enumerates the canonical set of card account deactivation reasons. */
export enum DeactivatedCardAccountReasonCode {
  /** The card account was deactivated due to user bankruptcy */
  Bankrupt = 'BANKRUPT',
  /** The card account was deactivated due to charge off of an unpaid balance. */
  Chargedoff = 'CHARGEDOFF',
  /** The card account was charged-off and sold */
  DebtSold = 'DEBT_SOLD',
  /** The card account was deactivated due to user passing away */
  Deceased = 'DECEASED',
  /** The card account was deactivated due to reported fraud against the account. */
  Fraud = 'FRAUD',
  /** The card account was deactivated due to being reported lost. */
  Lost = 'LOST',
  /** No reason given (ex: customer requested the account be terminated) */
  None = 'NONE',
  /** The card account was deactivated due to being replaced. */
  Replaced = 'REPLACED',
  /** The card account was deactivated due to being reported stolen. */
  Stolen = 'STOLEN',
}

/** Details for a card account in the DEACTIVATED state. */
export type DeactivatedCardAccountStatus = BaseCardAccountStatus & {
  __typename?: 'DeactivatedCardAccountStatus'
  /** Indicates the frequency of auto-pay for this account */
  autopayFrequency: PaymentFrequency
  /** Defines how the card account calculates auto-pay payment amounts */
  autopayModel: AutopayModel
  /** Card account balance */
  balance: CardAccountBalance
  /** Card account overdue status */
  cardAccountOverdueStatus?: Maybe<CardAccountOverdueStatus>
  /** Returns true if the card chargedOffStatus is anything other than NONE */
  chargedOffStatus?: Maybe<ChargedOffCardStatus>
  /** Canonical card account status code; the value is always DEACTIVATED. */
  code: CardAccountStatusCode
  /** Number of days the account it delinquent */
  delinquentNumberOfDays?: Maybe<Scalars['Int']['output']>
  /** Indicates when the card account transitioned into the DEACTIVATED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Overdue information for autopay payments */
  overduePaymentInfo?: Maybe<OverDuePaymentInfo>
  /** Overdue statement information for the account */
  overdueStatementInfo?: Maybe<OverDuePaymentInfo>
  /** Indicates the reason this card account transitioned into the DEACTIVATED state. */
  reasonCode?: Maybe<DeactivatedCardAccountReasonCode>
  /** Canonical card account subStatus code; the value is always DEACTIVATED. */
  subStatus: CardAccountSubStatus
}

/** Details for a card in the DEACTIVATED state. */
export type DeactivatedCardStatus = BaseCardStatus & {
  __typename?: 'DeactivatedCardStatus'
  /** Canonical card status code; the value is always DEACTIVATED. */
  code: CardStatusCode
  /** Indicates when the card transitioned into the DEACTIVATED state. */
  occurredAt: Scalars['DateTime']['output']
  /** Indicates why the card was deactived, a null value indicates a reason was not specified. */
  reason?: Maybe<CardDeactivationReasonCode>
}

/** Payment method for a debit card registration. */
export type DebitCardPaymentMethod = PaymentMethod & {
  __typename?: 'DebitCardPaymentMethod'
  /** List of all payments networks available for this payment method */
  availableNetworks: Array<ProcessorNetwork>
  /** Banking payment instrument id (this is a global ID for this payment method relevant to our banking subsystem) */
  bankingPaymentInstrumentId: Scalars['ID']['output']
  /** Information about the underlying debit card. */
  card: LinkedDebitCard
  /** Canonical payment method type category. This value is always `DEBIT_CARD`. */
  code: PaymentMethodTypeCode
  /** Timestamp tracking the payment instrument creation. */
  createdAt: Scalars['DateTime']['output']
  /** Indicates the payment directions for which this is a default method */
  defaultStatus: Array<DefaultPaymentMethodCode>
  /** expiration date of card in format YYYYMM */
  expDate?: Maybe<Scalars['String']['output']>
  /**
   * The fundingSource for the payment processor
   * @deprecated In case we have multiple funding sources for the same network its returning only the first one
   */
  fundingSource?: Maybe<FundingSource>
  /**
   * Id for the fundingSource for the payment processor
   * @deprecated In case we have multiple funding sources for the same network its returning only the first one
   */
  fundingSourceId?: Maybe<Scalars['UUID']['output']>
  /** Unique synthetic identifier for this debit card. */
  id: Scalars['ID']['output']
  /** The last 4 digits of the debit card number */
  mask?: Maybe<Scalars['String']['output']>
  /** Gets the next feasible payment date for a payment method */
  nextPaymentDate: NextPaymentDate
  /** The first 8 digits of the debit card number */
  prefix?: Maybe<Scalars['String']['output']>
  /** Captures the payment methods current status. */
  status: PaymentMethodStatus
  /** Timestamp tracking the payment instrument last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Payment method for a debit card registration. */
export type DebitCardPaymentMethodNextPaymentDateArgs = {
  desiredSettlementTime: Scalars['DateTime']['input']
}

/** Input object for adding a new debit card payment method */
export type DebitPaymentMethodInput = {
  /** Card Verification Value (CVV) - 3 or 4 digits */
  cardCvv: Scalars['String']['input']
  /** Expiration date. The expected format is 'YYYYMM'. */
  cardExp: Scalars['String']['input']
  /** Debit card number - 16 digits */
  cardNumber: Scalars['String']['input']
  /** Cardholder name */
  cardholderName?: InputMaybe<Scalars['String']['input']>
  /**
   * The linked account reference to be associated with the new payment method
   * If null, then no linked account will be associated to it
   */
  linkedAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type DecisioningLoanAggregateStatus = LoanAggregateStatusType & {
  __typename?: 'DecisioningLoanAggregateStatus'
  code: LoanAggregateStatusCode
  conversion: LoanConversion
  delayed: Scalars['Boolean']['output']
}

/** Indicates a default method for payments by direction of the payment */
export enum DefaultPaymentMethodCode {
  /** Payment method is the default for disbursements to the the customer */
  DisbursementDefault = 'DISBURSEMENT_DEFAULT',
  NotDefault = 'NOT_DEFAULT',
  /** Payment method is the default for payments from the the customer */
  PaymentDefault = 'PAYMENT_DEFAULT',
}

/** Represents a deleted user. */
export type DeletedUserStatus = BaseUserStatus & {
  __typename?: 'DeletedUserStatus'
  /** Canonical user status code; the value is always DELETED. */
  code: UserStatusCode
}

/** Enumerates the canonical reasons for disabling an account. */
export enum DisabledStatusCategory {
  /** Indicates the user has declared bankrupcy. */
  Bankrupt = 'BANKRUPT',
  /** Indicates the account was disabled due to death. */
  Deceased = 'DECEASED',
  /** Indicates the account was disabled due to being a duplicate. */
  Duplicate = 'DUPLICATE',
  /** Indicates that an unrecognized value was specified. */
  Unrecognized = 'UNRECOGNIZED',
}

/** Represents the DISABLED state. */
export type DisabledUserStatus = BaseUserStatus & {
  __typename?: 'DisabledUserStatus'
  /** Category indicating why the disabled status was placed on this account. */
  category?: Maybe<DisabledStatusCategory>
  /** Canonical user status code; the value is always DISABLED. */
  code: UserStatusCode
  /** Brief explanation of why the account was disabled. */
  reason?: Maybe<Scalars['String']['output']>
}

/** Captures information related to a Dispute */
export type Dispute = {
  __typename?: 'Dispute'
  /** Timestamp tracking when the Dispute is completed. */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  /** Timestamp tracking the Dispute creation. */
  createdAt: Scalars['DateTime']['output']
  /** Unique synthetic identifier for the entity which this Dispute relates to. */
  entityId: Scalars['ID']['output']
  /** Identifies which type of entity this Dispute is relates to. */
  entityType: DisputeEntityType
  /** Unique synthetic identifier for this Dispute. */
  id: Scalars['ID']['output']
  /** Canonical Dispute reason type code. */
  reasonType: DisputeReasonType
  /** Canonical Dispute status code. */
  status: DisputeStatus
  /** Canonical Dispute type code. */
  type: DisputeType
}

/** Canonical set of entity types that notes can be attached to. */
export enum DisputeEntityType {
  Card = 'CARD',
  Loan = 'LOAN',
}

/** Canonical set of dispute reason type codes. */
export enum DisputeReasonType {
  BillingError = 'BILLING_ERROR',
  CreditReporting = 'CREDIT_REPORTING',
  Other = 'OTHER',
}

/** Canonical set of dispute status codes. */
export enum DisputeStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
}

/** Canonical set of dispute type codes. */
export enum DisputeType {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT',
}

export type DisputesCollection = {
  __typename?: 'DisputesCollection'
  disputes?: Maybe<Array<Dispute>>
}

export type DonkeyApplyStepInput = {
  stepData: Scalars['String']['input']
  userId: Scalars['UUID']['input']
}

export type DonkeyCreateTemplateInput = {
  config?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type DonkeyCreateUserResponse = {
  __typename?: 'DonkeyCreateUserResponse'
  /** user_id of the newly created donkey user */
  userId: Scalars['UUID']['output']
}

export type DonkeyCreateUserWithConfigInput = {
  config: Scalars['String']['input']
  email: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
}

export type DonkeyCreateUserWithTemplateInput = {
  email: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  templateId: Scalars['UUID']['input']
}

export type DonkeyDeleteUserInput = {
  userId: Scalars['UUID']['input']
}

export type DonkeyTemplate = {
  __typename?: 'DonkeyTemplate'
  config: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type DonkeyUpdateTemplateInput = {
  config: Scalars['String']['input']
  id: Scalars['UUID']['input']
  name: Scalars['String']['input']
}

/** Representation of a Donkey User created for testing purposes in IAM/dev */
export type DonkeyUser = {
  __typename?: 'DonkeyUser'
  /** Timestamp tracking the user's creation. */
  createdAt: Scalars['DateTime']['output']
  /** id of the IAM user creator of this donkey user */
  creatorUserId: Scalars['UUID']['output']
  /** Full referenced user object of this DonkeyUser record */
  user: User
  /** user_id associated with this donkey user */
  userId: Scalars['UUID']['output']
}

export enum DueDatesChangeReason {
  CsProcessingError = 'CS_PROCESSING_ERROR',
  Other = 'OTHER',
  ReliefPlanDeathInFamily = 'RELIEF_PLAN_DEATH_IN_FAMILY',
  ReliefPlanHomeEmergency = 'RELIEF_PLAN_HOME_EMERGENCY',
  ReliefPlanMassCasualty = 'RELIEF_PLAN_MASS_CASUALTY',
  ReliefPlanMedicalCare = 'RELIEF_PLAN_MEDICAL_CARE',
  ReliefPlanNaturalDisaster = 'RELIEF_PLAN_NATURAL_DISASTER',
  ReliefPlanSeriousIllness = 'RELIEF_PLAN_SERIOUS_ILLNESS',
}

/** User's email address information. */
export type Email = Verified & {
  __typename?: 'Email'
  /** User's email address. */
  address?: Maybe<Scalars['String']['output']>
  /** Indicates whether the email address was verified. */
  verified: Scalars['Boolean']['output']
}

/** Represents the ENABLED state. */
export type EnabledUserStatus = BaseUserStatus & {
  __typename?: 'EnabledUserStatus'
  /** Canonical user status code; the value is always ENABLED. */
  code: UserStatusCode
}

/** Represents a standard entity descriptor */
export type EntityDescriptor = {
  __typename?: 'EntityDescriptor'
  id: Scalars['ID']['output']
  type: Scalars['String']['output']
}

export type EntityDescriptorInput = {
  /** Indicates the entities id. */
  id: Scalars['ID']['input']
  /** Indicates the canonical type of the entity. */
  type: Scalars['String']['input']
}

/** Canonical set of entity types. */
export enum EntityType {
  Card = 'CARD',
  Loan = 'LOAN',
  User = 'USER',
}

/** Details for a card account in the EXPIRED state. */
export type ExpiredCardAccountStatus = BaseCardAccountStatus & {
  __typename?: 'ExpiredCardAccountStatus'
  /** Card account balance */
  balance: CardAccountBalance
  /** Canonical card account status code; the value is always EXPIRED. */
  code: CardAccountStatusCode
  /** Indicates when a user can attempt to reapply again. */
  eligibleToReapplyAfter: Scalars['DateTime']['output']
  /** Indicates when the card account transitioned into the EXPIRED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Card account subStatus code. */
  subStatus: CardAccountSubStatus
}

/** Represents a loan in the EXPIRED state. */
export type ExpiredLoanStatus = BaseLoanStatus & {
  __typename?: 'ExpiredLoanStatus'
  /** Canonical loan status code; the value is always EXPIRED. */
  code: LoanStatusCode
  /** Indicates when the loan transitioned into the EXPIRED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

/** Information about a payment method in the `EXPIRED` state. */
export type ExpiredPaymentMethodStatus = PaymentMethodStatus & {
  __typename?: 'ExpiredPaymentMethodStatus'
  /** Canonical code associated with the current state. This value is always `EXPIRED`. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the `EXPIRED` state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

/** User feature flags and experiments */
export type FeatureFlag = {
  __typename?: 'FeatureFlag'
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** Fee Schedule */
export type FeeSchedule = {
  __typename?: 'FeeSchedule'
  /** Fee amount */
  amount?: Maybe<Scalars['Decimal']['output']>
  /** Date when the fee will be applied */
  date?: Maybe<Scalars['DateTime']['output']>
}

/** Enumerates the canonical reasons why a fraudulent status may be placed on an account. */
export enum FraudulentStatusCategory {
  /** Indicates an account was moved to the fraudulent state because identity theft was reported. */
  IdTheft = 'ID_THEFT',
  /** Indicates an account was moved to the fraudulent state due to internal reasons. */
  Internal = 'INTERNAL',
  /** Indicates that an unrecognized value was specified. */
  Unrecognized = 'UNRECOGNIZED',
}

/** Represents the FRAUDULENT state. */
export type FraudulentUserStatus = BaseUserStatus & {
  __typename?: 'FraudulentUserStatus'
  /** Category indicating why the fraudulent status was placed on this account. */
  category?: Maybe<FraudulentStatusCategory>
  /** Canonical user status code; the value is always FRAUDULENT. */
  code: UserStatusCode
  /** Brief explanation of why the fraudulent status was placed on this account. */
  reason?: Maybe<Scalars['String']['output']>
}

/** Fund recovery data, containing the info of the fund recovery partner that is handling the loan */
export type FundRecovery = {
  __typename?: 'FundRecovery'
  /** Fund recovery partner */
  partner: LoanFundRecoveryPartner
  /** Date when the loan was sent to the fund recovery partner */
  sentAt: Scalars['DateTime']['output']
}

/** Fund movement authorization */
export type FundingAuthorization = {
  __typename?: 'FundingAuthorization'
  achOutboundFileTraceNumber?: Maybe<Scalars['String']['output']>
  achReturnFileTraceNumber?: Maybe<Scalars['String']['output']>
  amount: Scalars['Decimal']['output']
  cancellableStatus?: Maybe<CancellableStatus>
  direction?: Maybe<FundingDirection>
  executeAt?: Maybe<Scalars['DateTime']['output']>
  failedAt?: Maybe<Scalars['DateTime']['output']>
  fundingSourceId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  processor?: Maybe<TransactionProcessor>
  processorStatus?: Maybe<Scalars['String']['output']>
  processorStatusCode?: Maybe<Scalars['String']['output']>
  processorStatusDescription?: Maybe<Scalars['String']['output']>
  processorTransactionId?: Maybe<Scalars['String']['output']>
  reason?: Maybe<Scalars['String']['output']>
  settledAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<FundingAuthorizationStatus>
  transferId?: Maybe<Scalars['String']['output']>
}

/** Input for funding authorization operations */
export type FundingAuthorizationInput = {
  /** Amount */
  amount: Scalars['Decimal']['input']
  /**
   * Compliance code override - usually defines what is the Possible banking account that should be used for the transaction
   * Keep it empty if the default complianceCode for the product (forEntity) should be use. Provide a value only if an overridden is required
   */
  complianceCodeOverride?: InputMaybe<ComplianceCodes>
  /** send funds "TO" the customer or pull the money "FROM" the customer */
  direction: FundingDirection
  /** When to execute the funding authorization. Originally named as "notBeforeDateTimeEffective" */
  executeAt: Scalars['DateTime']['input']
  /** Is this funding auth associated with a card or with a loan? */
  forEntity: EntityDescriptorInput
  /** (Optional) If not present, the one from the funding source will be used. */
  network?: InputMaybe<ProcessorNetwork>
  /** (Optional) If not present, the one from the funding source will be used. */
  paymentInstrumentId?: InputMaybe<Scalars['UUID']['input']>
  /**
   * The funding source where to debit or credit the amount
   * @deprecated We won't query the funding source anymore. The payment instrument fields should come from this input.
   */
  processorFundingSourceId?: InputMaybe<Scalars['UUID']['input']>
  /**
   * The reason for the funding authorization. It can use a list of prefixed values in the client but also accepts
   * an open text value as an input
   */
  reason: Scalars['String']['input']
}

export enum FundingAuthorizationProcessorStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  None = 'NONE',
  Pending = 'PENDING',
}

/** Funds transfer status */
export enum FundingAuthorizationStatus {
  /** Indicates authorization was cancelled before initiation */
  Cancelled = 'CANCELLED',
  /** Settled, in success or failure, see settledDate and failedDate to distinguish */
  Completed = 'COMPLETED',
  /** Initiated but not finalized */
  InProgress = 'IN_PROGRESS',
  /** Not initiated */
  Pending = 'PENDING',
  /** Indicates payment is in retry */
  Retry = 'RETRY',
}

/** Indicates fund movement direction */
export enum FundingDirection {
  /** From customer, i.e. a payment */
  From = 'FROM',
  /** To customer, i.e. a disbursement */
  To = 'TO',
}

/** Funding source for an account */
export type FundingSource = {
  __typename?: 'FundingSource'
  id: Scalars['ID']['output']
  paymentInstrumentId?: Maybe<Scalars['ID']['output']>
  processorAccountId: Scalars['ID']['output']
  processorAccountStatus: ProcessorStatus
  processorId: Processor
  processorNetwork: ProcessorNetwork
}

/** Identifies a geo-graphic location. */
export type GpsCoordinate = {
  __typename?: 'GpsCoordinate'
  /** Geographic coordinate that specifies north-south position of a point on Earth's surface. */
  latitude?: Maybe<Scalars['Float']['output']>
  /** Geographic coordinate that specifies east-west position of a point on Earth's surface. */
  longitude: Scalars['Float']['output']
}

/**
 * User provided information scanned (or supplied) from a government issued identification document, such as a drivers
 * license.
 */
export type IdentificationDocumentInfo = {
  __typename?: 'IdentificationDocumentInfo'
  /** The expiration of the id number reported on the government issued identification document. */
  expirationDate: Scalars['String']['output']
  /** The id number reported on the government issued identification document, such as a drivers license ID. */
  number: Scalars['String']['output']
}

/** Details for installment plans in the INACTIVE state. */
export type InactiveInstallmentPlanStatus = BaseInstallmentPlanStatus & {
  __typename?: 'InactiveInstallmentPlanStatus'
  /** Canonical installment plan status code; the value is always INACTIVE. */
  code?: Maybe<InstallmentPlanStatusCode>
}

/** Details about an individual installment payment. */
export type InstallmentPayment = {
  __typename?: 'InstallmentPayment'
  /**
   * The amount this installment payment.
   * @deprecated added many fields that are more verbose explaining what the amount is an amount of
   */
  amount?: Maybe<Scalars['Decimal']['output']>
  /** The amount without fees included in this installment payment. */
  amountWithoutFees?: Maybe<Scalars['Decimal']['output']>
  /** due date of the installment. */
  dueAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Old fields, It is the due date of the installment.
   * @deprecated execute at is for when the payment processing is initiated this values has always been the due date. adding new field with name to properly reflect that
   */
  executeAt?: Maybe<Scalars['DateTime']['output']>
  /** The fees included in this installment payment. */
  fees?: Maybe<Scalars['Decimal']['output']>
  /**
   * Unique synthetic identifier for this payment schedule. Until the installment plan associated with this payment goes
   * active `id` will be `null`.
   */
  id?: Maybe<Scalars['ID']['output']>
  /**
   * Indicates the sequence number for this payment within a plan.
   * @deprecated The installments can be sorted by due date and this field was unused
   */
  ordinal?: Maybe<Scalars['Int']['output']>
  /** True if installment is overdue. */
  overdue?: Maybe<Scalars['Boolean']['output']>
  /** The amount paid towards this installment. */
  paidAmount?: Maybe<Scalars['Decimal']['output']>
  /** True if full installment amount is covered. */
  paidOff?: Maybe<Scalars['Boolean']['output']>
  pendingAmount?: Maybe<Scalars['Decimal']['output']>
  /**
   * The payment amount currently pending for this installment.
   * @deprecated adding
   */
  pendingPaymentAmount?: Maybe<Scalars['Decimal']['output']>
  /** The remaining amount due for the installment including fees. */
  remainingDue?: Maybe<Scalars['Decimal']['output']>
  /** Indicates the payment status for this installment payment */
  status?: Maybe<CardPaymentStatus>
  /**
   * Indicates the payment status for this installment payment
   * @deprecated maps to status code of a payment but there is not necessarily one status code for one installment with the addition of adhoc
   */
  statusCode?: Maybe<PaymentStatusCode>
  /** The total due for the installment including fees. */
  totalDue?: Maybe<Scalars['Decimal']['output']>
}

/** Enumerates the canonical set of states for an installment plan. */
export enum InstallmentPlanStatusCode {
  /** Indicates the installment plan is active. */
  Active = 'ACTIVE',
  /** Indicates the installment plan is not active. */
  Inactive = 'INACTIVE',
}

/** Represents all potential installment plan states. */
export type InstallmentPlanStatuses = ActiveInstallmentPlanStatus | InactiveInstallmentPlanStatus

/** Provides information about the institution that issues a given payment method. */
export type Institution = {
  __typename?: 'Institution'
  /** Institutions name. */
  name: Scalars['String']['output']
}

/** Information about a payment method in the `INVALID` state. */
export type InvalidPaymentMethodStatus = PaymentMethodStatus & {
  __typename?: 'InvalidPaymentMethodStatus'
  /** Canonical code associated with the current state. This value is always `INVALID`. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the `INVALID` state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

export type IpGeoLocation = {
  __typename?: 'IpGeoLocation'
  city?: Maybe<Scalars['String']['output']>
  continentCode?: Maybe<Scalars['String']['output']>
  continentName?: Maybe<Scalars['String']['output']>
  countryCode?: Maybe<Scalars['String']['output']>
  countryName?: Maybe<Scalars['String']['output']>
  ip?: Maybe<Scalars['String']['output']>
  latitude?: Maybe<Scalars['Float']['output']>
  longitude?: Maybe<Scalars['Float']['output']>
  regionCode?: Maybe<Scalars['String']['output']>
  regionName?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

/** Contains various forms of user supplied identification information. */
export type IssuedIdentificationInfo = {
  __typename?: 'IssuedIdentificationInfo'
  /** User provided, or scanned, identification information; usually a drivers license. */
  identificationDocument?: Maybe<IdentificationDocumentInfo>
  /** User provided social security number info. */
  ssn?: Maybe<Ssn>
}

export type KeyValuePair = {
  __typename?: 'KeyValuePair'
  key?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type KeyValuePairInput = {
  key?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** KYC Validation result representation */
export type KycValidation = {
  __typename?: 'KycValidation'
  failureReason: Scalars['String']['output']
  id: Scalars['String']['output']
  manualReviewReason?: Maybe<Scalars['String']['output']>
  manualReviewStatus: KycValidationManualReviewStatus
  manualReviewUser?: Maybe<NamedEntityDescriptor>
  type: KycValidationType
}

/** Aggregator for responses including KycValidation records */
export type KycValidationCollection = {
  __typename?: 'KycValidationCollection'
  items: Array<KycValidation>
}

/** Aggregator for responses including KycValidation records */
export type KycValidationCollectionItemsArgs = {
  context: KycValidationContext
  forEntity: EntityDescriptorInput
}

/** Set of possible KYC validation context values */
export enum KycValidationContext {
  ConsumerStatements = 'CONSUMER_STATEMENTS',
  MlaAndOfac = 'MLA_AND_OFAC',
}

/** Review status set by a manual review */
export enum KycValidationManualReviewStatus {
  Cleared = 'CLEARED',
  NotReviewed = 'NOT_REVIEWED',
}

export type KycValidationReviewInput = {
  id: Scalars['UUID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  status: KycValidationManualReviewStatus
}

export type KycValidationReviewResponse = {
  __typename?: 'KycValidationReviewResponse'
  validation: KycValidation
}

/** Set of possible KYC validation types values */
export enum KycValidationType {
  Fraud = 'FRAUD',
  Mla = 'MLA',
  Ofac = 'OFAC',
}

/** Enumerates the Languages for i18n */
export enum Language {
  /** US - English */
  EnUs = 'EN_US',
  /** US - Spanish */
  EsUs = 'ES_US',
}

/** The banking account associated with a user. */
export type LinkedAccount = {
  __typename?: 'LinkedAccount'
  /** Indicates whether this account has ACH numbers already */
  achNumbersAvailable?: Maybe<Scalars['Boolean']['output']>
  /** Banking aggregator identifier (PLAID, YODLEE, etc) */
  bankingAggregatorId: AggregatorType
  /** Indicates whether this account has DEBIT numbers already */
  debitCardNumbersAvailable?: Maybe<Scalars['Boolean']['output']>
  /** Unique synthetic identifier for this bank account. */
  id: Scalars['ID']['output']
  /** Information about the institution that issues this ACH authorization. */
  institution?: Maybe<Institution>
  /** Flag to indicate if the user can delete this account */
  isDeletable: Scalars['Boolean']['output']
  /** Indicates if relinking with the aggregator is necessary */
  isLoginRequired: Scalars['Boolean']['output']
  /** Last time the account was synced with the aggregator */
  lastSyncedAt?: Maybe<Scalars['DateTime']['output']>
  /** The last 4 digits of the account number. */
  mask: Scalars['String']['output']
  /** The last 4 digits of the account number after correction. */
  maskCorrected: Scalars['String']['output']
  /** The name for this account specified by the user. */
  name?: Maybe<Scalars['String']['output']>
  /** The name for this account specified by the institution. */
  officialName?: Maybe<Scalars['String']['output']>
  /** Information about account ownership verification */
  ownershipSummary?: Maybe<LinkedAccountOwnershipSummary>
  /** Indicates whether this account is considered the preferred funding source. */
  preferredFundingSource?: Maybe<Scalars['Boolean']['output']>
  /** Indicates the accounts status. */
  status: LinkedAccountStatusCode
  /** The type of the account */
  type: LinkedAccountType
}

/** Collection of banking accounts that have been linked by the user. */
export type LinkedAccountCollection = {
  __typename?: 'LinkedAccountCollection'
  all?: Maybe<Array<LinkedAccount>>
}

/** Collection of banking accounts that have been linked by the user. */
export type LinkedAccountCollectionAllArgs = {
  accountFilter?: InputMaybe<AccountLookupFilter>
}

/** Linked account owner type */
export enum LinkedAccountOwnerType {
  Association = 'ASSOCIATION',
  Individual = 'INDIVIDUAL',
  Joint = 'JOINT',
  None = 'NONE',
  Trust = 'TRUST',
}

/**
 * Details about the account's ownership data retrieved from the aggregator
 * Useful for IAM operators to investigate mismatches in ownership data
 */
export type LinkedAccountOwnershipData = {
  __typename?: 'LinkedAccountOwnershipData'
  ownerNames: Array<Scalars['String']['output']>
  ownerStates: Array<Scalars['String']['output']>
}

/** Linked account ownership verification status */
export enum LinkedAccountOwnershipStatus {
  Invalid = 'INVALID',
  None = 'NONE',
  Pending = 'PENDING',
  Valid = 'VALID',
}

/**
 * Details about the ownership verification for a linked user bank account
 * This is needed both in IAM and the client app
 */
export type LinkedAccountOwnershipSummary = {
  __typename?: 'LinkedAccountOwnershipSummary'
  /** The type of the account owner indicating whether it is a individual, joint or business account */
  ownerType: LinkedAccountOwnerType
  /** The overall ownership verification status to indicate if the account has been validated */
  ownershipStatus: LinkedAccountOwnershipStatus
  /** A breakdown of the results of the validation if one or more of the validation criteria are not met */
  validationErrors?: Maybe<Array<LinkedAccountOwnershipValidationError>>
}

/**
 * Type to represent a validation result that needs external attention
 * in order to be resolved.
 */
export type LinkedAccountOwnershipValidationError = {
  __typename?: 'LinkedAccountOwnershipValidationError'
  matchScore: Scalars['Int']['output']
  validationStatus: LinkedAccountOwnershipValidationStatus
  validationType: LinkedAccountOwnershipValidationType
}

/**
 * The result of a validation attempt for a particular ownership
 * verification rule
 */
export enum LinkedAccountOwnershipValidationStatus {
  InternallyMatched = 'INTERNALLY_MATCHED',
  InternallyRejected = 'INTERNALLY_REJECTED',
  Matched = 'MATCHED',
  None = 'NONE',
  Review = 'REVIEW',
  Unmatched = 'UNMATCHED',
}

/** The type of validation for verification of a linked account */
export enum LinkedAccountOwnershipValidationType {
  Name = 'NAME',
  None = 'NONE',
  State = 'STATE',
}

/** Canonical set of linked account status codes. */
export enum LinkedAccountStatusCode {
  LinkedInUse = 'LINKED_IN_USE',
  Unlinked = 'UNLINKED',
}

export enum LinkedAccountType {
  Checking = 'CHECKING',
  Other = 'OTHER',
  Savings = 'SAVINGS',
}

/** The debit card backing the debit card payment method. */
export type LinkedDebitCard = {
  __typename?: 'LinkedDebitCard'
  /** The underlying banking account this debit card draws from. */
  account?: Maybe<LinkedAccount>
  /** The friendlyName associated with this debit card / payment instrument */
  friendlyName: Scalars['String']['output']
  /** Information about the institution that issues this debit card. */
  institution?: Maybe<Institution>
  /** The last 4 digits of the debit card number. */
  mask?: Maybe<Scalars['String']['output']>
}

/** Entity representing a loan. */
export type Loan = ChangeTracked & {
  __typename?: 'Loan'
  /**
   * When the loan was accepted by the customer.
   * Exists only for loans that have been accepted
   */
  activeAt?: Maybe<Scalars['DateTime']['output']>
  /** Values specific the application states that are broader than the basic status */
  aggregateStatus: LoanAggregateStatus
  /** Loan agreement data */
  agreement: LoanAgreement
  /** Indicates the loan principal amount disbursed (or potentially disbursed) to the borrower. */
  amountBorrowed?: Maybe<Scalars['Decimal']['output']>
  /** Indicates the sum of the principal and fees at loan origination */
  amountBorrowedTotal: Scalars['Decimal']['output']
  /** Indicates the remaining balance owed by the borrower. */
  amountOutstanding: Scalars['Decimal']['output']
  /** Indicates the remaining balance and fees owed by the borrower. */
  amountOutstandingTotal: Scalars['Decimal']['output']
  /** Loan amount requested by the borrower. */
  amountRequested?: Maybe<Scalars['Decimal']['output']>
  /** User that applied for this loan. */
  borrower?: Maybe<BorrowerInfo>
  /**
   * When the loan was charged off
   * Exists only for loans that are in a charged off status
   */
  chargedOffAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * When the loan status changed to chargedoff_collections
   * Exists only for loans that transistioned to that status
   * Does not indicate actually being sent to collections
   */
  collectionsStatusAt?: Maybe<Scalars['DateTime']['output']>
  /** Timestamp tracking the loan's creation. */
  createdAt: Scalars['DateTime']['output']
  /** Currency associated with all dollar figures in this loan. */
  currency?: Maybe<Scalars['String']['output']>
  documents: LoanDocumentCollection
  /** Indicates when loan repayment is due. */
  dueAt?: Maybe<Scalars['DateTime']['output']>
  /** An ID used by external sources such as credit reporting, that cannot accept a UUID */
  externalId: Scalars['String']['output']
  /** Fund recovery data, present when the loan was sent to a fund recovery partner */
  fundRecovery?: Maybe<FundRecovery>
  /** Loan's unique id. */
  id: Scalars['ID']['output']
  loanTermsSummary?: Maybe<LoanTermsSummary>
  /** Loan Type Variant, used to adjust UX based on the Possible Loan vs Possible Advance */
  loanTypeInfo?: Maybe<LoanTypeInfo>
  /** Notes attached to this loan. */
  notes?: Maybe<UserNoteCollection>
  /** Original loan before conversion */
  originalLoan?: Maybe<Loan>
  /** Date loan was funded. */
  originationAt?: Maybe<Scalars['DateTime']['output']>
  /** Financial company which originally owned this loan */
  originator: LoanOriginator
  /**
   * When the loan was most recently paid off
   * Exists only for loans that have been paid in full or settled
   */
  paidOffAt?: Maybe<Scalars['DateTime']['output']>
  /** List of funding authorizations and associated information for the loan */
  paymentHistory?: Maybe<LoanPaymentHistoryCollection>
  /** Payment methods registered by the user and associated with this loan. */
  paymentMethods: LoanPaymentMethodCollection
  /** Abbreviation of the US state the loan originates from */
  state: StateCodes
  /** State Database History */
  stateDatabaseHistory?: Maybe<StateDatabaseHistoryCollection>
  stateRecordId?: Maybe<Scalars['String']['output']>
  /** Loan status and status dependent information. */
  status: LoanStatus
  /** History of transitions between loan status and substatus */
  statusChangeHistory?: Maybe<LoanStatusChangeCollection>
  suspendedStatus?: Maybe<LoanSuspensionStatus>
  /** Underwriting data for this loan. */
  underwriting?: Maybe<Underwriting>
  /** Timestamp tracking the loan's last modification. */
  updatedAt: Scalars['DateTime']['output']
}

export type LoanActivationPreRequisiteCollection = {
  __typename?: 'LoanActivationPreRequisiteCollection'
  /** pre-requisites considered completed */
  met: Array<LoanActivationPreRequisiteType>
  /** pre-requisites that need to be completed */
  unmet: Array<LoanActivationPreRequisiteType>
}

/** Types to identify pre-requisites for the loan activation workflow */
export enum LoanActivationPreRequisiteType {
  AcceptArbitrationAgreementFl = 'ACCEPT_ARBITRATION_AGREEMENT_FL',
  AcceptArbitrationAgreementTx = 'ACCEPT_ARBITRATION_AGREEMENT_TX',
  AcceptAutopayAgreementExtended = 'ACCEPT_AUTOPAY_AGREEMENT_EXTENDED',
  AcceptAutopayAgreementStandard = 'ACCEPT_AUTOPAY_AGREEMENT_STANDARD',
  AcceptCreditServicesAgreementTx = 'ACCEPT_CREDIT_SERVICES_AGREEMENT_TX',
  AcceptCreditServicesDisclosureStatementTx = 'ACCEPT_CREDIT_SERVICES_DISCLOSURE_STATEMENT_TX',
  AcceptHowAutopayWorks = 'ACCEPT_HOW_AUTOPAY_WORKS',
  AcceptInstallmentPlanDisclosureHi = 'ACCEPT_INSTALLMENT_PLAN_DISCLOSURE_HI',
  AcceptLoanAgreement = 'ACCEPT_LOAN_AGREEMENT',
  AcceptPayments = 'ACCEPT_PAYMENTS',
  AcceptStateDisclosureLa = 'ACCEPT_STATE_DISCLOSURE_LA',
  AcceptStateDisclosureOh = 'ACCEPT_STATE_DISCLOSURE_OH',
  AcceptStatutoryNoticeFl = 'ACCEPT_STATUTORY_NOTICE_FL',
  AcceptTilaDisclosure = 'ACCEPT_TILA_DISCLOSURE',
  CollectDebitCardNumbers = 'COLLECT_DEBIT_CARD_NUMBERS',
  CompleteLoanReasonSurvey = 'COMPLETE_LOAN_REASON_SURVEY',
  ConfirmDebitCard = 'CONFIRM_DEBIT_CARD',
  ConfirmPreferredBankAccountDetails = 'CONFIRM_PREFERRED_BANK_ACCOUNT_DETAILS',
  FinalAcceptStandard = 'FINAL_ACCEPT_STANDARD',
  RelinkPreferredBankAccount = 'RELINK_PREFERRED_BANK_ACCOUNT',
  SelectDisbursementMethod = 'SELECT_DISBURSEMENT_METHOD',
}

export type LoanActiveAlertChargedOff = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertChargedOff'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertDisbursementCompleted = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertDisbursementCompleted'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertDisbursementDelayed = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertDisbursementDelayed'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertFailedPayment = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertFailedPayment'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertFinalPaymentProcessing = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertFinalPaymentProcessing'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertInDefault = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertInDefault'
  category: LoanActiveStatusPaymentAlertCategory
  daysUntilChargeOff: Scalars['Int']['output']
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertLatePayment = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertLatePayment'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertLatePaymentNotFailed = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertLatePaymentNotFailed'
  category: LoanActiveStatusPaymentAlertCategory
  paymentDate: Scalars['DateTime']['output']
  priority: Scalars['Int']['output']
  rescheduleOnOrBeforeDate: Scalars['DateTime']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertLoanSuspended = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertLoanSuspended'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertPaymentCompleted = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertPaymentCompleted'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertPaymentMethodIssue = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertPaymentMethodIssue'
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveAlertUpcomingPayment = LoanActiveStatusPaymentAlertType & {
  __typename?: 'LoanActiveAlertUpcomingPayment'
  category: LoanActiveStatusPaymentAlertCategory
  daysUntilDue: Scalars['Int']['output']
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export type LoanActiveStatusActionCollection = {
  __typename?: 'LoanActiveStatusActionCollection'
  actions: Array<LoanActiveStatusActions>
}

export enum LoanActiveStatusActions {
  ContactUs = 'CONTACT_US',
  MakePayment = 'MAKE_PAYMENT',
  UpdatePaymentDates = 'UPDATE_PAYMENT_DATES',
}

export type LoanActiveStatusAlertAggregation = {
  categories: Array<LoanActiveStatusPaymentAlertCategory>
  limit?: Scalars['Int']['input']
}

export type LoanActiveStatusAlertCollection = {
  __typename?: 'LoanActiveStatusAlertCollection'
  items: Array<LoanActiveStatusPaymentAlertItem>
}

export type LoanActiveStatusAlertCollectionItemsArgs = {
  aggregations?: InputMaybe<Array<InputMaybe<LoanActiveStatusAlertAggregation>>>
}

export enum LoanActiveStatusPaymentAlertCategory {
  Critical = 'CRITICAL',
  Info = 'INFO',
  InfoLight = 'INFO_LIGHT',
  Warning = 'WARNING',
}

export type LoanActiveStatusPaymentAlertItem =
  | LoanActiveAlertChargedOff
  | LoanActiveAlertDisbursementCompleted
  | LoanActiveAlertDisbursementDelayed
  | LoanActiveAlertFailedPayment
  | LoanActiveAlertFinalPaymentProcessing
  | LoanActiveAlertInDefault
  | LoanActiveAlertLatePayment
  | LoanActiveAlertLatePaymentNotFailed
  | LoanActiveAlertLoanSuspended
  | LoanActiveAlertPaymentCompleted
  | LoanActiveAlertPaymentMethodIssue
  | LoanActiveAlertUpcomingPayment

export type LoanActiveStatusPaymentAlertType = {
  category: LoanActiveStatusPaymentAlertCategory
  priority: Scalars['Int']['output']
  triggeredAt: Scalars['DateTime']['output']
  type: LoanActiveStatusPaymentAlerts
}

export enum LoanActiveStatusPaymentAlerts {
  DisbursementCompleted = 'DISBURSEMENT_COMPLETED',
  DisbursementDelayed = 'DISBURSEMENT_DELAYED',
  FinalPaymentProcessing = 'FINAL_PAYMENT_PROCESSING',
  LoanChargedoff = 'LOAN_CHARGEDOFF',
  LoanInDefault = 'LOAN_IN_DEFAULT',
  LoanSuspended = 'LOAN_SUSPENDED',
  None = 'NONE',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentLate = 'PAYMENT_LATE',
  PaymentLateNotFailed = 'PAYMENT_LATE_NOT_FAILED',
  PaymentMethodIssue = 'PAYMENT_METHOD_ISSUE',
  /** @deprecated replaced by PAYMENT_FAILED */
  PaymentUpcoming = 'PAYMENT_UPCOMING',
}

export type LoanAggregateStatus =
  | ActiveLoanAggregateStatus
  | ApprovedLoanAggregateStatus
  | ClosedLoanAggregateStatus
  | DecisioningLoanAggregateStatus
  | UndefinedLoanAggregateStatus

export enum LoanAggregateStatusCode {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Decisioning = 'DECISIONING',
  Undefined = 'UNDEFINED',
}

export type LoanAggregateStatusType = {
  code: LoanAggregateStatusCode
  conversion: LoanConversion
}

/** Loan agreement data */
export type LoanAgreement = {
  __typename?: 'LoanAgreement'
  apr: Scalars['String']['output']
  cabFee?: Maybe<Scalars['String']['output']>
  interestFee?: Maybe<Scalars['String']['output']>
  payments: Array<LoanAgreementPayment>
  totalCost: Scalars['String']['output']
  totalOwed: Scalars['String']['output']
}

/**
 * Payment data contained in the loan agreement
 * Contains the date of each payment (by ordinal) agreed in the loan agreement
 */
export type LoanAgreementPayment = {
  __typename?: 'LoanAgreementPayment'
  amount: Scalars['String']['output']
  date: Scalars['LocalDate']['output']
  ordinal: Scalars['Int']['output']
}

export type LoanCancelFundingAuthorizationRequest = {
  cancelStrategy: LoanCancelFundingAuthorizationStrategy
  fundingAuthorizationId: Scalars['UUID']['input']
  loanId: Scalars['UUID']['input']
}

export enum LoanCancelFundingAuthorizationStrategy {
  DoNotRetry = 'DO_NOT_RETRY',
  RetrySameMethod = 'RETRY_SAME_METHOD',
}

export type LoanCancelInput = {
  loanId: Scalars['UUID']['input']
  reason: Scalars['String']['input']
  reasonCode: Scalars['String']['input']
}

export enum LoanClosedReasonCode {
  Cancelled = 'CANCELLED',
  ChargedoffPaidoff = 'CHARGEDOFF_PAIDOFF',
  ChargedoffSettled = 'CHARGEDOFF_SETTLED',
  DecisioningExpired = 'DECISIONING_EXPIRED',
  LoanExpired = 'LOAN_EXPIRED',
  /** A placeholder for unanticipated reasons. In current code this should not happen and would be an error. */
  Other = 'OTHER',
  Paidoff = 'PAIDOFF',
  Rejected = 'REJECTED',
  UnmetStateRequirement = 'UNMET_STATE_REQUIREMENT',
}

export type LoanCompleteCheckPaymentResponse = {
  __typename?: 'LoanCompleteCheckPaymentResponse'
  success: Scalars['Boolean']['output']
}

export type LoanConversion = LoanConversionConverted | LoanConversionNone

export type LoanConversionConverted = {
  __typename?: 'LoanConversionConverted'
  originalLoanId: Scalars['UUID']['output']
  role: LoanRole
}

export type LoanConversionNone = {
  __typename?: 'LoanConversionNone'
  role: LoanRole
}

export type LoanConversionOption = {
  __typename?: 'LoanConversionOption'
  offerId?: Maybe<Scalars['UUID']['output']>
  paymentsToRefund?: Maybe<Array<Maybe<ConversionOptionLoanPayment>>>
  projectedPayments?: Maybe<Array<Maybe<ConversionOptionLoanPayment>>>
  variant?: Maybe<ConversionOptionLoanTerms>
}

/** Loan Convert Options for a loan identified by loan id */
export type LoanConversionOptionsInput = {
  effectiveDate?: InputMaybe<Scalars['DateTime']['input']>
  includeOffers: Scalars['Boolean']['input']
  loanId: Scalars['UUID']['input']
}

/** Loan Convert Options response containing offers available to convert the loan */
export type LoanConversionOptionsResponse = {
  __typename?: 'LoanConversionOptionsResponse'
  options?: Maybe<Array<Maybe<LoanConversionOption>>>
}

/** Convert Loan identified by loan Id using offer Id */
export type LoanConvertInput = {
  loanId: Scalars['UUID']['input']
  offerId: Scalars['UUID']['input']
}

/** Convert Loan response containing converted boolean status */
export type LoanConvertResponse = {
  __typename?: 'LoanConvertResponse'
  converted: Scalars['Boolean']['output']
}

export enum LoanCustomPaymentIntention {
  FullBalance = 'FULL_BALANCE',
  MissedInstallment = 'MISSED_INSTALLMENT',
  NextInstallment = 'NEXT_INSTALLMENT',
  OverdueBalance = 'OVERDUE_BALANCE',
}

export type LoanCustomPaymentOption = {
  __typename?: 'LoanCustomPaymentOption'
  /** The amount the customer would pay if they execute the option */
  amount: Scalars['Decimal']['output']
  /** The number of installments that would be paid off by executing the option */
  installmentCount: Scalars['Int']['output']
  intention: LoanCustomPaymentIntention
}

export type LoanCustomPaymentOptionCollection = {
  __typename?: 'LoanCustomPaymentOptionCollection'
  options: Array<LoanCustomPaymentOption>
}

export type LoanDisbursement = {
  __typename?: 'LoanDisbursement'
  accountFriendlyName: Scalars['String']['output']
  accountMask: Scalars['String']['output']
  amount: Scalars['Decimal']['output']
  completedOn?: Maybe<Scalars['DateTime']['output']>
  method: LoanPaymentMethod
  status: FundingAuthorizationProcessorStatus
  updatedOn: Scalars['DateTime']['output']
}

/** Entity representing a loan document from the history table */
export type LoanDocument = {
  __typename?: 'LoanDocument'
  createdAt: Scalars['DateTime']['output']
  documentKey: Scalars['String']['output']
  loanStatusAtCreation: LoanStatusCode
  type: LoanDocumentType
  updatedAt: Scalars['DateTime']['output']
  updatedBy: EntityDescriptor
}

/** Collection of documents associated to a Loan */
export type LoanDocumentCollection = {
  __typename?: 'LoanDocumentCollection'
  /**
   * All the history of documents created for a given Loan.
   * The documents can change and they can have different versions created during the Loan lifecycle
   */
  history: Array<LoanDocument>
  /**
   * List of most recent documents associated to a Loan.
   * The most recent documents are unique by the type of the document
   */
  mostRecent: Array<LoanDocument>
}

/** Collection of documents with downloadable links associated to a Loan */
export type LoanDocumentDownloadCollection = {
  __typename?: 'LoanDocumentDownloadCollection'
  documents: Array<LoanDocumentWithDownloadUrl>
}

export enum LoanDocumentType {
  AchAgreement = 'ACH_AGREEMENT',
  Arbitration = 'ARBITRATION',
  CoveredBorrowerAgreement = 'COVERED_BORROWER_AGREEMENT',
  CreditService = 'CREDIT_SERVICE',
  CreditServiceDisclosure = 'CREDIT_SERVICE_DISCLOSURE',
  ExtendedPaymentPlan = 'EXTENDED_PAYMENT_PLAN',
  InstallmentLoanAgreement = 'INSTALLMENT_LOAN_AGREEMENT',
  LoanAgreement = 'LOAN_AGREEMENT',
  PayOffConfirmation = 'PAY_OFF_CONFIRMATION',
  SinglePaymentLoanAgreement = 'SINGLE_PAYMENT_LOAN_AGREEMENT',
  StateDisclosure = 'STATE_DISCLOSURE',
}

/** Entity representing a loan document with a type and information required to download it */
export type LoanDocumentWithDownloadUrl = {
  __typename?: 'LoanDocumentWithDownloadUrl'
  download: PresignedUrl
  type: LoanDocumentType
}

export type LoanFailPaymentResponse = {
  __typename?: 'LoanFailPaymentResponse'
  paymentId: Scalars['UUID']['output']
  updatedStatus: LoanPaymentStatus
}

/** Enumerates the canonical set of fund recovery partners for a loan. */
export enum LoanFundRecoveryPartner {
  /** Indicates the loan was sent to Indebted */
  Indebted = 'INDEBTED',
  /** Indicates the loan was sent to TrueAccord */
  TrueAccord = 'TRUE_ACCORD',
}

export enum LoanFundingMethod {
  Ach = 'ACH',
  Check = 'CHECK',
  Debit = 'DEBIT',
  Other = 'OTHER',
}

export type LoanFundingStatus = {
  __typename?: 'LoanFundingStatus'
  displayProcessorStatus?: Maybe<Scalars['String']['output']>
  displayStatus?: Maybe<Scalars['String']['output']>
  processorStatus?: Maybe<Scalars['String']['output']>
  processorStatusReason?: Maybe<ProcessorStatusReason>
  status?: Maybe<Scalars['String']['output']>
}

export type LoanHistory = {
  __typename?: 'LoanHistory'
  changes: LoanHistoryItemCollection
  id: Scalars['UUID']['output']
  originationDate: Scalars['DateTime']['output']
}

/**
 * Snapshot of the loan at a point in time
 * Substatus has not been defined in the graphQL yet, so that field may need to be reconsidered as part of general changes
 */
export type LoanHistoryItem = {
  __typename?: 'LoanHistoryItem'
  date: Scalars['DateTime']['output']
  fees: Scalars['Decimal']['output']
  interest: Scalars['Decimal']['output']
  paymentTotal?: Maybe<Scalars['Decimal']['output']>
  paymentTotalCost?: Maybe<Scalars['Decimal']['output']>
  principal: Scalars['Decimal']['output']
  principalOutstanding?: Maybe<Scalars['Decimal']['output']>
  statusCode: LoanStatusCode
  substatusName?: Maybe<Scalars['String']['output']>
  terminationDate?: Maybe<Scalars['DateTime']['output']>
  total: Scalars['Decimal']['output']
  totalCost: Scalars['Decimal']['output']
  totalOutstanding?: Maybe<Scalars['Decimal']['output']>
}

export type LoanHistoryItemCollection = {
  __typename?: 'LoanHistoryItemCollection'
  history: Array<LoanHistoryItem>
}

/** Loan offer information. */
export type LoanOffer = {
  __typename?: 'LoanOffer'
  /** A friendly product name */
  displayName: Scalars['String']['output']
  /** Loan type id */
  id: Scalars['ID']['output']
  /** Loan Variant Type, this is used by the frontend to adjust behavior for Possible Loans ( STANDARD_INSTALLMENT ) and Possible Advances ( NON_RECOURSE_INSTALLMENT) */
  loanTypeVariant?: Maybe<LoanTypeVariant>
  /** Max loan amount offered */
  maximumLoanAmount: Scalars['Decimal']['output']
  /** All pre-requisites for this offer */
  preReqs: ProductPreRequisiteCollection
}

export enum LoanOfferAvailabilityStatus {
  IneligibleLoanStatus = 'INELIGIBLE_LOAN_STATUS',
  IneligibleLocation = 'INELIGIBLE_LOCATION',
  IneligibleOtherProductsStatus = 'INELIGIBLE_OTHER_PRODUCTS_STATUS',
  IneligibleProductStatus = 'INELIGIBLE_PRODUCT_STATUS',
  NoOfferAvailable = 'NO_OFFER_AVAILABLE',
  OfferAvailable = 'OFFER_AVAILABLE',
  UserNotEligible = 'USER_NOT_ELIGIBLE',
}

/** Details of the card offer available to the user */
export type LoanOfferInfo = {
  __typename?: 'LoanOfferInfo'
  /** Product category */
  category: ProductCategory
  /** offer availability status (available, notAvailable, InEligibleUser, InEligibleLoanStatus etc) */
  offerAvailabilityStatus: LoanOfferAvailabilityStatus
  /** Lists this user's loan offers. */
  offers: Array<LoanOffer>
}

/** Groups data related to user onboarding flow (loans) */
export type LoanOnboardingInfo = {
  __typename?: 'LoanOnboardingInfo'
  amountSelected?: Maybe<Scalars['Decimal']['output']>
  stateSelected?: Maybe<Scalars['String']['output']>
}

/** Financial company the loan was originated from */
export enum LoanOriginator {
  Coastal = 'COASTAL',
  Possible = 'POSSIBLE',
}

/** Captures information related to a Loan Payment */
export type LoanPayment = {
  __typename?: 'LoanPayment'
  /** Payment amount */
  amount: Scalars['Decimal']['output']
  /** The currency of this loan payment */
  currency?: Maybe<Scalars['String']['output']>
  /** Payment execute effective date time */
  executeEffectiveDateTime?: Maybe<Scalars['DateTime']['output']>
  /** Amount of failures for this payment */
  failureCount: Scalars['Int']['output']
  /** Payment fees */
  fees: Scalars['Decimal']['output']
  /** Unique synthetic identifier for this payment funding. */
  fundingId?: Maybe<Scalars['String']['output']>
  /** Unique synthetic identifier for this payment. */
  id: Scalars['ID']['output']
  /** Payment interest */
  interest: Scalars['Decimal']['output']
  /** Last failed transaction data for the payment */
  lastFailedTransaction?: Maybe<LoanPaymentTransaction>
  /** Last transaction data for the payment */
  lastTransaction?: Maybe<LoanPaymentTransaction>
  /** Unique synthetic identifier for the loan which this payment relates to. */
  loanId: Scalars['ID']['output']
  /**
   * Payment method
   * @deprecated use methodCode instead
   */
  method?: Maybe<Scalars['String']['output']>
  methodCode: LoanPaymentMethod
  /** The order of this payment within the loan it relates to */
  ordinal: Scalars['Int']['output']
  /** The date this payment was scheduled to */
  originalDate: Scalars['DateTime']['output']
  /** Payment paid off date time */
  paidOffDateTime?: Maybe<Scalars['DateTime']['output']>
  /** Flag to indicate payment is part of a pay now */
  payingOffNow: Scalars['Boolean']['output']
  /** Payment principal */
  principal: Scalars['Decimal']['output']
  /** The date this payment was rescheduled to */
  rescheduledDate?: Maybe<Scalars['DateTime']['output']>
  /**
   * The status of this loan payment
   * @deprecated use statusCode instead
   */
  status: Scalars['String']['output']
  statusCode: LoanPaymentStatusCode
  /** Unique synthetic identifier for user that owns the loan which this payment relates to. */
  userId: Scalars['ID']['output']
}

export type LoanPaymentCancelResponse = {
  __typename?: 'LoanPaymentCancelResponse'
  cancelledFundingAuthorizationId?: Maybe<Scalars['ID']['output']>
  currentPayment: LoanPayment
  resultCode: LoanPaymentCancelResultCode
}

export enum LoanPaymentCancelResultCode {
  Cancelled = 'CANCELLED',
  ExecutionDateMissing = 'EXECUTION_DATE_MISSING',
  ExecutionDateTooClose = 'EXECUTION_DATE_TOO_CLOSE',
  InvalidOther = 'INVALID_OTHER',
  InvalidPaymentStatus = 'INVALID_PAYMENT_STATUS',
  SkippedCancel = 'SKIPPED_CANCEL',
}

export type LoanPaymentCollection = {
  __typename?: 'LoanPaymentCollection'
  payments: Array<LoanPayment>
}

export type LoanPaymentCollectionPaymentsArgs = {
  orderBy?: InputMaybe<LoanPaymentCollectionOrderBy>
}

export enum LoanPaymentCollectionOrderBy {
  Ordinal = 'ORDINAL',
  RescheduledDate = 'RESCHEDULED_DATE',
}

export type LoanPaymentDateCollection = {
  __typename?: 'LoanPaymentDateCollection'
  dates?: Maybe<Array<Scalars['LocalDate']['output']>>
}

export type LoanPaymentDatesUpdate = {
  loanId: Scalars['ID']['input']
  payments: Array<LoanPaymentUpdate>
}

export enum LoanPaymentFailureCode {
  Chargeback = 'CHARGEBACK',
  Other = 'OTHER',
}

export type LoanPaymentFailureResponse = {
  __typename?: 'LoanPaymentFailureResponse'
  currentPayment: LoanPayment
  resultCode: LoanPaymentFailureResultCode
}

export enum LoanPaymentFailureResultCode {
  AuthorizationUpdateFailure = 'AUTHORIZATION_UPDATE_FAILURE',
  Failed = 'FAILED',
  InvalidOther = 'INVALID_OTHER',
  InvalidPaymentStatus = 'INVALID_PAYMENT_STATUS',
  SkippedFail = 'SKIPPED_FAIL',
}

export type LoanPaymentHistory = {
  __typename?: 'LoanPaymentHistory'
  accountMask?: Maybe<Scalars['String']['output']>
  accountName?: Maybe<Scalars['String']['output']>
  achOutboundFileTraceNumber?: Maybe<Scalars['String']['output']>
  achReturnFileTraceNumber?: Maybe<Scalars['String']['output']>
  amount: Scalars['Decimal']['output']
  direction: FundingDirection
  executeAt: Scalars['DateTime']['output']
  fundingAuthId: Scalars['UUID']['output']
  fundingAuthReason?: Maybe<Scalars['String']['output']>
  loanPaymentOrdinal?: Maybe<Scalars['Int']['output']>
  network: ProcessorNetwork
  processorId: PaymentProcessorTypeCode
  processorStatus: FundingAuthorizationProcessorStatus
  processorStatusDescription?: Maybe<Scalars['String']['output']>
  status: FundingAuthorizationStatus
}

export type LoanPaymentHistoryCollection = {
  __typename?: 'LoanPaymentHistoryCollection'
  history: Array<LoanPaymentHistory>
}

/**
 * Captures potential loan payment methods to and from the borrower.
 * NOTE: this enum and its usages will be replaced by payment methods.
 */
export enum LoanPaymentMethod {
  /** Indicates an ACH authorization was supplied for payment method. */
  Ach = 'ACH',
  /** Indicates a check was supplied for payment method. */
  Check = 'CHECK',
  /** Indicates a debit card was supplied for payment method. */
  DebitCard = 'DEBIT_CARD',
  /** Indicates no payment method was supplied. */
  None = 'NONE',
}

/** Collection of loan payment methods that the user has registered to their account and linked with the given loan. */
export type LoanPaymentMethodCollection = {
  __typename?: 'LoanPaymentMethodCollection'
  /** The disbursement method, associated with the given loan, that the user selected to be the default method. */
  disbursementDefault?: Maybe<LoanPaymentMethodType>
  /** The payment method, associated with the given loan, that the user selected to be the default method. */
  paymentDefault?: Maybe<LoanPaymentMethodType>
}

/** All payment methods that can be linked to a loan. */
export type LoanPaymentMethodType = AchPaymentMethod | CheckPaymentMethod | DebitCardPaymentMethod

export enum LoanPaymentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Replaced = 'REPLACED',
  Suspended = 'SUSPENDED',
}

export enum LoanPaymentStatusCode {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Replaced = 'REPLACED',
  Suspended = 'SUSPENDED',
}

export type LoanPaymentSuspendResponse = {
  __typename?: 'LoanPaymentSuspendResponse'
  paymentId: Scalars['UUID']['output']
  status: LoanPaymentStatus
}

export type LoanPaymentSuspendResponseCollection = {
  __typename?: 'LoanPaymentSuspendResponseCollection'
  all: Array<LoanPaymentSuspendResponse>
}

/** Captures information related to a Loan Payment transaction */
export type LoanPaymentTransaction = {
  __typename?: 'LoanPaymentTransaction'
  accountFriendlyName?: Maybe<Scalars['String']['output']>
  accountMask?: Maybe<Scalars['String']['output']>
  failedDateTime?: Maybe<Scalars['DateTime']['output']>
  fundingId?: Maybe<Scalars['ID']['output']>
  settledDateTime?: Maybe<Scalars['DateTime']['output']>
}

export type LoanPaymentUpdate = {
  date: Scalars['DateTime']['input']
  ordinal: Scalars['Int']['input']
  paymentId: Scalars['UUID']['input']
}

export type LoanPayments = {
  __typename?: 'LoanPayments'
  originalPayments: LoanPaymentCollection
  payments: LoanPaymentCollection
}

export type LoanPaymentsCancelRequest = {
  loanId: Scalars['ID']['input']
  paymentIds: Array<Scalars['ID']['input']>
}

export type LoanPaymentsCancelResponse = {
  __typename?: 'LoanPaymentsCancelResponse'
  resultCode: LoanPaymentsCancelResultCode
  results: Array<LoanPaymentCancelResponse>
}

export enum LoanPaymentsCancelResultCode {
  Completed = 'COMPLETED',
  Unchanged = 'UNCHANGED',
}

export type LoanPaymentsFailureRequest = {
  failureCode: LoanPaymentFailureCode
  failureDescription?: InputMaybe<Scalars['String']['input']>
  loanId: Scalars['ID']['input']
  paymentIds: Array<Scalars['ID']['input']>
}

export type LoanPaymentsFailureResponse = {
  __typename?: 'LoanPaymentsFailureResponse'
  resultCode: LoanPaymentsFailureResultCode
  results: Array<LoanPaymentFailureResponse>
}

export enum LoanPaymentsFailureResultCode {
  Completed = 'COMPLETED',
  Partial = 'PARTIAL',
  Unchanged = 'UNCHANGED',
}

export type LoanPrequalification = {
  __typename?: 'LoanPrequalification'
  amount: Scalars['Decimal']['output']
  status: LoanPrequalificationStatus
}

/** Enumerates the status for prequalification */
export enum LoanPrequalificationStatus {
  /** Prequal approved */
  Approved = 'APPROVED',
  /** Prequalification is running. This is the first status */
  InProgress = 'IN_PROGRESS',
  /** More data needed from aggregators */
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  /** Prequal rejected */
  Rejected = 'REJECTED',
}

export type LoanReactivateCanceledRequest = {
  loanId: Scalars['UUID']['input']
  /** user ID of the customer who owns the loan */
  userId: Scalars['UUID']['input']
}

export type LoanReactivateCanceledResponse = {
  __typename?: 'LoanReactivateCanceledResponse'
  reactivated: Scalars['Boolean']['output']
}

export enum LoanRejectionCode {
  BankingErrorAccountLinkingError = 'BANKING_ERROR_ACCOUNT_LINKING_ERROR',
  BankingErrorAccountMinHistoryNotMet = 'BANKING_ERROR_ACCOUNT_MIN_HISTORY_NOT_MET',
  BankingErrorIncomeTooLow = 'BANKING_ERROR_INCOME_TOO_LOW',
  LoanNoStateLicense = 'LOAN_NO_STATE_LICENSE',
  LoanRejectMisc = 'LOAN_REJECT_MISC',
  LoanUserSuspendedByProcessor = 'LOAN_USER_SUSPENDED_BY_PROCESSOR',
  UserAddressIsInvalid = 'USER_ADDRESS_IS_INVALID',
  UserIddocCheckFailed = 'USER_IDDOC_CHECK_FAILED',
  UserInvalidIncomeDocs = 'USER_INVALID_INCOME_DOCS',
  UserLegalMlaCheckFailed = 'USER_LEGAL_MLA_CHECK_FAILED',
  UserSsnInvalid = 'USER_SSN_INVALID',
  UwBalanceTooLow = 'UW_BALANCE_TOO_LOW',
  UwLoanRejectCannotVerifyIncome = 'UW_LOAN_REJECT_CANNOT_VERIFY_INCOME',
  UwLoanRejectIncomeUnpredictable = 'UW_LOAN_REJECT_INCOME_UNPREDICTABLE',
  UwLoanRejectStackingBehavior = 'UW_LOAN_REJECT_STACKING_BEHAVIOR',
}

export enum LoanRole {
  /** WA and LA loans after a conversion */
  ConversionReplacement = 'CONVERSION_REPLACEMENT',
  /** Initial role for CA loans */
  InitialSinglePayment = 'INITIAL_SINGLE_PAYMENT',
  Standard = 'STANDARD',
}

export type LoanSetGrossMonthlyIncomeResponse = {
  __typename?: 'LoanSetGrossMonthlyIncomeResponse'
  /** loan ID the income ws updated for */
  id: Scalars['ID']['output']
  /** The amount income was updated to */
  income: Scalars['Decimal']['output']
}

export type LoanSetPaymentDueDatesInput = {
  dueDatesChangeReason: DueDatesChangeReason
  loanId: Scalars['UUID']['input']
  paymentDueDateChanges?: InputMaybe<Array<PaymentDueDateChange>>
}

export type LoanSetUnderwritingMonthlyIncomeInput = {
  income: Scalars['Decimal']['input']
  loanId: Scalars['UUID']['input']
}

export type LoanSetUnderwritingMonthlyIncomeResponse = {
  __typename?: 'LoanSetUnderwritingMonthlyIncomeResponse'
  /** loan ID the income ws updated for */
  id: Scalars['ID']['output']
  /** The amount income was updated to */
  income: Scalars['Decimal']['output']
}

/** Represents all potential loan states. */
export type LoanStatus =
  | ActiveLoanStatus
  | ApprovedLoanStatus
  | CancelledLoanStatus
  | ChargedOffLoanStatus
  | ExpiredLoanStatus
  | PaidOffLoanStatus
  | PendingLoanStatus
  | RejectedLoanStatus
  | ReplacedLoanStatus

export type LoanStatusChange = {
  __typename?: 'LoanStatusChange'
  changedAt: Scalars['DateTime']['output']
  newStatus: LoanStatusCode
  newSubstatus: LoanSubstatusCode
  previousStatus: LoanStatusCode
  previousSubstatus: LoanSubstatusCode
}

export type LoanStatusChangeCollection = {
  __typename?: 'LoanStatusChangeCollection'
  history: Array<LoanStatusChange>
  id: Scalars['UUID']['output']
}

/** Enumerates the canonical set of states for a loan. */
export enum LoanStatusCode {
  /**
   * Indicates that the loan is active.
   *
   * Successor states: PAIDOFF, CHARGEDOFF, CANCELLED, REPLACED
   */
  Active = 'ACTIVE',
  /**
   * Indicates that the loan application has been approved.
   *
   * Successor states: ACTIVE, CANCELLED, EXPIRED
   */
  Approved = 'APPROVED',
  /** Indicates that the loan was cancelled by the user. This is a terminal state. */
  Cancelled = 'CANCELLED',
  /**
   * Indicates that the loan was seriously delinquint and declared unlikely to be paid back. A loan in this state may
   * move back to the active state if a user contacts us and communicates their intention to resume payments.
   *
   * Successor states: ACTIVE
   */
  Chargedoff = 'CHARGEDOFF',
  /** Indicates that the loan was not accepted by the user in time following approval. This is a terminal state. */
  Expired = 'EXPIRED',
  /**
   * Indicates that the final loan payment has been submitted. After submitting payment it may take up to 5 days for the
   * payment to process; in the event that the payment fails the loan will be moved to the LATE state.
   *
   * Successor states: LATE
   */
  Paidoff = 'PAIDOFF',
  /**
   * Indicates that the loan is awaiting the outcome of a review.
   *
   * Successor states: APROVED, REJECTED
   */
  Pending = 'PENDING',
  /** Indicates that the loan application was rejected. This is a terminal state. */
  Rejected = 'REJECTED',
  /**
   * Indicates that the loan was replaced by another. @Prasad, can you share an example scearnio for this? This is a
   * terminal state.
   */
  Replaced = 'REPLACED',
}

export enum LoanSubstatusCode {
  Active = 'ACTIVE',
  ActiveCancelled = 'ACTIVE_CANCELLED',
  ActiveCancelFailed = 'ACTIVE_CANCEL_FAILED',
  ActiveCancelIssued = 'ACTIVE_CANCEL_ISSUED',
  ActiveCancelRequested = 'ACTIVE_CANCEL_REQUESTED',
  ActiveDefault = 'ACTIVE_DEFAULT',
  ActiveLate = 'ACTIVE_LATE',
  Approved = 'APPROVED',
  ApprovedAuto = 'APPROVED_AUTO',
  ApprovedManual = 'APPROVED_MANUAL',
  Cancelled = 'CANCELLED',
  CancelledActive = 'CANCELLED_ACTIVE',
  CancelledApproved = 'CANCELLED_APPROVED',
  Chargedoff = 'CHARGEDOFF',
  ChargedoffBankruptcy = 'CHARGEDOFF_BANKRUPTCY',
  ChargedoffCollectedFull = 'CHARGEDOFF_COLLECTED_FULL',
  ChargedoffCollections = 'CHARGEDOFF_COLLECTIONS',
  ChargedoffSettled = 'CHARGEDOFF_SETTLED',
  Expired = 'EXPIRED',
  ExpiredApproved = 'EXPIRED_APPROVED',
  ExpiredPending = 'EXPIRED_PENDING',
  None = 'NONE',
  Paidoff = 'PAIDOFF',
  PaidoffDefault = 'PAIDOFF_DEFAULT',
  PaidoffEarly = 'PAIDOFF_EARLY',
  PaidoffLate = 'PAIDOFF_LATE',
  Pending = 'PENDING',
  PendingIgnore = 'PENDING_IGNORE',
  PendingInfo = 'PENDING_INFO',
  PendingReview = 'PENDING_REVIEW',
  Rejected = 'REJECTED',
  RejectedAuto = 'REJECTED_AUTO',
  RejectedManual = 'REJECTED_MANUAL',
}

export type LoanSuspendResponse = {
  __typename?: 'LoanSuspendResponse'
  /** This can be reset on unsuspend */
  electronicAuthAcceptedAt?: Maybe<Scalars['DateTime']['output']>
  /** ID of the loan suspension was changed on */
  id: Scalars['ID']['output']
  payments: LoanPaymentSuspendResponseCollection
  status: LoanSuspensionStatus
}

export enum LoanSuspensionStatus {
  NotSuspended = 'NOT_SUSPENDED',
  Suspended = 'SUSPENDED',
}

export type LoanTerms = {
  __typename?: 'LoanTerms'
  collectionWeekInterval: Scalars['Int']['output']
  complexRate?: Maybe<Array<Maybe<ComplexRate>>>
  defaultAmount: Scalars['Decimal']['output']
  estimatedApr: Scalars['Decimal']['output']
  lenderApr?: Maybe<Scalars['Decimal']['output']>
  loanDurationWeeks: Scalars['Int']['output']
  maximumAmount: Scalars['Decimal']['output']
  maximumAmountRepeatLoan: Scalars['Decimal']['output']
  minimumAmount: Scalars['Decimal']['output']
  rate?: Maybe<Scalars['Decimal']['output']>
  step: Scalars['Int']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type LoanTermsSummary = {
  __typename?: 'LoanTermsSummary'
  installments: Scalars['Int']['output']
}

export type LoanType = {
  __typename?: 'LoanType'
  id: Scalars['ID']['output']
  /** @deprecated Use state instead */
  regionCode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  terms: LoanTerms
  title: Scalars['String']['output']
  variant: LoanTypeVariant
}

/** A reduced version of the LoanType, we are trying to not expose all of LoanType to the UX */
export type LoanTypeInfo = {
  __typename?: 'LoanTypeInfo'
  id: Scalars['ID']['output']
  variant: LoanTypeVariant
}

export enum LoanTypeVariant {
  ConversionInstallment = 'CONVERSION_INSTALLMENT',
  NonRecourseInstallment = 'NON_RECOURSE_INSTALLMENT',
  SinglePayment = 'SINGLE_PAYMENT',
  StandardInstallment = 'STANDARD_INSTALLMENT',
}

export type LoanWorkflowState = {
  __typename?: 'LoanWorkflowState'
  inputData: Scalars['String']['output']
  loanId: Scalars['UUID']['output']
  previousState: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type LoanWorkflowStatistics = {
  __typename?: 'LoanWorkflowStatistics'
  count: Scalars['Int']['output']
  state: Scalars['String']['output']
}

/** Information about a specific location, such as a user's home. */
export type Location = {
  __typename?: 'Location'
  /** A specific location's mailing address. */
  address?: Maybe<Address>
  /** A specific location's gps coordinates. */
  gps?: Maybe<GpsCoordinate>
  /** The time zone covering the underlying location. */
  timeZone?: Maybe<TimeZone>
}

export type LookupAddressInput = {
  /** City name. */
  city: Scalars['String']['input']
  /** Country name. */
  country: Scalars['String']['input']
  /** State code */
  state: Scalars['String']['input']
  /** Line 1 of the street address. */
  street1: Scalars['String']['input']
  /** Optional, line 2 of the street address. */
  street2?: InputMaybe<Scalars['String']['input']>
  /** Zip code. */
  zipCode: Scalars['String']['input']
}

/** Individual information to check for OFAC and MLA hits */
export type LookupInput = {
  address: LookupAddressInput
  dob: Scalars['String']['input']
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phone: Scalars['String']['input']
  ssn: Scalars['String']['input']
}

/** Result of the OFAC and MLA check */
export type LookupResult = {
  __typename?: 'LookupResult'
  errorMessage?: Maybe<Scalars['String']['output']>
  isActiveDuty?: Maybe<Scalars['Boolean']['output']>
  isOfac?: Maybe<Scalars['Boolean']['output']>
  provider?: Maybe<Provider>
}

/** The type (or channel) the message is sent to */
export enum MessageType {
  Amplitude = 'AMPLITUDE',
  Braze = 'BRAZE',
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS',
  SmsMfa = 'SMS_MFA',
  SmsVerify = 'SMS_VERIFY',
  Subspace = 'SUBSPACE',
  Ura = 'URA',
  Voice = 'VOICE',
  Zendesk = 'ZENDESK',
}

/** Represents a Map<String,String> */
export type MessageVariable = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

/** Represents the strategy for the minimum payment calculation of a program */
export type MinimumPaymentCalculation = {
  __typename?: 'MinimumPaymentCalculation'
  /** Threshold that identifies limits for min pay internal calculation logic */
  threshold: Scalars['Int']['output']
  /**
   * Type to be used in the internal calculation logic.
   * Examples:
   * * fixed $100 min pay: type=FIX_VALUE,value=100
   * * 5% of the statement balance: type=PERCENTAGE_OF_STATEMENT_BALANCE,value=5
   */
  type: MinimumPaymentCalculationType
  /** Value to be used in the internal calculation logic */
  value: Scalars['Int']['output']
}

/** Enumeration of the types available for minimum payment calculation */
export enum MinimumPaymentCalculationType {
  FixValue = 'FIX_VALUE',
  PercentageOfStatementBalance = 'PERCENTAGE_OF_STATEMENT_BALANCE',
}

export type ModifyEmailInput = {
  /** New email */
  proposedEmail: Scalars['String']['input']
  /** Target user */
  userId: Scalars['UUID']['input']
  /** Type for sending the verification code */
  verificationCodeMedium: ModifyEmailSendCodeMedium
}

export enum ModifyEmailSendCodeMedium {
  EmailCode = 'EMAIL_CODE',
  EmailLink = 'EMAIL_LINK',
}

/** Captures information needed to determine monthly gross income. */
export type MonthlyIncomeInfo = {
  __typename?: 'MonthlyIncomeInfo'
  /** Ending date for the monthly income period. */
  endPeriod?: Maybe<Scalars['Date']['output']>
  /** Gross income per month. */
  gross?: Maybe<Scalars['Decimal']['output']>
  /** Starting date for the monthly income period. */
  startPeriod?: Maybe<Scalars['Date']['output']>
}

export type MultiToggleCardAccountApplicationPropertiesResult = {
  __typename?: 'MultiToggleCardAccountApplicationPropertiesResult'
  /** Typed result of the update to card application properties */
  result: ToggleCardAccountApplicationPropertiesResult
  /** List of userids with this result */
  userIds: Array<Scalars['UUID']['output']>
}

export type MultiUserCardAccountApplicationPropertiesInput = {
  /** Card application properties that need to be set */
  properties?: InputMaybe<ToggleCardAccountApplicationPropertiesInput>
  /** Extra flags that may be required to be saved and consumed later by the clients */
  userIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type MultiUserToggleCardAccountApplicationPropertiesInput = {
  /** Card application properties that need to be set */
  properties?: InputMaybe<ToggleCardAccountApplicationPropertiesInput>
  /** Extra flags that may be required to be saved and consumed later by the clients */
  userIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type MultiUserToggleCardAccountApplicationPropertiesResponse = {
  __typename?: 'MultiUserToggleCardAccountApplicationPropertiesResponse'
  /** Collection of results */
  results: Array<MultiToggleCardAccountApplicationPropertiesResult>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Saves user bankruptcy information */
  addUserBankruptcyRecord: Scalars['Boolean']['output']
  /** Refresh transactions data */
  bankAccountRefreshTransactionsData: Scalars['Boolean']['output']
  /** Update linked account status */
  bankAccountUpdateStatus: Scalars['Boolean']['output']
  /** Adds an ACH payment method to a user banking information */
  bankAddAchPaymentMethod: AddPaymentMethodResponse
  /**
   * Created an adhoc funding authorization for a user. Returns the created funding authorization ID
   * or a GraphQL error in case of an unexpected error
   */
  bankCreateFundingAuthorization: Scalars['UUID']['output']
  /** Set linked account as primary funding source (gets account numbers from aggregator and creates payment instruments) */
  bankSetLinkedAccountAsPrimaryFundingSource: SetPrimaryAccountResponse
  bankSetProcessorAccountStatus: SetProcessorAccountStatusResponse
  bankSwitchBankingAggregator: SwitchBankingAggregatorResponse
  /** Synchronize funding sources with respective processors */
  bankSyncFundingSources: SyncFundingSourcesResponse
  /** Approve a card application from the hopper */
  cardAccountApplicationApprove?: Maybe<Scalars['ID']['output']>
  /** Cancel a card pending/approved application */
  cardAccountApplicationCancel?: Maybe<Scalars['ID']['output']>
  /** Expire a card pending/approved application */
  cardAccountApplicationExpire?: Maybe<Scalars['ID']['output']>
  /** Reject a card application from the hopper */
  cardAccountApplicationReject?: Maybe<Scalars['ID']['output']>
  /** Resume a card application from the hopper */
  cardAccountApplicationResume?: Maybe<Scalars['ID']['output']>
  cardAccountApplicationResumeToUra?: Maybe<Scalars['ID']['output']>
  /** Snooze a card application from the hopper */
  cardAccountApplicationSnooze?: Maybe<Scalars['ID']['output']>
  /** Approves a refund request */
  cardAccountApproveRefund: Scalars['Boolean']['output']
  /** Request to deactivate card account */
  cardAccountDeactivate?: Maybe<Scalars['ID']['output']>
  /** Allows the IAM operator to opt-out of pay-over-time */
  cardAccountDeactivateInstallmentPlan: Scalars['Boolean']['output']
  /** Request to decline an approved card account */
  cardAccountDecline?: Maybe<Scalars['ID']['output']>
  /**
   * Allows the IAM operator to disable automatic payments for the specified card account
   * @deprecated Use cardAccountToggleAutopay
   */
  cardAccountDisableAutomaticPayments?: Maybe<Scalars['ID']['output']>
  /**
   * Allows the IAM operator to enable automatic payments for the specified card account
   * @deprecated Use cardAccountToggleAutopay
   */
  cardAccountEnableAutomaticPayments?: Maybe<Array<AutomaticPaymentSchedule>>
  /**
   * Links the authenticated user's payment method to the authenticated user's specified card-account. For this operation
   * to succeed the following conditions must be met:
   *
   * - The payment method must already be registered to the authenticated user
   * - The payment method must be in a valid state.
   */
  cardAccountLinkPaymentMethod: Scalars['ID']['output']
  /**
   * Allows the user to set a card account payments status. This is typically only applicable to "CHECK" payments
   * where a user is allowed to do so. If a user is not allowed them the method will throw an error
   */
  cardAccountPaymentStatus?: Maybe<Payment>
  /** Request to reactivate card account */
  cardAccountReactivate?: Maybe<Scalars['ID']['output']>
  /** Rejects a refund request with a valid reason */
  cardAccountRejectRefund: Scalars['Boolean']['output']
  /** Request to replace a user's card */
  cardAccountReplaceCard?: Maybe<Scalars['ID']['output']>
  /** Modify the date for a card account scheduled payment */
  cardAccountReschedulePayment: Scalars['Boolean']['output']
  /** Allows the IAM operator to reset the payroll dates for the specified card account */
  cardAccountResetPayrollDates: Scalars['Boolean']['output']
  /**
   * Submits a card account payment for immediate or future processing at the specified execution time. This operation
   * will fail with an `errorCode` of MGE0011 if the specified payment amount does not satisfy the user's specified
   * intention.
   *
   * Note that the `cardPaymentMethodId` must have already been linked to the specified card account.
   */
  cardAccountSchedulePayment: Payment
  /** Allows the IAM operator to set a payment method as default */
  cardAccountSetDefaultPaymentMethod?: Maybe<Scalars['ID']['output']>
  /** Returns a pre-signed URL for the specified card account statement. */
  cardAccountStatementGenerateDownloadLink?: Maybe<PresignedUrl>
  /** Submit a refund request to a card account */
  cardAccountSubmitRefund: Scalars['Boolean']['output']
  /** Request to suspend card account */
  cardAccountSuspend?: Maybe<Scalars['ID']['output']>
  /** Enable or Disable autopay. Also sets autopay calculation model */
  cardAccountToggleAutopay: Scalars['Boolean']['output']
  /** Request to unlocked the card account */
  cardAccountUnlock?: Maybe<Scalars['ID']['output']>
  /** Request to activate card account */
  cardActivate?: Maybe<Scalars['ID']['output']>
  donkeyApplyStep: Scalars['Boolean']['output']
  donkeyCreateTemplate: Scalars['Boolean']['output']
  donkeyCreateUserWithConfig: DonkeyCreateUserResponse
  donkeyCreateUserWithTemplate: DonkeyCreateUserResponse
  donkeyDeleteTemplate: Scalars['Boolean']['output']
  donkeyDeleteUser: Scalars['Boolean']['output']
  donkeyUpdateTemplate: Scalars['Boolean']['output']
  /** Generates a pre-signed url with the given file name to be uploaded for cards buyback */
  generateCardBuybackUploadUrl: Scalars['String']['output']
  /** Generates a pre-signed url with the given file name to be uploaded for fund recovery partner loans upload */
  generateFundRecoveryPartnerLoansUploadPreSignedUrl: Scalars['String']['output']
  /** Generates a pre-signed url with the given file name to be uploaded for loans placement possible data upload */
  generateLoanPlacementPossibleDataUploadPreSignedUrl: Scalars['String']['output']
  /**
   * Generates a pre-signed url with the given file name to be uploaded for loans buyback
   * @deprecated Use generateLoanPlacementPossibleDataUploadPreSignedUrl
   */
  generateLoanPlacementUploadPreSignedUrl: Scalars['String']['output']
  /** Generates a pre-signed url with the given file name to be uploaded for loans buyback */
  generateLoansBuybackUploadUrl: Scalars['String']['output']
  /** Overrides account ownership verification status after a manual review */
  linkedAccountOwnershipStatusUpdate: Scalars['Boolean']['output']
  /** Approve a loan in manual review (UW) */
  loanApprove: Scalars['Boolean']['output']
  /** Cancel a loan */
  loanCancel: Scalars['Boolean']['output']
  /** Force cancel a loan, skipping the usual process and checks */
  loanCancelForce: Scalars['Boolean']['output']
  /**
   * Cancel a single loan funding authorization, according to the given strategy.
   * If the funding authorization can not be cancelled, it will cause an error.
   */
  loanCancelFundingAuthorization: Scalars['Boolean']['output']
  /** Cancel multiple payments. If any of the payments cannot be cancelled, none will be */
  loanCancelPayments: LoanPaymentsCancelResponse
  /** Manually complete a check payment */
  loanCompleteCheckPayment?: Maybe<LoanCompleteCheckPaymentResponse>
  /** Generates loan conversion options/offers for the loan */
  loanConversionOptions: LoanConversionOptionsResponse
  /** Converts loan from single payment to multiple payments */
  loanConvert: LoanConvertResponse
  /**
   * Returns an S3 presigned URL for the most recent loan document within a type
   * This is considered a mutation since it generates pre-signed URLs
   */
  loanDocumentDownload?: Maybe<PresignedUrl>
  /**
   * Move a loan to Expired.
   * This is only valid for loans currently in a Pending or Approved status
   */
  loanExpire: Scalars['Boolean']['output']
  /**
   * Update multiple payments from a completed to failed status to account for chargebacks.
   * All payments must be in a completed status, or none will process. If the funding authorization for
   * the payment fails to update, the response may indicate that there was only a partial success,
   * and will show which payments updated and which did not
   */
  loanFailPayments: LoanPaymentsFailureResponse
  /**
   * Move a currently Chargedoff loan to the chanrgedoff_settled substatus
   * This only updates the substatus and performs no other loan settlement operations
   */
  loanMarkAsSettled: Scalars['Boolean']['output']
  /**
   * Allows for the reactivation of a loan that was canceled after being accepted, generally because there
   * was an issue with the disbursement which has been corrected.
   */
  loanReactivateCanceled: LoanReactivateCanceledResponse
  /** Reject a loan in manual review (UW) */
  loanReject: Scalars['Boolean']['output']
  /**
   * Update the user's income for a loan application
   * This is primarily for validating users with joint bank accounts
   */
  loanSetGrossMonthlyIncome: LoanSetGrossMonthlyIncomeResponse
  /** Change individual payment dates on a loan */
  loanSetPaymentDates: LoanPaymentCollection
  /** Change loan payments due dates */
  loanSetPaymentDueDates?: Maybe<Scalars['Boolean']['output']>
  /**
   * Update the user's income used for underwriting during a loan application
   * This would override the value derived from analyzing bank transactions
   */
  loanSetUnderwritingMonthlyIncome: LoanSetUnderwritingMonthlyIncomeResponse
  /**
   * Suspend or unsuspend a loan. This can also cause the status of any
   * open payments to move to suspended
   */
  loanSuspend: LoanSuspendResponse
  loanUpdateDisbursementMethod?: Maybe<Scalars['Boolean']['output']>
  loanUpdatePaymentMethod?: Maybe<Scalars['Boolean']['output']>
  /**
   * Updates card related misc property for multiple users
   * @deprecated Use multiUserToggleCardAccountApplicationProperties
   */
  multiUserCardAccountToggleApplicationPropertiesRequest: Scalars['Boolean']['output']
  /** Updates card related misc property for multiple users */
  multiUserToggleCardAccountApplicationProperties: MultiUserToggleCardAccountApplicationPropertiesResponse
  /** Runs audit against specified card account */
  runCardAccountAudit?: Maybe<Scalars['Boolean']['output']>
  /** Set linked account mask by linked account id */
  setLinkedAccountMask: Scalars['Boolean']['output']
  /** Sets a user misc property */
  setUserProperty: Scalars['Boolean']['output']
  /** Cancel an active subscription for a user */
  subscriptionCancel: Subscription
  /**
   * Returns an S3 presigned URL for a loan document
   * This is considered a mutation since it generates pre-signed URLs
   */
  transformLoanDocumentKeyForDownload?: Maybe<PresignedUrl>
  uhuraCommunicationTemplateCreate: CommunicationTemplateResponse
  uhuraCommunicationTemplateRender: RenderCommunicationTemplateResponse
  uhuraCommunicationTemplateUpdate: CommunicationTemplateResponse
  /** Delete user */
  userDelete: UserDeleteResponse
  /** Create the record for the document and associate with the user */
  userDocumentCreate: UserDocumentWithPresignedUrl
  /** Generates a pre-signed url with the given file name to be uploaded for user documents collection */
  userDocumentGenerateUploadUrl: UserDocumentGenerateUploadUrlResponse
  /** Set status for a specific document by its ID */
  userDocumentSetStatus?: Maybe<Scalars['Boolean']['output']>
  /** Set sub type for a specific document by its ID */
  userDocumentSetSubType?: Maybe<Scalars['Boolean']['output']>
  /** Submit a review for a KYC Validation record */
  userKycValidationReview: KycValidationReviewResponse
  /** Updates the user's address components by type */
  userModifyAddressComponents?: Maybe<Location>
  /** Changes logged user email */
  userModifyEmail: Scalars['Boolean']['output']
  /**
   * Creates a new user note. The `noteId` field in the `UserNoteKeyInput` is optional here. If the caller omits it then
   * the server will generate a random one; otherwise the client supplied value will get passed.
   */
  userNoteCreate?: Maybe<UserNote>
  /**
   * Updates an existing user note. The `noteId` field in `UserNoteKeyInput` is required here. An error is emitted if
   * the client omits that field.
   */
  userNoteUpdate?: Maybe<UserNote>
  /** Send messages (multiple types) to a user with a templateId and aggregate the result of each send into a response object */
  userSendMessages: UserSendMessagesResponse
  /** Sends a temporary password to a user */
  userSendTemporaryPassword: Scalars['Boolean']['output']
  userSetCreditReporting: Scalars['Boolean']['output']
  userSetStatus?: Maybe<User>
  /** Set URA status */
  userSetUraStatus: SetUraStatusResponse
  /** Updates the communication preferences for a given user */
  userUpdateCommunicationPreferences: CommunicationPreferences
  userUpdateProfile?: Maybe<User>
  /** Sends a verification code to be used to verify the email */
  userVerifyEmailSendCode: Scalars['Boolean']['output']
  /** Reject a loan application with the given status code. */
  workflowReject: Scalars['Boolean']['output']
  /** Restart a failed workflow in the hopes that whatever was broken is now working. */
  workflowRestart: Scalars['Boolean']['output']
  /** Resume a loan application. */
  workflowResume: Scalars['Boolean']['output']
  /** Resume from a manual review by sending one or more URAs to the user. */
  workflowSendUras: Scalars['Boolean']['output']
  /** Hide the manual review from review for an hour in the vain hope that whatever is wrong miraculously goes away. */
  workflowSnooze: Scalars['Boolean']['output']
}

export type MutationAddUserBankruptcyRecordArgs = {
  createdById: Scalars['UUID']['input']
  usersBankruptcy: UserBankruptcyInput
}

export type MutationBankAccountRefreshTransactionsDataArgs = {
  daysRequested: Scalars['Int']['input']
  userId: Scalars['ID']['input']
}

export type MutationBankAccountUpdateStatusArgs = {
  linkedAccountId: Scalars['ID']['input']
  newStatus: LinkedAccountStatusCode
}

export type MutationBankAddAchPaymentMethodArgs = {
  input: AchPaymentMethodInput
  userId: Scalars['ID']['input']
}

export type MutationBankCreateFundingAuthorizationArgs = {
  input: FundingAuthorizationInput
  userId: Scalars['ID']['input']
}

export type MutationBankSetLinkedAccountAsPrimaryFundingSourceArgs = {
  input: SetPrimaryAccountInput
}

export type MutationBankSetProcessorAccountStatusArgs = {
  input?: InputMaybe<SetProcessorAccountStatusInput>
}

export type MutationBankSwitchBankingAggregatorArgs = {
  input?: InputMaybe<SwitchBankingAggregatorInput>
}

export type MutationBankSyncFundingSourcesArgs = {
  input?: InputMaybe<SyncFundingSourcesInput>
}

export type MutationCardAccountApplicationApproveArgs = {
  maxCreditLimit: Scalars['Decimal']['input']
  taskId: Scalars['ID']['input']
}

export type MutationCardAccountApplicationCancelArgs = {
  taskId: Scalars['ID']['input']
}

export type MutationCardAccountApplicationExpireArgs = {
  taskId: Scalars['ID']['input']
}

export type MutationCardAccountApplicationRejectArgs = {
  reviewReasonCode?: InputMaybe<Scalars['Int']['input']>
  taskId: Scalars['ID']['input']
}

export type MutationCardAccountApplicationResumeArgs = {
  taskId: Scalars['ID']['input']
}

export type MutationCardAccountApplicationResumeToUraArgs = {
  taskId: Scalars['ID']['input']
  uraData?: InputMaybe<Array<InputMaybe<UraDataInput>>>
}

export type MutationCardAccountApplicationSnoozeArgs = {
  snoozeForHours: Scalars['Int']['input']
  taskId: Scalars['ID']['input']
}

export type MutationCardAccountApproveRefundArgs = {
  refundId: Scalars['ID']['input']
}

export type MutationCardAccountDeactivateArgs = {
  cardAccountId: Scalars['ID']['input']
  deactivationStatusReason: Scalars['String']['input']
}

export type MutationCardAccountDeactivateInstallmentPlanArgs = {
  cardAccountId: Scalars['ID']['input']
}

export type MutationCardAccountDeclineArgs = {
  cardAccountId: Scalars['ID']['input']
}

export type MutationCardAccountDisableAutomaticPaymentsArgs = {
  cardAccountId: Scalars['ID']['input']
  reason: Scalars['String']['input']
}

export type MutationCardAccountEnableAutomaticPaymentsArgs = {
  cardAccountId: Scalars['ID']['input']
  cardPaymentMethodId: Scalars['ID']['input']
}

export type MutationCardAccountLinkPaymentMethodArgs = {
  bankingPaymentInstrumentId: Scalars['ID']['input']
  cardAccountId: Scalars['ID']['input']
  makePrimary?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationCardAccountPaymentStatusArgs = {
  cardAccountId: Scalars['ID']['input']
  paymentId: Scalars['ID']['input']
  status: CardPaymentStatus
}

export type MutationCardAccountReactivateArgs = {
  cardAccountId: Scalars['ID']['input']
  reactivationStatusReason: Scalars['String']['input']
}

export type MutationCardAccountRejectRefundArgs = {
  reason: Scalars['String']['input']
  refundId: Scalars['ID']['input']
}

export type MutationCardAccountReplaceCardArgs = {
  cardAccountId: Scalars['ID']['input']
  cardId: Scalars['ID']['input']
  cardReplacementReason: Scalars['String']['input']
  shippingMethod?: InputMaybe<ShippingMethod>
}

export type MutationCardAccountReschedulePaymentArgs = {
  input: CardAccountReschedulePaymentInput
}

export type MutationCardAccountResetPayrollDatesArgs = {
  cardAccountId: Scalars['ID']['input']
  payrollDates: PayrollDateCollectionInput
}

export type MutationCardAccountSchedulePaymentArgs = {
  cardAccountId?: InputMaybe<Scalars['ID']['input']>
  cardPaymentMethodId?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<CardAccountSchedulePaymentInput>
  payment?: InputMaybe<PaymentSpecificationInput>
}

export type MutationCardAccountSetDefaultPaymentMethodArgs = {
  cardAccountId: Scalars['UUID']['input']
  paymentMethodId: Scalars['UUID']['input']
}

export type MutationCardAccountStatementGenerateDownloadLinkArgs = {
  cardAccountId: Scalars['UUID']['input']
  statementId: Scalars['UUID']['input']
}

export type MutationCardAccountSubmitRefundArgs = {
  input: CardAccountSubmitRefundInput
}

export type MutationCardAccountSuspendArgs = {
  cardAccountId: Scalars['ID']['input']
  suspensionStatusReason: Scalars['String']['input']
}

export type MutationCardAccountToggleAutopayArgs = {
  input: CardAccountToggleAutopayInput
}

export type MutationCardAccountUnlockArgs = {
  cardAccountId: Scalars['ID']['input']
}

export type MutationCardActivateArgs = {
  cardAccountId: Scalars['ID']['input']
  cardId: Scalars['ID']['input']
}

export type MutationDonkeyApplyStepArgs = {
  input?: InputMaybe<DonkeyApplyStepInput>
}

export type MutationDonkeyCreateTemplateArgs = {
  input?: InputMaybe<DonkeyCreateTemplateInput>
}

export type MutationDonkeyCreateUserWithConfigArgs = {
  input?: InputMaybe<DonkeyCreateUserWithConfigInput>
}

export type MutationDonkeyCreateUserWithTemplateArgs = {
  input?: InputMaybe<DonkeyCreateUserWithTemplateInput>
}

export type MutationDonkeyDeleteTemplateArgs = {
  templateId: Scalars['UUID']['input']
}

export type MutationDonkeyDeleteUserArgs = {
  input?: InputMaybe<DonkeyDeleteUserInput>
}

export type MutationDonkeyUpdateTemplateArgs = {
  input: DonkeyUpdateTemplateInput
}

export type MutationGenerateCardBuybackUploadUrlArgs = {
  fileName: Scalars['String']['input']
}

export type MutationGenerateFundRecoveryPartnerLoansUploadPreSignedUrlArgs = {
  fileName: Scalars['String']['input']
}

export type MutationGenerateLoanPlacementPossibleDataUploadPreSignedUrlArgs = {
  fileName: Scalars['String']['input']
}

export type MutationGenerateLoanPlacementUploadPreSignedUrlArgs = {
  fileName: Scalars['String']['input']
}

export type MutationGenerateLoansBuybackUploadUrlArgs = {
  fileName: Scalars['String']['input']
}

export type MutationLinkedAccountOwnershipStatusUpdateArgs = {
  linkedAccountId: Scalars['UUID']['input']
  validationStatus: LinkedAccountOwnershipValidationStatus
}

export type MutationLoanApproveArgs = {
  loanId: Scalars['UUID']['input']
}

export type MutationLoanCancelArgs = {
  input: LoanCancelInput
}

export type MutationLoanCancelForceArgs = {
  input: LoanCancelInput
}

export type MutationLoanCancelFundingAuthorizationArgs = {
  request: LoanCancelFundingAuthorizationRequest
}

export type MutationLoanCancelPaymentsArgs = {
  request: LoanPaymentsCancelRequest
}

export type MutationLoanCompleteCheckPaymentArgs = {
  completionDate: Scalars['LocalDate']['input']
  paymentId: Scalars['UUID']['input']
}

export type MutationLoanConversionOptionsArgs = {
  input?: InputMaybe<LoanConversionOptionsInput>
}

export type MutationLoanConvertArgs = {
  input?: InputMaybe<LoanConvertInput>
}

export type MutationLoanDocumentDownloadArgs = {
  loanId: Scalars['ID']['input']
  type: LoanDocumentType
}

export type MutationLoanExpireArgs = {
  loanId: Scalars['UUID']['input']
}

export type MutationLoanFailPaymentsArgs = {
  request: LoanPaymentsFailureRequest
}

export type MutationLoanMarkAsSettledArgs = {
  loanId: Scalars['UUID']['input']
}

export type MutationLoanReactivateCanceledArgs = {
  request: LoanReactivateCanceledRequest
}

export type MutationLoanRejectArgs = {
  loanId: Scalars['UUID']['input']
  rejectionCode: LoanRejectionCode
  rejectionReason?: InputMaybe<Scalars['String']['input']>
}

export type MutationLoanSetGrossMonthlyIncomeArgs = {
  income: Scalars['Decimal']['input']
  loanId: Scalars['UUID']['input']
  userId: Scalars['UUID']['input']
}

export type MutationLoanSetPaymentDatesArgs = {
  paymentDatesInput: LoanPaymentDatesUpdate
}

export type MutationLoanSetPaymentDueDatesArgs = {
  input: LoanSetPaymentDueDatesInput
}

export type MutationLoanSetUnderwritingMonthlyIncomeArgs = {
  input?: InputMaybe<LoanSetUnderwritingMonthlyIncomeInput>
}

export type MutationLoanSuspendArgs = {
  loanId: Scalars['UUID']['input']
  status: LoanSuspensionStatus
  userId: Scalars['UUID']['input']
}

export type MutationLoanUpdateDisbursementMethodArgs = {
  loanId: Scalars['UUID']['input']
  network?: InputMaybe<ProcessorNetwork>
  paymentInstrumentId: Scalars['UUID']['input']
}

export type MutationLoanUpdatePaymentMethodArgs = {
  loanId: Scalars['UUID']['input']
  network?: InputMaybe<ProcessorNetwork>
  paymentInstrumentId: Scalars['UUID']['input']
}

export type MutationMultiUserCardAccountToggleApplicationPropertiesRequestArgs = {
  applicationDataInput: MultiUserCardAccountApplicationPropertiesInput
}

export type MutationMultiUserToggleCardAccountApplicationPropertiesArgs = {
  input: MultiUserToggleCardAccountApplicationPropertiesInput
}

export type MutationRunCardAccountAuditArgs = {
  cardAccountId: Scalars['UUID']['input']
}

export type MutationSetLinkedAccountMaskArgs = {
  linkedAccountId: Scalars['ID']['input']
  mask: Scalars['String']['input']
}

export type MutationSetUserPropertyArgs = {
  property: Scalars['String']['input']
  userId: Scalars['UUID']['input']
  value: Scalars['String']['input']
}

export type MutationSubscriptionCancelArgs = {
  reason: Scalars['String']['input']
  subscriptionId: Scalars['UUID']['input']
}

export type MutationTransformLoanDocumentKeyForDownloadArgs = {
  documentKey: Scalars['String']['input']
  type: LoanDocumentType
}

export type MutationUhuraCommunicationTemplateCreateArgs = {
  communicationTemplateInput: CommunicationTemplateInput
}

export type MutationUhuraCommunicationTemplateRenderArgs = {
  renderCommunicationTemplateInput: RenderCommunicationTemplateInput
}

export type MutationUhuraCommunicationTemplateUpdateArgs = {
  communicationTemplateInput: CommunicationTemplateInput
}

export type MutationUserDeleteArgs = {
  input: UserDeleteInput
}

export type MutationUserDocumentCreateArgs = {
  input: CreateUserDocumentInput
}

export type MutationUserDocumentGenerateUploadUrlArgs = {
  fileName: Scalars['String']['input']
  userId: Scalars['UUID']['input']
}

export type MutationUserDocumentSetStatusArgs = {
  documentId: Scalars['UUID']['input']
  status: UserDocumentStatus
}

export type MutationUserDocumentSetSubTypeArgs = {
  documentId: Scalars['UUID']['input']
  status: UserDocumentSubType
}

export type MutationUserKycValidationReviewArgs = {
  input: KycValidationReviewInput
}

export type MutationUserModifyAddressComponentsArgs = {
  addressId?: InputMaybe<Scalars['ID']['input']>
  addressType: AddressType
  physicalAddress: ApplicantPhysicalAddressComponentsInput
  userId: Scalars['ID']['input']
}

export type MutationUserModifyEmailArgs = {
  input: ModifyEmailInput
}

export type MutationUserNoteCreateArgs = {
  key: UserNoteKeyInput
  text: Scalars['String']['input']
}

export type MutationUserNoteUpdateArgs = {
  key: UserNoteKeyInput
  text: Scalars['String']['input']
}

export type MutationUserSendMessagesArgs = {
  input: UserSendMessagesInput
}

export type MutationUserSendTemporaryPasswordArgs = {
  userId: Scalars['UUID']['input']
}

export type MutationUserSetCreditReportingArgs = {
  userId: Scalars['UUID']['input']
  value: Scalars['Boolean']['input']
}

export type MutationUserSetStatusArgs = {
  status: UserStatusCode
  subStatus: UserSubStatus
  userId: Scalars['ID']['input']
}

export type MutationUserSetUraStatusArgs = {
  input?: InputMaybe<SetUraStatusInput>
}

export type MutationUserUpdateCommunicationPreferencesArgs = {
  input?: InputMaybe<UserUpdateCommunicationPreferencesInput>
}

export type MutationUserUpdateProfileArgs = {
  userId: Scalars['ID']['input']
  userProfileInput: UserProfileInput
}

export type MutationUserVerifyEmailSendCodeArgs = {
  input: UserVerifyEmailSendCodeInput
}

export type MutationWorkflowRejectArgs = {
  input?: InputMaybe<WorkflowRejectInput>
}

export type MutationWorkflowRestartArgs = {
  loanId: Scalars['UUID']['input']
}

export type MutationWorkflowResumeArgs = {
  loanId: Scalars['UUID']['input']
}

export type MutationWorkflowSendUrasArgs = {
  input: WorkflowSendUraInput
}

export type MutationWorkflowSnoozeArgs = {
  loanId: Scalars['UUID']['input']
}

/** User's name. */
export type Name = {
  __typename?: 'Name'
  /** User's first name. */
  firstName: Scalars['String']['output']
  /** User's last name. */
  lastName?: Maybe<Scalars['String']['output']>
  /** User's middle name. */
  middleName?: Maybe<Scalars['String']['output']>
  /** User's name suffix. */
  suffix?: Maybe<Scalars['String']['output']>
}

/** Represents an entity descriptor with a friendly name */
export type NamedEntityDescriptor = {
  __typename?: 'NamedEntityDescriptor'
  friendlyName: Scalars['String']['output']
  id: Scalars['ID']['output']
  type: Scalars['String']['output']
}

export type NextPaymentDate = {
  __typename?: 'NextPaymentDate'
  /** The desired DateTime adjusted considering banking Holidays and other rules */
  adjustedSettlementDatetime?: Maybe<Scalars['DateTime']['output']>
  /** Returns true if the adjusted DateTime is not the same as the desired DateTime */
  desiredSettlementDateTimeAdjusted?: Maybe<Scalars['Boolean']['output']>
  /** The desired DateTime for a payment */
  desiredSettlementDatetime?: Maybe<Scalars['DateTime']['output']>
  /** Return the base delay in days for the processor network */
  settlementDelayDays?: Maybe<Scalars['Int']['output']>
  /** Returns true if the processor accepts payments on Holidays and Weekends */
  settlesOnHolidaysAndWeekends?: Maybe<Scalars['Boolean']['output']>
  /** Returns a DateTime adjusted by the submission cut off time delay */
  submitForProcessingBy?: Maybe<Scalars['DateTime']['output']>
  /** The current DateTime */
  timeNow?: Maybe<Scalars['DateTime']['output']>
}

/** Canonical set of entity types that notes can be attached to. */
export enum NoteParentEntityTypeCode {
  Card = 'CARD',
  Loan = 'LOAN',
  User = 'USER',
}

export type Offer = {
  __typename?: 'Offer'
  /** Annual fee applicable to the offer */
  annualFee: Scalars['Decimal']['output']
  /** Approved amount */
  approvedAmount: Scalars['Decimal']['output']
  /** APR applicable to the offer */
  apr: Scalars['Decimal']['output']
  /** Unique id for offer */
  id: Scalars['ID']['output']
  /** Interest rate applicable to the offer */
  interestRate: Scalars['Decimal']['output']
  /** The monthly payment amount */
  monthlyPayment: Scalars['Decimal']['output']
  /** Loan duration in months */
  months: Scalars['Int']['output']
  /** The origination fee for the offer */
  originationFee: Scalars['Decimal']['output']
  /** Partner ID responsible for the offer request */
  partnerId: PartnerId
  /** Offer status */
  status: Status
  /** Offer title */
  title: Scalars['String']['output']
  /** Landing Page URL (deep link) that the consumer will be redirected to, to complete the online application */
  url: Scalars['String']['output']
}

export type OnboardingCurrentModule = {
  __typename?: 'OnboardingCurrentModule'
  currentModule: OnboardingModule
  nextModulesAllowed: OnboardingNextModulesAllowedCollection
  onboardingFinished: Scalars['Boolean']['output']
  previousModules: OnboardingPreviousModulesCollection
}

/** Groups data related to user onboarding flow (generic) */
export type OnboardingInfo = {
  __typename?: 'OnboardingInfo'
  loan?: Maybe<LoanOnboardingInfo>
}

export enum OnboardingModule {
  BasicPii = 'BASIC_PII',
  CardActivation = 'CARD_ACTIVATION',
  CardBankLink = 'CARD_BANK_LINK',
  CardEmailVerification = 'CARD_EMAIL_VERIFICATION',
  CardEnd = 'CARD_END',
  CardLv2Selected = 'CARD_LV2_SELECTED',
  /** card specific modules */
  CardLv2Ssn = 'CARD_LV2_SSN',
  CardLv3Selected = 'CARD_LV3_SELECTED',
  CardLv3Ssn = 'CARD_LV3_SSN',
  CardPhotoId = 'CARD_PHOTO_ID',
  CardProcessing = 'CARD_PROCESSING',
  CardSelfie = 'CARD_SELFIE',
  CardSubmission = 'CARD_SUBMISSION',
  HomeAddress = 'HOME_ADDRESS',
  LoanAcceptance = 'LOAN_ACCEPTANCE',
  LoanBankLink = 'LOAN_BANK_LINK',
  LoanEmailVerification = 'LOAN_EMAIL_VERIFICATION',
  LoanEnd = 'LOAN_END',
  LoanHowMuch = 'LOAN_HOW_MUCH',
  LoanPhotoId = 'LOAN_PHOTO_ID',
  LoanProcessing = 'LOAN_PROCESSING',
  LoanSelected = 'LOAN_SELECTED',
  LoanSelfie = 'LOAN_SELFIE',
  /** loan specific modules */
  LoanSsn = 'LOAN_SSN',
  LoanSubmission = 'LOAN_SUBMISSION',
  ProductSelection = 'PRODUCT_SELECTION',
  Start = 'START',
  VerifyPhoneNumber = 'VERIFY_PHONE_NUMBER',
  /** no product available for the user */
  Waitlist = 'WAITLIST',
}

export type OnboardingNextModulesAllowedCollection = {
  __typename?: 'OnboardingNextModulesAllowedCollection'
  nextModulesAllowed: Array<OnboardingModule>
}

export type OnboardingPreviousModulesCollection = {
  __typename?: 'OnboardingPreviousModulesCollection'
  previousModules: Array<OnboardingModule>
}

export type OverDuePaymentInfo = {
  __typename?: 'OverDuePaymentInfo'
  /** Total overdue amount */
  amount?: Maybe<Scalars['Decimal']['output']>
  /** Oldest pending payment due date */
  paymentDueAt?: Maybe<Scalars['DateTime']['output']>
}

/**
 * Input responsible for controlling offset based pagination, allowing the client to jump to any page. Each page is a chunk
 * of data identified by an integer, starting by 1, the subsequent pages will skip the first count*number items, until there
 * are no more items to be returned.
 */
export type PageInfoInput = {
  /** Determines how many items are returned per page. */
  count: Scalars['Int']['input']
  /** The `number` determines where to begin pagination. It should start in 1. Each page has a size, indicated by the `count` property */
  number: Scalars['Int']['input']
}

/** Input responsible for controlling cursor based pagination, usually with an opaque cursor. */
export type PageSelectorInput = {
  /**
   * Determines how many items are returned per page. Leaving this value empty, or 0, produces undefined results. Some
   * pagination will default to a finite count and others may return the full recordset.
   */
  count?: InputMaybe<Scalars['Int']['input']>
  /**
   * The `cursor` determines where to begin pagination. All elements occuring AFTER the cursor will be returned. When
   * null or an empty string get supplied the pagination begins at the target collections canonical beginning.
   */
  cursor?: InputMaybe<Scalars['String']['input']>
}

/** The standard interface for paginated entities. */
export type Paginated = {
  /** Metadata that can be used to fetch the next page. */
  cursor: PaginationCursor
}

/** Metadata used to support pagination based lookups. */
export type PaginationCursor = {
  __typename?: 'PaginationCursor'
  /** Indicates whether there are pages after the returned page. */
  continues: Scalars['Boolean']['output']
  /**
   * Opaque cursor used to identify the last element of the returned page. This cursor can be used to fetch the next page
   * by supplying to the `cursor` field of a `PageSelectorInput` input object. The result set will consist of the first
   * `n` elements immediately after the item identified by this cursor.
   */
  last: Scalars['String']['output']
}

/** Represents a loan in the PAIDOFF state. */
export type PaidOffLoanStatus = BaseLoanStatus & {
  __typename?: 'PaidOffLoanStatus'
  /** Indicates the loan amount disbursed to the borrower. */
  amountBorrowed?: Maybe<Scalars['Decimal']['output']>
  /** Canonical loan status code; the value is always PAIDOFF. */
  code: LoanStatusCode
  /** Indicates when the loan transitioned into the PAIDOFF state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

export enum PartnerId {
  LendingTree = 'LENDING_TREE',
}

/** Captures information about a payment submitted by the user. */
export type Payment = ChangeTracked & {
  __typename?: 'Payment'
  /** Payment amount */
  amount?: Maybe<Scalars['Decimal']['output']>
  /** Card account id associated with this payment */
  cardAccountId: Scalars['ID']['output']
  /** Timestamp tracking the payments creation timestamp. */
  createdAt: Scalars['DateTime']['output']
  /** Timestamp tracking the scheduled execution date for the payment */
  executeAt: Scalars['DateTime']['output']
  /** Funding authorization associated with this payment */
  fundingAuthorization?: Maybe<FundingAuthorization>
  /**
   * Funding authorization id associated with this payment
   * @deprecated Use fundingAuthorization field
   */
  fundingAuthorizationId?: Maybe<Scalars['ID']['output']>
  /** Unique synthetic identifier for this payment. */
  id: Scalars['ID']['output']
  /** Date the payment was initiated */
  initiatedAt?: Maybe<Scalars['DateTime']['output']>
  /** Type of card payment */
  paymentType?: Maybe<CardPaymentType>
  /** Date the payment was posted */
  postedAt?: Maybe<Scalars['DateTime']['output']>
  /** Date the payment was schedule */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>
  /** The status of this payment */
  status: CardPaymentStatus
  /** Status code associated with this payment */
  statusCode?: Maybe<Scalars['String']['output']>
  /** Reason for this payment's status */
  statusReason?: Maybe<Scalars['String']['output']>
  /** Timestamp tracking the payments last update timestamp. */
  updatedAt: Scalars['DateTime']['output']
}

export type PaymentDueDateChange = {
  dueDate: Scalars['LocalDate']['input']
  paymentId: Scalars['UUID']['input']
}

/**
 * Object wrapping information for the payment execution
 * Execution means the moment the payment should be staged and the balance cut off to submit it to banking
 */
export type PaymentExecutionInfo = {
  __typename?: 'PaymentExecutionInfo'
  /**
   * Timestamp representing the exactly time the balance will be cut off to submit the payment to banking
   * Usually, for ACH this represents one day before the payment due date
   */
  executeAt: Scalars['DateTime']['output']
}

/** Canonical set of payment scheduling frequencies. */
export enum PaymentFrequency {
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Unknown = 'UNKNOWN',
  Unspecified = 'UNSPECIFIED',
  Weekly = 'WEEKLY',
}

export type PaymentInstrument = {
  __typename?: 'PaymentInstrument'
  availableNetworks: Array<ProcessorNetwork>
  friendlyName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  linkedAccountId?: Maybe<Scalars['UUID']['output']>
  mask: Scalars['String']['output']
  status: PaymentInstrumentStatus
  verifiedByAllProviders: Scalars['Boolean']['output']
}

export type PaymentInstrumentCollection = {
  __typename?: 'PaymentInstrumentCollection'
  all: Array<PaymentInstrument>
  usable: Array<PaymentInstrument>
}

export type PaymentInstrumentCollectionUsableArgs = {
  direction: FundingDirection
}

/** Canonical set of payment instrument states. */
export enum PaymentInstrumentStatus {
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Removed = 'REMOVED',
  Suspended = 'SUSPENDED',
  SystemPurged = 'SYSTEM_PURGED',
  Verified = 'VERIFIED',
}

/** Indicates the user's payment intentions. */
export enum PaymentIntention {
  /**
   * Signals the user's intention to pay their cards full balance. This call fails and returns a MGE0011 `errorCode` if
   * the payment amount does not exactly match the current card balance at submission time (now).
   */
  FullBalance = 'FULL_BALANCE',
  /**
   * Signals the user's intention to pay their card's statement minimum payment. This call fails and returns a MGE0011
   * `errorCode` if the payment amount does not exactly match the target statements min pay at submission time (now).
   */
  MinimumPayment = 'MINIMUM_PAYMENT',
  /** Signals the user's intention to pay a portion of their card balance. */
  PartialPayment = 'PARTIAL_PAYMENT',
  /**
   * Signals the user's intention to pay their card's past due amount. This call fails and returns a MGE0011
   * `errorCode` if the payment amount does not exactly match the target statements min pay at submission time (now).
   */
  PastDue = 'PAST_DUE',
  /**
   * Signals the user's intention to pay their card's statement balance. This call fails and returns a MGE0011
   * `errorCode` if the payment amount does not exactly match the target statements balance at submission time (now).
   */
  StatementBalance = 'STATEMENT_BALANCE',
}

/** Captures information common to all payment methods. */
export type PaymentMethod = {
  /** List of all payments networks available for this payment method */
  availableNetworks: Array<ProcessorNetwork>
  /** Banking payment instrument id (this is a global ID for this payment method relevant to our banking subsystem) */
  bankingPaymentInstrumentId: Scalars['ID']['output']
  /** Canonical payment method type category. */
  code: PaymentMethodTypeCode
  /** Timestamp tracking the payment instrument creation. */
  createdAt: Scalars['DateTime']['output']
  /** Indicates the payment directions for which this is a default method */
  defaultStatus: Array<DefaultPaymentMethodCode>
  /** Unique synthetic identifier for this payment method. */
  id: Scalars['ID']['output']
  /** Gets the next feasible payment date for a payment method */
  nextPaymentDate: NextPaymentDate
  /** Captures the payment methods current status. */
  status: PaymentMethodStatus
  /** Timestamp tracking the payment instrument last modification. */
  updatedAt: Scalars['DateTime']['output']
}

/** Captures information common to all payment methods. */
export type PaymentMethodNextPaymentDateArgs = {
  desiredSettlementTime: Scalars['DateTime']['input']
}

/** Collection of payment methods that the user has registered to their account. */
export type PaymentMethodCollection = {
  __typename?: 'PaymentMethodCollection'
  /** All payment methods currently registered to the user. */
  all?: Maybe<Array<Maybe<PaymentMethodType>>>
  /** All payment methods currently registered to the user that can be used as a card payment method. */
  cardEligible?: Maybe<Array<CardPaymentMethodType>>
  /** All payment methods currently registered to the user that can be used as a loan payment method. */
  loanEligible?: Maybe<Array<LoanPaymentMethodType>>
}

/** Collection of payment methods that the user has registered to their account. */
export type PaymentMethodCollectionAllArgs = {
  typeCodeFilter?: InputMaybe<Array<PaymentMethodTypeCode>>
}

/** Collection of payment methods that the user has registered to their account. */
export type PaymentMethodCollectionCardEligibleArgs = {
  typeCodeFilter?: InputMaybe<Array<PaymentMethodTypeCode>>
}

/** Collection of payment methods that the user has registered to their account. */
export type PaymentMethodCollectionLoanEligibleArgs = {
  typeCodeFilter?: InputMaybe<Array<PaymentMethodTypeCode>>
}

/** Information about the current state for the payment method. */
export type PaymentMethodStatus = {
  /** Canonical code associated with the current state. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the given state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

/** Canonical set of payment method states. */
export enum PaymentMethodStatusCode {
  /** Indicates that the payment method has expired. */
  Expired = 'EXPIRED',
  /** Indicates that the payment method is invalid. */
  Invalid = 'INVALID',
  /** Indicates the payment method is registered but not verified. */
  Pending = 'PENDING',
  /** Indicates that the payment method was removed by the user or support. */
  Removed = 'REMOVED',
  /** Indicates that the payment method has been suspended. (??? fraud) */
  Suspended = 'SUSPENDED',
  /** Indicates that the payment method has been purged from the system. */
  SystemPurged = 'SYSTEM_PURGED',
  /** Indicates the payment method's ownership by the user has been confirmed. */
  Verified = 'VERIFIED',
}

export type PaymentMethodStatusType =
  | ExpiredPaymentMethodStatus
  | InvalidPaymentMethodStatus
  | PendingPaymentMethodStatus
  | RemovedPaymentMethodStatus
  | SuspendedPaymentMethodStatus
  | SystemPurgedPaymentMethodStatus
  | VerifiedPaymentMethodStatus

/** All registerable payment methods. */
export type PaymentMethodType = AchPaymentMethod | CheckPaymentMethod | DebitCardPaymentMethod

/** Canonical set of payment method types. */
export enum PaymentMethodTypeCode {
  /** Code used for ACH authorizations */
  Ach = 'ACH',
  /** Code used for checks */
  Check = 'CHECK',
  /** Code used for debit cards */
  DebitCard = 'DEBIT_CARD',
}

/** Provides information regarding payment periods and due dates */
export type PaymentPeriodCollection = {
  __typename?: 'PaymentPeriodCollection'
  /** Set of active payment periods */
  active?: Maybe<Array<CardPaymentPeriod>>
  /** current payment period */
  current: CardPaymentPeriod
  /** Set of payment due dates */
  dueDates?: Maybe<Array<Scalars['LocalDate']['output']>>
  /** Provides overdue payment information */
  overdue?: Maybe<OverDuePaymentInfo>
  /**
   * Provides a paginated view of all previous payment periods sorted in reverse chronological order.
   * When no `pageSelector` is supplied all previous payment periods is returned.
   */
  previous: PaymentPeriodPage
}

/** Provides information regarding payment periods and due dates */
export type PaymentPeriodCollectionPreviousArgs = {
  pageSelector?: InputMaybe<PageSelectorInput>
}

/** A paginated card account payment period view. */
export type PaymentPeriodPage = Paginated & {
  __typename?: 'PaymentPeriodPage'
  /** Metadata that can be used to fetch the next page. */
  cursor: PaginationCursor
  /** The payment period contained by this page. */
  items?: Maybe<Array<CardPaymentPeriod>>
}

export enum PaymentProcessorTypeCode {
  Check = 'CHECK',
  Coastal = 'COASTAL',
  Donkey = 'DONKEY',
  Dwolla = 'DWOLLA',
  Fiserv = 'FISERV',
  Leadbank = 'LEADBANK',
  Lpp = 'LPP',
  LppAch = 'LPP_ACH',
  Pnc = 'PNC',
  PncAch = 'PNC_ACH',
  PncRtp = 'PNC_RTP',
  RepublicBankOfChicago = 'REPUBLIC_BANK_OF_CHICAGO',
  Seattlebank = 'SEATTLEBANK',
  Stripe = 'STRIPE',
  Synapsefi = 'SYNAPSEFI',
}

/** Provides user payment specifications. */
export type PaymentSpecificationInput = {
  /** Indicates the user's payment amount. */
  amount: Scalars['Decimal']['input']
  /** Indicates the user's desired processing start date for this payment. */
  executeDate?: InputMaybe<Scalars['DateTime']['input']>
  /**
   * Indicates the user's objective with this payment. Validation will run during this call to determine whether the
   * user's objective is met by the payment; the payment is reject and an `errorCode` of MGE0011 is returned if the
   * amount does not meet the objective.
   */
  intention: PaymentIntention
}

/** Canonical set of payment states. */
export enum PaymentStatusCode {
  /** The payment was cancelled. */
  Cancelled = 'CANCELLED',
  /** The payment failed. */
  Failed = 'FAILED',
  /** The payment has posted and is currently processing. */
  Posted = 'POSTED',
  /** The payment was replaced. Usually this replacement involves going from a single payment to an installment. */
  Replaced = 'REPLACED',
  /** The payment was originally processed successfully but was later reversed by the banking institution. */
  Reversed = 'REVERSED',
  /** The payment post date is in the future. */
  Scheduled = 'SCHEDULED',
  /** The payment was processed successfully. */
  Succeeded = 'SUCCEEDED',
  /** The payment was suspended. */
  Suspended = 'SUSPENDED',
}

/** Payment method for an ACH authorization. */
export type PaymentsProcessor = {
  __typename?: 'PaymentsProcessor'
  /** Canonical payment processor type category */
  code: PaymentProcessorTypeCode
  /** Supported compliance codes by this processor */
  complianceCodes?: Maybe<Array<ComplianceCodes>>
  /** Unique synthetic identifier for this processor (can be null if the processor hasn't been initialized yet) */
  id?: Maybe<Scalars['UUID']['output']>
  /** Processor status code */
  status: ProcessorStatus
  /** Processor status code */
  subStatus: ProcessorSubStatus
}

/** Collection of payment processors that the user has registered to their account. */
export type PaymentsProcessorsCollection = {
  __typename?: 'PaymentsProcessorsCollection'
  /** All payment methods currently registered to the user. */
  all?: Maybe<Array<PaymentsProcessor>>
}

/** Collection of payment processors that the user has registered to their account. */
export type PaymentsProcessorsCollectionAllArgs = {
  complianceCode?: InputMaybe<Array<ComplianceCodes>>
}

/** Represents a payroll date */
export type PayrollDate = {
  __typename?: 'PayrollDate'
  /** Holiday adjusted payroll date */
  payrollDate: Scalars['LocalDate']['output']
  /** Holiday unadjusted payroll date */
  unadjustedPayrollDate: Scalars['LocalDate']['output']
}

/** Represents a set of potential payroll dates that will be used to determine the automatic payment schedule. */
export type PayrollDateCollection = {
  __typename?: 'PayrollDateCollection'
  /** Payroll frequency */
  frequency?: Maybe<PaymentFrequency>
  /** List of payroll dates */
  payrollDates?: Maybe<Array<PayrollDate>>
}

/** Represents a set of potential payroll dates that will be used to determine the automatic payment schedule. */
export type PayrollDateCollectionInput = {
  /** Payroll frequency */
  frequency?: InputMaybe<PaymentFrequency>
  /** List of payroll dates */
  payrollDates?: InputMaybe<Array<PayrollDateInput>>
}

/** Represents a payroll date */
export type PayrollDateInput = {
  /** Holiday adjusted payroll date */
  payrollDate: Scalars['LocalDate']['input']
  /** Holiday unadjusted payroll date */
  unadjustedPayrollDate: Scalars['LocalDate']['input']
}

/** Details for a card account in the PENDING state. */
export type PendingCardAccountStatus = BaseCardAccountStatus & {
  __typename?: 'PendingCardAccountStatus'
  /** Canonical card account status code; the value is always PENDING. */
  code: CardAccountStatusCode
  /** Indicates when the card account transitioned into the PENDING state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Card account subStatus code. */
  subStatus: CardAccountSubStatus
}

/** Details for a card in the PENDING state. */
export type PendingCardStatus = BaseCardStatus & {
  __typename?: 'PendingCardStatus'
  /** Canonical card status code; the value is always PENDING. */
  code: CardStatusCode
  /** Indicates when the card transitioned into the PENDING state. */
  occurredAt: Scalars['DateTime']['output']
}

/** Represents a loan in the PENDING state. */
export type PendingLoanStatus = BaseLoanStatus & {
  __typename?: 'PendingLoanStatus'
  /** Canonical loan status code; the value is always PENDING. */
  code: LoanStatusCode
  /** Indicates when the loan transitioned into the PENDING state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

/** Information about a payment method in the `PENDING` state. */
export type PendingPaymentMethodStatus = PaymentMethodStatus & {
  __typename?: 'PendingPaymentMethodStatus'
  /** Canonical code associated with the current state. This value is always `PENDING`. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the `PENDING` state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

/** User's phone number information. */
export type Phone = Verified & {
  __typename?: 'Phone'
  /** User's phone number. */
  number?: Maybe<Scalars['String']['output']>
  /** Indicates whether the phone number was verified. */
  verified: Scalars['Boolean']['output']
}

/** Represents a set of potential automatic payments that the user has not committed to */
export type PotentialAutomaticPaymentCollection = {
  __typename?: 'PotentialAutomaticPaymentCollection'
  /** Indicates the frequency used to schedule the payments */
  frequency?: Maybe<PaymentFrequency>
  /** List of potential payments */
  payments?: Maybe<Array<AutomaticPaymentSchedule>>
}

/** Structure to hold a presigned url for file downloads with its expiry date/time */
export type PresignedUrl = {
  __typename?: 'PresignedUrl'
  /** Timestamp when the url will expire */
  expiresAt: Scalars['DateTime']['output']
  /** Presigned download URL */
  url: Scalars['String']['output']
}

export enum Processor {
  Check = 'CHECK',
  Coastal = 'COASTAL',
  Donkey = 'DONKEY',
  Fiserv = 'FISERV',
  Leadbank = 'LEADBANK',
  Lpp = 'LPP',
  None = 'NONE',
  Pnc = 'PNC',
}

export enum ProcessorNetwork {
  Ach = 'ACH',
  Check = 'CHECK',
  Interchange = 'INTERCHANGE',
  None = 'NONE',
  Rtp = 'RTP',
}

export enum ProcessorStatus {
  Deactivated = 'DEACTIVATED',
  Document = 'DOCUMENT',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Retry = 'RETRY',
  Ssn = 'SSN',
  Suspended = 'SUSPENDED',
  Unknown = 'UNKNOWN',
  Verified = 'VERIFIED',
}

export type ProcessorStatusReason = {
  __typename?: 'ProcessorStatusReason'
  achOutboundFile?: Maybe<AchData>
  achReturnFile?: Maybe<AchData>
  code?: Maybe<Scalars['String']['output']>
  dateFailed?: Maybe<Scalars['String']['output']>
  dateSettled?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  originalCode?: Maybe<Scalars['String']['output']>
  processorId?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export enum ProcessorSubStatus {
  Receive = 'RECEIVE',
  Send = 'SEND',
  SendAndReceive = 'SEND_AND_RECEIVE',
}

export type Product = CardAccount | Loan

/** References an application for a product */
export type ProductApplication = {
  __typename?: 'ProductApplication'
  /** Available offer identification (card offer ID, loan type ID, ...) */
  offerId: Scalars['ID']['output']
  /** The product (loan, card). We would usually need ID and status at this point but other fields may be also available for access */
  product: Product
}

/** Collection of user applications to products. It should have at most one each product (loans, cards) */
export type ProductApplicationCollection = {
  __typename?: 'ProductApplicationCollection'
  /** Applications list */
  all: Array<ProductApplication>
}

/** All properties used during any product application */
export type ProductApplicationProperties = {
  __typename?: 'ProductApplicationProperties'
  /** card cohort phase */
  cardCohortPhase?: Maybe<CohortPhase>
}

/** All properties used during any product application */
export type ProductApplicationPropertiesCardCohortPhaseArgs = {
  channel: ApplicationChannel
}

/** Enumerates the canonical set of product types offered by Possible */
export enum ProductCategory {
  /** Indicates the card (credit card) product */
  Card = 'CARD',
  /** Indicates the loan product */
  Loan = 'LOAN',
}

export type ProductOffer = CardAccountOfferInfo | LoanOfferInfo

export type ProductPreRequisiteCollection = {
  __typename?: 'ProductPreRequisiteCollection'
  /** e-tag is a versioning value computed server side for the present state of the server side pre-requirements set. */
  etag: Scalars['String']['output']
  /** pre-requisites considered completed */
  requirementMet: Array<ProductPreRequisiteType>
  /** pre-requisites that need to be completed */
  requirementNotMet: Array<ProductPreRequisiteType>
}

/** Enumerates the canonical set of (sub)-categorical pre-requisites for a product */
export enum ProductPreRequisiteType {
  AddressHome = 'ADDRESS_HOME',
  AddressMailing = 'ADDRESS_MAILING',
  AmountSelection = 'AMOUNT_SELECTION',
  CardApplicationFinances = 'CARD_APPLICATION_FINANCES',
  CardApplicationSubmission = 'CARD_APPLICATION_SUBMISSION',
  ConfirmBankReapplicationNoAccounts = 'CONFIRM_BANK_REAPPLICATION_NO_ACCOUNTS',
  ConfirmBankReapplicationRelinkAccount = 'CONFIRM_BANK_REAPPLICATION_RELINK_ACCOUNT',
  ConfirmBankReapplicationValidAccount = 'CONFIRM_BANK_REAPPLICATION_VALID_ACCOUNT',
  DepositoryAccounts = 'DEPOSITORY_ACCOUNTS',
  Dob = 'DOB',
  DriversLicenseBack = 'DRIVERS_LICENSE_BACK',
  DriversLicenseFront = 'DRIVERS_LICENSE_FRONT',
  Email = 'EMAIL',
  EmailVerification = 'EMAIL_VERIFICATION',
  FullName = 'FULL_NAME',
  LoanApplicationSubmission = 'LOAN_APPLICATION_SUBMISSION',
  MarketingSurvey = 'MARKETING_SURVEY',
  Passport = 'PASSPORT',
  Phone = 'PHONE',
  PhoneVerification = 'PHONE_VERIFICATION',
  PhotoSelfie = 'PHOTO_SELFIE',
  PrimaryAccount = 'PRIMARY_ACCOUNT',
  ProofOfIncome = 'PROOF_OF_INCOME',
  ProofOfResidence = 'PROOF_OF_RESIDENCE',
  Ssn = 'SSN',
  SsnCard = 'SSN_CARD',
}

/** Collection of products offered, pending, wait-listed or past with Possible per user */
export type ProductsCollection = {
  __typename?: 'ProductsCollection'
  /** All product applications (eg: pending cards, active loans, rejected cards, paidoff loans ...) */
  applications: ProductApplicationCollection
  /** All eligible product offers per user (eg: card, loan ...) */
  eligible: ProductsOffersCollection
  /** All ineligible product offers per user (eg: card, loan ...) */
  ineligible: ProductsOffersCollection
  /** Minimum preReqs */
  offersMinPreReqs: ProductPreRequisiteCollection
  /** Properties used during any product application */
  properties?: Maybe<ProductApplicationProperties>
}

/** Collection of products that a user is eligible or ineligible for ... */
export type ProductsOffersCollection = {
  __typename?: 'ProductsOffersCollection'
  all: Array<ProductOffer>
}

/** List of KYC providers */
export enum Provider {
  Clarity = 'CLARITY',
  Credegraph = 'CREDEGRAPH',
  FactorTrust = 'FACTOR_TRUST',
  LexisNexis = 'LEXIS_NEXIS',
  SentiLink = 'SENTI_LINK',
  Transunion = 'TRANSUNION',
}

export type Query = {
  __typename?: 'Query'
  /**
   * Get paginated transactions from a specific banking account
   * TODO: this could be under an IAM-specific banking account if we create that
   */
  bankingAccountListTransactions: BankingAccountTransactionPage
  /** Gets account agreement documents download pre-signed urls */
  cardAccountAgreementsDownload?: Maybe<Array<CardAccountAgreementWithDownloadUrl>>
  cardAccountWorkflowStatistics: Array<CardAccountWorkflowStatistics>
  /** Gets specific card account in the manual review queue */
  cardManualReviewActionByCardAccountId: CardManualReviewAction
  /** Gets next card manual review action */
  cardNextManualReviewAction: CardManualReviewAction
  /** Performs OFAC and MLA checks sending the individual information (name, dob, ssn, address) directly to the providers */
  checkMlaAndOfac?: Maybe<LookupResult>
  /** Performs OFAC and MLA checks for a specific Possible user */
  checkMlaAndOfacForUser?: Maybe<LookupResult>
  /** Fetch the communication template details by its id */
  communicationTemplate?: Maybe<CommunicationTemplate>
  /** Get the list of communication templates basic info */
  communicationTemplateSummaries: Array<CommunicationTemplateSummary>
  donkeyTemplates: Array<DonkeyTemplate>
  getAccountBalances?: Maybe<Array<Maybe<AccountBalance>>>
  getCardAccountAuditEntries?: Maybe<Array<Maybe<CardAccountAuditEntry>>>
  /** Returns the disputes of a given entity */
  getDisputesByEntityId: DisputesCollection
  /** Gets the list of donkey users created by the logged IAM user */
  getDonkeyUsers: Array<DonkeyUser>
  /** Get FundingAuthorization by entity */
  getFundingAuthorizations: Array<Maybe<FundingAuthorization>>
  /** Gets the account ownership data for IAM users to dive deep into an account validation problem */
  getLinkedAccountOwnershipData: LinkedAccountOwnershipData
  /** Get loan's record database changes for auditing purposes */
  getLoanAuditTrail: AuditTrail
  /** Get the payroll dates of a user */
  getPayrollDatesForReset: PayrollDateCollection
  /** Gets trade line data for the given loan and period. This data is also used to generate the Credit Reports */
  getTradeLine: TradeLineResult
  /** Gets trade lines for a given entity id */
  getTradelinesForEntity: Array<Maybe<TradeLine>>
  /** Get user's record database changes for auditing purposes */
  getUserAuditTrail: AuditTrail
  /** Gets the next feasible payment date for a user */
  getUserNextAvailablePaymentDate?: Maybe<NextPaymentDate>
  /** Gets a Loan */
  loan: Loan
  /**
   * Gets Loan Payments
   * TODO: this data needs to be resolved as part as the Loan object instead of a direct query
   */
  loanGetPayments: LoanPayments
  /**
   * Gets Loan Payments
   * TODO: this could be under an IAM-specific loan if we create that
   */
  loanHistory: LoanHistory
  /** Gets a list of users */
  searchUsers: Array<User>
  /** Gets underwriting simulator available templates */
  underwritingSimulatorGetTemplates: UnderwritingSimulatorTemplatesCollection
  /** Gets a user */
  user?: Maybe<User>
  /**
   * Temporary query using a UUID as the input parameter
   * Need to migrate the client to start using UUID over ID
   * Migration plan: https://possible.atlassian.net/browse/ENG-6457
   */
  userByUUID?: Maybe<User>
  /** Fetch the current workflow state for a given loan, which may not exist. */
  workflowCurrentState?: Maybe<LoanWorkflowState>
  /** Fetch the workflow state for the next loan in manual review, which may not exist. */
  workflowGetNextManualReview?: Maybe<LoanWorkflowState>
  workflowStatistics: Array<LoanWorkflowStatistics>
}

export type QueryBankingAccountListTransactionsArgs = {
  bankingAccountId: Scalars['UUID']['input']
  dateRange?: InputMaybe<DateRangeInput>
  pageSelector?: InputMaybe<PageSelectorInput>
}

export type QueryCardAccountAgreementsDownloadArgs = {
  cardAccountAgreementId: Array<InputMaybe<Scalars['ID']['input']>>
  cardAccountId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type QueryCardManualReviewActionByCardAccountIdArgs = {
  cardAccountId: Scalars['ID']['input']
}

export type QueryCardNextManualReviewActionArgs = {
  userId: Scalars['ID']['input']
}

export type QueryCheckMlaAndOfacArgs = {
  input: LookupInput
}

export type QueryCheckMlaAndOfacForUserArgs = {
  forEntity: EntityDescriptorInput
  userId: Scalars['UUID']['input']
}

export type QueryCommunicationTemplateArgs = {
  templateId: Scalars['String']['input']
}

export type QueryGetAccountBalancesArgs = {
  userId: Scalars['ID']['input']
}

export type QueryGetCardAccountAuditEntriesArgs = {
  cardAccountId: Scalars['UUID']['input']
  entryTypes?: InputMaybe<Array<InputMaybe<CardAccountAuditEntryType>>>
}

export type QueryGetDisputesByEntityIdArgs = {
  entityId: Scalars['UUID']['input']
  entityType: DisputeEntityType
}

export type QueryGetDonkeyUsersArgs = {
  creatorUserId: Scalars['UUID']['input']
}

export type QueryGetFundingAuthorizationsArgs = {
  entityId: Scalars['UUID']['input']
  entityType: EntityType
}

export type QueryGetLinkedAccountOwnershipDataArgs = {
  linkedAccountId: Scalars['UUID']['input']
}

export type QueryGetLoanAuditTrailArgs = {
  loanId: Scalars['UUID']['input']
}

export type QueryGetPayrollDatesForResetArgs = {
  assessmentDateTime: Scalars['DateTime']['input']
  cardAccountId: Scalars['UUID']['input']
}

export type QueryGetTradeLineArgs = {
  loanId: Scalars['ID']['input']
  runPeriodAt: Scalars['DateTime']['input']
}

export type QueryGetTradelinesForEntityArgs = {
  entityId: Scalars['ID']['input']
}

export type QueryGetUserAuditTrailArgs = {
  userId: Scalars['UUID']['input']
}

export type QueryGetUserNextAvailablePaymentDateArgs = {
  desiredSettlementTime: Scalars['DateTime']['input']
  direction: FundingDirection
  network: ProcessorNetwork
  userId: Scalars['ID']['input']
}

export type QueryLoanArgs = {
  loanId: Scalars['ID']['input']
}

export type QueryLoanGetPaymentsArgs = {
  loanId: Scalars['ID']['input']
}

export type QueryLoanHistoryArgs = {
  loanId: Scalars['ID']['input']
}

export type QuerySearchUsersArgs = {
  query: SearchUsersInput
}

export type QueryUserArgs = {
  userId: Scalars['ID']['input']
}

export type QueryUserByUuidArgs = {
  userId: Scalars['UUID']['input']
}

export type QueryWorkflowCurrentStateArgs = {
  loanId: Scalars['UUID']['input']
}

export type QueryWorkflowGetNextManualReviewArgs = {
  statusCode?: InputMaybe<Scalars['String']['input']>
}

/** Reason we are issuing a refund to a card account */
export enum RefundReason {
  FullPayment = 'FULL_PAYMENT',
  MonthlyFee = 'MONTHLY_FEE',
  OverdraftFee = 'OVERDRAFT_FEE',
  PartialPayment = 'PARTIAL_PAYMENT',
}

/** Possible status for a refund record */
export enum RefundStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

/** Type of the refund. It also means where the refund needs to happen (banking accounts, i2c, ...) */
export enum RefundType {
  BankingAndIssuerProcessor = 'BANKING_AND_ISSUER_PROCESSOR',
  BankingOnly = 'BANKING_ONLY',
  IssuerProcessorOnly = 'ISSUER_PROCESSOR_ONLY',
}

/** Details for a card account in the REJECTED state. */
export type RejectedCardAccountStatus = BaseCardAccountStatus & {
  __typename?: 'RejectedCardAccountStatus'
  /** Canonical card account status code; the value is always REJECTED. */
  code: CardAccountStatusCode
  /** Indicates when a user can attempt to reapply again. */
  eligibleToReapplyAfter: Scalars['DateTime']['output']
  /** Indicates when the card account transitioned into the REJECTED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** Rejection reason codes (NOAA codes) */
  rejectionReasonCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Card account subStatus code. */
  subStatus: CardAccountSubStatus
}

/** Represents a loan in the REJECTED state. */
export type RejectedLoanStatus = BaseLoanStatus & {
  __typename?: 'RejectedLoanStatus'
  /** Canonical loan status code; the value is always REJECTED. */
  code: LoanStatusCode
  /** Indicates when the loan transitioned into the REJECTED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /** List of codes indicating the reasons for a rejection */
  reasonCodes: StatusReasonCodeCollection
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

/** Information about a payment method in the `REMOVED` state. */
export type RemovedPaymentMethodStatus = PaymentMethodStatus & {
  __typename?: 'RemovedPaymentMethodStatus'
  /** Canonical code associated with the current state. This value is always `REMOVED`. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the `REMOVED` state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

export type RenderCommunicationTemplateInput = {
  templateId: Scalars['String']['input']
  type: MessageType
  userId: Scalars['UUID']['input']
  variables?: InputMaybe<Array<InputMaybe<TemplateVariableInput>>>
}

export type RenderCommunicationTemplateResponse = {
  __typename?: 'RenderCommunicationTemplateResponse'
  content: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Represents a loan in the REPLACED state. */
export type ReplacedLoanStatus = BaseLoanStatus & {
  __typename?: 'ReplacedLoanStatus'
  /** Canonical loan status code; the value is always REPLACED. */
  code: LoanStatusCode
  /** Indicates when the loan transitioned into the REPLACED state. */
  occurredAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Canonical loan substatus code.
   * Should be of a substatus type that matches the status, but this is not currently enforced
   */
  substatusCode: LoanSubstatusCode
}

/** User provided social security number information. */
export type Ssn = Verified & {
  __typename?: 'SSN'
  /** The last 4 digits of the social security number. */
  mask: Scalars['String']['output']
  /** Indicates whether the user's social security number was verified. */
  verified: Scalars['Boolean']['output']
}

export type SearchUsersInput = {
  /** Search query expressed with Elasticsearch syntax */
  elasticSearchQuery: Scalars['String']['input']
}

export type SelfReportedDataInput = {
  /** Cohort phase during card application */
  annualGrossIncome?: InputMaybe<Scalars['Decimal']['input']>
  /** Cohort user type during card application */
  monthlyHousingCost?: InputMaybe<Scalars['Decimal']['input']>
}

/** Input values for setting a linked account as primary funding source */
export type SetPrimaryAccountInput = {
  accountId: Scalars['UUID']['input']
}

/** Response for set primary account operation */
export type SetPrimaryAccountResponse = {
  __typename?: 'SetPrimaryAccountResponse'
  success: Scalars['Boolean']['output']
}

/** Input values for setting the processor account status */
export type SetProcessorAccountStatusInput = {
  processorAccountId: Scalars['UUID']['input']
  processorId: PaymentProcessorTypeCode
  processorStatus: ProcessorStatus
}

/** Response for set processor account operation */
export type SetProcessorAccountStatusResponse = {
  __typename?: 'SetProcessorAccountStatusResponse'
  success: Scalars['Boolean']['output']
}

/** Input values for setting URA status */
export type SetUraStatusInput = {
  status: UserRequestedActionStatusCode
  uraId: Scalars['UUID']['input']
}

/** Response for setting URA status */
export type SetUraStatusResponse = {
  __typename?: 'SetUraStatusResponse'
  success: Scalars['Boolean']['output']
}

/** The type of shipment for a card */
export enum ShippingMethod {
  BulkShipment = 'BULK_SHIPMENT',
  BulkShipmentUrgent = 'BULK_SHIPMENT_URGENT',
  BulkShipmentVip = 'BULK_SHIPMENT_VIP',
  NormalShipment_6Days = 'NORMAL_SHIPMENT_6_DAYS',
  UrgentShipment_3Days = 'URGENT_SHIPMENT_3_DAYS',
  VipShipment_24Hrs = 'VIP_SHIPMENT_24_HRS',
}

export enum StateCodes {
  Aa = 'AA',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Any = 'ANY',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

/** State Database transaction records associated with a loan */
export type StateDatabaseHistory = {
  __typename?: 'StateDatabaseHistory'
  action: Scalars['String']['output']
  amount?: Maybe<Scalars['Decimal']['output']>
  createdAt: Scalars['DateTime']['output']
  recordId?: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
  statusDescription: Scalars['String']['output']
}

/** Collection of State Database History records */
export type StateDatabaseHistoryCollection = {
  __typename?: 'StateDatabaseHistoryCollection'
  all: Array<StateDatabaseHistory>
}

export enum Status {
  AccountCreation = 'ACCOUNT_CREATION',
  Pending = 'PENDING',
  ProductRequest = 'PRODUCT_REQUEST',
  Selected = 'SELECTED',
}

/** A list of codes indicating why a loan is in a particular status */
export type StatusReasonCodeCollection = {
  __typename?: 'StatusReasonCodeCollection'
  codes: Array<Maybe<Scalars['String']['output']>>
}

export type Subscription = {
  __typename?: 'Subscription'
  firstPaymentAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  payments: SubscriptionPaymentCollection
  status: SubscriptionStatus
  statusReason: Scalars['String']['output']
  userId: Scalars['UUID']['output']
}

export type SubscriptionCollection = {
  __typename?: 'SubscriptionCollection'
  current?: Maybe<Subscription>
}

export type SubscriptionPayment = {
  __typename?: 'SubscriptionPayment'
  amount: Scalars['Decimal']['output']
  fundingAuthorization?: Maybe<FundingAuthorization>
  fundingAuthorizationId?: Maybe<Scalars['UUID']['output']>
  id: Scalars['ID']['output']
  paymentInstrument?: Maybe<PaymentInstrument>
  paymentInstrumentId?: Maybe<Scalars['UUID']['output']>
  processorNetwork?: Maybe<ProcessorNetwork>
  scheduledAt: Scalars['DateTime']['output']
  status: SubscriptionPaymentStatus
  statusReason: Scalars['String']['output']
  subscriptionId: Scalars['UUID']['output']
}

export type SubscriptionPaymentCollection = {
  __typename?: 'SubscriptionPaymentCollection'
  all: Array<SubscriptionPayment>
  next?: Maybe<SubscriptionPayment>
}

export enum SubscriptionPaymentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Delinquent = 'DELINQUENT',
}

/** Details for a card in the SUSPENDED state. */
export type SuspendedCardStatus = BaseCardStatus & {
  __typename?: 'SuspendedCardStatus'
  /** Canonical card status code; the value is always SUSPENDED. */
  code: CardStatusCode
  /** Indicates when the card transitioned into the SUSPENDED state. */
  occurredAt: Scalars['DateTime']['output']
  /** Identifies the restriction, if any, applied to this card manifestation. */
  restriction?: Maybe<CardSuspendedRestriction>
}

/** Information about a payment method in the `SUSPENDED` state. */
export type SuspendedPaymentMethodStatus = PaymentMethodStatus & {
  __typename?: 'SuspendedPaymentMethodStatus'
  /** Canonical code associated with the current state. This value is always `SUSPENDED`. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the `SUSPENDED` state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

/** Input values for switching the banking aggregator in use */
export type SwitchBankingAggregatorInput = {
  aggregatorId: AggregatorType
  userId: Scalars['UUID']['input']
}

/** Response for switch banking aggregator operation */
export type SwitchBankingAggregatorResponse = {
  __typename?: 'SwitchBankingAggregatorResponse'
  success: Scalars['Boolean']['output']
}

/** Input for sync funding sources operation. Will sync all user payment instruments if paymentInstrumentId is null */
export type SyncFundingSourcesInput = {
  /** Payment instrument ID */
  paymentInstrumentId?: InputMaybe<Scalars['UUID']['input']>
  /** User ID */
  userId: Scalars['UUID']['input']
}

/** Response for sync funding sources operation */
export type SyncFundingSourcesResponse = {
  __typename?: 'SyncFundingSourcesResponse'
  success: Scalars['Boolean']['output']
}

/** Information about a payment method in the `SYSTEM_PURGED` state. */
export type SystemPurgedPaymentMethodStatus = PaymentMethodStatus & {
  __typename?: 'SystemPurgedPaymentMethodStatus'
  /** Canonical code associated with the current state. This value is always `SYSTEM_PURGED`. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the `SYSTEM_PURGED` state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

export type TemplateVariable = {
  __typename?: 'TemplateVariable'
  name?: Maybe<Scalars['String']['output']>
  testValue?: Maybe<Scalars['String']['output']>
}

export type TemplateVariableInput = {
  name?: InputMaybe<Scalars['String']['input']>
  testValue?: InputMaybe<Scalars['String']['input']>
}

/** Time zone information. */
export type TimeZone = {
  __typename?: 'TimeZone'
  /** Time zone's name, such as "America/Los_Angeles". */
  id?: Maybe<Scalars['ZoneId']['output']>
  /**
   * The time zone's UTC offset in hours.
   * @deprecated This is gone. Remove after next FE release.
   */
  utcOffset?: Maybe<Scalars['Float']['output']>
}

export type ToggleCardAccountApplicationPropertiesInput = {
  /** This input can be used to both enable or disable the card application flags */
  enabled: Scalars['Boolean']['input']
  /** Extra flags that may be required to be saved and consumed later by the clients */
  extraFlags?: InputMaybe<Array<CardAccountApplicationExtraFlagInput>>
  /**
   * Flags that need to be set within the card application properties
   * It is possible that we just want to toggle the `enabled` flag. In this case, this field is not required
   */
  miscData?: InputMaybe<CardAccountMiscDataInput>
  /**
   * Contains the self reported data that needs to be set within the card application properties
   * It is possible that we just want to toggle the `enabled` flag. In this case, this field is not required
   */
  selfReportedData?: InputMaybe<SelfReportedDataInput>
}

/** Result of the updates to card application properties */
export enum ToggleCardAccountApplicationPropertiesResult {
  Created = 'CREATED',
  HasDisqualifyingProducts = 'HAS_DISQUALIFYING_PRODUCTS',
  LastCardCoolOffPeriodHasntPassed = 'LAST_CARD_COOL_OFF_PERIOD_HASNT_PASSED',
  NoChange = 'NO_CHANGE',
  Updated = 'UPDATED',
}

export type TradeLine = {
  __typename?: 'TradeLine'
  accountStatus?: Maybe<Scalars['String']['output']>
  accountType?: Maybe<Scalars['String']['output']>
  actualPayment?: Maybe<Scalars['Int']['output']>
  addressIndicator?: Maybe<Scalars['String']['output']>
  amountPastDue?: Maybe<Scalars['Int']['output']>
  complianceConditionCode?: Maybe<Scalars['String']['output']>
  consumerAccountNumber?: Maybe<Scalars['String']['output']>
  consumerInformationIndicator?: Maybe<Scalars['String']['output']>
  creditLimit?: Maybe<Scalars['Int']['output']>
  currentBalance?: Maybe<Scalars['Int']['output']>
  cycleIdentifier?: Maybe<Scalars['String']['output']>
  dateClosed?: Maybe<Scalars['Date']['output']>
  dateOfAccountInformation?: Maybe<Scalars['Date']['output']>
  dateOfFirstDelinquency?: Maybe<Scalars['Date']['output']>
  dateOfLastPayment?: Maybe<Scalars['Date']['output']>
  dateOpened?: Maybe<Scalars['DateTime']['output']>
  ecoaCode?: Maybe<Scalars['String']['output']>
  entityId?: Maybe<Scalars['ID']['output']>
  highestCreditOrOriginalLoanAmount?: Maybe<Scalars['Int']['output']>
  identificationNumber?: Maybe<Scalars['String']['output']>
  interestTypeIndicator?: Maybe<Scalars['String']['output']>
  originalChargeoffAmount?: Maybe<Scalars['Int']['output']>
  paymentHistoryProfile?: Maybe<Scalars['String']['output']>
  paymentHistoryStartDate?: Maybe<Scalars['Date']['output']>
  paymentRating?: Maybe<Scalars['String']['output']>
  portfolioType?: Maybe<Scalars['String']['output']>
  reportId?: Maybe<Scalars['ID']['output']>
  residenceCode?: Maybe<Scalars['String']['output']>
  scheduledMonthlyPayment?: Maybe<Scalars['Int']['output']>
  specialComment?: Maybe<Scalars['String']['output']>
  termsDuration?: Maybe<Scalars['String']['output']>
  termsFrequency?: Maybe<Scalars['String']['output']>
  timeStamp?: Maybe<Scalars['DateTime']['output']>
  userId?: Maybe<Scalars['ID']['output']>
}

export type TradeLineResult = {
  __typename?: 'TradeLineResult'
  enabled: Scalars['Boolean']['output']
  tradeLine?: Maybe<TradeLine>
  validationMessages: Array<Scalars['String']['output']>
}

/** Transaction processor details */
export type TransactionProcessor = {
  __typename?: 'TransactionProcessor'
  accountId?: Maybe<Scalars['ID']['output']>
  fundingSourceId?: Maybe<Scalars['ID']['output']>
  network?: Maybe<Scalars['String']['output']>
  processorId: PaymentProcessorTypeCode
}

export type UndefinedLoanAggregateStatus = LoanAggregateStatusType & {
  __typename?: 'UndefinedLoanAggregateStatus'
  code: LoanAggregateStatusCode
  conversion: LoanConversion
}

/**
 * All underwriting data for an individual product.
 *
 * Includes input data that underwriting analyzes to determine eligibility, such as
 * payroll and bank account data, and the result output of underwriting including the user's
 * score and projected payment dates.
 */
export type Underwriting = {
  __typename?: 'Underwriting'
  bankData: UnderwritingBankData
  id: Scalars['UUID']['output']
  income: UnderwritingIncome
  otherLoans: UnderwritingOtherLoans
  result: UnderwritingResult
}

/** Aggregated data about all of a user's bank accounts used for underwriting. */
export type UnderwritingBankData = {
  __typename?: 'UnderwritingBankData'
  availableBankBalance?: Maybe<Scalars['Decimal']['output']>
  feeGroups: UnderwritingBankDataFeeGroups
  id: Scalars['UUID']['output']
  latestBalanceDate?: Maybe<Scalars['LocalDate']['output']>
  totalBankFees?: Maybe<Scalars['Decimal']['output']>
}

export type UnderwritingBankDataFeeGroup = {
  __typename?: 'UnderwritingBankDataFeeGroup'
  feeTransactions: Array<UnderwritingBankTransaction>
  instancesInLastThreeMonths: Scalars['Int']['output']
  name: Scalars['String']['output']
  totalFeesInLastThreeMonths: Scalars['Decimal']['output']
}

/** Grouping of bank account transactions. Includes things other than income like overdrafts or reversals. */
export type UnderwritingBankDataFeeGroups = {
  __typename?: 'UnderwritingBankDataFeeGroups'
  atm?: Maybe<UnderwritingBankDataFeeGroup>
  maintenance?: Maybe<UnderwritingBankDataFeeGroup>
  nsf?: Maybe<UnderwritingBankDataFeeGroup>
  overdrafts?: Maybe<UnderwritingBankDataFeeGroup>
  refund?: Maybe<UnderwritingBankDataFeeGroup>
  returns?: Maybe<UnderwritingBankDataFeeGroup>
  reversals?: Maybe<UnderwritingBankDataFeeGroup>
}

/** A bank transaction used for underwriting. */
export type UnderwritingBankTransaction = {
  __typename?: 'UnderwritingBankTransaction'
  amount: Scalars['Decimal']['output']
  categoryId: Scalars['String']['output']
  date: Scalars['LocalDate']['output']
  name: Scalars['String']['output']
}

/** A user's income data used for underwriting. */
export type UnderwritingIncome = {
  __typename?: 'UnderwritingIncome'
  estimatedMonthlyIncome?: Maybe<Scalars['Decimal']['output']>
  estimatedWeeklyIncome?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  /** Expiring, signed S3 URL to an image graphing the user's income. */
  incomeGraphUrl?: Maybe<Scalars['String']['output']>
  /** The LinkedAccount that underwriting used to analyze the user's payroll / income. */
  payrollLinkedAccount?: Maybe<LinkedAccount>
  /** Income transactions categorized into groups such as direct deposit and government aid. */
  payrollTypeGroups?: Maybe<Array<UnderwritingIncomePayrollTypeGroup>>
}

/**
 * A grouping of payroll transactions by type that is used for underwriting.
 * For example, payroll may be grouped into direct deposit and government aid.
 */
export type UnderwritingIncomePayrollTypeGroup = {
  __typename?: 'UnderwritingIncomePayrollTypeGroup'
  name: Scalars['String']['output']
  nextEstimatedPayrollDates: Array<Scalars['LocalDate']['output']>
  payrollTransactions: Array<UnderwritingBankTransaction>
  reasonForInclusion: Scalars['String']['output']
}

/** Details about loans that a user has from providers other than Possible. */
export type UnderwritingOtherLoans = {
  __typename?: 'UnderwritingOtherLoans'
  /** Total payment due next payroll for loans from other providers. */
  amountDueNextPayroll?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  loanGroups?: Maybe<Array<UnderwritingOtherLoansGroup>>
  /** Total estimated payment due for loans from other providers. */
  loanStackingAmount?: Maybe<Scalars['Decimal']['output']>
}

/** Grouping of loans from providers other than Possible. */
export type UnderwritingOtherLoansGroup = {
  __typename?: 'UnderwritingOtherLoansGroup'
  estimatedPayment?: Maybe<Scalars['Decimal']['output']>
  name?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  transactions?: Maybe<Array<UnderwritingBankTransaction>>
}

/** Underwriting result after analyzing a user's income and bank account data. */
export type UnderwritingResult = {
  __typename?: 'UnderwritingResult'
  /** The minimum score required for approval. Varies by state, model, oa, etc. */
  approvalScore?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  isRejected?: Maybe<Scalars['Boolean']['output']>
  /** List of the lowest scoring features that contributed to the user's score. */
  lowestScoringFeatures?: Maybe<Array<Scalars['String']['output']>>
  /** Proposed amount to offer to the borrower. */
  proposedAmount?: Maybe<Scalars['Decimal']['output']>
  proposedPaymentDates: Array<Scalars['LocalDate']['output']>
  rejectReason?: Maybe<Scalars['String']['output']>
  /** Likelihood of approval from 0-1. */
  score?: Maybe<Scalars['Decimal']['output']>
  /** Score as a percentile. Used for grouping into buckets. */
  scorePercentile?: Maybe<Scalars['Int']['output']>
}

export type UnderwritingSimulatorTemplate = {
  __typename?: 'UnderwritingSimulatorTemplate'
  key: Scalars['String']['output']
  template: Scalars['String']['output']
}

export type UnderwritingSimulatorTemplatesCollection = {
  __typename?: 'UnderwritingSimulatorTemplatesCollection'
  templates: Array<UnderwritingSimulatorTemplate>
}

export type UraActionDataInput = {
  linkedAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type UraDataInput = {
  /** Optional action data to send with this URA */
  actionData?: InputMaybe<UraActionDataInput>
  /** Card account id to associate with this URA */
  cardAccountId: Scalars['ID']['input']
  /** Type of URA to send */
  uraType: UserRequestedActionTypesCode
  /** User id to associate with this URA */
  userId: Scalars['ID']['input']
}

/** Entity representing a mobile client user. */
export type User = ChangeTracked & {
  __typename?: 'User'
  /** Is true if user contains no loans that are late, default or charged off */
  areLoansInGoodStanding?: Maybe<Scalars['Boolean']['output']>
  /** Bank accounts linked by the user. */
  bankAccounts: LinkedAccountCollection
  /** User credit card accounts. */
  cardAccounts: CardAccountCollection
  /** User communication preferences */
  communicationPreferences?: Maybe<CommunicationPreferences>
  /** User's communications audit trail */
  communications?: Maybe<UserCommunicationsCollection>
  /** Timestamp tracking the user's creation. */
  createdAt: Scalars['DateTime']['output']
  /** Indicates if credit reporting to the bureaus is disabled for this user */
  creditReportingDisabled?: Maybe<Scalars['Boolean']['output']>
  /** Documents associated to a user account */
  documents?: Maybe<UserDocumentsCollection>
  /** Environment properties associated to a user account */
  env?: Maybe<UserEnv>
  /** List of feature flags and experiments */
  featureFlags?: Maybe<Array<FeatureFlag>>
  fraudMatches: UserFraudCollection
  /** User's unique id. */
  id: Scalars['ID']['output']
  /** User supplied government issued identification information. */
  identification?: Maybe<IssuedIdentificationInfo>
  /** User's KYC validation records */
  kycValidations: KycValidationCollection
  lastLogin?: Maybe<UserLastLogin>
  /** User's loans. */
  loans: UserLoanCollection
  /** Notes attached to this user. */
  notes?: Maybe<UserNoteCollection>
  /** Data related to user onboarding flow */
  onboarding?: Maybe<OnboardingInfo>
  /** A more technical and simplified representation of the payment methods for the users to be used on IAM */
  paymentInstruments?: Maybe<PaymentInstrumentCollection>
  /** Payment methods registered by the user. */
  paymentMethods: PaymentMethodCollection
  /** Payment processors registered by the user. */
  paymentProcessors: PaymentsProcessorsCollection
  /** Selected language */
  preferredLanguage?: Maybe<Language>
  /** Products available to this user */
  products: ProductsCollection
  /**
   * User's profile information. Note that user profile information may be null in certain situations
   * Explicitly query for that user to get this info
   */
  profile?: Maybe<UserProfile>
  /** Misc properties associated to a user account */
  properties?: Maybe<UserPropertyCollection>
  /**
   * User's current status.
   * @deprecated This field is replaced by userStatus and subStatus.
   */
  status: UserStatus
  /** User's current subStatus. */
  subStatus: UserSubStatus
  /** User subscription records */
  subscriptions: SubscriptionCollection
  /** Timestamp tracking the user's last modification. */
  updatedAt: Scalars['DateTime']['output']
  /** User Requested Actions */
  userRequestedActions: UserRequestedActionCollection
  /** User's current status. */
  userStatus: UserStatusCode
}

/** User authentication response. */
export type UserAuthentication = {
  __typename?: 'UserAuthentication'
  /** The authenticated user. */
  me: User
  /** Opaque token identifying the authenticated users login session. */
  token: Scalars['String']['output']
}

/** User Bankruptcy Information */
export type UserBankruptcyInput = {
  bankruptcyCode: BankruptcyCode
  filedDate: Scalars['LocalDate']['input']
  s3Key: Scalars['String']['input']
  userId: Scalars['UUID']['input']
}

export type UserCommunicationsCollection = {
  __typename?: 'UserCommunicationsCollection'
  all?: Maybe<UserCommunicationsPage>
}

export type UserCommunicationsCollectionAllArgs = {
  options?: InputMaybe<UserCommunicationsFilter>
  pageInfo: PageInfoInput
}

/** Filters for users communications */
export type UserCommunicationsFilter = {
  /** Indicates if the server should fetch the communication full text and html */
  includeText: Scalars['Boolean']['input']
}

export type UserCommunicationsPage = {
  __typename?: 'UserCommunicationsPage'
  /** Indicates whether there are pages after the returned page. */
  continues: Scalars['Boolean']['output']
  items: Array<CommunicationAuditTrail>
}

export type UserDeleteInput = {
  userId: Scalars['UUID']['input']
}

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse'
  success: Scalars['Boolean']['output']
}

/** Document information associated to a user */
export type UserDocument = {
  __typename?: 'UserDocument'
  amount?: Maybe<Scalars['Decimal']['output']>
  contentType?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  endAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  loanId?: Maybe<Scalars['UUID']['output']>
  payAt?: Maybe<Scalars['DateTime']['output']>
  startAt?: Maybe<Scalars['DateTime']['output']>
  status: UserDocumentStatus
  subType?: Maybe<UserDocumentSubType>
  type: UserDocumentType
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['UUID']['output']
}

/** Response with the document ID plus the presigned URL to be used to upload a UserDocument */
export type UserDocumentGenerateUploadUrlResponse = {
  __typename?: 'UserDocumentGenerateUploadUrlResponse'
  documentId: Scalars['UUID']['output']
  presignedUrl: Scalars['String']['output']
}

export enum UserDocumentStatus {
  Discarded = 'DISCARDED',
  Fraudulent = 'FRAUDULENT',
  NotVerified = 'NOT_VERIFIED',
  Verified = 'VERIFIED',
}

export enum UserDocumentSubType {
  AchAuthorization = 'ACH_AUTHORIZATION',
  Adp = 'ADP',
  BankStatement = 'BANK_STATEMENT',
  CashCheck = 'CASH_CHECK',
  ConsularIdentificationCard = 'CONSULAR_IDENTIFICATION_CARD',
  DeathCertificate = 'DEATH_CERTIFICATE',
  DebtConsolidation = 'DEBT_CONSOLIDATION',
  DriversLicenseBack = 'DRIVERS_LICENSE_BACK',
  DriversLicenseFront = 'DRIVERS_LICENSE_FRONT',
  Face = 'FACE',
  FraudClaim = 'FRAUD_CLAIM',
  GreenCard = 'GREEN_CARD',
  Gusto = 'GUSTO',
  I_872AmericanIndianCard = 'I_872_AMERICAN_INDIAN_CARD',
  LicenseToCarry = 'LICENSE_TO_CARRY',
  Other = 'OTHER',
  Passport = 'PASSPORT',
  PassportBook = 'PASSPORT_BOOK',
  PassportCard = 'PASSPORT_CARD',
  PoliceReport = 'POLICE_REPORT',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  ProofOfBankruptcy = 'PROOF_OF_BANKRUPTCY',
  ProofOfIncome = 'PROOF_OF_INCOME',
  ProofOfResidency = 'PROOF_OF_RESIDENCY',
  PuertoRicoId = 'PUERTO_RICO_ID',
  Ssn = 'SSN',
  TribalEnrollmentCard = 'TRIBAL_ENROLLMENT_CARD',
  UnemploymentBenefits = 'UNEMPLOYMENT_BENEFITS',
  WorkPermit = 'WORK_PERMIT',
}

export enum UserDocumentType {
  Identity = 'IDENTITY',
  Legal = 'LEGAL',
  Payroll = 'PAYROLL',
}

/** User document entity with its presigned URL */
export type UserDocumentWithPresignedUrl = {
  __typename?: 'UserDocumentWithPresignedUrl'
  document: UserDocument
  presignedUrl: PresignedUrl
}

/** List of UserDocuments. */
export type UserDocumentsCollection = {
  __typename?: 'UserDocumentsCollection'
  /** All documents (can be filtered by types and statuses) */
  all: Array<UserDocumentWithPresignedUrl>
}

/** List of UserDocuments. */
export type UserDocumentsCollectionAllArgs = {
  loanId?: InputMaybe<Scalars['UUID']['input']>
  statuses?: InputMaybe<Array<InputMaybe<UserDocumentStatus>>>
  types?: InputMaybe<Array<InputMaybe<UserDocumentType>>>
}

export type UserEnv = {
  __typename?: 'UserEnv'
  bankingAggregatorId?: Maybe<Scalars['String']['output']>
  fraudFilterLevel?: Maybe<Scalars['Int']['output']>
  /** @deprecated This system is dead ENG-11319. */
  ipGeoLocation?: Maybe<IpGeoLocation>
  userId: Scalars['ID']['output']
}

export type UserFraud = {
  __typename?: 'UserFraud'
  matchingUserId: Scalars['UUID']['output']
  reason: Scalars['String']['output']
  status: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type UserFraudCollection = {
  __typename?: 'UserFraudCollection'
  all: Array<UserFraud>
}

export type UserLastLogin = {
  __typename?: 'UserLastLogin'
  carrier?: Maybe<Scalars['String']['output']>
  clientType?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deviceId?: Maybe<Scalars['String']['output']>
  deviceManufacturer?: Maybe<Scalars['String']['output']>
  operatingSystem?: Maybe<Scalars['String']['output']>
}

/** Users collection of loans. */
export type UserLoanCollection = {
  __typename?: 'UserLoanCollection'
  /** Lists this user's loans starting from the most recent. */
  all?: Maybe<Array<Loan>>
  /** total number of loans a user has accepted */
  countAccepted: Scalars['Int']['output']
  /** total number of loans a user has applied for */
  countApplied: Scalars['Int']['output']
  /**
   * Identifies the borrower's most recent actionable loan. This loan is usually, but not always, the most recent
   * loan.
   */
  latestActionableLoan?: Maybe<Loan>
}

/** Notes about a user captured by the support team during or relating to a customer support call. */
export type UserNote = {
  __typename?: 'UserNote'
  /** Uniquely identifies this note. */
  key: UserNoteKey
  /**
   * A reverse chronologically sorted list of note versions. Note versions are immutable; when a note is revised a
   * new version is added to this list. The most recent version of a note is always first. There is always at least
   * on note version.
   */
  versions?: Maybe<Array<UserNoteVersion>>
}

/** Notes collection attached to an entity. */
export type UserNoteCollection = {
  __typename?: 'UserNoteCollection'
  items?: Maybe<Array<UserNote>>
}

/** Composite unique identifier for a user note. */
export type UserNoteKey = {
  __typename?: 'UserNoteKey'
  /** Unique identifier of this note. */
  noteId: Scalars['ID']['output']
  /**
   * Identifies the id of the entity this note is attached to. When the `parentEntityType` is USER this value will be
   * the same as `parentUserId`.
   */
  parentEntityId: Scalars['ID']['output']
  /** Identifies which type of entity this note is attached to. */
  parentEntityType: NoteParentEntityTypeCode
  /** Identifies the user_id of the target customer for this note. */
  parentUserId: Scalars['ID']['output']
}

export type UserNoteKeyInput = {
  /** Unique identifier of this note. */
  noteId?: InputMaybe<Scalars['ID']['input']>
  /**
   * Identifies the id of the entity this note is attached to. When the `parentEntityType` is USER this value will be
   * the same as `parentUserId`.
   */
  parentEntityId: Scalars['ID']['input']
  /** Identifies which type of entity this note is attached to. */
  parentEntityType: NoteParentEntityTypeCode
  /** Identifies the user_id of the target customer for this note. */
  parentUserId: Scalars['ID']['input']
}

/** An immutable note version. */
export type UserNoteVersion = {
  __typename?: 'UserNoteVersion'
  /**
   * Unique identifier for this version (within the context of a note). Note versions are versioned by their creation
   * time.
   */
  createdAt: Scalars['DateTime']['output']
  /** Text input by the customer support team. */
  text?: Maybe<Scalars['String']['output']>
  /** Unique identifier of the team member responsible for this version of the note. */
  updatedBy?: Maybe<EntityDescriptor>
  /** Full name of the user responsible for this version */
  updatedByFullName?: Maybe<Scalars['String']['output']>
}

/** Personal data associated with the user. */
export type UserProfile = {
  __typename?: 'UserProfile'
  /** User's date of birth. */
  birthDate?: Maybe<Scalars['LocalDate']['output']>
  /** User's verified primary email address. */
  email?: Maybe<Email>
  /** User's current home location details. */
  home?: Maybe<Location>
  /** User's current mailing address details. */
  mailing?: Maybe<Location>
  /** User's name. */
  name?: Maybe<Name>
  /** User's verified primary phone number. */
  phone?: Maybe<Phone>
}

export type UserProfileInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  idNumber?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

/** User Misc Property */
export type UserProperty = {
  __typename?: 'UserProperty'
  property: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** Collection of User Misc Properties for this user. */
export type UserPropertyCollection = {
  __typename?: 'UserPropertyCollection'
  /** All user properties associated with the user */
  all?: Maybe<Array<UserProperty>>
}

/** User requested action data structure */
export type UserRequestedAction = ChangeTracked & {
  __typename?: 'UserRequestedAction'
  /** The datetime the user will be able to complete the URA. Trying before this time a exception will be thrown. */
  ableToCompleteAt: Scalars['DateTime']['output']
  /** URA display body */
  actionBody: Scalars['String']['output']
  /** URA display body */
  actionData: UserRequestedActionData
  /** URA display title */
  actionTitle: Scalars['String']['output']
  /** The type of URA */
  actionType: UserRequestedActionTypesCode
  /** Timestamp tracking the loan's creation. */
  createdAt: Scalars['DateTime']['output']
  /** The entity that created this URA */
  createdBy?: Maybe<NamedEntityDescriptor>
  /** URA unique id. */
  id: Scalars['ID']['output']
  /** Is this URA required to be completed by the user? or can be dismissed */
  optional: Scalars['Boolean']['output']
  /** The entity for which this URA was sent (a specific loan/card) */
  requestContext: EntityDescriptor
  /** URA status */
  status: UserRequestedActionStatusCode
  /** Timestamp tracking the loan's last modification. */
  updatedAt: Scalars['DateTime']['output']
  /** The entity that last updated this URA */
  updatedBy?: Maybe<NamedEntityDescriptor>
  /** User's unique id. */
  userId: Scalars['ID']['output']
}

/** Collection of URAs for this user */
export type UserRequestedActionCollection = {
  __typename?: 'UserRequestedActionCollection'
  /** All user requested actions for the user */
  all?: Maybe<Array<Maybe<UserRequestedAction>>>
  /** All pending user requested actions for the user. */
  pending?: Maybe<Array<Maybe<UserRequestedAction>>>
}

export enum UserRequestedActionContextCode {
  /** Indicates URA was issued due to a failed payment */
  FailedPayment = 'FAILED_PAYMENT',
}

/** User requested action data structure containing additional fields per actionType */
export type UserRequestedActionData = {
  __typename?: 'UserRequestedActionData'
  /** Optional linked bank account ID (returned with RELINK_ACCOUNT URA for example) */
  accountId?: Maybe<Scalars['ID']['output']>
  /** Optional enum containing some context information about the URA (for ex: failed_payment) */
  context?: Maybe<UserRequestedActionContextCode>
  /** Optional payment instrument ID (returned with CHANGE_DEFAULT_PAYMENT_METHOD_REQUEST URA for example) */
  paymentInstrumentId?: Maybe<Scalars['UUID']['output']>
}

/** Enumerates the status values of URAs. */
export enum UserRequestedActionStatusCode {
  /** Indicates URA has been completed by the user */
  Completed = 'COMPLETED',
  /** Indicates URA has expired (prior to completion by the user) */
  Expired = 'EXPIRED',
  /** Indicates URA is pending completion by the user */
  Pending = 'PENDING',
}

export enum UserRequestedActionTypesCode {
  /** A request to enter ach account and routing info */
  AccountNumberRequiredAch = 'ACCOUNT_NUMBER_REQUIRED_ACH',
  /** A request to enter debit account and routing info */
  AccountNumberRequiredInterchange = 'ACCOUNT_NUMBER_REQUIRED_INTERCHANGE',
  /**
   * Used to request to change the default payment method. Then an agent can request the change on IAM by sending
   * this URA so the user can agree with the autopay agreement before we effectively change the payment method.
   */
  ChangeDefaultPaymentMethodRequest = 'CHANGE_DEFAULT_PAYMENT_METHOD_REQUEST',
  /** A request to contact CS to continue ... */
  ContactUs = 'CONTACT_US',
  /** A request to submit proof income documents */
  DocsProofOfIncome = 'DOCS_PROOF_OF_INCOME',
  /** User is requested to provide additional Proof of Identity such as a driver's license */
  IdentityDocsRequired = 'IDENTITY_DOCS_REQUIRED',
  /**
   * Use when customers indicate that their account is actually a joint account,
   * and need to upload bank statements indicating their name is actually on the account
   */
  JointAccountVerification = 'JOINT_ACCOUNT_VERIFICATION',
  /** A request to link a bank account with more transaction history */
  LinkAccountWithMoreData = 'LINK_ACCOUNT_WITH_MORE_DATA',
  /** A request to link a bank account with an owner name that matches the customer's name */
  LinkAccountWithNewName = 'LINK_ACCOUNT_WITH_NEW_NAME',
  /** A request to link a bank account with individual owner instead of a business owner */
  LinkPersonalAccount = 'LINK_PERSONAL_ACCOUNT',
  /** A request to link a supported banking institution account type (e.g: checking or savings account) */
  LinkSupportedAccounts = 'LINK_SUPPORTED_ACCOUNTS',
  /** A request to link a supported banking institution (e.g not Chime if Chime is not supported) */
  LinkSupportedInstitution = 'LINK_SUPPORTED_INSTITUTION',
  /** Loan rejected URA */
  LoanRejected = 'LOAN_REJECTED',
  /** Other URA */
  Other = 'OTHER',
  /** A request to submit proof of residence */
  OutOfState = 'OUT_OF_STATE',
  /** Payments overdue URA */
  PaymentsOverdue = 'PAYMENTS_OVERDUE',
  /** Payments reauthorization required (for example, when a stop payment is placed on an account) */
  PaymentsReauthRequired = 'PAYMENTS_REAUTH_REQUIRED',
  /** Payment instrument update required (for example, when a card is expired) */
  PaymentInstrumentUpdateRequired = 'PAYMENT_INSTRUMENT_UPDATE_REQUIRED',
  /** Payment rescheduled URA */
  PaymentRescheduled = 'PAYMENT_RESCHEDULED',
  /** Payment reschedule request URA */
  PaymentRescheduleRequest = 'PAYMENT_RESCHEDULE_REQUEST',
  /**
   * A request to provide proof-of-residence because the state on the bank account address does not match the
   * state on the user's address
   */
  PorBankAddressMismatch = 'POR_BANK_ADDRESS_MISMATCH',
  /** A request to submit proof of residence date */
  PorDate = 'POR_DATE',
  /**
   * Proof of Residence - Lexis Nexis - Mismatch
   * User is requested to provide additional Proof of Residence for their street address
   */
  PorLnMismatch = 'POR_LN_MISMATCH',
  /** A request to relink an existing bank account */
  RelinkAccount = 'RELINK_ACCOUNT',
  /** A request to relink an existing bank account and adjust primary payment method using account management page */
  RelinkAccountSetPrimary = 'RELINK_ACCOUNT_SET_PRIMARY',
  /** A request retake ID photo due cut off corners */
  RetakeIdCorners = 'RETAKE_ID_CORNERS',
  /** A request retake ID photo due to expired ID */
  RetakeIdExpired = 'RETAKE_ID_EXPIRED',
  /** A request retake ID photo due fingers blocking view */
  RetakeIdFingers = 'RETAKE_ID_FINGERS',
  /** A request retake ID form */
  RetakeIdForm = 'RETAKE_ID_FORM',
  /** A request to re-take and submit the ID front side */
  RetakeIdFront = 'RETAKE_ID_FRONT',
  /** A request to re-take and submit the ID front side with better lighting */
  RetakeIdLighting = 'RETAKE_ID_LIGHTING',
  /** A request to re-take and submit selfie */
  RetakeLiveness = 'RETAKE_LIVENESS',
  /** A request to upload a photo of your SSN card */
  SsnCard = 'SSN_CARD',
  /**
   * Used when the OFAC/MLA validation returns a "Frozen file" error, meaning that the user has to unfreeze the bureau
   * frozen file and resume the application (by completing the URA)
   */
  UnfreezeBureauFrozenFile = 'UNFREEZE_BUREAU_FROZEN_FILE',
  /** Unsupported URA was received (likely requires a gateway mapping) */
  Unrecognized = 'UNRECOGNIZED',
  /** A request to update a verifiable residential address */
  UpdatePiiAddress = 'UPDATE_PII_ADDRESS',
  /** A request to update SSN */
  UpdatePiiSsn = 'UPDATE_PII_SSN',
  /** A request to update name so that it matches the ID submitted */
  VerifyNameMatchesId = 'VERIFY_NAME_MATCHES_ID',
}

/** Input values for a send message operation. The messages should be sent by an array of types with a templateId for a distinct user */
export type UserSendMessagesInput = {
  /** If set to true, any duplicated URAs will be expired */
  expireDuplicatedURAs: Scalars['Boolean']['input']
  /** Indicates if the message should be sent tagged as a test */
  isTest: Scalars['Boolean']['input']
  /** The template identification */
  templateId: Scalars['String']['input']
  /** List of message types (EMAIL, SMS, BRAZE, ...) */
  types?: InputMaybe<Array<MessageType>>
  /** The recipient user ID */
  userId: Scalars['UUID']['input']
  /** A Map of key,value of event properties */
  variables?: InputMaybe<Array<MessageVariable>>
}

/** Response for the SendMessages operation */
export type UserSendMessagesResponse = {
  __typename?: 'UserSendMessagesResponse'
  /** Returns only the types that were successfully sent */
  successfulTypes: Array<MessageType>
}

/** Represents all potential user states. */
export type UserStatus =
  | DeletedUserStatus
  | DisabledUserStatus
  | EnabledUserStatus
  | FraudulentUserStatus

/** Enumerates the canonical user status codes observable by the mobile client. */
export enum UserStatusCode {
  /** Indicates the user account is disabled. */
  Disabled = 'DISABLED',
  /** Indicates the user account is enabled. */
  Enabled = 'ENABLED',
  /** Indicates the user account is flagged for fraudulent activity. */
  Fraudulent = 'FRAUDULENT',
  /** Indicates the user account is softpurged and all PII was removed */
  Softpurged = 'SOFTPURGED',
}

/** Enumerates the user sub status in the system */
export enum UserSubStatus {
  Bankrupt = 'BANKRUPT',
  Deceased = 'DECEASED',
  Duplicate = 'DUPLICATE',
  FraudulentIdTheft = 'FRAUDULENT_ID_THEFT',
  FraudulentInternal = 'FRAUDULENT_INTERNAL',
  None = 'NONE',
}

/** Input values for updating the user communication preferences */
export type UserUpdateCommunicationPreferencesInput = {
  blacklisted?: InputMaybe<Scalars['Boolean']['input']>
  complaint?: InputMaybe<Scalars['Boolean']['input']>
  doNotContact?: InputMaybe<Scalars['Boolean']['input']>
  escalate?: InputMaybe<Scalars['Boolean']['input']>
  loginContact?: InputMaybe<Scalars['Boolean']['input']>
  userId: Scalars['UUID']['input']
}

export enum UserVerificationMessageType {
  EmailCode = 'EMAIL_CODE',
  EmailLink = 'EMAIL_LINK',
  PhoneSms = 'PHONE_SMS',
  PhoneVoice = 'PHONE_VOICE',
}

export enum UserVerificationType {
  MfaCards = 'MFA_CARDS',
  MfaLogin = 'MFA_LOGIN',
  MfaStatements = 'MFA_STATEMENTS',
}

export type UserVerifyEmailSendCodeInput = {
  /** User ID to receive the verification code */
  userId: Scalars['UUID']['input']
  /** Type for sending the verification code */
  verificationCodeMedium: ModifyEmailSendCodeMedium
}

/**
 * Affixes verification metadata onto some underlying data object; examples of target data objects include phone numbers
 * and addresses.
 */
export type Verified = {
  /** Indicates whether the underlying data was verified. */
  verified: Scalars['Boolean']['output']
}

/** Information about a payment method in the `VERIFIED` state. */
export type VerifiedPaymentMethodStatus = PaymentMethodStatus & {
  __typename?: 'VerifiedPaymentMethodStatus'
  /** Canonical code associated with the current state. This value is always `VERIFIED`. */
  code: PaymentMethodStatusCode
  /** Reason the payment method is in the `VERIFIED` state, if any. */
  reason?: Maybe<Scalars['String']['output']>
}

export type WorkflowRejectInput = {
  loanId: Scalars['UUID']['input']
  statusCode: Scalars['String']['input']
}

export type WorkflowSendUraInput = {
  loanId: Scalars['UUID']['input']
  urasToIssue?: InputMaybe<Array<WorkflowUraInput>>
}

export type WorkflowUraInput = {
  actionData?: InputMaybe<Scalars['String']['input']>
  actionType: Scalars['String']['input']
}

export type BankAccountsFragmentFragment = {
  __typename?: 'User'
  bankAccounts: {
    __typename?: 'LinkedAccountCollection'
    all?: Array<{
      __typename?: 'LinkedAccount'
      id: string
      name?: string | null
      mask: string
      officialName?: string | null
      status: LinkedAccountStatusCode
      preferredFundingSource?: boolean | null
      achNumbersAvailable?: boolean | null
      debitCardNumbersAvailable?: boolean | null
      isLoginRequired: boolean
      type: LinkedAccountType
      institution?: {__typename?: 'Institution'; name: string} | null
    }> | null
  }
}

export type CardBalanceFragmentFragment = {
  __typename?: 'CardAccountBalance'
  availableBalance?: string | null
  availableSpent?: string | null
  ledgerBalance?: string | null
  ledgerSpent?: string | null
  ledgerSpentAfterPayments?: string | null
  minimumPaymentDue?: string | null
  updatedAt?: string | null
}

type CardStatusFragment_ActiveCardAccountStatus_Fragment = {
  __typename?: 'ActiveCardAccountStatus'
  autopayFrequency: PaymentFrequency
  code: CardAccountStatusCode
  occurredAt?: string | null
  subStatus: CardAccountSubStatus
  cardAccountOverdueStatus?: CardAccountOverdueStatus | null
  daysUntilDelinquent?: number | null
  delinquentNumberOfDays?: number | null
  amountOverdue?: string | null
  chargedOffStatus?: ChargedOffCardStatus | null
  overduePaymentInfo?: {
    __typename?: 'OverDuePaymentInfo'
    amount?: string | null
    paymentDueAt?: string | null
  } | null
  overdueInstallmentInfo?: Array<{
    __typename?: 'OverDuePaymentInfo'
    amount?: string | null
    paymentDueAt?: string | null
  } | null> | null
  restriction?:
    | {
        __typename?: 'CardAccountLockedRestriction'
        code: ActiveCardAccountRestrictionCode
        occurredAt: string
        expiresAt?: string | null
      }
    | {
        __typename?: 'CardAccountSuspendedRestriction'
        code: ActiveCardAccountRestrictionCode
        reason?: CardAccountSuspensionReasonCode | null
        occurredAt: string
      }
    | null
  balance: {
    __typename?: 'CardAccountBalance'
    availableBalance?: string | null
    availableSpent?: string | null
    ledgerBalance?: string | null
    ledgerSpent?: string | null
    ledgerSpentAfterPayments?: string | null
    minimumPaymentDue?: string | null
    updatedAt?: string | null
  }
}

type CardStatusFragment_ApprovedCardAccountStatus_Fragment = {
  __typename?: 'ApprovedCardAccountStatus'
  code: CardAccountStatusCode
  occurredAt?: string | null
  subStatus: CardAccountSubStatus
}

type CardStatusFragment_CancelledCardAccountStatus_Fragment = {
  __typename?: 'CancelledCardAccountStatus'
  code: CardAccountStatusCode
  occurredAt?: string | null
  subStatus: CardAccountSubStatus
}

type CardStatusFragment_DeactivatedCardAccountStatus_Fragment = {
  __typename?: 'DeactivatedCardAccountStatus'
  code: CardAccountStatusCode
  occurredAt?: string | null
  subStatus: CardAccountSubStatus
  cardAccountOverdueStatus?: CardAccountOverdueStatus | null
  reasonCode?: DeactivatedCardAccountReasonCode | null
  delinquentNumberOfDays?: number | null
  chargedOffStatus?: ChargedOffCardStatus | null
  balance: {
    __typename?: 'CardAccountBalance'
    availableBalance?: string | null
    availableSpent?: string | null
    ledgerBalance?: string | null
    ledgerSpent?: string | null
    ledgerSpentAfterPayments?: string | null
    minimumPaymentDue?: string | null
    updatedAt?: string | null
  }
  overduePaymentInfo?: {
    __typename?: 'OverDuePaymentInfo'
    amount?: string | null
    paymentDueAt?: string | null
  } | null
  overdueStatementInfo?: {
    __typename?: 'OverDuePaymentInfo'
    amount?: string | null
    paymentDueAt?: string | null
  } | null
}

type CardStatusFragment_ExpiredCardAccountStatus_Fragment = {
  __typename?: 'ExpiredCardAccountStatus'
  code: CardAccountStatusCode
  occurredAt?: string | null
  subStatus: CardAccountSubStatus
  eligibleToReapplyAfter: string
}

type CardStatusFragment_PendingCardAccountStatus_Fragment = {
  __typename?: 'PendingCardAccountStatus'
  code: CardAccountStatusCode
  occurredAt?: string | null
  subStatus: CardAccountSubStatus
}

type CardStatusFragment_RejectedCardAccountStatus_Fragment = {
  __typename?: 'RejectedCardAccountStatus'
  code: CardAccountStatusCode
  occurredAt?: string | null
  subStatus: CardAccountSubStatus
  eligibleToReapplyAfter: string
  rejectionReasonCodes?: Array<string | null> | null
}

export type CardStatusFragmentFragment =
  | CardStatusFragment_ActiveCardAccountStatus_Fragment
  | CardStatusFragment_ApprovedCardAccountStatus_Fragment
  | CardStatusFragment_CancelledCardAccountStatus_Fragment
  | CardStatusFragment_DeactivatedCardAccountStatus_Fragment
  | CardStatusFragment_ExpiredCardAccountStatus_Fragment
  | CardStatusFragment_PendingCardAccountStatus_Fragment
  | CardStatusFragment_RejectedCardAccountStatus_Fragment

export type CardsFragmentFragment = {
  __typename?: 'CardCollection'
  issued: Array<{
    __typename?: 'Card'
    id?: string | null
    externalId?: string | null
    mask?: string | null
    createdAt: string
    updatedAt: string
    type?: CardType | null
    status?:
      | {
          __typename?: 'ActiveCardStatus'
          code: CardStatusCode
          occurredAt: string
          restriction?: {
            __typename?: 'CardSuspendedRestriction'
            code: ActiveCardRestrictionCode
            occurredAt: string
          } | null
        }
      | {__typename?: 'DeactivatedCardStatus'; code: CardStatusCode; occurredAt: string}
      | {__typename?: 'PendingCardStatus'; code: CardStatusCode; occurredAt: string}
      | {
          __typename?: 'SuspendedCardStatus'
          code: CardStatusCode
          occurredAt: string
          restriction?: {
            __typename?: 'CardSuspendedRestriction'
            code: ActiveCardRestrictionCode
            occurredAt: string
          } | null
        }
      | null
  }>
}

export type CardTransactionsFragmentFragment = {
  __typename?: 'CardAccountTransactionCollection'
  all: {
    __typename?: 'CardAccountTransactionPage'
    items: Array<{
      __typename?: 'CardAccountTransaction'
      transactionId: string
      authorizationCode?: string | null
      transactionDatetime: string
      transactionPostedDatetime?: string | null
      transactionExpiryDatetime?: string | null
      amount: string
      description?: string | null
      status?: CardTransactionStatus | null
      merchant?: {
        __typename?: 'CardMerchant'
        id: string
        categoryCode?: string | null
        name?: string | null
        city?: string | null
        state?: string | null
      } | null
    }>
  }
}

export type CardTransactionsFragmentV2Fragment = {
  __typename?: 'CardAccountTransactionCollection'
  all: {
    __typename?: 'CardAccountTransactionPage'
    items: Array<{
      __typename?: 'CardAccountTransaction'
      transactionId: string
      authorizationCode?: string | null
      transactionDatetime: string
      transactionPostedDatetime?: string | null
      transactionExpiryDatetime?: string | null
      amount: string
      description?: string | null
      status?: CardTransactionStatus | null
      merchant?: {
        __typename?: 'CardMerchant'
        id: string
        categoryCode?: string | null
        name?: string | null
        city?: string | null
        state?: string | null
      } | null
    }>
  }
  pending: {
    __typename?: 'CardAccountTransactionPage'
    items: Array<{
      __typename?: 'CardAccountTransaction'
      transactionId: string
      authorizationCode?: string | null
      transactionDatetime: string
      transactionPostedDatetime?: string | null
      transactionExpiryDatetime?: string | null
      amount: string
      description?: string | null
      status?: CardTransactionStatus | null
      merchant?: {
        __typename?: 'CardMerchant'
        id: string
        categoryCode?: string | null
        name?: string | null
        city?: string | null
        state?: string | null
      } | null
    }>
  }
}

export type StatementFragmentFragment = {
  __typename?: 'CardAccountStatement'
  id: string
  balance?: string | null
  statementDate: string
  paymentDueDate?: string | null
}

export type CardStatementsFragmentFragment = {
  __typename?: 'CardAccountStatementCollection'
  active?: {
    __typename?: 'CardAccountStatement'
    id: string
    balance?: string | null
    statementDate: string
    paymentDueDate?: string | null
  } | null
  mostRecent?: {
    __typename?: 'CardAccountStatement'
    id: string
    balance?: string | null
    statementDate: string
    paymentDueDate?: string | null
  } | null
  historical: {
    __typename?: 'CardAccountStatementPage'
    statements: Array<{
      __typename?: 'CardAccountStatement'
      id: string
      balance?: string | null
      statementDate: string
      paymentDueDate?: string | null
    }>
  }
}

export type CardStatementsFragmentV2Fragment = {
  __typename?: 'CardAccountStatementCollection'
  active?: {
    __typename?: 'CardAccountStatement'
    id: string
    balance?: string | null
    statementDate: string
    paymentDueDate?: string | null
  } | null
  mostRecent?: {
    __typename?: 'CardAccountStatement'
    id: string
    balance?: string | null
    statementDate: string
    paymentDueDate?: string | null
  } | null
  historical: {
    __typename?: 'CardAccountStatementPage'
    statements: Array<{
      __typename?: 'CardAccountStatement'
      id: string
      balance?: string | null
      statementDate: string
      paymentDueDate?: string | null
    }>
  }
}

export type CardAgreementsFragmentFragment = {
  __typename?: 'CardAccountAgreementCollection'
  all: Array<{
    __typename?: 'CardAccountAgreement'
    id: string
    createdAt: string
    updatedAt: string
    type: CardAgreementType
    status: CardAgreementStatus
  }>
}

export type PaymentsFragmentFragment = {
  __typename?: 'Payment'
  id: string
  cardAccountId: string
  amount?: string | null
  status: CardPaymentStatus
  statusCode?: string | null
  statusReason?: string | null
  executeAt: string
  updatedAt: string
  createdAt: string
}

export type CardPaymentsFragmentFragment = {
  __typename?: 'CardPaymentCollection'
  all?: Array<{
    __typename?: 'Payment'
    id: string
    cardAccountId: string
    amount?: string | null
    status: CardPaymentStatus
    statusCode?: string | null
    statusReason?: string | null
    executeAt: string
    updatedAt: string
    createdAt: string
  }> | null
  pending?: Array<{
    __typename?: 'Payment'
    id: string
    cardAccountId: string
    amount?: string | null
    status: CardPaymentStatus
    statusCode?: string | null
    statusReason?: string | null
    executeAt: string
    updatedAt: string
    createdAt: string
  }> | null
  completed?: Array<{
    __typename?: 'Payment'
    id: string
    cardAccountId: string
    amount?: string | null
    status: CardPaymentStatus
    statusCode?: string | null
    statusReason?: string | null
    executeAt: string
    updatedAt: string
    createdAt: string
  }> | null
  schedule?: Array<{
    __typename?: 'AutomaticPaymentSchedule'
    id?: string | null
    paymentDate: string
  }> | null
  potential?: {
    __typename?: 'PotentialAutomaticPaymentCollection'
    frequency?: PaymentFrequency | null
    payments?: Array<{
      __typename?: 'AutomaticPaymentSchedule'
      id?: string | null
      paymentDate: string
    }> | null
  } | null
}

export type CardPaymentsFragmentV2Fragment = {
  __typename?: 'CardPaymentCollection'
  all?: Array<{
    __typename?: 'Payment'
    id: string
    cardAccountId: string
    amount?: string | null
    status: CardPaymentStatus
    statusCode?: string | null
    statusReason?: string | null
    executeAt: string
    updatedAt: string
    createdAt: string
  }> | null
  pending?: Array<{
    __typename?: 'Payment'
    id: string
    cardAccountId: string
    amount?: string | null
    status: CardPaymentStatus
    statusCode?: string | null
    statusReason?: string | null
    executeAt: string
    updatedAt: string
    createdAt: string
  }> | null
  completed?: Array<{
    __typename?: 'Payment'
    id: string
    cardAccountId: string
    amount?: string | null
    status: CardPaymentStatus
    statusCode?: string | null
    statusReason?: string | null
    executeAt: string
    updatedAt: string
    createdAt: string
  }> | null
  schedule?: Array<{
    __typename?: 'AutomaticPaymentSchedule'
    id?: string | null
    paymentDate: string
  }> | null
  potential?: {
    __typename?: 'PotentialAutomaticPaymentCollection'
    frequency?: PaymentFrequency | null
    payments?: Array<{
      __typename?: 'AutomaticPaymentSchedule'
      id?: string | null
      paymentDate: string
    }> | null
  } | null
}

export type CardPaymentMethodsFragmentFragment = {
  __typename?: 'CardPaymentMethodCollection'
  default?:
    | {
        __typename?: 'AchPaymentMethod'
        id: string
        code: PaymentMethodTypeCode
        bankingPaymentInstrumentId: string
        status:
          | {
              __typename?: 'ExpiredPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'InvalidPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'PendingPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'RemovedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SuspendedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SystemPurgedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'VerifiedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
        account?: {
          __typename?: 'LinkedAccount'
          id: string
          mask: string
          name?: string | null
          preferredFundingSource?: boolean | null
          type: LinkedAccountType
          institution?: {__typename?: 'Institution'; name: string} | null
        } | null
      }
    | {__typename?: 'CheckPaymentMethod'}
    | {
        __typename?: 'DebitCardPaymentMethod'
        id: string
        code: PaymentMethodTypeCode
        bankingPaymentInstrumentId: string
        status:
          | {
              __typename?: 'ExpiredPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'InvalidPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'PendingPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'RemovedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SuspendedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SystemPurgedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'VerifiedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
        card: {
          __typename?: 'LinkedDebitCard'
          mask?: string | null
          account?: {
            __typename?: 'LinkedAccount'
            id: string
            mask: string
            name?: string | null
            preferredFundingSource?: boolean | null
            type: LinkedAccountType
            institution?: {__typename?: 'Institution'; name: string} | null
          } | null
        }
      }
    | null
  all?: Array<
    | {
        __typename?: 'AchPaymentMethod'
        id: string
        code: PaymentMethodTypeCode
        bankingPaymentInstrumentId: string
        status:
          | {
              __typename?: 'ExpiredPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'InvalidPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'PendingPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'RemovedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SuspendedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SystemPurgedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'VerifiedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
        account?: {
          __typename?: 'LinkedAccount'
          id: string
          mask: string
          name?: string | null
          preferredFundingSource?: boolean | null
          type: LinkedAccountType
          institution?: {__typename?: 'Institution'; name: string} | null
        } | null
      }
    | {__typename?: 'CheckPaymentMethod'}
    | {
        __typename?: 'DebitCardPaymentMethod'
        id: string
        code: PaymentMethodTypeCode
        bankingPaymentInstrumentId: string
        status:
          | {
              __typename?: 'ExpiredPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'InvalidPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'PendingPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'RemovedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SuspendedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'SystemPurgedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
          | {
              __typename?: 'VerifiedPaymentMethodStatus'
              code: PaymentMethodStatusCode
              reason?: string | null
            }
        card: {
          __typename?: 'LinkedDebitCard'
          mask?: string | null
          account?: {
            __typename?: 'LinkedAccount'
            id: string
            mask: string
            name?: string | null
            preferredFundingSource?: boolean | null
            type: LinkedAccountType
            institution?: {__typename?: 'Institution'; name: string} | null
          } | null
        }
      }
  > | null
}

export type CardInstallmentPlansFragmentFragment = {
  __typename?: 'CardInstallmentPlanCollection'
  eligibleForInstallments: boolean
  current?: {
    __typename?: 'CardAccountInstallmentPlan'
    id?: string | null
    frequency?: PaymentFrequency | null
    totalPaid?: string | null
    pendingAmount?: string | null
    remainingAmount?: string | null
    totalDue?: string | null
    totalOverdueAmount?: string | null
    numberOfOverdueInstallments?: number | null
    status:
      | {
          __typename?: 'ActiveInstallmentPlanStatus'
          code?: InstallmentPlanStatusCode | null
          installmentsRemaining?: number | null
        }
      | {__typename?: 'InactiveInstallmentPlanStatus'; code?: InstallmentPlanStatusCode | null}
    payments?: Array<{
      __typename?: 'InstallmentPayment'
      id?: string | null
      executeAt?: string | null
      amount?: string | null
      ordinal?: number | null
      statusCode?: PaymentStatusCode | null
      fees?: string | null
      totalDue?: string | null
      pendingAmount?: string | null
      paidAmount?: string | null
      paidOff?: boolean | null
      overdue?: boolean | null
      amountWithoutFees?: string | null
      dueAt?: string | null
      remainingDue?: string | null
    }> | null
    installments?: Array<{
      __typename?: 'InstallmentPayment'
      id?: string | null
      executeAt?: string | null
      amount?: string | null
      ordinal?: number | null
      statusCode?: PaymentStatusCode | null
      fees?: string | null
      totalDue?: string | null
      pendingAmount?: string | null
      paidAmount?: string | null
      paidOff?: boolean | null
      overdue?: boolean | null
      amountWithoutFees?: string | null
      dueAt?: string | null
      remainingDue?: string | null
    }> | null
  } | null
  potential?: {
    __typename?: 'CardAccountInstallmentPlan'
    id?: string | null
    frequency?: PaymentFrequency | null
    totalPaid?: string | null
    pendingAmount?: string | null
    remainingAmount?: string | null
    totalDue?: string | null
    totalOverdueAmount?: string | null
    numberOfOverdueInstallments?: number | null
    status:
      | {
          __typename?: 'ActiveInstallmentPlanStatus'
          code?: InstallmentPlanStatusCode | null
          installmentsRemaining?: number | null
        }
      | {__typename?: 'InactiveInstallmentPlanStatus'; code?: InstallmentPlanStatusCode | null}
    payments?: Array<{
      __typename?: 'InstallmentPayment'
      id?: string | null
      executeAt?: string | null
      amount?: string | null
      ordinal?: number | null
      statusCode?: PaymentStatusCode | null
      fees?: string | null
      totalDue?: string | null
      pendingAmount?: string | null
      paidAmount?: string | null
      paidOff?: boolean | null
      overdue?: boolean | null
      amountWithoutFees?: string | null
      dueAt?: string | null
      remainingDue?: string | null
    }> | null
    installments?: Array<{
      __typename?: 'InstallmentPayment'
      id?: string | null
      executeAt?: string | null
      amount?: string | null
      ordinal?: number | null
      statusCode?: PaymentStatusCode | null
      fees?: string | null
      totalDue?: string | null
      pendingAmount?: string | null
      paidAmount?: string | null
      paidOff?: boolean | null
      overdue?: boolean | null
      amountWithoutFees?: string | null
      dueAt?: string | null
      remainingDue?: string | null
    }> | null
  } | null
}

export type CardAccountTypeFragmentFragment = {
  __typename?: 'CardAccountType'
  id: string
  cardNetwork: CardNetwork
  monthlyMembershipFees: string
  maxBalanceLimit: string
}

export type CardAccountFeesFragmentFragment = {
  __typename?: 'CardAccountFees'
  potential?: Array<{
    __typename?: 'FeeSchedule'
    date?: string | null
    amount?: string | null
  } | null> | null
}

export type CardAccountOffersFragmentFragment = {
  __typename?: 'CardAccountOffer'
  id: string
  balanceLimit: string
  network: CardNetwork
  monthlyMembershipFees: string
}

export type CardAccountOfferInfoFragmentFragment = {
  __typename?: 'CardAccountOfferInfo'
  offerAvailabilityStatus: CardAccountOfferAvailabilityStatus
  offers: Array<{__typename?: 'CardAccountOffer'; id: string}>
}

export type CardAccountMiscDataFragmentFragment = {
  __typename?: 'CardAccountMiscData'
  cohortPhase?: CohortPhase | null
  cohortUserType?: CohortUserTpe | null
}

type LoanStatusFragment_ActiveLoanStatus_Fragment = {
  __typename: 'ActiveLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
  amountBorrowed?: string | null
  amountOutstanding?: string | null
  fundingSettledAt?: string | null
}

type LoanStatusFragment_ApprovedLoanStatus_Fragment = {
  __typename: 'ApprovedLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
  amountApproved: string
  counterOfferReasonCodes: {
    __typename?: 'AdverseReasonCodeCollection'
    codes: Array<AdverseReasonCode | null>
  }
}

type LoanStatusFragment_CancelledLoanStatus_Fragment = {
  __typename: 'CancelledLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
}

type LoanStatusFragment_ChargedOffLoanStatus_Fragment = {
  __typename: 'ChargedOffLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
  amountBorrowed?: string | null
  amountOutstanding?: string | null
}

type LoanStatusFragment_ExpiredLoanStatus_Fragment = {
  __typename: 'ExpiredLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
}

type LoanStatusFragment_PaidOffLoanStatus_Fragment = {
  __typename: 'PaidOffLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
  amountBorrowed?: string | null
}

type LoanStatusFragment_PendingLoanStatus_Fragment = {
  __typename: 'PendingLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
}

type LoanStatusFragment_RejectedLoanStatus_Fragment = {
  __typename: 'RejectedLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
  reasonCodes: {__typename?: 'StatusReasonCodeCollection'; codes: Array<string | null>}
}

type LoanStatusFragment_ReplacedLoanStatus_Fragment = {
  __typename: 'ReplacedLoanStatus'
  code: LoanStatusCode
  occurredAt?: string | null
}

export type LoanStatusFragmentFragment =
  | LoanStatusFragment_ActiveLoanStatus_Fragment
  | LoanStatusFragment_ApprovedLoanStatus_Fragment
  | LoanStatusFragment_CancelledLoanStatus_Fragment
  | LoanStatusFragment_ChargedOffLoanStatus_Fragment
  | LoanStatusFragment_ExpiredLoanStatus_Fragment
  | LoanStatusFragment_PaidOffLoanStatus_Fragment
  | LoanStatusFragment_PendingLoanStatus_Fragment
  | LoanStatusFragment_RejectedLoanStatus_Fragment
  | LoanStatusFragment_ReplacedLoanStatus_Fragment

export type LoanFragmentFragment = {
  __typename?: 'Loan'
  id: string
  createdAt: string
  updatedAt: string
  dueAt?: string | null
  currency?: string | null
  amountRequested?: string | null
  status:
    | {
        __typename: 'ActiveLoanStatus'
        code: LoanStatusCode
        occurredAt?: string | null
        amountBorrowed?: string | null
        amountOutstanding?: string | null
        fundingSettledAt?: string | null
      }
    | {
        __typename: 'ApprovedLoanStatus'
        code: LoanStatusCode
        occurredAt?: string | null
        amountApproved: string
        counterOfferReasonCodes: {
          __typename?: 'AdverseReasonCodeCollection'
          codes: Array<AdverseReasonCode | null>
        }
      }
    | {__typename: 'CancelledLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
    | {
        __typename: 'ChargedOffLoanStatus'
        code: LoanStatusCode
        occurredAt?: string | null
        amountBorrowed?: string | null
        amountOutstanding?: string | null
      }
    | {__typename: 'ExpiredLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
    | {
        __typename: 'PaidOffLoanStatus'
        code: LoanStatusCode
        occurredAt?: string | null
        amountBorrowed?: string | null
      }
    | {__typename: 'PendingLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
    | {
        __typename: 'RejectedLoanStatus'
        code: LoanStatusCode
        occurredAt?: string | null
        reasonCodes: {__typename?: 'StatusReasonCodeCollection'; codes: Array<string | null>}
      }
    | {__typename: 'ReplacedLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
  borrower?: {
    __typename?: 'BorrowerInfo'
    disbursementMethod?: LoanPaymentMethod | null
    monthlyIncome?: {
      __typename?: 'MonthlyIncomeInfo'
      gross?: string | null
      startPeriod?: string | null
      endPeriod?: string | null
    } | null
  } | null
}

export type LocationFragmentFragment = {
  __typename?: 'Location'
  address?: {
    __typename?: 'Address'
    street1?: string | null
    street2?: string | null
    city?: string | null
    state?: string | null
    postalCode?: string | null
    country?: string | null
    verified: boolean
  } | null
  gps?: {__typename?: 'GpsCoordinate'; latitude?: number | null; longitude: number} | null
  timeZone?: {__typename?: 'TimeZone'; id?: string | null} | null
}

export type UserProfileFragmentFragment = {
  __typename?: 'User'
  profile?: {
    __typename?: 'UserProfile'
    birthDate?: string | null
    name?: {
      __typename?: 'Name'
      firstName: string
      lastName?: string | null
      middleName?: string | null
      suffix?: string | null
    } | null
    email?: {__typename?: 'Email'; verified: boolean; address?: string | null} | null
    phone?: {__typename?: 'Phone'; number?: string | null; verified: boolean} | null
    home?: {
      __typename?: 'Location'
      address?: {
        __typename?: 'Address'
        street1?: string | null
        street2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        verified: boolean
      } | null
      gps?: {__typename?: 'GpsCoordinate'; latitude?: number | null; longitude: number} | null
      timeZone?: {__typename?: 'TimeZone'; id?: string | null} | null
    } | null
    mailing?: {
      __typename?: 'Location'
      address?: {
        __typename?: 'Address'
        street1?: string | null
        street2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        verified: boolean
      } | null
      gps?: {__typename?: 'GpsCoordinate'; latitude?: number | null; longitude: number} | null
      timeZone?: {__typename?: 'TimeZone'; id?: string | null} | null
    } | null
  } | null
}

export type UserFragmentFragment = {
  __typename?: 'User'
  id: string
  createdAt: string
  updatedAt: string
  identification?: {
    __typename?: 'IssuedIdentificationInfo'
    ssn?: {__typename?: 'SSN'; mask: string; verified: boolean} | null
    identificationDocument?: {
      __typename?: 'IdentificationDocumentInfo'
      number: string
      expirationDate: string
    } | null
  } | null
  status:
    | {__typename?: 'DeletedUserStatus'}
    | {
        __typename?: 'DisabledUserStatus'
        code: UserStatusCode
        reason?: string | null
        disabledCategory?: DisabledStatusCategory | null
      }
    | {__typename?: 'EnabledUserStatus'; code: UserStatusCode}
    | {
        __typename?: 'FraudulentUserStatus'
        code: UserStatusCode
        reason?: string | null
        fraudulentCategory?: FraudulentStatusCategory | null
      }
}

export type UserLoansFragmentFragment = {
  __typename?: 'User'
  loans: {
    __typename?: 'UserLoanCollection'
    latestActionableLoan?: {
      __typename?: 'Loan'
      id: string
      createdAt: string
      updatedAt: string
      dueAt?: string | null
      currency?: string | null
      amountRequested?: string | null
      status:
        | {
            __typename: 'ActiveLoanStatus'
            code: LoanStatusCode
            occurredAt?: string | null
            amountBorrowed?: string | null
            amountOutstanding?: string | null
            fundingSettledAt?: string | null
          }
        | {
            __typename: 'ApprovedLoanStatus'
            code: LoanStatusCode
            occurredAt?: string | null
            amountApproved: string
            counterOfferReasonCodes: {
              __typename?: 'AdverseReasonCodeCollection'
              codes: Array<AdverseReasonCode | null>
            }
          }
        | {__typename: 'CancelledLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
        | {
            __typename: 'ChargedOffLoanStatus'
            code: LoanStatusCode
            occurredAt?: string | null
            amountBorrowed?: string | null
            amountOutstanding?: string | null
          }
        | {__typename: 'ExpiredLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
        | {
            __typename: 'PaidOffLoanStatus'
            code: LoanStatusCode
            occurredAt?: string | null
            amountBorrowed?: string | null
          }
        | {__typename: 'PendingLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
        | {
            __typename: 'RejectedLoanStatus'
            code: LoanStatusCode
            occurredAt?: string | null
            reasonCodes: {__typename?: 'StatusReasonCodeCollection'; codes: Array<string | null>}
          }
        | {__typename: 'ReplacedLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
      borrower?: {
        __typename?: 'BorrowerInfo'
        disbursementMethod?: LoanPaymentMethod | null
        monthlyIncome?: {
          __typename?: 'MonthlyIncomeInfo'
          gross?: string | null
          startPeriod?: string | null
          endPeriod?: string | null
        } | null
      } | null
    } | null
  }
}

export type UraFragmentFragment = {
  __typename?: 'User'
  userRequestedActions: {
    __typename?: 'UserRequestedActionCollection'
    pending?: Array<{
      __typename?: 'UserRequestedAction'
      id: string
      userId: string
      status: UserRequestedActionStatusCode
      actionType: UserRequestedActionTypesCode
      actionTitle: string
      actionBody: string
      optional: boolean
      ableToCompleteAt: string
      actionData: {
        __typename?: 'UserRequestedActionData'
        accountId?: string | null
        paymentInstrumentId?: string | null
      }
      requestContext: {__typename?: 'EntityDescriptor'; type: string; id: string}
    } | null> | null
  }
}

export type UraAllFragmentFragment = {
  __typename?: 'User'
  userRequestedActions: {
    __typename?: 'UserRequestedActionCollection'
    pending?: Array<{
      __typename?: 'UserRequestedAction'
      id: string
      userId: string
      status: UserRequestedActionStatusCode
      actionType: UserRequestedActionTypesCode
      actionTitle: string
      actionBody: string
      optional: boolean
      createdAt: string
      updatedAt: string
      actionData: {
        __typename?: 'UserRequestedActionData'
        paymentInstrumentId?: string | null
        accountId?: string | null
      }
      requestContext: {__typename?: 'EntityDescriptor'; type: string; id: string}
    } | null> | null
    all?: Array<{
      __typename?: 'UserRequestedAction'
      id: string
      userId: string
      status: UserRequestedActionStatusCode
      actionType: UserRequestedActionTypesCode
      actionTitle: string
      actionBody: string
      optional: boolean
      createdAt: string
      updatedAt: string
      actionData: {
        __typename?: 'UserRequestedActionData'
        paymentInstrumentId?: string | null
        accountId?: string | null
      }
      requestContext: {__typename?: 'EntityDescriptor'; type: string; id: string}
    } | null> | null
  }
}

export type LoanPaymentFragmentFragment = {
  __typename?: 'LoanPayment'
  id: string
  userId: string
  loanId: string
  status: string
  currency?: string | null
  amount: string
  rescheduledDate?: string | null
  fundingId?: string | null
  method?: string | null
  ordinal: number
  originalDate: string
  failureCount: number
  fees: string
  interest: string
  principal: string
  executeEffectiveDateTime?: string | null
  paidOffDateTime?: string | null
  payingOffNow: boolean
}

export type CardAccountsFragmentFragment = {
  __typename?: 'User'
  cardAccounts: {
    __typename?: 'CardAccountCollection'
    active?: {
      __typename?: 'CardAccount'
      id: string
      createdAt: string
      updatedAt: string
      creditLimit: string
      cardNetwork: CardNetwork
      autopayEnabled: boolean
      balance?: {
        __typename?: 'CardAccountBalance'
        availableBalance?: string | null
        availableSpent?: string | null
        ledgerBalance?: string | null
        ledgerSpent?: string | null
        ledgerSpentAfterPayments?: string | null
        minimumPaymentDue?: string | null
        updatedAt?: string | null
      } | null
      status:
        | {
            __typename?: 'ActiveCardAccountStatus'
            autopayFrequency: PaymentFrequency
            code: CardAccountStatusCode
            occurredAt?: string | null
            subStatus: CardAccountSubStatus
            cardAccountOverdueStatus?: CardAccountOverdueStatus | null
            daysUntilDelinquent?: number | null
            delinquentNumberOfDays?: number | null
            amountOverdue?: string | null
            chargedOffStatus?: ChargedOffCardStatus | null
            overduePaymentInfo?: {
              __typename?: 'OverDuePaymentInfo'
              amount?: string | null
              paymentDueAt?: string | null
            } | null
            overdueInstallmentInfo?: Array<{
              __typename?: 'OverDuePaymentInfo'
              amount?: string | null
              paymentDueAt?: string | null
            } | null> | null
            restriction?:
              | {
                  __typename?: 'CardAccountLockedRestriction'
                  code: ActiveCardAccountRestrictionCode
                  occurredAt: string
                  expiresAt?: string | null
                }
              | {
                  __typename?: 'CardAccountSuspendedRestriction'
                  code: ActiveCardAccountRestrictionCode
                  reason?: CardAccountSuspensionReasonCode | null
                  occurredAt: string
                }
              | null
            balance: {
              __typename?: 'CardAccountBalance'
              availableBalance?: string | null
              availableSpent?: string | null
              ledgerBalance?: string | null
              ledgerSpent?: string | null
              ledgerSpentAfterPayments?: string | null
              minimumPaymentDue?: string | null
              updatedAt?: string | null
            }
          }
        | {
            __typename?: 'ApprovedCardAccountStatus'
            code: CardAccountStatusCode
            occurredAt?: string | null
            subStatus: CardAccountSubStatus
          }
        | {
            __typename?: 'CancelledCardAccountStatus'
            code: CardAccountStatusCode
            occurredAt?: string | null
            subStatus: CardAccountSubStatus
          }
        | {
            __typename?: 'DeactivatedCardAccountStatus'
            code: CardAccountStatusCode
            occurredAt?: string | null
            subStatus: CardAccountSubStatus
            cardAccountOverdueStatus?: CardAccountOverdueStatus | null
            reasonCode?: DeactivatedCardAccountReasonCode | null
            delinquentNumberOfDays?: number | null
            chargedOffStatus?: ChargedOffCardStatus | null
            balance: {
              __typename?: 'CardAccountBalance'
              availableBalance?: string | null
              availableSpent?: string | null
              ledgerBalance?: string | null
              ledgerSpent?: string | null
              ledgerSpentAfterPayments?: string | null
              minimumPaymentDue?: string | null
              updatedAt?: string | null
            }
            overduePaymentInfo?: {
              __typename?: 'OverDuePaymentInfo'
              amount?: string | null
              paymentDueAt?: string | null
            } | null
            overdueStatementInfo?: {
              __typename?: 'OverDuePaymentInfo'
              amount?: string | null
              paymentDueAt?: string | null
            } | null
          }
        | {
            __typename?: 'ExpiredCardAccountStatus'
            code: CardAccountStatusCode
            occurredAt?: string | null
            subStatus: CardAccountSubStatus
            eligibleToReapplyAfter: string
          }
        | {
            __typename?: 'PendingCardAccountStatus'
            code: CardAccountStatusCode
            occurredAt?: string | null
            subStatus: CardAccountSubStatus
          }
        | {
            __typename?: 'RejectedCardAccountStatus'
            code: CardAccountStatusCode
            occurredAt?: string | null
            subStatus: CardAccountSubStatus
            eligibleToReapplyAfter: string
            rejectionReasonCodes?: Array<string | null> | null
          }
      cards: {
        __typename?: 'CardCollection'
        issued: Array<{
          __typename?: 'Card'
          id?: string | null
          externalId?: string | null
          mask?: string | null
          createdAt: string
          updatedAt: string
          type?: CardType | null
          status?:
            | {
                __typename?: 'ActiveCardStatus'
                code: CardStatusCode
                occurredAt: string
                restriction?: {
                  __typename?: 'CardSuspendedRestriction'
                  code: ActiveCardRestrictionCode
                  occurredAt: string
                } | null
              }
            | {__typename?: 'DeactivatedCardStatus'; code: CardStatusCode; occurredAt: string}
            | {__typename?: 'PendingCardStatus'; code: CardStatusCode; occurredAt: string}
            | {
                __typename?: 'SuspendedCardStatus'
                code: CardStatusCode
                occurredAt: string
                restriction?: {
                  __typename?: 'CardSuspendedRestriction'
                  code: ActiveCardRestrictionCode
                  occurredAt: string
                } | null
              }
            | null
        }>
      }
      transactions: {
        __typename?: 'CardAccountTransactionCollection'
        all: {
          __typename?: 'CardAccountTransactionPage'
          items: Array<{
            __typename?: 'CardAccountTransaction'
            transactionId: string
            authorizationCode?: string | null
            transactionDatetime: string
            transactionPostedDatetime?: string | null
            transactionExpiryDatetime?: string | null
            amount: string
            description?: string | null
            status?: CardTransactionStatus | null
            merchant?: {
              __typename?: 'CardMerchant'
              id: string
              categoryCode?: string | null
              name?: string | null
              city?: string | null
              state?: string | null
            } | null
          }>
        }
      }
      statements: {
        __typename?: 'CardAccountStatementCollection'
        active?: {
          __typename?: 'CardAccountStatement'
          id: string
          balance?: string | null
          statementDate: string
          paymentDueDate?: string | null
        } | null
        mostRecent?: {
          __typename?: 'CardAccountStatement'
          id: string
          balance?: string | null
          statementDate: string
          paymentDueDate?: string | null
        } | null
        historical: {
          __typename?: 'CardAccountStatementPage'
          statements: Array<{
            __typename?: 'CardAccountStatement'
            id: string
            balance?: string | null
            statementDate: string
            paymentDueDate?: string | null
          }>
        }
      }
      agreements: {
        __typename?: 'CardAccountAgreementCollection'
        all: Array<{
          __typename?: 'CardAccountAgreement'
          id: string
          createdAt: string
          updatedAt: string
          type: CardAgreementType
          status: CardAgreementStatus
        }>
      }
      payments: {
        __typename?: 'CardPaymentCollection'
        all?: Array<{
          __typename?: 'Payment'
          id: string
          cardAccountId: string
          amount?: string | null
          status: CardPaymentStatus
          statusCode?: string | null
          statusReason?: string | null
          executeAt: string
          updatedAt: string
          createdAt: string
        }> | null
        pending?: Array<{
          __typename?: 'Payment'
          id: string
          cardAccountId: string
          amount?: string | null
          status: CardPaymentStatus
          statusCode?: string | null
          statusReason?: string | null
          executeAt: string
          updatedAt: string
          createdAt: string
        }> | null
        completed?: Array<{
          __typename?: 'Payment'
          id: string
          cardAccountId: string
          amount?: string | null
          status: CardPaymentStatus
          statusCode?: string | null
          statusReason?: string | null
          executeAt: string
          updatedAt: string
          createdAt: string
        }> | null
        schedule?: Array<{
          __typename?: 'AutomaticPaymentSchedule'
          id?: string | null
          paymentDate: string
        }> | null
        potential?: {
          __typename?: 'PotentialAutomaticPaymentCollection'
          frequency?: PaymentFrequency | null
          payments?: Array<{
            __typename?: 'AutomaticPaymentSchedule'
            id?: string | null
            paymentDate: string
          }> | null
        } | null
      }
      paymentMethods: {
        __typename?: 'CardPaymentMethodCollection'
        default?:
          | {
              __typename?: 'AchPaymentMethod'
              id: string
              code: PaymentMethodTypeCode
              bankingPaymentInstrumentId: string
              status:
                | {
                    __typename?: 'ExpiredPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'InvalidPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'PendingPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'RemovedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SuspendedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SystemPurgedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'VerifiedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
              account?: {
                __typename?: 'LinkedAccount'
                id: string
                mask: string
                name?: string | null
                preferredFundingSource?: boolean | null
                type: LinkedAccountType
                institution?: {__typename?: 'Institution'; name: string} | null
              } | null
            }
          | {__typename?: 'CheckPaymentMethod'}
          | {
              __typename?: 'DebitCardPaymentMethod'
              id: string
              code: PaymentMethodTypeCode
              bankingPaymentInstrumentId: string
              status:
                | {
                    __typename?: 'ExpiredPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'InvalidPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'PendingPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'RemovedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SuspendedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SystemPurgedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'VerifiedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
              card: {
                __typename?: 'LinkedDebitCard'
                mask?: string | null
                account?: {
                  __typename?: 'LinkedAccount'
                  id: string
                  mask: string
                  name?: string | null
                  preferredFundingSource?: boolean | null
                  type: LinkedAccountType
                  institution?: {__typename?: 'Institution'; name: string} | null
                } | null
              }
            }
          | null
        all?: Array<
          | {
              __typename?: 'AchPaymentMethod'
              id: string
              code: PaymentMethodTypeCode
              bankingPaymentInstrumentId: string
              status:
                | {
                    __typename?: 'ExpiredPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'InvalidPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'PendingPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'RemovedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SuspendedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SystemPurgedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'VerifiedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
              account?: {
                __typename?: 'LinkedAccount'
                id: string
                mask: string
                name?: string | null
                preferredFundingSource?: boolean | null
                type: LinkedAccountType
                institution?: {__typename?: 'Institution'; name: string} | null
              } | null
            }
          | {__typename?: 'CheckPaymentMethod'}
          | {
              __typename?: 'DebitCardPaymentMethod'
              id: string
              code: PaymentMethodTypeCode
              bankingPaymentInstrumentId: string
              status:
                | {
                    __typename?: 'ExpiredPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'InvalidPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'PendingPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'RemovedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SuspendedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'SystemPurgedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
                | {
                    __typename?: 'VerifiedPaymentMethodStatus'
                    code: PaymentMethodStatusCode
                    reason?: string | null
                  }
              card: {
                __typename?: 'LinkedDebitCard'
                mask?: string | null
                account?: {
                  __typename?: 'LinkedAccount'
                  id: string
                  mask: string
                  name?: string | null
                  preferredFundingSource?: boolean | null
                  type: LinkedAccountType
                  institution?: {__typename?: 'Institution'; name: string} | null
                } | null
              }
            }
        > | null
      }
      installmentPlans: {
        __typename?: 'CardInstallmentPlanCollection'
        eligibleForInstallments: boolean
        current?: {
          __typename?: 'CardAccountInstallmentPlan'
          id?: string | null
          frequency?: PaymentFrequency | null
          totalPaid?: string | null
          pendingAmount?: string | null
          remainingAmount?: string | null
          totalDue?: string | null
          totalOverdueAmount?: string | null
          numberOfOverdueInstallments?: number | null
          status:
            | {
                __typename?: 'ActiveInstallmentPlanStatus'
                code?: InstallmentPlanStatusCode | null
                installmentsRemaining?: number | null
              }
            | {
                __typename?: 'InactiveInstallmentPlanStatus'
                code?: InstallmentPlanStatusCode | null
              }
          payments?: Array<{
            __typename?: 'InstallmentPayment'
            id?: string | null
            executeAt?: string | null
            amount?: string | null
            ordinal?: number | null
            statusCode?: PaymentStatusCode | null
            fees?: string | null
            totalDue?: string | null
            pendingAmount?: string | null
            paidAmount?: string | null
            paidOff?: boolean | null
            overdue?: boolean | null
            amountWithoutFees?: string | null
            dueAt?: string | null
            remainingDue?: string | null
          }> | null
          installments?: Array<{
            __typename?: 'InstallmentPayment'
            id?: string | null
            executeAt?: string | null
            amount?: string | null
            ordinal?: number | null
            statusCode?: PaymentStatusCode | null
            fees?: string | null
            totalDue?: string | null
            pendingAmount?: string | null
            paidAmount?: string | null
            paidOff?: boolean | null
            overdue?: boolean | null
            amountWithoutFees?: string | null
            dueAt?: string | null
            remainingDue?: string | null
          }> | null
        } | null
        potential?: {
          __typename?: 'CardAccountInstallmentPlan'
          id?: string | null
          frequency?: PaymentFrequency | null
          totalPaid?: string | null
          pendingAmount?: string | null
          remainingAmount?: string | null
          totalDue?: string | null
          totalOverdueAmount?: string | null
          numberOfOverdueInstallments?: number | null
          status:
            | {
                __typename?: 'ActiveInstallmentPlanStatus'
                code?: InstallmentPlanStatusCode | null
                installmentsRemaining?: number | null
              }
            | {
                __typename?: 'InactiveInstallmentPlanStatus'
                code?: InstallmentPlanStatusCode | null
              }
          payments?: Array<{
            __typename?: 'InstallmentPayment'
            id?: string | null
            executeAt?: string | null
            amount?: string | null
            ordinal?: number | null
            statusCode?: PaymentStatusCode | null
            fees?: string | null
            totalDue?: string | null
            pendingAmount?: string | null
            paidAmount?: string | null
            paidOff?: boolean | null
            overdue?: boolean | null
            amountWithoutFees?: string | null
            dueAt?: string | null
            remainingDue?: string | null
          }> | null
          installments?: Array<{
            __typename?: 'InstallmentPayment'
            id?: string | null
            executeAt?: string | null
            amount?: string | null
            ordinal?: number | null
            statusCode?: PaymentStatusCode | null
            fees?: string | null
            totalDue?: string | null
            pendingAmount?: string | null
            paidAmount?: string | null
            paidOff?: boolean | null
            overdue?: boolean | null
            amountWithoutFees?: string | null
            dueAt?: string | null
            remainingDue?: string | null
          }> | null
        } | null
      }
      cardAccountType?: {
        __typename?: 'CardAccountType'
        id: string
        cardNetwork: CardNetwork
        monthlyMembershipFees: string
        maxBalanceLimit: string
      } | null
      fees?: {
        __typename?: 'CardAccountFees'
        potential?: Array<{
          __typename?: 'FeeSchedule'
          date?: string | null
          amount?: string | null
        } | null> | null
      } | null
    } | null
    offers: Array<{
      __typename?: 'CardAccountOffer'
      id: string
      balanceLimit: string
      network: CardNetwork
      monthlyMembershipFees: string
    }>
    offerInfo: {
      __typename?: 'CardAccountOfferInfo'
      offerAvailabilityStatus: CardAccountOfferAvailabilityStatus
      offers: Array<{__typename?: 'CardAccountOffer'; id: string}>
    }
  }
}

export type OfferFragmentFragment = {
  __typename?: 'Offer'
  id: string
  annualFee: string
  approvedAmount: string
  apr: string
  interestRate: string
  monthlyPayment: string
  months: number
  originationFee: string
  partnerId: PartnerId
  status: Status
  title: string
  url: string
}

export type AchPaymentMethodFragmentFragment = {
  __typename?: 'AchPaymentMethod'
  id: string
  code: PaymentMethodTypeCode
  bankingPaymentInstrumentId: string
  status:
    | {
        __typename?: 'ExpiredPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'InvalidPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'PendingPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'RemovedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'SuspendedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'SystemPurgedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'VerifiedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
  account?: {
    __typename?: 'LinkedAccount'
    id: string
    mask: string
    name?: string | null
    preferredFundingSource?: boolean | null
    type: LinkedAccountType
    institution?: {__typename?: 'Institution'; name: string} | null
  } | null
}

export type DebitCardPaymentMethodFragmentFragment = {
  __typename?: 'DebitCardPaymentMethod'
  id: string
  code: PaymentMethodTypeCode
  bankingPaymentInstrumentId: string
  status:
    | {
        __typename?: 'ExpiredPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'InvalidPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'PendingPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'RemovedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'SuspendedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'SystemPurgedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
    | {
        __typename?: 'VerifiedPaymentMethodStatus'
        code: PaymentMethodStatusCode
        reason?: string | null
      }
  card: {
    __typename?: 'LinkedDebitCard'
    mask?: string | null
    account?: {
      __typename?: 'LinkedAccount'
      id: string
      mask: string
      name?: string | null
      preferredFundingSource?: boolean | null
      type: LinkedAccountType
      institution?: {__typename?: 'Institution'; name: string} | null
    } | null
  }
}

export type CardsPaymentMethodsFragmentFragment = {
  __typename?: 'User'
  paymentMethods: {
    __typename?: 'PaymentMethodCollection'
    cardEligible?: Array<
      | {
          __typename?: 'AchPaymentMethod'
          id: string
          code: PaymentMethodTypeCode
          bankingPaymentInstrumentId: string
          status:
            | {
                __typename?: 'ExpiredPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'InvalidPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'PendingPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'RemovedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'SuspendedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'SystemPurgedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'VerifiedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
          account?: {
            __typename?: 'LinkedAccount'
            id: string
            mask: string
            name?: string | null
            preferredFundingSource?: boolean | null
            type: LinkedAccountType
            institution?: {__typename?: 'Institution'; name: string} | null
          } | null
        }
      | {__typename?: 'CheckPaymentMethod'}
      | {
          __typename?: 'DebitCardPaymentMethod'
          id: string
          code: PaymentMethodTypeCode
          bankingPaymentInstrumentId: string
          status:
            | {
                __typename?: 'ExpiredPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'InvalidPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'PendingPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'RemovedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'SuspendedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'SystemPurgedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
            | {
                __typename?: 'VerifiedPaymentMethodStatus'
                code: PaymentMethodStatusCode
                reason?: string | null
              }
          card: {
            __typename?: 'LinkedDebitCard'
            mask?: string | null
            account?: {
              __typename?: 'LinkedAccount'
              id: string
              mask: string
              name?: string | null
              preferredFundingSource?: boolean | null
              type: LinkedAccountType
              institution?: {__typename?: 'Institution'; name: string} | null
            } | null
          }
        }
    > | null
  }
}

export type UserQueryQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type UserQueryQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    createdAt: string
    updatedAt: string
    identification?: {
      __typename?: 'IssuedIdentificationInfo'
      ssn?: {__typename?: 'SSN'; mask: string; verified: boolean} | null
      identificationDocument?: {
        __typename?: 'IdentificationDocumentInfo'
        number: string
        expirationDate: string
      } | null
    } | null
    status:
      | {__typename?: 'DeletedUserStatus'}
      | {
          __typename?: 'DisabledUserStatus'
          code: UserStatusCode
          reason?: string | null
          disabledCategory?: DisabledStatusCategory | null
        }
      | {__typename?: 'EnabledUserStatus'; code: UserStatusCode}
      | {
          __typename?: 'FraudulentUserStatus'
          code: UserStatusCode
          reason?: string | null
          fraudulentCategory?: FraudulentStatusCategory | null
        }
    profile?: {
      __typename?: 'UserProfile'
      birthDate?: string | null
      name?: {
        __typename?: 'Name'
        firstName: string
        lastName?: string | null
        middleName?: string | null
        suffix?: string | null
      } | null
      email?: {__typename?: 'Email'; verified: boolean; address?: string | null} | null
      phone?: {__typename?: 'Phone'; number?: string | null; verified: boolean} | null
      home?: {
        __typename?: 'Location'
        address?: {
          __typename?: 'Address'
          street1?: string | null
          street2?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          verified: boolean
        } | null
        gps?: {__typename?: 'GpsCoordinate'; latitude?: number | null; longitude: number} | null
        timeZone?: {__typename?: 'TimeZone'; id?: string | null} | null
      } | null
      mailing?: {
        __typename?: 'Location'
        address?: {
          __typename?: 'Address'
          street1?: string | null
          street2?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          verified: boolean
        } | null
        gps?: {__typename?: 'GpsCoordinate'; latitude?: number | null; longitude: number} | null
        timeZone?: {__typename?: 'TimeZone'; id?: string | null} | null
      } | null
    } | null
    loans: {
      __typename?: 'UserLoanCollection'
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        createdAt: string
        updatedAt: string
        dueAt?: string | null
        currency?: string | null
        amountRequested?: string | null
        status:
          | {
              __typename: 'ActiveLoanStatus'
              code: LoanStatusCode
              occurredAt?: string | null
              amountBorrowed?: string | null
              amountOutstanding?: string | null
              fundingSettledAt?: string | null
            }
          | {
              __typename: 'ApprovedLoanStatus'
              code: LoanStatusCode
              occurredAt?: string | null
              amountApproved: string
              counterOfferReasonCodes: {
                __typename?: 'AdverseReasonCodeCollection'
                codes: Array<AdverseReasonCode | null>
              }
            }
          | {__typename: 'CancelledLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
          | {
              __typename: 'ChargedOffLoanStatus'
              code: LoanStatusCode
              occurredAt?: string | null
              amountBorrowed?: string | null
              amountOutstanding?: string | null
            }
          | {__typename: 'ExpiredLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
          | {
              __typename: 'PaidOffLoanStatus'
              code: LoanStatusCode
              occurredAt?: string | null
              amountBorrowed?: string | null
            }
          | {__typename: 'PendingLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
          | {
              __typename: 'RejectedLoanStatus'
              code: LoanStatusCode
              occurredAt?: string | null
              reasonCodes: {__typename?: 'StatusReasonCodeCollection'; codes: Array<string | null>}
            }
          | {__typename: 'ReplacedLoanStatus'; code: LoanStatusCode; occurredAt?: string | null}
        borrower?: {
          __typename?: 'BorrowerInfo'
          disbursementMethod?: LoanPaymentMethod | null
          monthlyIncome?: {
            __typename?: 'MonthlyIncomeInfo'
            gross?: string | null
            startPeriod?: string | null
            endPeriod?: string | null
          } | null
        } | null
      } | null
    }
    cardAccounts: {
      __typename?: 'CardAccountCollection'
      active?: {
        __typename?: 'CardAccount'
        id: string
        createdAt: string
        updatedAt: string
        creditLimit: string
        cardNetwork: CardNetwork
        autopayEnabled: boolean
        balance?: {
          __typename?: 'CardAccountBalance'
          availableBalance?: string | null
          availableSpent?: string | null
          ledgerBalance?: string | null
          ledgerSpent?: string | null
          ledgerSpentAfterPayments?: string | null
          minimumPaymentDue?: string | null
          updatedAt?: string | null
        } | null
        status:
          | {
              __typename?: 'ActiveCardAccountStatus'
              autopayFrequency: PaymentFrequency
              code: CardAccountStatusCode
              occurredAt?: string | null
              subStatus: CardAccountSubStatus
              cardAccountOverdueStatus?: CardAccountOverdueStatus | null
              daysUntilDelinquent?: number | null
              delinquentNumberOfDays?: number | null
              amountOverdue?: string | null
              chargedOffStatus?: ChargedOffCardStatus | null
              overduePaymentInfo?: {
                __typename?: 'OverDuePaymentInfo'
                amount?: string | null
                paymentDueAt?: string | null
              } | null
              overdueInstallmentInfo?: Array<{
                __typename?: 'OverDuePaymentInfo'
                amount?: string | null
                paymentDueAt?: string | null
              } | null> | null
              restriction?:
                | {
                    __typename?: 'CardAccountLockedRestriction'
                    code: ActiveCardAccountRestrictionCode
                    occurredAt: string
                    expiresAt?: string | null
                  }
                | {
                    __typename?: 'CardAccountSuspendedRestriction'
                    code: ActiveCardAccountRestrictionCode
                    reason?: CardAccountSuspensionReasonCode | null
                    occurredAt: string
                  }
                | null
              balance: {
                __typename?: 'CardAccountBalance'
                availableBalance?: string | null
                availableSpent?: string | null
                ledgerBalance?: string | null
                ledgerSpent?: string | null
                ledgerSpentAfterPayments?: string | null
                minimumPaymentDue?: string | null
                updatedAt?: string | null
              }
            }
          | {
              __typename?: 'ApprovedCardAccountStatus'
              code: CardAccountStatusCode
              occurredAt?: string | null
              subStatus: CardAccountSubStatus
            }
          | {
              __typename?: 'CancelledCardAccountStatus'
              code: CardAccountStatusCode
              occurredAt?: string | null
              subStatus: CardAccountSubStatus
            }
          | {
              __typename?: 'DeactivatedCardAccountStatus'
              code: CardAccountStatusCode
              occurredAt?: string | null
              subStatus: CardAccountSubStatus
              cardAccountOverdueStatus?: CardAccountOverdueStatus | null
              reasonCode?: DeactivatedCardAccountReasonCode | null
              delinquentNumberOfDays?: number | null
              chargedOffStatus?: ChargedOffCardStatus | null
              balance: {
                __typename?: 'CardAccountBalance'
                availableBalance?: string | null
                availableSpent?: string | null
                ledgerBalance?: string | null
                ledgerSpent?: string | null
                ledgerSpentAfterPayments?: string | null
                minimumPaymentDue?: string | null
                updatedAt?: string | null
              }
              overduePaymentInfo?: {
                __typename?: 'OverDuePaymentInfo'
                amount?: string | null
                paymentDueAt?: string | null
              } | null
              overdueStatementInfo?: {
                __typename?: 'OverDuePaymentInfo'
                amount?: string | null
                paymentDueAt?: string | null
              } | null
            }
          | {
              __typename?: 'ExpiredCardAccountStatus'
              code: CardAccountStatusCode
              occurredAt?: string | null
              subStatus: CardAccountSubStatus
              eligibleToReapplyAfter: string
            }
          | {
              __typename?: 'PendingCardAccountStatus'
              code: CardAccountStatusCode
              occurredAt?: string | null
              subStatus: CardAccountSubStatus
            }
          | {
              __typename?: 'RejectedCardAccountStatus'
              code: CardAccountStatusCode
              occurredAt?: string | null
              subStatus: CardAccountSubStatus
              eligibleToReapplyAfter: string
              rejectionReasonCodes?: Array<string | null> | null
            }
        cards: {
          __typename?: 'CardCollection'
          issued: Array<{
            __typename?: 'Card'
            id?: string | null
            externalId?: string | null
            mask?: string | null
            createdAt: string
            updatedAt: string
            type?: CardType | null
            status?:
              | {
                  __typename?: 'ActiveCardStatus'
                  code: CardStatusCode
                  occurredAt: string
                  restriction?: {
                    __typename?: 'CardSuspendedRestriction'
                    code: ActiveCardRestrictionCode
                    occurredAt: string
                  } | null
                }
              | {__typename?: 'DeactivatedCardStatus'; code: CardStatusCode; occurredAt: string}
              | {__typename?: 'PendingCardStatus'; code: CardStatusCode; occurredAt: string}
              | {
                  __typename?: 'SuspendedCardStatus'
                  code: CardStatusCode
                  occurredAt: string
                  restriction?: {
                    __typename?: 'CardSuspendedRestriction'
                    code: ActiveCardRestrictionCode
                    occurredAt: string
                  } | null
                }
              | null
          }>
        }
        transactions: {
          __typename?: 'CardAccountTransactionCollection'
          all: {
            __typename?: 'CardAccountTransactionPage'
            items: Array<{
              __typename?: 'CardAccountTransaction'
              transactionId: string
              authorizationCode?: string | null
              transactionDatetime: string
              transactionPostedDatetime?: string | null
              transactionExpiryDatetime?: string | null
              amount: string
              description?: string | null
              status?: CardTransactionStatus | null
              merchant?: {
                __typename?: 'CardMerchant'
                id: string
                categoryCode?: string | null
                name?: string | null
                city?: string | null
                state?: string | null
              } | null
            }>
          }
        }
        statements: {
          __typename?: 'CardAccountStatementCollection'
          active?: {
            __typename?: 'CardAccountStatement'
            id: string
            balance?: string | null
            statementDate: string
            paymentDueDate?: string | null
          } | null
          mostRecent?: {
            __typename?: 'CardAccountStatement'
            id: string
            balance?: string | null
            statementDate: string
            paymentDueDate?: string | null
          } | null
          historical: {
            __typename?: 'CardAccountStatementPage'
            statements: Array<{
              __typename?: 'CardAccountStatement'
              id: string
              balance?: string | null
              statementDate: string
              paymentDueDate?: string | null
            }>
          }
        }
        agreements: {
          __typename?: 'CardAccountAgreementCollection'
          all: Array<{
            __typename?: 'CardAccountAgreement'
            id: string
            createdAt: string
            updatedAt: string
            type: CardAgreementType
            status: CardAgreementStatus
          }>
        }
        payments: {
          __typename?: 'CardPaymentCollection'
          all?: Array<{
            __typename?: 'Payment'
            id: string
            cardAccountId: string
            amount?: string | null
            status: CardPaymentStatus
            statusCode?: string | null
            statusReason?: string | null
            executeAt: string
            updatedAt: string
            createdAt: string
          }> | null
          pending?: Array<{
            __typename?: 'Payment'
            id: string
            cardAccountId: string
            amount?: string | null
            status: CardPaymentStatus
            statusCode?: string | null
            statusReason?: string | null
            executeAt: string
            updatedAt: string
            createdAt: string
          }> | null
          completed?: Array<{
            __typename?: 'Payment'
            id: string
            cardAccountId: string
            amount?: string | null
            status: CardPaymentStatus
            statusCode?: string | null
            statusReason?: string | null
            executeAt: string
            updatedAt: string
            createdAt: string
          }> | null
          schedule?: Array<{
            __typename?: 'AutomaticPaymentSchedule'
            id?: string | null
            paymentDate: string
          }> | null
          potential?: {
            __typename?: 'PotentialAutomaticPaymentCollection'
            frequency?: PaymentFrequency | null
            payments?: Array<{
              __typename?: 'AutomaticPaymentSchedule'
              id?: string | null
              paymentDate: string
            }> | null
          } | null
        }
        paymentMethods: {
          __typename?: 'CardPaymentMethodCollection'
          default?:
            | {
                __typename?: 'AchPaymentMethod'
                id: string
                code: PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                account?: {
                  __typename?: 'LinkedAccount'
                  id: string
                  mask: string
                  name?: string | null
                  preferredFundingSource?: boolean | null
                  type: LinkedAccountType
                  institution?: {__typename?: 'Institution'; name: string} | null
                } | null
              }
            | {__typename?: 'CheckPaymentMethod'}
            | {
                __typename?: 'DebitCardPaymentMethod'
                id: string
                code: PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                card: {
                  __typename?: 'LinkedDebitCard'
                  mask?: string | null
                  account?: {
                    __typename?: 'LinkedAccount'
                    id: string
                    mask: string
                    name?: string | null
                    preferredFundingSource?: boolean | null
                    type: LinkedAccountType
                    institution?: {__typename?: 'Institution'; name: string} | null
                  } | null
                }
              }
            | null
          all?: Array<
            | {
                __typename?: 'AchPaymentMethod'
                id: string
                code: PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                account?: {
                  __typename?: 'LinkedAccount'
                  id: string
                  mask: string
                  name?: string | null
                  preferredFundingSource?: boolean | null
                  type: LinkedAccountType
                  institution?: {__typename?: 'Institution'; name: string} | null
                } | null
              }
            | {__typename?: 'CheckPaymentMethod'}
            | {
                __typename?: 'DebitCardPaymentMethod'
                id: string
                code: PaymentMethodTypeCode
                bankingPaymentInstrumentId: string
                status:
                  | {
                      __typename?: 'ExpiredPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'InvalidPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'PendingPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'RemovedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SuspendedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'SystemPurgedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                  | {
                      __typename?: 'VerifiedPaymentMethodStatus'
                      code: PaymentMethodStatusCode
                      reason?: string | null
                    }
                card: {
                  __typename?: 'LinkedDebitCard'
                  mask?: string | null
                  account?: {
                    __typename?: 'LinkedAccount'
                    id: string
                    mask: string
                    name?: string | null
                    preferredFundingSource?: boolean | null
                    type: LinkedAccountType
                    institution?: {__typename?: 'Institution'; name: string} | null
                  } | null
                }
              }
          > | null
        }
        installmentPlans: {
          __typename?: 'CardInstallmentPlanCollection'
          eligibleForInstallments: boolean
          current?: {
            __typename?: 'CardAccountInstallmentPlan'
            id?: string | null
            frequency?: PaymentFrequency | null
            totalPaid?: string | null
            pendingAmount?: string | null
            remainingAmount?: string | null
            totalDue?: string | null
            totalOverdueAmount?: string | null
            numberOfOverdueInstallments?: number | null
            status:
              | {
                  __typename?: 'ActiveInstallmentPlanStatus'
                  code?: InstallmentPlanStatusCode | null
                  installmentsRemaining?: number | null
                }
              | {
                  __typename?: 'InactiveInstallmentPlanStatus'
                  code?: InstallmentPlanStatusCode | null
                }
            payments?: Array<{
              __typename?: 'InstallmentPayment'
              id?: string | null
              executeAt?: string | null
              amount?: string | null
              ordinal?: number | null
              statusCode?: PaymentStatusCode | null
              fees?: string | null
              totalDue?: string | null
              pendingAmount?: string | null
              paidAmount?: string | null
              paidOff?: boolean | null
              overdue?: boolean | null
              amountWithoutFees?: string | null
              dueAt?: string | null
              remainingDue?: string | null
            }> | null
            installments?: Array<{
              __typename?: 'InstallmentPayment'
              id?: string | null
              executeAt?: string | null
              amount?: string | null
              ordinal?: number | null
              statusCode?: PaymentStatusCode | null
              fees?: string | null
              totalDue?: string | null
              pendingAmount?: string | null
              paidAmount?: string | null
              paidOff?: boolean | null
              overdue?: boolean | null
              amountWithoutFees?: string | null
              dueAt?: string | null
              remainingDue?: string | null
            }> | null
          } | null
          potential?: {
            __typename?: 'CardAccountInstallmentPlan'
            id?: string | null
            frequency?: PaymentFrequency | null
            totalPaid?: string | null
            pendingAmount?: string | null
            remainingAmount?: string | null
            totalDue?: string | null
            totalOverdueAmount?: string | null
            numberOfOverdueInstallments?: number | null
            status:
              | {
                  __typename?: 'ActiveInstallmentPlanStatus'
                  code?: InstallmentPlanStatusCode | null
                  installmentsRemaining?: number | null
                }
              | {
                  __typename?: 'InactiveInstallmentPlanStatus'
                  code?: InstallmentPlanStatusCode | null
                }
            payments?: Array<{
              __typename?: 'InstallmentPayment'
              id?: string | null
              executeAt?: string | null
              amount?: string | null
              ordinal?: number | null
              statusCode?: PaymentStatusCode | null
              fees?: string | null
              totalDue?: string | null
              pendingAmount?: string | null
              paidAmount?: string | null
              paidOff?: boolean | null
              overdue?: boolean | null
              amountWithoutFees?: string | null
              dueAt?: string | null
              remainingDue?: string | null
            }> | null
            installments?: Array<{
              __typename?: 'InstallmentPayment'
              id?: string | null
              executeAt?: string | null
              amount?: string | null
              ordinal?: number | null
              statusCode?: PaymentStatusCode | null
              fees?: string | null
              totalDue?: string | null
              pendingAmount?: string | null
              paidAmount?: string | null
              paidOff?: boolean | null
              overdue?: boolean | null
              amountWithoutFees?: string | null
              dueAt?: string | null
              remainingDue?: string | null
            }> | null
          } | null
        }
        cardAccountType?: {
          __typename?: 'CardAccountType'
          id: string
          cardNetwork: CardNetwork
          monthlyMembershipFees: string
          maxBalanceLimit: string
        } | null
        fees?: {
          __typename?: 'CardAccountFees'
          potential?: Array<{
            __typename?: 'FeeSchedule'
            date?: string | null
            amount?: string | null
          } | null> | null
        } | null
      } | null
      offers: Array<{
        __typename?: 'CardAccountOffer'
        id: string
        balanceLimit: string
        network: CardNetwork
        monthlyMembershipFees: string
      }>
      offerInfo: {
        __typename?: 'CardAccountOfferInfo'
        offerAvailabilityStatus: CardAccountOfferAvailabilityStatus
        offers: Array<{__typename?: 'CardAccountOffer'; id: string}>
      }
    }
    userRequestedActions: {
      __typename?: 'UserRequestedActionCollection'
      pending?: Array<{
        __typename?: 'UserRequestedAction'
        id: string
        userId: string
        status: UserRequestedActionStatusCode
        actionType: UserRequestedActionTypesCode
        actionTitle: string
        actionBody: string
        optional: boolean
        createdAt: string
        updatedAt: string
        actionData: {
          __typename?: 'UserRequestedActionData'
          paymentInstrumentId?: string | null
          accountId?: string | null
        }
        requestContext: {__typename?: 'EntityDescriptor'; type: string; id: string}
      } | null> | null
      all?: Array<{
        __typename?: 'UserRequestedAction'
        id: string
        userId: string
        status: UserRequestedActionStatusCode
        actionType: UserRequestedActionTypesCode
        actionTitle: string
        actionBody: string
        optional: boolean
        createdAt: string
        updatedAt: string
        actionData: {
          __typename?: 'UserRequestedActionData'
          paymentInstrumentId?: string | null
          accountId?: string | null
        }
        requestContext: {__typename?: 'EntityDescriptor'; type: string; id: string}
      } | null> | null
    }
  } | null
}

export type CardNextManualReviewActionQueryQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type CardNextManualReviewActionQueryQuery = {
  __typename?: 'Query'
  cardNextManualReviewAction: {
    __typename?: 'CardManualReviewAction'
    userId?: string | null
    userFullName?: string | null
    manualReviewTask?: {
      __typename?: 'CardManualReviewTask'
      cardAccountId?: string | null
      assigneeId?: string | null
      taskId?: string | null
      reviewReasonCode?: CardManualReviewReasonCodes | null
      reviewReasonMessage?: string | null
    } | null
    cardAccount?: {
      __typename?: 'CardAccount'
      id: string
      createdAt: string
      cardNetwork: CardNetwork
      status:
        | {__typename: 'ActiveCardAccountStatus'}
        | {__typename: 'ApprovedCardAccountStatus'}
        | {__typename: 'CancelledCardAccountStatus'}
        | {__typename: 'DeactivatedCardAccountStatus'}
        | {__typename: 'ExpiredCardAccountStatus'}
        | {__typename: 'PendingCardAccountStatus'}
        | {__typename: 'RejectedCardAccountStatus'}
    } | null
  }
}

export type CardAccountApplicationResumeMutationVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type CardAccountApplicationResumeMutation = {
  __typename?: 'Mutation'
  cardAccountApplicationResume?: string | null
}

export type CardAccountApplicationSnoozeMutationVariables = Exact<{
  taskId: Scalars['ID']['input']
  snoozeForHours: Scalars['Int']['input']
}>

export type CardAccountApplicationSnoozeMutation = {
  __typename?: 'Mutation'
  cardAccountApplicationSnooze?: string | null
}

export type CardAccountApplicationRejectMutationVariables = Exact<{
  taskId: Scalars['ID']['input']
  reviewReasonCode?: InputMaybe<Scalars['Int']['input']>
}>

export type CardAccountApplicationRejectMutation = {
  __typename?: 'Mutation'
  cardAccountApplicationReject?: string | null
}

export type CardActivateMutationVariables = Exact<{
  cardAccountId: Scalars['ID']['input']
  cardId: Scalars['ID']['input']
}>

export type CardActivateMutation = {__typename?: 'Mutation'; cardActivate?: string | null}

export type CardAccountSuspendMutationVariables = Exact<{
  cardAccountId: Scalars['ID']['input']
  suspensionStatusReason: Scalars['String']['input']
}>

export type CardAccountSuspendMutation = {
  __typename?: 'Mutation'
  cardAccountSuspend?: string | null
}

export type CardAccountDeactivateMutationVariables = Exact<{
  cardAccountId: Scalars['ID']['input']
  deactivationStatusReason: Scalars['String']['input']
}>

export type CardAccountDeactivateMutation = {
  __typename?: 'Mutation'
  cardAccountDeactivate?: string | null
}

export type UnderwritingSimulatorGetTemplatesQueryQueryVariables = Exact<{[key: string]: never}>

export type UnderwritingSimulatorGetTemplatesQueryQuery = {
  __typename?: 'Query'
  underwritingSimulatorGetTemplates: {
    __typename?: 'UnderwritingSimulatorTemplatesCollection'
    templates: Array<{__typename?: 'UnderwritingSimulatorTemplate'; key: string; template: string}>
  }
}

export type LoanDocumentsMostRecentQueryVariables = Exact<{
  loanId: Scalars['ID']['input']
}>

export type LoanDocumentsMostRecentQuery = {
  __typename?: 'Query'
  loan: {
    __typename?: 'Loan'
    documents: {
      __typename?: 'LoanDocumentCollection'
      mostRecent: Array<{
        __typename?: 'LoanDocument'
        documentKey: string
        type: LoanDocumentType
        loanStatusAtCreation: LoanStatusCode
        createdAt: string
        updatedAt: string
        updatedBy: {__typename?: 'EntityDescriptor'; id: string; type: string}
      }>
    }
  }
}

export type LoanDocumentsHistoryQueryVariables = Exact<{
  loanId: Scalars['ID']['input']
}>

export type LoanDocumentsHistoryQuery = {
  __typename?: 'Query'
  loan: {
    __typename?: 'Loan'
    documents: {
      __typename?: 'LoanDocumentCollection'
      history: Array<{
        __typename?: 'LoanDocument'
        documentKey: string
        type: LoanDocumentType
        loanStatusAtCreation: LoanStatusCode
        createdAt: string
        updatedAt: string
        updatedBy: {__typename?: 'EntityDescriptor'; id: string; type: string}
      }>
    }
  }
}

export type TransformLoanDocumentKeyForDownloadMutationVariables = Exact<{
  documentKey: Scalars['String']['input']
  documentType: LoanDocumentType
}>

export type TransformLoanDocumentKeyForDownloadMutation = {
  __typename?: 'Mutation'
  transformLoanDocumentKeyForDownload?: {
    __typename?: 'PresignedUrl'
    url: string
    expiresAt: string
  } | null
}

export type UserModifyAddressComponentsMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  addressId?: InputMaybe<Scalars['ID']['input']>
  addressType: AddressType
  physicalAddress: ApplicantPhysicalAddressComponentsInput
}>

export type UserModifyAddressComponentsMutation = {
  __typename?: 'Mutation'
  userModifyAddressComponents?: {
    __typename?: 'Location'
    address?: {
      __typename?: 'Address'
      street1?: string | null
      street2?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      verified: boolean
    } | null
    gps?: {__typename?: 'GpsCoordinate'; latitude?: number | null; longitude: number} | null
    timeZone?: {__typename?: 'TimeZone'; id?: string | null} | null
  } | null
}

export const BankAccountsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BankAccountsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'bankAccounts'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'all'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'accountFilter'},
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {kind: 'Name', value: 'onlyAchEligible'},
                            value: {kind: 'BooleanValue', value: true},
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'officialName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'institution'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'achNumbersAvailable'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'debitCardNumbersAvailable'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'isLoginRequired'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankAccountsFragmentFragment, unknown>
export const CardTransactionsFragmentV2FragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardTransactionsFragmentV2'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountTransactionCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'startDate'}},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'items'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'authorizationCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionPostedDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionExpiryDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'merchant'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'categoryCode'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pending'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'startDate'}},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'items'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'authorizationCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionPostedDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionExpiryDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'merchant'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'categoryCode'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardTransactionsFragmentV2Fragment, unknown>
export const StatementFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'StatementFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatement'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statementDate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'paymentDueDate'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatementFragmentFragment, unknown>
export const CardStatementsFragmentV2FragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardStatementsFragmentV2'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountStatementCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'active'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'mostRecent'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'historical'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'startDate'}},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '12'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'statements'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'StatementFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatement'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statementDate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'paymentDueDate'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardStatementsFragmentV2Fragment, unknown>
export const CardPaymentsFragmentV2FragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardPaymentsFragmentV2'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardPaymentCollection'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'startDate'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'endDate'}},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
                {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pending'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
                {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'completed'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'startDate'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'endDate'}},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
                {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'schedule'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'numberOfPayments'},
                value: {kind: 'IntValue', value: '100'},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardPaymentsFragmentV2Fragment, unknown>
export const CardAccountMiscDataFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountMiscDataFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountMiscData'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'cohortPhase'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cohortUserType'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountMiscDataFragmentFragment, unknown>
export const LocationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LocationFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Location'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'address'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'street1'}},
                {kind: 'Field', name: {kind: 'Name', value: 'street2'}},
                {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                {kind: 'Field', name: {kind: 'Name', value: 'postalCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'country'}},
                {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'gps'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'latitude'}},
                {kind: 'Field', name: {kind: 'Name', value: 'longitude'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'timeZone'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationFragmentFragment, unknown>
export const UserProfileFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UserProfileFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'profile'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'name'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'middleName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'suffix'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'email'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'address'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'phone'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'birthDate'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'home'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LocationFragment'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'mailing'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LocationFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LocationFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Location'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'address'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'street1'}},
                {kind: 'Field', name: {kind: 'Name', value: 'street2'}},
                {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                {kind: 'Field', name: {kind: 'Name', value: 'postalCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'country'}},
                {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'gps'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'latitude'}},
                {kind: 'Field', name: {kind: 'Name', value: 'longitude'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'timeZone'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserProfileFragmentFragment, unknown>
export const UserFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UserFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'identification'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'ssn'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'identificationDocument'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'expirationDate'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'EnabledUserStatus'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DisabledUserStatus'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                      {
                        kind: 'Field',
                        alias: {kind: 'Name', value: 'disabledCategory'},
                        name: {kind: 'Name', value: 'category'},
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'FraudulentUserStatus'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                      {
                        kind: 'Field',
                        alias: {kind: 'Name', value: 'fraudulentCategory'},
                        name: {kind: 'Name', value: 'category'},
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragmentFragment, unknown>
export const LoanStatusFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanStatusFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanStatus'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BaseLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ActiveLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fundingSettledAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ApprovedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountApproved'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'counterOfferReasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PendingLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'RejectedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'reasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PaidOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ExpiredLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CancelledLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ReplacedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanStatusFragmentFragment, unknown>
export const LoanFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Loan'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LoanStatusFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'borrower'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'disbursementMethod'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'monthlyIncome'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'gross'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'startPeriod'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endPeriod'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amountRequested'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanStatusFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanStatus'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BaseLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ActiveLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fundingSettledAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ApprovedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountApproved'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'counterOfferReasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PendingLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'RejectedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'reasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PaidOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ExpiredLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CancelledLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ReplacedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanFragmentFragment, unknown>
export const UserLoansFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UserLoansFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loans'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'latestActionableLoan'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LoanFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanStatusFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanStatus'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BaseLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ActiveLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fundingSettledAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ApprovedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountApproved'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'counterOfferReasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PendingLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'RejectedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'reasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PaidOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ExpiredLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CancelledLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ReplacedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Loan'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LoanStatusFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'borrower'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'disbursementMethod'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'monthlyIncome'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'gross'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'startPeriod'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endPeriod'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amountRequested'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLoansFragmentFragment, unknown>
export const UraFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UraFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userRequestedActions'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pending'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionTitle'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionBody'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'optional'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'actionData'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'accountId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'paymentInstrumentId'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'ableToCompleteAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'requestContext'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UraFragmentFragment, unknown>
export const UraAllFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UraAllFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userRequestedActions'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pending'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionTitle'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionBody'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'actionData'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'paymentInstrumentId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'accountId'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'optional'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'requestContext'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'all'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionTitle'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionBody'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'actionData'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'paymentInstrumentId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'accountId'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'optional'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'requestContext'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UraAllFragmentFragment, unknown>
export const LoanPaymentFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanPaymentFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanPayment'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'loanId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'rescheduledDate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'fundingId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'method'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
          {kind: 'Field', name: {kind: 'Name', value: 'originalDate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'failureCount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
          {kind: 'Field', name: {kind: 'Name', value: 'interest'}},
          {kind: 'Field', name: {kind: 'Name', value: 'principal'}},
          {kind: 'Field', name: {kind: 'Name', value: 'executeEffectiveDateTime'}},
          {kind: 'Field', name: {kind: 'Name', value: 'paidOffDateTime'}},
          {kind: 'Field', name: {kind: 'Name', value: 'payingOffNow'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanPaymentFragmentFragment, unknown>
export const CardBalanceFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardBalanceFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountBalance'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'availableBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'availableSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpentAfterPayments'}},
          {kind: 'Field', name: {kind: 'Name', value: 'minimumPaymentDue'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardBalanceFragmentFragment, unknown>
export const CardStatusFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardStatusFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatuses'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'PendingCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'RejectedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'eligibleToReapplyAfter'}},
                {kind: 'Field', name: {kind: 'Name', value: 'rejectionReasonCodes'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ApprovedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CancelledCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ExpiredCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'eligibleToReapplyAfter'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ActiveCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'autopayFrequency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountOverdueStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'daysUntilDelinquent'}},
                {kind: 'Field', name: {kind: 'Name', value: 'delinquentNumberOfDays'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOverdue'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overduePaymentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overdueInstallmentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'chargedOffStatus'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'restriction'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'CardAccountLockedRestriction'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'expiresAt'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'CardAccountSuspendedRestriction'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'balance'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardBalanceFragment'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'DeactivatedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountOverdueStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reasonCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'delinquentNumberOfDays'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'balance'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardBalanceFragment'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'chargedOffStatus'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overduePaymentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overdueStatementInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardBalanceFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountBalance'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'availableBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'availableSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpentAfterPayments'}},
          {kind: 'Field', name: {kind: 'Name', value: 'minimumPaymentDue'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardStatusFragmentFragment, unknown>
export const CardsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardCollection'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'issued'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'states'},
                value: {
                  kind: 'ListValue',
                  values: [
                    {kind: 'EnumValue', value: 'PENDING'},
                    {kind: 'EnumValue', value: 'ACTIVE'},
                    {kind: 'EnumValue', value: 'SUSPENDED'},
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'externalId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'PendingCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'restriction'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CardSuspendedRestriction'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'SuspendedCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'restriction'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CardSuspendedRestriction'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'DeactivatedCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardsFragmentFragment, unknown>
export const CardTransactionsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardTransactionsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountTransactionCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'items'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'authorizationCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionPostedDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionExpiryDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'merchant'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'categoryCode'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardTransactionsFragmentFragment, unknown>
export const CardStatementsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardStatementsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountStatementCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'active'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'mostRecent'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'historical'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '12'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'statements'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'StatementFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatement'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statementDate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'paymentDueDate'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardStatementsFragmentFragment, unknown>
export const CardAgreementsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAgreementsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountAgreementCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAgreementsFragmentFragment, unknown>
export const PaymentsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'PaymentsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Payment'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
          {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentsFragmentFragment, unknown>
export const CardPaymentsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardPaymentsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardPaymentCollection'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pending'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'completed'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'schedule'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'numberOfPayments'},
                value: {kind: 'IntValue', value: '100'},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'PaymentsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Payment'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
          {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardPaymentsFragmentFragment, unknown>
export const AchPaymentMethodFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AchPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'account'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'institution'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AchPaymentMethodFragmentFragment, unknown>
export const DebitCardPaymentMethodFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DebitCardPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'card'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'account'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'institution'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DebitCardPaymentMethodFragmentFragment, unknown>
export const CardPaymentMethodsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardPaymentMethodsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardPaymentMethodCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'default'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'AchPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'AchPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AchPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'account'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'institution'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DebitCardPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'card'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'account'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'institution'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardPaymentMethodsFragmentFragment, unknown>
export const CardInstallmentPlansFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardInstallmentPlansFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardInstallmentPlanCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'current'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'installmentsRemaining'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'InactiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'installments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'remainingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOverdueAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'numberOfOverdueInstallments'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'installmentsRemaining'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'InactiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'installments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'remainingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOverdueAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'numberOfOverdueInstallments'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'eligibleForInstallments'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardInstallmentPlansFragmentFragment, unknown>
export const CardAccountTypeFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountTypeFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountType'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cardNetwork'}},
          {kind: 'Field', name: {kind: 'Name', value: 'monthlyMembershipFees'}},
          {kind: 'Field', name: {kind: 'Name', value: 'maxBalanceLimit'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountTypeFragmentFragment, unknown>
export const CardAccountFeesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountFeesFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountFees'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'date'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountFeesFragmentFragment, unknown>
export const CardAccountOffersFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountOffersFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountOffer'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balanceLimit'}},
          {kind: 'Field', name: {kind: 'Name', value: 'network'}},
          {kind: 'Field', name: {kind: 'Name', value: 'monthlyMembershipFees'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountOffersFragmentFragment, unknown>
export const CardAccountOfferInfoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountOfferInfoFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountOfferInfo'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'offerAvailabilityStatus'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'offers'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountOfferInfoFragmentFragment, unknown>
export const CardAccountsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccounts'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'active'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'creditLimit'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'cardNetwork'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'balance'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardBalanceFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'status'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardStatusFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'cards'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardsFragment'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transactions'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardTransactionsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'statements'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardStatementsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'agreements'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardAgreementsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'payments'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardPaymentsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentMethods'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardPaymentMethodsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'installmentPlans'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardInstallmentPlansFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'cardAccountType'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardAccountTypeFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'fees'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardAccountFeesFragment'},
                            },
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'autopayEnabled'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'offers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'CardAccountOffersFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'offerInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'CardAccountOfferInfoFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardBalanceFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountBalance'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'availableBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'availableSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpentAfterPayments'}},
          {kind: 'Field', name: {kind: 'Name', value: 'minimumPaymentDue'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'StatementFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatement'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statementDate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'paymentDueDate'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'PaymentsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Payment'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
          {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AchPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'account'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'institution'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DebitCardPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'card'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'account'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'institution'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardStatusFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatuses'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'PendingCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'RejectedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'eligibleToReapplyAfter'}},
                {kind: 'Field', name: {kind: 'Name', value: 'rejectionReasonCodes'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ApprovedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CancelledCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ExpiredCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'eligibleToReapplyAfter'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ActiveCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'autopayFrequency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountOverdueStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'daysUntilDelinquent'}},
                {kind: 'Field', name: {kind: 'Name', value: 'delinquentNumberOfDays'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOverdue'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overduePaymentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overdueInstallmentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'chargedOffStatus'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'restriction'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'CardAccountLockedRestriction'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'expiresAt'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'CardAccountSuspendedRestriction'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'balance'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardBalanceFragment'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'DeactivatedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountOverdueStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reasonCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'delinquentNumberOfDays'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'balance'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardBalanceFragment'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'chargedOffStatus'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overduePaymentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overdueStatementInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardCollection'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'issued'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'states'},
                value: {
                  kind: 'ListValue',
                  values: [
                    {kind: 'EnumValue', value: 'PENDING'},
                    {kind: 'EnumValue', value: 'ACTIVE'},
                    {kind: 'EnumValue', value: 'SUSPENDED'},
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'externalId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'PendingCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'restriction'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CardSuspendedRestriction'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'SuspendedCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'restriction'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CardSuspendedRestriction'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'DeactivatedCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardTransactionsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountTransactionCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'items'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'authorizationCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionPostedDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionExpiryDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'merchant'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'categoryCode'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardStatementsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountStatementCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'active'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'mostRecent'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'historical'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '12'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'statements'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAgreementsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountAgreementCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardPaymentsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardPaymentCollection'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pending'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'completed'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'schedule'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'numberOfPayments'},
                value: {kind: 'IntValue', value: '100'},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardPaymentMethodsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardPaymentMethodCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'default'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'AchPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'AchPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardInstallmentPlansFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardInstallmentPlanCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'current'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'installmentsRemaining'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'InactiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'installments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'remainingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOverdueAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'numberOfOverdueInstallments'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'installmentsRemaining'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'InactiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'installments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'remainingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOverdueAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'numberOfOverdueInstallments'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'eligibleForInstallments'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountTypeFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountType'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cardNetwork'}},
          {kind: 'Field', name: {kind: 'Name', value: 'monthlyMembershipFees'}},
          {kind: 'Field', name: {kind: 'Name', value: 'maxBalanceLimit'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountFeesFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountFees'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'date'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountOffersFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountOffer'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balanceLimit'}},
          {kind: 'Field', name: {kind: 'Name', value: 'network'}},
          {kind: 'Field', name: {kind: 'Name', value: 'monthlyMembershipFees'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountOfferInfoFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountOfferInfo'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'offerAvailabilityStatus'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'offers'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountsFragmentFragment, unknown>
export const OfferFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'OfferFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Offer'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'annualFee'}},
          {kind: 'Field', name: {kind: 'Name', value: 'approvedAmount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'apr'}},
          {kind: 'Field', name: {kind: 'Name', value: 'interestRate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'monthlyPayment'}},
          {kind: 'Field', name: {kind: 'Name', value: 'months'}},
          {kind: 'Field', name: {kind: 'Name', value: 'originationFee'}},
          {kind: 'Field', name: {kind: 'Name', value: 'partnerId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'title'}},
          {kind: 'Field', name: {kind: 'Name', value: 'url'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfferFragmentFragment, unknown>
export const CardsPaymentMethodsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardsPaymentMethodsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'paymentMethods'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardEligible'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'AchPaymentMethod'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AchPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'account'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'institution'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DebitCardPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'card'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'account'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'institution'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardsPaymentMethodsFragmentFragment, unknown>
export const UserQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UserQuery'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'user'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'userId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'UserFragment'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'UserProfileFragment'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'UserLoansFragment'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardAccountsFragment'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'UraAllFragment'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LocationFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Location'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'address'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'street1'}},
                {kind: 'Field', name: {kind: 'Name', value: 'street2'}},
                {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                {kind: 'Field', name: {kind: 'Name', value: 'postalCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'country'}},
                {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'gps'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'latitude'}},
                {kind: 'Field', name: {kind: 'Name', value: 'longitude'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'timeZone'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanStatusFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanStatus'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BaseLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ActiveLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fundingSettledAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ApprovedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountApproved'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'counterOfferReasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PendingLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'RejectedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'reasonCodes'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'codes'}}],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PaidOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ChargedOffLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountBorrowed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOutstanding'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ExpiredLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CancelledLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'ReplacedLoanStatus'}},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LoanFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Loan'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LoanStatusFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'borrower'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'disbursementMethod'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'monthlyIncome'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'gross'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'startPeriod'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endPeriod'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amountRequested'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardBalanceFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountBalance'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'availableBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'availableSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerBalance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpent'}},
          {kind: 'Field', name: {kind: 'Name', value: 'ledgerSpentAfterPayments'}},
          {kind: 'Field', name: {kind: 'Name', value: 'minimumPaymentDue'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardStatusFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatuses'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'PendingCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'RejectedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'eligibleToReapplyAfter'}},
                {kind: 'Field', name: {kind: 'Name', value: 'rejectionReasonCodes'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ApprovedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'CancelledCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ExpiredCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'eligibleToReapplyAfter'}},
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ActiveCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'autopayFrequency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountOverdueStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'daysUntilDelinquent'}},
                {kind: 'Field', name: {kind: 'Name', value: 'delinquentNumberOfDays'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amountOverdue'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overduePaymentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overdueInstallmentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'chargedOffStatus'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'restriction'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'CardAccountLockedRestriction'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'expiresAt'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'CardAccountSuspendedRestriction'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'balance'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardBalanceFragment'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'DeactivatedCardAccountStatus'},
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'subStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cardAccountOverdueStatus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reasonCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'delinquentNumberOfDays'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'balance'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardBalanceFragment'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'chargedOffStatus'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overduePaymentInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'overdueStatementInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDueAt'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardCollection'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'issued'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'states'},
                value: {
                  kind: 'ListValue',
                  values: [
                    {kind: 'EnumValue', value: 'PENDING'},
                    {kind: 'EnumValue', value: 'ACTIVE'},
                    {kind: 'EnumValue', value: 'SUSPENDED'},
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'externalId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'PendingCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'restriction'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CardSuspendedRestriction'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'SuspendedCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'restriction'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CardSuspendedRestriction'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'DeactivatedCardStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'occurredAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardTransactionsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountTransactionCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'items'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'authorizationCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionPostedDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'transactionExpiryDatetime'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'merchant'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'categoryCode'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'StatementFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountStatement'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balance'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statementDate'}},
          {kind: 'Field', name: {kind: 'Name', value: 'paymentDueDate'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardStatementsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountStatementCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'active'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'mostRecent'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'historical'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '12'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'statements'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'StatementFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAgreementsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardAccountAgreementCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'PaymentsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Payment'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
          {kind: 'Field', name: {kind: 'Name', value: 'statusReason'}},
          {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardPaymentsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardPaymentCollection'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pending'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'completed'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dateRange'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'start'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'end'},
                      value: {kind: 'StringValue', value: '', block: false},
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageSelector'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'cursor'},
                      value: {kind: 'NullValue'},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'count'},
                      value: {kind: 'IntValue', value: '100'},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'PaymentsFragment'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'schedule'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'numberOfPayments'},
                value: {kind: 'IntValue', value: '100'},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paymentDate'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AchPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'account'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'institution'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DebitCardPaymentMethod'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'code'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'bankingPaymentInstrumentId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'card'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'account'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'institution'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'name'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardPaymentMethodsFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardPaymentMethodCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'default'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'AchPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'all'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'AchPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'AchPaymentMethodFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DebitCardPaymentMethod'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'DebitCardPaymentMethodFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardInstallmentPlansFragment'},
      typeCondition: {
        kind: 'NamedType',
        name: {kind: 'Name', value: 'CardInstallmentPlanCollection'},
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'current'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'installmentsRemaining'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'InactiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'installments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'remainingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOverdueAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'numberOfOverdueInstallments'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'frequency'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'status'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'ActiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'installmentsRemaining'}},
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {kind: 'Name', value: 'InactiveInstallmentPlanStatus'},
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'payments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'installments'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'executeAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'ordinal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'statusCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'paidOff'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'overdue'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'amountWithoutFees'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dueAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'remainingDue'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'totalPaid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pendingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'remainingAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalDue'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOverdueAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'numberOfOverdueInstallments'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'eligibleForInstallments'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountTypeFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountType'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'cardNetwork'}},
          {kind: 'Field', name: {kind: 'Name', value: 'monthlyMembershipFees'}},
          {kind: 'Field', name: {kind: 'Name', value: 'maxBalanceLimit'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountFeesFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountFees'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'potential'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'date'}},
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountOffersFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountOffer'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'balanceLimit'}},
          {kind: 'Field', name: {kind: 'Name', value: 'network'}},
          {kind: 'Field', name: {kind: 'Name', value: 'monthlyMembershipFees'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountOfferInfoFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CardAccountOfferInfo'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'offerAvailabilityStatus'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'offers'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UserFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'identification'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'ssn'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'identificationDocument'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'expirationDate'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'status'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'EnabledUserStatus'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'code'}}],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'DisabledUserStatus'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                      {
                        kind: 'Field',
                        alias: {kind: 'Name', value: 'disabledCategory'},
                        name: {kind: 'Name', value: 'category'},
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {kind: 'Name', value: 'FraudulentUserStatus'},
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                      {
                        kind: 'Field',
                        alias: {kind: 'Name', value: 'fraudulentCategory'},
                        name: {kind: 'Name', value: 'category'},
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'reason'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UserProfileFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'profile'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'name'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'middleName'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'suffix'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'email'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'address'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'phone'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'number'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'birthDate'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'home'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LocationFragment'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'mailing'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LocationFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UserLoansFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loans'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'latestActionableLoan'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LoanFragment'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'CardAccountsFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccounts'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'active'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'creditLimit'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'cardNetwork'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'balance'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardBalanceFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'status'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardStatusFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'cards'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'FragmentSpread', name: {kind: 'Name', value: 'CardsFragment'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transactions'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardTransactionsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'statements'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardStatementsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'agreements'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardAgreementsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'payments'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardPaymentsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'paymentMethods'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardPaymentMethodsFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'installmentPlans'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardInstallmentPlansFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'cardAccountType'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardAccountTypeFragment'},
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'fees'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {kind: 'Name', value: 'CardAccountFeesFragment'},
                            },
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'autopayEnabled'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'offers'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'CardAccountOffersFragment'},
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'offerInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {kind: 'Name', value: 'CardAccountOfferInfoFragment'},
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'UraAllFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userRequestedActions'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pending'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionTitle'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionBody'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'actionData'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'paymentInstrumentId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'accountId'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'optional'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'requestContext'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'all'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionType'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionTitle'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'actionBody'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'actionData'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'paymentInstrumentId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'accountId'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'optional'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'requestContext'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserQueryQuery, UserQueryQueryVariables>
export const CardNextManualReviewActionQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CardNextManualReviewActionQuery'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardNextManualReviewAction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'userId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'manualReviewTask'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'cardAccountId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'assigneeId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'taskId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'reviewReasonCode'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'reviewReasonMessage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'cardAccount'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'cardNetwork'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'status'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: '__typename'}}],
                        },
                      },
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'userFullName'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardNextManualReviewActionQueryQuery,
  CardNextManualReviewActionQueryQueryVariables
>
export const CardAccountApplicationResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cardAccountApplicationResume'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'taskId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountApplicationResume'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'taskId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'taskId'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardAccountApplicationResumeMutation,
  CardAccountApplicationResumeMutationVariables
>
export const CardAccountApplicationSnoozeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cardAccountApplicationSnooze'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'taskId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'snoozeForHours'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountApplicationSnooze'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'taskId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'taskId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'snoozeForHours'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'snoozeForHours'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardAccountApplicationSnoozeMutation,
  CardAccountApplicationSnoozeMutationVariables
>
export const CardAccountApplicationRejectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cardAccountApplicationReject'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'taskId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'reviewReasonCode'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountApplicationReject'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'taskId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'taskId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reviewReasonCode'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'reviewReasonCode'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CardAccountApplicationRejectMutation,
  CardAccountApplicationRejectMutationVariables
>
export const CardActivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cardActivate'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardActivate'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardId'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardActivateMutation, CardActivateMutationVariables>
export const CardAccountSuspendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cardAccountSuspend'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'suspensionStatusReason'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountSuspend'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'suspensionStatusReason'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'suspensionStatusReason'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountSuspendMutation, CardAccountSuspendMutationVariables>
export const CardAccountDeactivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'cardAccountDeactivate'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'deactivationStatusReason'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cardAccountDeactivate'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cardAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cardAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'deactivationStatusReason'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'deactivationStatusReason'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardAccountDeactivateMutation, CardAccountDeactivateMutationVariables>
export const UnderwritingSimulatorGetTemplatesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UnderwritingSimulatorGetTemplatesQuery'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'underwritingSimulatorGetTemplates'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'templates'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'key'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'template'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnderwritingSimulatorGetTemplatesQueryQuery,
  UnderwritingSimulatorGetTemplatesQueryQueryVariables
>
export const LoanDocumentsMostRecentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanDocumentsMostRecent'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loan'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'loanId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'documents'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'mostRecent'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'documentKey'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'loanStatusAtCreation'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'updatedBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanDocumentsMostRecentQuery, LoanDocumentsMostRecentQueryVariables>
export const LoanDocumentsHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanDocumentsHistory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loan'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'loanId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'loanId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'documents'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'history'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'documentKey'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'loanStatusAtCreation'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'updatedBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanDocumentsHistoryQuery, LoanDocumentsHistoryQueryVariables>
export const TransformLoanDocumentKeyForDownloadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'TransformLoanDocumentKeyForDownload'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'documentKey'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'documentType'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoanDocumentType'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'transformLoanDocumentKeyForDownload'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'documentKey'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'documentKey'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'documentType'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'url'}},
                {kind: 'Field', name: {kind: 'Name', value: 'expiresAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransformLoanDocumentKeyForDownloadMutation,
  TransformLoanDocumentKeyForDownloadMutationVariables
>
export const UserModifyAddressComponentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserModifyAddressComponents'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'addressId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'addressType'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'AddressType'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'physicalAddress'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ApplicantPhysicalAddressComponentsInput'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userModifyAddressComponents'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'userId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'userId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'addressId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'addressId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'addressType'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'addressType'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'physicalAddress'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'physicalAddress'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'LocationFragment'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'LocationFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Location'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'address'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'street1'}},
                {kind: 'Field', name: {kind: 'Name', value: 'street2'}},
                {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                {kind: 'Field', name: {kind: 'Name', value: 'postalCode'}},
                {kind: 'Field', name: {kind: 'Name', value: 'country'}},
                {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'gps'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'latitude'}},
                {kind: 'Field', name: {kind: 'Name', value: 'longitude'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'timeZone'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserModifyAddressComponentsMutation,
  UserModifyAddressComponentsMutationVariables
>
