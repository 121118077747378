import React, {FC, useEffect} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {AppEventName} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {WorkflowsErrorType} from 'src/workflows/types'

export type WorkflowErrorAnalytics = {
  errorEvent: AppEventName
  errorContactUsEvent: AppEventName
}

type WorkflowErrorProps = WorkflowsErrorType & WorkflowErrorAnalytics

export const WorkflowError: FC<WorkflowErrorProps> = (props) => {
  const {error, refetch: handleOnRefetch, errorEvent, errorContactUsEvent} = props
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()

  useEffect(() => {
    TrackAppEvent(errorEvent, AppEvents.Category.Workflows, {
      error: error.message,
    })
  }, [error.message, errorEvent])

  const handleOnContactUs = (): void => {
    TrackAppEvent(errorContactUsEvent, AppEvents.Category.Workflows)
    openContactUsForm(navigation)
  }

  return <ErrorTemplate onRetry={handleOnRefetch} onContactUs={handleOnContactUs} />
}
