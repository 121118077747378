import React, {FC, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {CardTileBase} from 'src/products/card/LoanDash/CardTileBase'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import LargeArrow from 'src/assets/icons/LargeArrow.svg'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {CardApplyTileProps} from 'src/products/card/LoanDash/CardApplyTile'
import {useResetOneTimeMessages} from 'src/products/card/LoanDash/useResetOneTimeMessages'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'

export const CardReapplyTile: FC<CardApplyTileProps> = ({
  navigation,
  loading,
  creditLimit,
  tileMarginHorizontal,
  tileRadius,
}) => {
  usePageViewedAnalytics({
    eventName: CardEvents.reapply_dashboard_card_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation('CardDashboard')
  const resetOneTimeMessages = useResetOneTimeMessages()

  const handleOnPress = useCallback(async () => {
    TrackAppEvent(CardEvents.reapply_dashboard_card_cta, AppEvents.Category.Card)
    // Make sure this user has been added to cards before entering onboarding
    await resetOneTimeMessages({enableCards: true})
    navigation.navigate('ProductHub', {isInNonInitialOnboardingFlow: true})
  }, [navigation, resetOneTimeMessages])

  return (
    <CardTileBase
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      titleText="CardReapplyTitle"
      content={t('CardReapplyContent', {creditLimit: convertToDollarAmt(creditLimit, false)})}
      buttonText="CardReapplyButton"
      img={
        <PFSvg
          SvgUri={LargeArrow}
          svgProps={{
            height: 32,
            width: 33,
          }}
        />
      }
      onPress={handleOnPress}
      loading={loading}
      pillText="Beta"
    />
  )
}
