import React, {FC, useCallback, useEffect, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useFocusEffect} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {UserGenerateZendeskTokenDocument} from 'src/products/general/ZendeskHelpCenter/queries/UserGenerateZendeskToken.gqls'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import List from 'src/designSystem/components/molecules/List/List'
import {isDev} from 'src/lib/utils/environmentUtil'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {ZendeskSSOFormWeb} from 'src/products/general/ZendeskHelpCenter/ZendeskSSOFormWeb'
import {usePfSelector} from 'src/store/utils'
import {isEmailVerifiedSelector} from 'src/lib/user/selector'
import {UnverifiedUserContactUs} from 'src/products/general/ZendeskHelpCenter/UnverifiedUserContactUs'
import {logErrorAndShowException} from 'src/lib/errors'

type Props = StackScreenProps<MainStackParamList, 'ZendeskHelpCenter'>

const zendeskAuthenticationUrlProduction = `https://possiblefinance.zendesk.com/access/jwt?return_to=https://possiblefinance.zendesk.com/hc/en-us/requests/new`
const zendeskAuthenticationUrlSandbox = `https://possiblefinance1722620762.zendesk.com/access/jwt?return_to=https://possiblefinance1722620762.zendesk.com/hc/en-us/`

const Zendesk: FC<Props> = (props) => {
  const {navigation} = props
  const [generateZdToken, {loading: isLoadingZdToken, data: zdTokenData}] = useCassandraMutation(
    UserGenerateZendeskTokenDocument,
  )
  const [zendeskUrl, setZendeskUrl] = useState<string>(zendeskAuthenticationUrlProduction)
  const [isLoadingURL, setIsLoadingURL] = useState<boolean>(false)
  const [shouldRenderForm, setShouldRenderForm] = useState<boolean>(false)

  useFocusEffect(
    useCallback(() => {
      sendAnalyticEvent(ampli.helpCenterRedirectViewed.bind(ampli))
      void generateZdToken()
    }, [generateZdToken]),
  )

  useEffect(() => {
    const getEnv: () => Promise<void> = async () => {
      setIsLoadingURL(true)
      if ((await isDev()) === true) {
        setZendeskUrl(zendeskAuthenticationUrlSandbox)
      }
      setIsLoadingURL(false)
    }
    void getEnv()
  }, [])

  // If the user goes to zendesk and then comes back to the app, we need to re-render the form
  const reRenderForm = async (): Promise<void> => {
    setIsLoadingURL(true)
    setShouldRenderForm(false)
    await generateZdToken()
    setIsLoadingURL(false)
    setShouldRenderForm(true)
  }

  const renderZendesk: () => void = () => {
    // On web we just submit the form to launch the page in a new tab
    // On mobile we open the zendesk page in a webview
    if (isDeviceWeb()) {
      if (shouldRenderForm) {
        void reRenderForm()
      } else {
        setShouldRenderForm(true)
      }
    } else {
      if (zdTokenData?.userGenerateZendeskToken.token) {
        navigation.push('ZendeskSSOForm', {
          token: zdTokenData?.userGenerateZendeskToken.token,
          zendeskUrl,
        })
      } else {
        void logErrorAndShowException(new Error('No token found, unable to open zendesk'))
      }
    }
  }

  const getZendeskForm = useCallback(() => {
    if (shouldRenderForm) {
      return (
        <ZendeskSSOFormWeb
          token={zdTokenData?.userGenerateZendeskToken.token}
          zendeskUrl={zendeskUrl}
        />
      )
    } else {
      return null
    }
  }, [zdTokenData, zendeskUrl, shouldRenderForm])

  const {t} = useTranslation(['ZendeskHelpCenter', 'Common'])

  const action = {
    onPress: renderZendesk,
    text: t('Common:Continue'),
    disabled: isLoadingZdToken || isLoadingURL,
  }

  const listItems = [
    {
      title: t('ListTitle1'),
      text: t('ListText1'),
      name: 'search',
    },
    {
      title: t('ListTitle2'),
      text: t('ListText2'),
      name: 'message',
    },
    {
      title: t('ListTitle3'),
      text: t('ListText3'),
      name: 'chat',
    },
  ]

  return (
    <Page
      variant="generic"
      buttonProps={buttonLockupProperties(action)}
      title={t('HelpCenterTitle')}
      testID="ZendeskHelpCenterPage"
      description={t('HelpCenterDescription')}
      smallTopGap
    >
      <List variant={'icon'} items={listItems} />
      {getZendeskForm()}
    </Page>
  )
}

const ZendeskHelpCenter: FC<Props> = (props) => {
  const isEmailVerified = usePfSelector(isEmailVerifiedSelector)
  if (isEmailVerified) {
    return <Zendesk {...props} />
  } else {
    return <UnverifiedUserContactUs isVisible={!isEmailVerified} />
  }
}

export {ZendeskHelpCenter}
