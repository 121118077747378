import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import MoneyRoundAbout from 'src/assets/illustrations/MoneyRoundAbout.svg'

/**
 * A tile to inform the user that their loan has been submitted.
 * @example <LoanDecisioningTile />
 */

export const LoanDecisioningTile: React.FC = () => {
  const {t} = useTranslation('DashboardLoanDecisioningTile')
  return (
    <BasicTile padding="medium">
      <Box>
        <Box fill="horizontal" align="start" marginBottom="tiny">
          <PFSvgContain svg={MoneyRoundAbout} width={92} />
        </Box>

        <Box gap="small" marginBottom="medium">
          <PFText testID="Loan-Decisioning-Tile-Application-Submitted" variant="h3">
            {t('ApplicationSubmitted')}
          </PFText>
          <PFText testID="Loan-Decisioning-Tile-Take-Up-Hours" variant="p">
            {t('ThisMayTakeUpToACoupleHours')}
          </PFText>
        </Box>

        <Box gap="tiny">
          <PFText testID="Loan-Decisioning-Tile-If-Approved-Text" variant="p_semibold">
            {t('IfApprovedBoldText')}
          </PFText>
          <PFText testID="Loan-Decisioning-Tile-If-Approved1" variant="p">
            • {t('IfApproved1')}
          </PFText>
          <PFText testID="Loan-Decisioning-Tile-If-Approved2" variant="p">
            • {t('IfApproved2')}
          </PFText>
          <PFText testID="Loan-Decisioning-Tile-If-Approved3" variant="p">
            • {t('IfApproved3')}
          </PFText>
        </Box>
        <Box marginTop="medium">
          <PFText testID="Loan-Decisioning-Tile-Offer-Highest-Amount" variant="p">
            {t('OfferHighestAmount')}
          </PFText>
        </Box>
      </Box>
    </BasicTile>
  )
}
