import React, {FC, useCallback, useMemo, useState} from 'react'
import {DateData as RnCalendarsDateData} from 'react-native-calendars'
import {MarkedDates} from 'react-native-calendars/src/types'
import {useTranslation} from 'react-i18next'
import moment, {Moment} from 'moment-timezone'
import {ApolloError} from '@apollo/client'

import {
  LoanPaymentInput,
  LoanSetPaymentDatesInput,
  NextAvailablePaymentDateQuery,
} from 'src/cassandra'
import {
  LoanSetPaymentDateMutationDocument,
  ReschedulePaymentDocument,
} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePayment.gqls'
import {
  calendarDateFormat,
  FeasibleDateCode,
  getAdjustedDateTimeFromDay,
  getFeasibleDateCode,
  getNotFeasibleMarkedDates,
  getRescheduleOverlayContent,
  ReschedulePaymentAlertOverlayContentProps,
  trackRescheduleOverlayShown,
  warnAndAllowContinue,
} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePayment.utils'
import {allowedEndDay} from 'src/lib/loans/payments'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {getNextAvailableSettlementDate} from 'src/api/actions/loans/loanActions'
import {logErrorAndShowException} from 'src/lib/errors'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {RescheduleLoanPaymentItemType} from 'src/products/loans/Reschedule/Reschedule.types'
import {ReschedulePaymentAlertOverlayProps} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePaymentAlertOverlay/ReschedulePaymentAlertOverlay'
import {ReschedulePaymentTemplate} from 'src/products/loans/Reschedule/ReschedulePayment/ReschedulePaymentTemplate/ReschedulePaymentTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import AppEvents from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'

export type ReschedulePaymentGQLContainerProps = {
  payment: RescheduleLoanPaymentItemType
  onContinue: () => void
  onContactUs: () => void
}

const ReschedulePaymentGQLContainer: FC<ReschedulePaymentGQLContainerProps> = (props) => {
  const {payment, onContinue, onContactUs: handleOnContactUs} = props
  const {t} = useTranslation(['Reschedule', 'Common'])

  const paymentAmount = payment.amount

  const [nextAvailableSettlementDate, setNextAvailableSettlementDate] =
    useState<NextAvailablePaymentDateQuery['getNextAvailablePaymentDate']>()
  const [hasDateChanged, setHasDateChanged] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showRescheduleOverlay, setShowRescheduleOverlay] = useState(false)
  const [showReschedulePaymentErrorOverlay, setShowReschedulePaymentErrorOverlay] = useState(false)
  /* 
  the default for this value is FeasibleDateCode.Allowed because it is assumed that the current payment date will always have been allowed at the time it was set
  */
  const [feasibleDateCode, setFeasibleDateCode] = useState<FeasibleDateCode>(
    FeasibleDateCode.Allowed,
  )

  usePageViewedAnalytics({
    eventName: AppEvents.Name.reschedule_payments_calendar_screen_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
    eventArgs: {value: payment.ordinal},
  })

  const [loanSetPaymentDates] = useCassandraMutation(LoanSetPaymentDateMutationDocument, {
    refetchQueries: ['SelectPaymentToReschedule'],
  })

  const {
    selectedData,
    error: queryError,
    loading: isLoadingQuery,
  } = useCassandraQuery(
    ReschedulePaymentDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        Log.error(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'ReschedulePayment query error',
        )
      },
      onCompleted: (data) => {
        const loanId = data.me.loans.latestActionableLoan?.id
        const timeZoneId = selectedData?.timeZoneId
        const timeNow = moment().tz(timeZoneId ?? 'America/Los_Angeles')

        if (!loanId) {
          Log.warn('No loanId found in ReschedulePaymentGQLContainer')
          return
        }
        void getNextAvailableSettlementDate(loanId, timeNow.format(), false)
          .then((response) => {
            setNextAvailableSettlementDate(response)
          })
          .catch((e) => {
            void logErrorAndShowException(e)
          })
      },
    },
    (data) => {
      let creditImpactDate: string | null | undefined = undefined
      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ActiveLoanAggregateStatus'
      ) {
        creditImpactDate = data.me.loans.latestActionableLoan.aggregateStatus.creditImpactDate
      }
      return {
        loanId: data.me.loans.latestActionableLoan?.id,
        creditImpactDate: creditImpactDate,
        timeZoneId: data.me.profile?.home?.timeZone?.id,
      }
    },
  )

  const loanId = selectedData?.loanId
  const timeZoneId = selectedData?.timeZoneId ?? 'America/Los_Angeles'
  const todayDate = moment().tz(timeZoneId)

  // this removes the 'Z' at the end -- it's a hack and shouldn't be needed, but here we are
  const creditImpactDate =
    moment(selectedData?.creditImpactDate?.slice(0, -1)).tz(timeZoneId) ?? undefined

  const originalPaymentDate = moment(payment.originalDate).tz(timeZoneId)
  const originalPaymentDateText = originalPaymentDate.format('MMM Do')

  const nextAvailSettlementDate = moment(
    nextAvailableSettlementDate?.adjustedSettlementDatetime,
  ).tz(timeZoneId)
  const firstAllowedCalendarDate = nextAvailSettlementDate.format(calendarDateFormat)

  const finalAllowedDate = allowedEndDay(originalPaymentDate, payment.statusCode)
  const finalAllowedCalendarDate = finalAllowedDate.format(calendarDateFormat)
  const finalAllowedDateText = finalAllowedDate.format('MMM Do')

  const rescheduledPaymentDate = payment.rescheduledDate
    ? moment(payment.rescheduledDate).tz(timeZoneId)
    : undefined
  const rescheduledPaymentDateText = rescheduledPaymentDate?.format('MMM Do')

  const currentPaymentDate = rescheduledPaymentDate ?? originalPaymentDate

  const [selectedDate, setSelectedDate] = useState<Moment>(currentPaymentDate)
  const [newPaymentDate, setNewPaymentDate] = useState<Moment>(currentPaymentDate)
  const hasPaymentBeenRescheduled = !originalPaymentDate.isSame(rescheduledPaymentDate, 'day')

  const datesNotFeasible: MarkedDates = useMemo(() => {
    return getNotFeasibleMarkedDates({
      creditImpactDate,
      endDay: finalAllowedDate,
      originalDate: originalPaymentDate,
      payment,
      startDay: nextAvailSettlementDate,
      todayDate: todayDate,
    })
  }, [
    creditImpactDate,
    finalAllowedDate,
    nextAvailSettlementDate,
    originalPaymentDate,
    payment,
    todayDate,
  ])

  const markedDates = useMemo(() => {
    const newSelectedMarked = {
      [selectedDate.format(calendarDateFormat)]: {
        selected: true,
      },
    }
    let currentPaymentDateMarked = {}
    if (currentPaymentDate !== selectedDate) {
      currentPaymentDateMarked = {
        [currentPaymentDate.format(calendarDateFormat)]: {
          marked: true,
        },
      }
    }
    return Object.assign(datesNotFeasible, currentPaymentDateMarked, newSelectedMarked)
  }, [selectedDate, currentPaymentDate, datesNotFeasible])

  const rescheduleOverlayContent: ReschedulePaymentAlertOverlayContentProps = useMemo(() => {
    return getRescheduleOverlayContent({
      reasonCode: feasibleDateCode,
      earliestAvailableDate: nextAvailSettlementDate,
      todayDate: todayDate,
      t: t,
    })
  }, [feasibleDateCode, todayDate, nextAvailSettlementDate, t])

  const reschedulePaymentAlertOverlayProps: ReschedulePaymentAlertOverlayProps = useMemo(() => {
    const resetToPreviouslySelectedDate = (): void => {
      setSelectedDate(currentPaymentDate)
      setFeasibleDateCode(FeasibleDateCode.Allowed)
      setHasDateChanged(false)
      setShowRescheduleOverlay(false)
    }
    return {
      reschedulePaymentAlertContentProps: rescheduleOverlayContent,
      isVisible: showRescheduleOverlay,
      onConfirmSelectedRescheduleDate: (): void => {
        // if warnAndAllowContinue is true, allow the user to select the date
        if (warnAndAllowContinue(feasibleDateCode)) {
          setShowRescheduleOverlay(false)
        } else {
          resetToPreviouslySelectedDate()
        }
      },
      onConfirmDeclineRescheduleDate: (): void => {
        resetToPreviouslySelectedDate()
      },
    }
  }, [currentPaymentDate, feasibleDateCode, rescheduleOverlayContent, showRescheduleOverlay])

  const onConfirmSetNewPaymentDate = useCallback(
    (day: RnCalendarsDateData): void => {
      const dayDateTime = getAdjustedDateTimeFromDay(day, timeZoneId, todayDate)
      setNewPaymentDate(dayDateTime)
    },
    [timeZoneId, todayDate],
  )

  const onDayPressDay = useCallback(
    (day: RnCalendarsDateData): void => {
      const dayMomentDate = moment(day.dateString, calendarDateFormat).tz(timeZoneId)
      if (currentPaymentDate.isSame(dayMomentDate, 'day')) {
        setSelectedDate(dayMomentDate)
        setHasDateChanged(false)
        return
      }

      setSelectedDate(dayMomentDate)
      setHasDateChanged(true)

      TrackAppEvent(
        AppEvents.Name.reschedule_payments_calendar_selected,
        AppEvents.Category.ManageActiveLoan,
        day.dateString,
      )

      const dayFeasibilityDateCode = getFeasibleDateCode({
        creditImpactDate,
        earliestAvailableDate: moment(nextAvailableSettlementDate?.adjustedSettlementDatetime).tz(
          timeZoneId,
        ),
        originalDate: originalPaymentDate,
        payment: payment,
        selectedDate: dayMomentDate,
        todayDate: todayDate,
      })

      Log.log('=========== onDayPress')
      Log.log(`Time now = ${todayDate.format()}`)
      Log.log(`User timezone = ${timeZoneId}`)
      Log.log(`Selected date = ${dayMomentDate.format()}`)
      Log.log(`dayFeasibilityDateCode = ${FeasibleDateCode[dayFeasibilityDateCode]}`)

      setFeasibleDateCode(dayFeasibilityDateCode)

      if (
        dayFeasibilityDateCode !== FeasibleDateCode.Allowed &&
        !warnAndAllowContinue(dayFeasibilityDateCode)
      ) {
        trackRescheduleOverlayShown({dayFeasibilityDateCode, nextAvailSettlementDate, todayDate, t})
        setShowRescheduleOverlay(true)
        return
      } else if (warnAndAllowContinue(dayFeasibilityDateCode)) {
        trackRescheduleOverlayShown({dayFeasibilityDateCode, nextAvailSettlementDate, todayDate, t})
        setShowRescheduleOverlay(true)
        onConfirmSetNewPaymentDate(day)
      }

      onConfirmSetNewPaymentDate(day)
    },
    [
      creditImpactDate,
      currentPaymentDate,
      nextAvailSettlementDate,
      nextAvailableSettlementDate?.adjustedSettlementDatetime,
      onConfirmSetNewPaymentDate,
      originalPaymentDate,
      payment,
      t,
      timeZoneId,
      todayDate,
    ],
  )

  const handleOnConfirm = async (): Promise<void> => {
    setIsSubmitting(true)

    if (!loanId) return

    try {
      const updatedPayment: LoanPaymentInput = {
        paymentId: payment.id,
        date: newPaymentDate.format(),
        ordinal: payment.ordinal,
      }

      const changedPayment = [updatedPayment]
      const paymentDatesInput: LoanSetPaymentDatesInput = {
        loanId,
        payments: changedPayment,
        payNow: false,
      }

      const response = await loanSetPaymentDates({variables: {paymentDatesInput}})

      if (response.errors) {
        throw Error(response.errors[0]?.message)
      }

      TrackAppEvent(
        AppEvents.Name.reschedule_payments_calendar_completed,
        AppEvents.Category.ManageActiveLoan,
        {
          id: payment?.id,
          original: payment?.originalDate,
          new: newPaymentDate?.format(calendarDateFormat),
        },
      )

      onContinue()
    } catch (error) {
      setShowReschedulePaymentErrorOverlay(true)

      TrackAppEvent(
        AppEvents.Name.reschedule_payments_calendar_confirm_failed,
        AppEvents.Category.ManageActiveLoan,
        {
          id: payment?.id,
          original: payment?.originalDate,
        },
      )

      logOfferActivationErrorAndShowException(
        error,
        'Failed to confirm rescheduled loan payment dates',
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const isLoading = isLoadingQuery
  const showCTALoading = isSubmitting
  const isDisabled = !hasDateChanged || isSubmitting || isLoading

  return (
    <BaseTemplate isError={!!queryError} testID={'ReschedulePayment'} isLoading={isLoading}>
      <ReschedulePaymentTemplate
        alertOverlayProps={reschedulePaymentAlertOverlayProps}
        finalAllowedDateText={finalAllowedDateText}
        hasPaymentBeenRescheduled={hasPaymentBeenRescheduled}
        isDisabled={isDisabled}
        isLoading={showCTALoading}
        onContinue={handleOnConfirm}
        originalPaymentDateText={originalPaymentDateText}
        paymentAmount={paymentAmount}
        rescheduledPaymentDateText={rescheduledPaymentDateText}
        markedDates={markedDates}
        minDate={firstAllowedCalendarDate}
        maxDate={finalAllowedCalendarDate}
        onDayPress={(day): void => {
          onDayPressDay(day)
        }}
        selectedDate={selectedDate.format(calendarDateFormat)}
        showReschedulePaymentErrorOverlay={showReschedulePaymentErrorOverlay}
        onContactUs={handleOnContactUs}
      />
    </BaseTemplate>
  )
}

export {ReschedulePaymentGQLContainer}
