import {SerializedError} from '@reduxjs/toolkit'

import * as mobileTypes from './consumer'
import * as iamTypes from './iam'

export const mobile: {types: typeof mobileTypes} = {
  types: mobileTypes,
}

export const iam: {types: typeof iamTypes} = {
  types: iamTypes,
}

export enum QueryStatus {
  Initial = 'Initial',
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
  Refreshing = 'Refreshing',
}

export type StateBase = {
  error?: SerializedError
  queryStatus: QueryStatus
  applyForCardQueryStatus: QueryStatus
  cardAccountAgreementsDownload?: mobileTypes.CardAccountAgreementWithDownloadUrl[] | null
  getUserNextAvailablePaymentDateQueryStatus: QueryStatus
  getUserNextAvailablePaymentDate: mobileTypes.NextPaymentDate | undefined | null
}

export enum Applications {
  IAM,
  MOBILE,
}

export enum EnvironmentType {
  NotSet = 'not_set',
  Local = 'local',
  LocalJava = 'local_java',
  Dev = 'dev',
  Prod = 'prod',
}
