import {CLEAR_USER_ACTION, NAVIGATOR_READY, TOP_SCENE_KEY} from 'src/api/actions/actionsNames'
import * as ui_actions from 'src/lib/ui/actions'

const UIStateDefault = {
  flashOn: false,
  selectedStateAbv: undefined,
  userInfoChanges: undefined,
  prequalEnabled: false,
}

export default function reducer(state = UIStateDefault, action) {
  switch (action.type) {
    case TOP_SCENE_KEY:
      return {...state, topSceneKey: action.topSceneKey}
    case NAVIGATOR_READY:
      return {...state, navigatorReady: action.ready}
    case CLEAR_USER_ACTION:
      return UIStateDefault
    case ui_actions.UI_UPDATE:
      return {...state, ...action.state}
    default:
      return state
  }
}
