import {capitalize, cloneDeep} from 'lodash'
import moment from 'moment'

import {formatDate, graphqlDateFormat} from 'src/lib/utils/date'

export function validateDate(date: moment.Moment | Date, start: moment.Moment, end: moment.Moment) {
  date = moment(date)

  try {
    if (!date) return ''

    if (!date.isValid() || date.isBefore(start) || date.isAfter(end)) {
      return ''
    }

    return formatDate(date, graphqlDateFormat)
  } catch (e) {
    return ''
  }
}

export function UpdatePIFields(
  userInfo,
  userInfoChanges,
  fields = [
    'first_name',
    'middle_name',
    'last_name',
    'name_suffix',
    'id_number',
    'birth_date',
    'address',
    'id_expiration_date',
    'ssn',
  ],
) {
  //Create a copy of the current user pi into redux
  //this is so we can keep changes even if they go backwards
  const updates = cloneDeep(userInfoChanges || {})

  fields.forEach((field) => {
    if (updates[field] === undefined) {
      updates[field] = userInfo?.[field]
    }
  })
  return updates
}

export const suffixOptions = [
  {label: 'Junior', value: 'junior'},
  {label: 'Senior', value: 'senior'},
  {label: 'Jr', value: 'jr'},
  {label: 'Sr', value: 'sr'},
  {label: 'I', value: 'i'},
  {label: 'II', value: 'ii'},
  {label: 'III', value: 'iii'},
  {label: 'IV', value: 'iv'},
  {label: 'V', value: 'v'},
  {label: 'VI', value: 'vi'},
  {label: 'VII', value: 'vii'},
  {label: 'VIII', value: 'viii'},
  {label: 'IX', value: 'ix'},
]

type FormatUserNameType = {
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  nameSuffix?: string | null
}

export const formatNameForOnboarding = (userInfo?: FormatUserNameType): string => {
  if (!userInfo) return ''
  return [
    userInfo?.firstName ?? '',
    userInfo?.middleName ?? '',
    userInfo?.lastName ?? '',
    capitalize(userInfo?.nameSuffix ?? ''),
  ].join(' ')
}
