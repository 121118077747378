import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'

export type NoAutoPayOverlayProps = {
  isVisible: boolean
  isExtendedInstallmentPlan?: boolean
  onConfirmNoAutoPay: () => void
  onCancel: () => void
}

const NoAutoPayOverlay: FC<NoAutoPayOverlayProps> = (props) => {
  const {isExtendedInstallmentPlan, isVisible = false, onConfirmNoAutoPay, onCancel} = props
  const {t} = useTranslation(['LoanApproved', 'Common', 'LoanApprovedExtendedPlan'])

  const extendedInstallmentPlanBody = (
    <PFText variant={'p'}>{t('LoanApprovedExtendedPlan:NoAutoPayBody')}</PFText>
  )

  const defaultBody = (
    <PFText variant={'p'}>
      <Trans i18nKey={'NoAutoPayDefaultBody'} t={t}>
        Not accepting the Electronic Payment Authorization means that
        <PFText variant={'p'} color={'error'}>
          you will not receive funds for at least 15 to 20 business days
        </PFText>
        . If you choose to opt out, you must contact us so we can verify your mailing address and
        send you a check for your funds via standard mail (15–20 business days).
      </Trans>
    </PFText>
  )

  const handleOnPressCancel = (): void => {
    TrackAppEvent(AppEvents.Name.non_ach_option_nevermind_selected, AppEvents.Category.Activation)
    onCancel()
  }

  const handleOnConfirmNoAutoPay = (): void => {
    TrackAppEvent(
      AppEvents.Name.non_ach_option_agree_to_delay_of_funds_selected,
      AppEvents.Category.Activation,
    )
    onConfirmNoAutoPay()
  }

  return (
    <OverlaySimple
      visible={isVisible}
      title={t('PayByMoneyOrder')}
      testID={'Non-Ach-Template-Overlay'}
      okayButtonText={t('Common:NeverMind')}
      onOkay={handleOnPressCancel}
      dismissButtonText={
        isExtendedInstallmentPlan
          ? t('LoanApprovedExtendedPlan:AgreeToPayByMoneyOrder')
          : t('AgreeToDelayOfFunds')
      }
      onDismiss={handleOnConfirmNoAutoPay}
      content={isExtendedInstallmentPlan ? extendedInstallmentPlanBody : defaultBody}
    />
  )
}

export {NoAutoPayOverlay}
