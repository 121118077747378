import React, {useCallback, useEffect, useMemo, useState} from 'react'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {LogException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import Linking from 'src/nav/Linking'
import {NavContextType} from 'src/nav/NavContext.types'
import {defineDestination, initialContext} from 'src/nav/NavContext.utils'

export type NavContextUpdateCallback = (current: NavContextType) => Partial<NavContextType>
export type NavContextUpdateFunction = (callback: NavContextUpdateCallback) => void
type NavValueType = [NavContextType, NavContextUpdateFunction]
export const NavContext = React.createContext<NavValueType>([
  initialContext,
  (): void => {
    // noop
  },
])

export const NavContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const {children} = props

  const [state, setState] = useState<NavContextType>(initialContext)

  const updateContext: NavContextUpdateFunction = useCallback(
    (callback: NavContextUpdateCallback) => {
      setState((prev) => ({...prev, ...callback(prev)}))
    },
    [],
  )

  useEffect(() => {
    const blankParams = (): void => {
      setState((prev) => ({
        ...prev,
        initialParams: new URLSearchParams(),
        hasProcessedParams: true,
      }))
    }

    const doIt = async (): Promise<void> => {
      const urlStr = await Linking.getInitialURL()
      if (!urlStr) {
        blankParams()
        return
      }

      const url = new URL(urlStr)
      if (!url) {
        blankParams()
        return
      }

      try {
        const urlSearch = url.search
        const params = new URLSearchParams(urlSearch)

        // turn params into a normal object so we can
        // pass it to the analytics event
        // we do this so we can create user cohorts based on
        // the params we find in the URL
        const paramsObj: Record<string, string> = {}
        params.forEach((value, key) => {
          paramsObj[key] = value
        })
        TrackAppEvent(AppEvents.Name.url_params_found, AppEvents.Category.Functional, paramsObj)

        setState((prev) => ({...prev, initialParams: params}))
      } catch (e) {
        // if the URL isn't null but doesn't contain any params we will end up here
        Log.log('URL found but no params. Moving on...')
        blankParams()
      }
    }

    doIt().catch(LogException)
  }, [])

  const {initialParams, hasProcessedParams, tempAuthToken, destination} = state

  useEffect(() => {
    if (hasProcessedParams) {
      return
    }

    const result = defineDestination({initialParams, tempAuthToken, destination})

    if (!result) {
      return
    }

    setState((prev) => ({
      ...prev,
      ...result,
      hasProcessedParams: true,
    }))
  }, [destination, initialParams, hasProcessedParams, tempAuthToken])

  const value = useMemo((): NavValueType => [state, updateContext], [state, updateContext])

  if (!state.hasProcessedParams) {
    return <Loading type="loader0" size="large" />
  }

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}
