import React, {ErrorInfo} from 'react'
import {View, Text, SafeAreaView, StyleSheet} from 'react-native'

import Analytics from 'src/lib/Analytics'
import Datadog from 'src/lib/Analytics/Datadog'
import AppEvents, {GeneralErrorEvents} from 'src/lib/Analytics/app_events'
import EventStreamSingleton from 'src/lib/EventStream/EventStream'

export type ErrorBoundaryProps = {
  children: React.ReactNode
}
export type ErrorBoundaryState = {
  initializing: boolean
  hasError: boolean
  error: Error | null
  errorInfo: Record<string, string> | null
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    initializing: true,
    hasError: false,
    error: null,
    errorInfo: null,
  }

  constructor(initialProps: ErrorBoundaryProps) {
    super(initialProps)
    this.initialize()
  }

  async initialize() {
    try {
      await Analytics._initDatadog()
    } finally {
      this.setState((prev) => ({...prev, initializing: false}))
    }
  }

  static getDerivedStateFromError(error): ErrorBoundaryState {
    return {initializing: false, hasError: true, error, errorInfo: null}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState((prev) => ({...prev, ...ErrorBoundary.getDerivedStateFromError(error)}))

    const errorMsg = `ErrorBoundary - ${error?.message ?? 'no error message'}`
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error(errorMsg, {error, errorInfo})
    }
    Datadog.error(errorMsg, {error, errorInfo})

    // EventStreamSingelton should be safe even if some handlers haven't been initialized yet (such as during app startup) as opposed to TrackAppEvent
    EventStreamSingleton.emit({
      type: 'analytic',
      name: GeneralErrorEvents.error_boundary_screen_viewed,
      category: AppEvents.Category.GeneralError,
      destinations: ['amplitude'],
    })
  }

  render() {
    if (this.state.initializing) {
      return <View />
    }

    if (this.state.hasError) {
      return (
        <SafeAreaView style={styles.safeAreaView}>
          <View style={styles.container}>
            <View style={styles.content}>
              <Text style={styles.oops}>Oops, Something Went Wrong</Text>
              <Text style={styles.description}>
                The app ran into a problem and could not continue. We apologise for any
                inconvenience this has caused! Try closing the app and opening it again.
              </Text>
            </View>
          </View>
        </SafeAreaView>
      )
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  content: {
    padding: 20,
  },
  description: {fontWeight: '500', lineHeight: 23, marginVertical: 10},
  oops: {fontSize: 32},
  safeAreaView: {
    flex: 1,
  },
})
