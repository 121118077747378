import React, {useEffect} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {NotificationsPermissionsTemplate} from 'src/products/general/NotificationsPermissions/NotificationsPermissionsTemplate'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import {isEmulatorSync} from 'react-native-device-info'

const isEmulator = isEmulatorSync()

type NotificationsPermissionsContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'NotificationsPermissions'>

const NotificationsPermissionsContainer: React.FC<NotificationsPermissionsContainerProps> = (
  NotificationsPermissionsContainerProps,
): JSX.Element => {
  const {onPreReqFulfilled} = NotificationsPermissionsContainerProps

  const [hasPushPermissions, requestPushPermissions] = usePushPermissions()

  useEffect(() => {
    sendAnalyticEvent(ampli.notificationPermissionScreenViewed.bind(ampli))
  }, [])

  const handleOnPermissionsGranted = async (): Promise<void> => {
    sendAnalyticEvent(ampli.notificationPermissionGrantedCta.bind(ampli))
    // the __DEV_ condition is to allow detox to bypass the push permission
    if (__DEV__ || isEmulator || hasPushPermissions) {
      await onPreReqFulfilled()
      return
    }

    await requestPushPermissions()
    await onPreReqFulfilled()
  }

  const handleOnDismiss = async (): Promise<void> => {
    sendAnalyticEvent(ampli.notificationPermissionNotNowCta.bind(ampli))
    await onPreReqFulfilled()
  }

  return (
    <NotificationsPermissionsTemplate
      onPermissionsGranted={handleOnPermissionsGranted}
      onDismiss={handleOnDismiss}
    />
  )
}

export {NotificationsPermissionsContainer}
