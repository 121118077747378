import {Consumer} from '@possible/cassandra'
import {createSelector} from 'reselect'

import {PfReduxState} from 'src/reducers/types'

/*
There are two places accounts are stored in the redux store. The legacy V1 location, V1 is in
snakecase.
V1 location : state.lib.users.profile.accounts

The new V2 location is in camelCase
V2 location : state.lib.users.linked_accounts.linked_accounts.accounts

T
 */

export const userIdSelector = (state: PfReduxState): string | undefined =>
  state.cassandra.user.me?.id

export const userMeSelector = (state: PfReduxState) => state.cassandra?.user?.me

export const userProfileSelector = (state): Consumer.types.UserProfile =>
  state.cassandra?.user?.me?.profile

export const userIdentificationSelector = (state: PfReduxState) =>
  state.cassandra?.user?.me?.identification

export const isEmailVerifiedSelector = createSelector(
  userProfileSelector,
  (user): boolean => !!user?.email?.verified,
)

export const userEmailSelector = createSelector(
  userProfileSelector,
  (user) => user?.email?.address ?? undefined,
)

export const usernameSelector = createSelector(userProfileSelector, (user) => user?.name)

export const isSSNInfoComplete = createSelector(
  userIdentificationSelector,
  (identification): string | undefined => identification?.ssn?.mask,
)

export const formattedPI = createSelector(userMeSelector, isSSNInfoComplete, (me, ssn) => {
  return {
    first_name: me?.profile?.name?.firstName,
    last_name: me?.profile?.name?.lastName,
    middle_name: me?.profile?.name?.middleName,
    name_suffix: me?.profile?.name?.suffix,
    id_number: me?.identification?.identificationDocument?.number,
    id_expiration_date: me?.identification?.identificationDocument?.expirationDate,
    birth_date: me?.profile?.birthDate,
    address: me?.profile?.home?.address,
    ssn,
  }
})

export const userInfoChanges = (state) => state.lib?.ui?.userInfoChanges
