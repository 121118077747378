/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-fallthrough */
import {CardAccountInstallmentPlan, Maybe} from '@possible/cassandra/src/types/consumer'
import moment, {now} from 'moment'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  PFInfoCapsule,
  PFInfoCapsuleVariant,
} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import {
  UpcomingPaymentsCard,
  UpcomingPaymentsCardPropsFromUseCards,
} from 'src/products/card/components/molecules/UpcomingPaymentsCard/UpcomingPaymentsCard'
import {mediumGap} from 'src/designSystem/layout'
import {suspendedFraudInfoCapsuleText} from 'src/designSystem/semanticColors'
import {
  formatDate,
  humanReadableDateDayMonth,
  humanReadableDateDayMonth2,
  utcDate,
} from 'src/lib/utils/date'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {CardDashboardPaymentBody} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPaymentBody'
import {
  CardDashboardPaymentFooter,
  CardDashboardPaymentFooterPropsFromUseCards,
} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPaymentFooter'
import {PaymentStatus} from 'src/products/card/Dashboard/CardDashboardPayment/PaymentStatus'
import MissedPaymentTile from 'src/products/card/Dashboard/MissedPaymentTile/MissedPaymentTile'
import {CardPOTOverdue, CardPOTOverdueProps} from 'src/products/card/PayOverTime/CardPOTOverdue'
import {useCardsType} from 'src/products/card/hooks/useCards'
import {CardAccountDashboardStatus, CardAccountPOTStatus} from 'src/products/card/types'

export type CardDashboardPaymentFromUseCards = Pick<
  useCardsType,
  | 'accountStatus'
  | 'currentInstallmentPlan'
  | 'nextPaymentDueDate'
  | 'daysUntilDelinquent'
  | 'daysUntilReportedDelinquent'
  | 'accountPOTStatus'
  | 'autopayEnabled'
  | 'activeStatement'
  | 'delinquentNumberOfDays'
>
export type CardDashboardPaymentProps = CardDashboardPaymentFromUseCards &
  UpcomingPaymentsCardPropsFromUseCards &
  CardDashboardPaymentFooterPropsFromUseCards &
  CardPOTOverdueProps & {
    navigation: MainStackNavigationProp
  }

export const hasUnpaidInstallments = (accountPOTStatus: CardAccountPOTStatus): boolean => {
  return [
    CardAccountPOTStatus.OverdueMultipleMissedPayments,
    CardAccountPOTStatus.OverdueOneMissedPayment,
  ].includes(accountPOTStatus)
}

export const lastInstallmentDayPassed = (
  installmentPlan: Maybe<CardAccountInstallmentPlan> | undefined,
): boolean => {
  if (installmentPlan?.installments && installmentPlan?.installments.length > 0) {
    return moment(
      installmentPlan?.installments[installmentPlan.installments.length - 1].dueAt,
    ).isBefore(now())
  } else {
    return false
  }
}
export const CardDashboardPayment: React.FC<CardDashboardPaymentProps> = (props) => {
  const {t} = useTranslation(['CardDashboardPayment', 'UpcomingPaymentsCard', 'Common'])
  const {
    currentInstallmentPlan,
    nextPaymentDueDate,
    daysUntilDelinquent,
    daysUntilReportedDelinquent,
    accountPOTStatus,
    autopayEnabled,
    activeStatement,
    delinquentNumberOfDays,
    accountStatus,
  } = props

  const getHeaderText = (): string => {
    return t('NextPayment')
  }
  const getInstallmentContent = (): JSX.Element | undefined => {
    if (accountStatus !== CardAccountDashboardStatus.SuspendedPayOverTime) {
      return undefined
    }
    return (
      <Box paddingBottom={'medium'} width={'100%'} gap={'little'}>
        {currentInstallmentPlan?.payments?.map((item, index) => (
          <Box direction={'row'} key={index}>
            <View style={styling.date}>
              <PFText variant={'p_sm_semibold'}>
                {formatDate(item?.executeAt, humanReadableDateDayMonth, utcDate)}
              </PFText>
            </View>
            <View style={styling.paymentStatus}>
              <PaymentStatus status={item?.statusCode} />
            </View>
            <View style={styling.amount}>
              <PFText variant={'p_sm'}>{convertToDollarAmt(item?.amount)}</PFText>
            </View>
          </Box>
        ))}
      </Box>
    )
  }
  const getPOTOverdueComponent = (): ReactNode => {
    if (
      accountPOTStatus === CardAccountPOTStatus.OverdueOneMissedPayment ||
      accountPOTStatus === CardAccountPOTStatus.OverdueMultipleMissedPayments
    ) {
      return <CardPOTOverdue {...props} />
    }
  }

  const getOverdueComponent = (includeMissedPayments: boolean): JSX.Element => {
    return (
      <View style={{marginHorizontal: mediumGap}}>
        <Box {...boxProps}>
          {includeMissedPayments ? (
            <Box align={'center'} gap={'little'}>
              <MissedPaymentTile />
            </Box>
          ) : null}
          <CardDashboardPaymentBody
            accountStatus={accountStatus}
            t={t}
            daysUntilDelinquent={daysUntilDelinquent}
            daysUntilReportedDelinquent={daysUntilReportedDelinquent}
            autopayEnabled={autopayEnabled}
            activeStatement={activeStatement}
            delinquentNumberOfDays={delinquentNumberOfDays}
          />

          <Box align={'center'}>
            <CardDashboardPaymentFooter {...props} />
          </Box>
        </Box>
      </View>
    )
  }

  switch (accountStatus) {
    case CardAccountDashboardStatus.SuspendedFraud: {
      return (
        <PFInfoCapsule
          variant={PFInfoCapsuleVariant.Element}
          svgIcon={{name: 'warning', colorVariant: 'warning', isFilled: true}}
          element={
            <Box paddingLeft={'small'} flex>
              <PFText variant={'label_md'} color={suspendedFraudInfoCapsuleText}>
                {t('YourNewCard')}
              </PFText>
              <PFText variant={'p_sm'}>{t('SomeTransactions')}</PFText>
            </Box>
          }
        />
      )
    }
    case CardAccountDashboardStatus.Active:
      return <UpcomingPaymentsCard key="upcomingPayments" footer {...props} />

    case CardAccountDashboardStatus.Overdue:
    case CardAccountDashboardStatus.OverdueNSF:
    case CardAccountDashboardStatus.AtRiskDelinquency:
    case CardAccountDashboardStatus.AtRiskDelinquencyNSF:
    case CardAccountDashboardStatus.Delinquent:
    case CardAccountDashboardStatus.DelinquentNSF:
    case CardAccountDashboardStatus.DelinquencyReported:
    case CardAccountDashboardStatus.DelinquencyReportedNSF:
    case CardAccountDashboardStatus.DeactivatedDelinquent:
    case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
      return getOverdueComponent(true)
    case CardAccountDashboardStatus.ChargedOff:
    case CardAccountDashboardStatus.Deactivated:
      return getOverdueComponent(false)
    case CardAccountDashboardStatus.SuspendedPayOverTime:
      return (
        <Box gap="medium">
          {getPOTOverdueComponent()}
          {hasUnpaidInstallments(accountPOTStatus) &&
          lastInstallmentDayPassed(currentInstallmentPlan) ? null : (
            <UpcomingPaymentsCard
              key="upcomingPayments"
              footer={!getPOTOverdueComponent()}
              {...props}
            />
          )}
        </Box>
      )
    default:
      return (
        <View style={{marginHorizontal: mediumGap}}>
          <Box {...boxProps}>
            <Box align={'center'} gap={'little'}>
              {nextPaymentDueDate ? (
                <Box paddingBottom={'small'} align={'center'} gap={'little'}>
                  <PFText variant={'label_md'} color="textDisabled">
                    {getHeaderText()}
                  </PFText>
                  <Box direction={'row'}>
                    <PFText variant={'h2'}>
                      {formatDate(nextPaymentDueDate, humanReadableDateDayMonth2)}
                    </PFText>
                  </Box>
                </Box>
              ) : null}
              {getInstallmentContent()}
            </Box>
            <Box align={'center'} gap={'little'}>
              <CardDashboardPaymentFooter {...props} />
            </Box>
          </Box>
        </View>
      )
  }
}

const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'small',
  boxStyle: {overflow: 'hidden'},
  gap: 'none',
}

const styling = StyleSheet.create({
  amount: {alignItems: 'flex-end', flex: 1},
  date: {flex: 1},
  paymentStatus: {alignItems: 'center', flex: 1.5},
})
