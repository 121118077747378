import React, {useContext} from 'react'
import {CompositeScreenProps} from '@react-navigation/native'
import {ApolloQueryResult} from '@apollo/client'
import {StackScreenProps} from '@react-navigation/stack'

import {
  ViewPaymentMethodsContainer,
  ViewPaymentMethodsAnalyticsType,
  ViewPaymentMethodsContainerProps,
} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/ViewPaymentMethodsContainer'
import AppEvents, {ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {trackLoansAdhocScreenRenderFailure} from 'src/products/loans/AdhocPayment/LoanPay.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  LoanPayMethodsDocument,
  LoanPayMethodsQuery,
} from 'src/products/loans/AdhocPayment/LoanPayMethods/LoanPayMethods.gqls'
import {
  LoanPayMethodsAch,
  LoanPayMethodsDebitCard,
} from 'src/products/loans/AdhocPayment/LoanPayMethods/LoanPayMethods.types'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'
import {convertAggregatorStringToType} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {LoanPayContext, LoanPaySetterContext} from 'src/products/loans/AdhocPayment/LoanPayProvider'

const viewPaymentMethodsAnalytics: ViewPaymentMethodsAnalyticsType = {
  changePaymentMethodAppEvent: {
    name: ManageActiveLoanEvents.loans_adhoc_payment_methods_change_payment_method,
    category: AppEvents.Category.ManageActiveLoan,
  },
  changePaymentMethodCompletedAppEvent: {
    name: ManageActiveLoanEvents.loans_adhoc_payment_methods_change_payment_method_completed,
    category: AppEvents.Category.ManageActiveLoan,
  },
}

type LoanPaymentMethodsProps = Pick<
  CompositeScreenProps<
    StackScreenProps<LoanPayNavigatorStack, 'LoanPayMethods'>,
    StackScreenProps<MainStackParamList>
  >,
  'route' | 'navigation'
>

/**
 * Screen to view all payment methods for a loan user and add new payment methods.
 */
export const LoanPayMethods: React.FunctionComponent<LoanPaymentMethodsProps> = (
  props: LoanPaymentMethodsProps,
) => {
  const {navigation} = props
  const {selectedPaymentMethodId} = useContext(LoanPayContext)
  const {setSelectedPaymentMethodId} = useContext(LoanPaySetterContext)

  const queryResult = useCassandraQuery(
    LoanPayMethodsDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
    (data) => {
      const eligiblePaymentMethods: (LoanPayMethodsAch | LoanPayMethodsDebitCard)[] = []
      for (const paymentMethod of data.me.paymentMethods.loanEligible ?? []) {
        if (
          paymentMethod.__typename === 'AchPaymentMethod' ||
          paymentMethod.__typename === 'DebitCardPaymentMethod'
        ) {
          eligiblePaymentMethods.push(paymentMethod)
        }
      }
      const bankingAggregatorId: aggregatorsId | undefined = convertAggregatorStringToType(
        data.getUserEnv.bankingAggregatorId,
      )
      return {
        paymentMethods: eligiblePaymentMethods,
        bankingAggregatorId,
      }
    },
  )

  const selectedPaymentMethod = queryResult.selectedData?.paymentMethods?.find(
    (method) =>
      (method.__typename === 'AchPaymentMethod' ||
        method.__typename === 'DebitCardPaymentMethod') &&
      method.bankingPaymentInstrumentId === selectedPaymentMethodId,
  )

  usePageViewedAnalytics({
    eventName: ManageActiveLoanEvents.loans_adhoc_payment_methods_screen_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
  })

  const handleOnRetry = async (): Promise<ApolloQueryResult<LoanPayMethodsQuery>> => {
    return queryResult.refetch()
  }

  const handleOnSelectAndContinue: ViewPaymentMethodsContainerProps['onContinue'] = (): void => {
    navigation.navigate('LoanPayConfirm')
  }

  const handleOnErrorBoundary = (e: Error): void => {
    trackLoansAdhocScreenRenderFailure(e, 'LoanPayMethods')
  }

  return (
    <BaseTemplate
      // the selected payment method will be found from query data cache so when it's not found yet
      // we're still loading
      isLoading={queryResult.loading && !selectedPaymentMethod && !queryResult.error}
      isError={!!queryResult.error}
      testID={'LoanPayMethods'}
      onRetry={handleOnRetry}
      transparentBackground={true}
      onErrorBoundary={handleOnErrorBoundary}
      trackError={{
        eventName: ManageActiveLoanEvents.loans_adhoc_payment_methods_error_viewed,
        eventCategory: AppEvents.Category.ManageActiveLoan,
      }}
    >
      <ViewPaymentMethodsContainer
        paymentMethods={queryResult.selectedData?.paymentMethods ?? []}
        selectedPaymentMethod={selectedPaymentMethod ?? null}
        bankingAggregatorId={queryResult.selectedData?.bankingAggregatorId}
        viewPaymentMethodsAnalytics={viewPaymentMethodsAnalytics}
        addPaymentMethodSuccessRouteName={'LoanPayConfirm'}
        onContinue={handleOnSelectAndContinue}
        onSelectPaymentMethodId={(id?: string): void => setSelectedPaymentMethodId(id)}
      />
    </BaseTemplate>
  )
}
