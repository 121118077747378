import {StackNavigationProp} from '@react-navigation/stack'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {PaymentInfoType} from 'src/products/loans/LoanSelection/FeeCalc'
import StateLoanInfo from 'src/products/loans/LoanSelection/StateLoanInfo'
import {WorkflowsStackParams} from 'src/workflows/types'

type Props = {
  navigation: StackNavigationProp<WorkflowsStackParams, 'LoanAmountSelection'>
  paymentInfo: PaymentInfoType
}

const LouisianaLoanInfo: FC<Props> = ({navigation, paymentInfo}) => {
  const {t} = useTranslation('LoanSelection')
  return (
    <StateLoanInfo
      navigation={navigation}
      description={t('LAMainDescription')}
      paymentInfo={paymentInfo ?? {}}
      feesTitle={t('DocumentationFee')}
    />
  )
}
export default LouisianaLoanInfo
