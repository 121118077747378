import React from 'react'
import {CompositeScreenProps} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {CardAdhocPaymentStack} from 'src/products/card/AdhocPayment/CardAdhocPaymentStack'
import {useCardAdhocPayment} from 'src/products/card/AdhocPayment/useCardAdhocPayment/useCardAdhocPayment'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  PaymentAction,
  PaymentInput,
} from 'src/products/general/components/molecules/PaymentInput/PaymentInput'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {useCardAdhocPaymentInput} from 'src/products/card/AdhocPayment/useCardAdhocPaymentInput'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCardAdhocPaymentAnalytics} from 'src/products/card/AdhocPayment/useCardAdhocPaymentAnalytics'

type CardAdhocPaymentAmountEntryProps = CompositeScreenProps<
  StackScreenProps<CardAdhocPaymentStack, 'CardAdhocPaymentAmountEntry'>,
  StackScreenProps<MainStackParamList>
>

const CardAdhocPaymentAmountEntry = ({
  navigation,
}: CardAdhocPaymentAmountEntryProps): JSX.Element => {
  const {currentBalance, data, dispatchPaymentState, paymentState} = useCardAdhocPayment()
  const {isLoading, eventArgs} = useCardAdhocPaymentAnalytics()

  usePageViewedAnalytics({
    eventName: CardEvents.card_adhoc_payment_select_amount_page_viewed,
    eventCategory: AppEvents.Category.Card,
    eventArgs,
    isLoading,
  })

  const {buttonActions} = useCardAdhocPaymentInput()
  const {t} = useTranslation('CardAdhocPaymentAmountEntry')

  /* `payment.amount` is initialized as an empty string so we need a default value. I don't really
   * know a better way than to rely on `getButtonsActions[0]`. It shouldn't be a problem though so
   * long as we are thoughtful in how we generate the array in `getButtonActions` */
  const paymentValue =
    paymentState.paymentSpecification.amount || buttonActions?.[0].amount.toString() || '0'
  const isOverpaying = Number(paymentState.paymentSpecification.amount) > Number(currentBalance)
  const isValidPayment = Number(paymentValue) > 0 && !isOverpaying

  const handleOnChange = (value: string): void => {
    dispatchPaymentState({type: 'setAmount', payload: {amount: value, currentBalance}})
  }

  const handleOnPress = (): void => {
    handleOnChange(paymentValue)

    TrackAppEvent(CardEvents.card_adhoc_payment_select_amount_page_cta, AppEvents.Category.Card, {
      payment_amount: paymentState.paymentSpecification.amount,
      ...eventArgs,
    })

    navigation.navigate('CardAdhocPaymentReview')
  }

  const handleOnSubmitEditing = (): void => (isValidPayment ? handleOnPress() : undefined)

  const handleonPressButtonAction = (action: PaymentAction): void => {
    TrackAppEvent(CardEvents.card_adhoc_payment_amount_selected, AppEvents.Category.Card, {
      adhoc_payment_type: action.trackingId,
      ...eventArgs,
    })
    dispatchPaymentState({
      type: 'setUserSelectedButtonId',
      payload: {userSelectedButtonId: action.trackingId},
    })
  }

  return !data?.me ? (
    <Loading type={'loader0'} size={'large'} />
  ) : (
    <>
      {isOverpaying ? (
        <Box paddingHorizontal={'medium'} paddingTop={'small'}>
          <Alert
            title={t('OverpayAlertTitle')}
            description={t('OverpayAlertDescription')}
            level={'warning'}
          />
        </Box>
      ) : null}
      <Page
        buttonProps={{
          type: 'singleButton',
          primary: {
            text: t('CtaText'),
            onPress: handleOnPress,
            disabled: !isValidPayment,
          },
        }}
        variant={'interstitial'}
        enabledKeyboardAvoidingView
        banner={(): null => null}
      >
        <Box gap={'tiny'} paddingTop={'small'}>
          <PFText variant={'label_md'} textAlign={'center'}>
            {t('Title')}
          </PFText>
          <PaymentInput
            buttonActions={buttonActions}
            onChange={handleOnChange}
            onPressButtonAction={handleonPressButtonAction}
            onSubmitEditing={handleOnSubmitEditing}
            value={paymentValue}
          />
        </Box>
      </Page>
    </>
  )
}

export {CardAdhocPaymentAmountEntry}
