import React, {FC, useCallback, useEffect, useState} from 'react'
import {ApolloError} from '@apollo/client'
import {useTranslation} from 'react-i18next'

import {AdverseReasonCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {capitalizeFirstLetter} from 'src/lib/user/utils'
import {
  LoanPaymentReviewDocument,
  LoanPaymentReviewUpdateLoanActivationRequirementsDocument,
} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReview.gqls'
import {LoanPaymentReviewTemplate} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewTemplate/LoanPaymentReviewTemplate'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {usePfDispatch} from 'src/store/utils'
import {usePreferredAccount} from 'src/lib/bank/usePreferredAccount'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import AppEvents from 'src/lib/Analytics/app_events'

export type LoanPaymentReviewGQLContainerProps = {
  onContinue: () => Promise<void>
}

const LoanPaymentReviewGQLContainer: FC<LoanPaymentReviewGQLContainerProps> = (props) => {
  const {onContinue} = props
  const {t} = useTranslation(['LoanPaymentReview'])
  const dispatch = usePfDispatch()
  const [isProcessingGetLoan, setIsProcessingGetLoan] = useState<boolean>(false)
  const [isRefreshingEnv, setIsRefreshingEnv] = useState<boolean>(false)

  const {
    value: preferredAccount,
    isLoading: isPreferredAccountLoading,
    preferredAccountError,
  } = usePreferredAccount()

  const accountMask = preferredAccount?.mask ?? ''
  const institutionName = preferredAccount?.institution?.name ?? ''
  const accountType = preferredAccount?.type ?? ''
  const accountTypeFormatted = capitalizeFirstLetter(accountType.toLowerCase())

  const {
    selectedData,
    error: queryError,
    loading: isLoadingQuery,
  } = useCassandraQuery(
    LoanPaymentReviewDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationError(err, 'LoanPaymentReview query error')
      },
    },
    (data) => {
      let amountApproved: string | undefined = undefined
      let counterOfferCode: AdverseReasonCode | null = null
      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ApprovedLoanAggregateStatus'
      ) {
        amountApproved = data.me.loans.latestActionableLoan.aggregateStatus.amountApproved
        counterOfferCode =
          data.me.loans.latestActionableLoan.aggregateStatus.counterOfferCodes.codes[0]
      }
      return {
        amountApproved: amountApproved,
        apr: data.me.loans.latestActionableLoan?.agreement?.apr,
        counterOfferCode: counterOfferCode,
        loanId: data.me.loans.latestActionableLoan?.id,
        payments: data.me.loans.latestActionableLoan?.agreement?.payments,
        totalCost: data.me.loans.latestActionableLoan?.agreement?.totalCost,
        totalOwed: data.me.loans.latestActionableLoan?.agreement?.totalOwed,
      }
    },
  )

  const amountApproved = selectedData?.amountApproved ?? '0'
  const apr = selectedData?.apr ?? '0'
  const isCounterOffer = selectedData?.counterOfferCode !== undefined
  const loanId = selectedData?.loanId ?? undefined
  const payments = selectedData?.payments ?? []
  const totalCost = selectedData?.totalCost ?? '0'
  const totalOwed = selectedData?.totalOwed ?? '0'

  const refreshEnv = useCallback(async (): Promise<void> => {
    // refresh redux data since it is used internally by MPO
    if (!loanId) {
      return
    }
    setIsRefreshingEnv(true)
    try {
      const didRefresh = await dispatch(UserStateRefresh())
      if (!didRefresh) {
        throw new Error('LoanPaymentReviewGQLContainer failed user profile refresh')
      }
    } catch (e) {
      logOfferActivationError(e, 'LoanPaymentReviewGQLContainer refreshEnv')
    } finally {
      setIsRefreshingEnv(false)
    }
  }, [dispatch, loanId])

  useEffect(() => {
    void refreshEnv()
  }, [refreshEnv])

  const [submitPaymentReview, {loading: isSubmittingPaymentReview}] = useCassandraMutation(
    LoanPaymentReviewUpdateLoanActivationRequirementsDocument,
  )

  const handleOnPressContinue = async (): Promise<void> => {
    if (!loanId) {
      logOfferActivationError('LoanPaymentReview handleOnPressContinue no loan id found')
      return
    }
    setIsProcessingGetLoan(true)

    try {
      const res = await submitPaymentReview({
        variables: {
          loanId,
          acceptPayments: true,
        },
      })
      if (res?.errors) {
        throw res?.errors[0]
      }

      if (!res?.data?.loanActivationUpdateRequirements.success) {
        throw new Error('Failed to update loan activation requirements')
      }

      TrackAppEvent(
        AppEvents.Name.review_payment_schedule_screen_completed,
        AppEvents.Category.Activation,
      )
      await onContinue()
    } catch (e) {
      setIsProcessingGetLoan(false)
      logOfferActivationErrorAndShowException(e, 'LoanPaymentReview onPressContinue error')
    } finally {
      setIsProcessingGetLoan(false)
    }
  }

  return (
    <BaseTemplate
      isError={!!queryError || !!preferredAccountError}
      isLoading={isLoadingQuery || isPreferredAccountLoading || isRefreshingEnv}
      pageTitle={isCounterOffer ? t('ReviewYourOffer') : t('YourApplicationIsApproved')}
      showTileBorder={false}
      testID="LoanPaymentReview"
    >
      <LoanPaymentReviewTemplate
        accountMask={accountMask}
        amountApproved={amountApproved}
        apr={apr}
        onPressContinue={handleOnPressContinue}
        isCounterOffer={isCounterOffer}
        payments={payments}
        preferredAccountInstitutionName={institutionName}
        preferredAccountType={accountTypeFormatted}
        totalCost={totalCost}
        totalOwed={totalOwed}
        isSubmitting={isProcessingGetLoan || isSubmittingPaymentReview}
      />
    </BaseTemplate>
  )
}

export {LoanPaymentReviewGQLContainer}
