import React, {FC, useState} from 'react'
import {ApolloError} from '@apollo/client'
import {useTranslation} from 'react-i18next'

import {useCassandraQuery, useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {LoanTilaContentData} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent.types'
import {LoanTilaDisclosureTemplate} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosureTemplate'
import {
  LoanTilaDisclosureDocument,
  LoanTilaDisclosureUpdateLoanActivationRequirementsDocument,
} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosure.gqls'
import {
  logOfferActivationError,
  logOfferActivationErrorAndShowException,
} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'

export type LoanTilaDisclosureGQLContainerProps = {
  onContinue: () => Promise<void>
}

const LoanTilaDisclosureGQLContainer: FC<LoanTilaDisclosureGQLContainerProps> = (props) => {
  const {onContinue} = props
  const {t} = useTranslation(['LoanApproved'])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const {
    selectedData,
    error: queryError,
    loading: isQueryLoading,
  } = useCassandraQuery(
    LoanTilaDisclosureDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        logOfferActivationError(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'LoanTilaDisclosure query error',
        )
      },
    },
    (data) => {
      let amountApproved: string | undefined = undefined
      if (
        data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
        'ApprovedLoanAggregateStatus'
      ) {
        amountApproved = data.me.loans.latestActionableLoan.aggregateStatus.amountApproved
      }
      return {
        amountApproved: amountApproved,
        apr: data.me.loans.latestActionableLoan?.agreement?.apr,
        cabFee: data.me.loans.latestActionableLoan?.agreement?.cabFee,
        interestFee: data.me.loans.latestActionableLoan?.agreement?.interestFee,
        loanId: data.me.loans.latestActionableLoan?.id,
        stateCode: data.me.loans.latestActionableLoan?.state,
        totalCost: data.me.loans.latestActionableLoan?.agreement?.totalCost,
        totalOwed: data.me.loans.latestActionableLoan?.agreement?.totalOwed,
      }
    },
  )

  const loanId = selectedData?.loanId

  const loanTilaContentData: LoanTilaContentData = {
    amountApproved: selectedData?.amountApproved,
    apr: selectedData?.apr,
    cabFee: selectedData?.cabFee,
    interestFee: selectedData?.interestFee,
    stateCode: selectedData?.stateCode,
    totalCost: selectedData?.totalCost,
    totalOwed: selectedData?.totalOwed,
  }

  const [loanActivationUpdateRequirements, {loading: isLoadingMutation}] = useCassandraMutation(
    LoanTilaDisclosureUpdateLoanActivationRequirementsDocument,
  )

  const onPressContinue = async (): Promise<void> => {
    setIsSubmitting(true)
    if (!loanId) {
      setIsSubmitting(false)
      return
    }
    try {
      const {errors} = await loanActivationUpdateRequirements({
        variables: {loanId: loanId},
      })
      if (errors && errors.length > 0) {
        throw errors[0]
      }
      TrackAppEvent(AppEvents.Name.tila_disclosure_screen_completed, AppEvents.Category.Activation)
      await onContinue()
    } catch (error) {
      logOfferActivationErrorAndShowException(
        error,
        'LoanTilaDisclosure Failed to update loan activation requirements',
      )
      setIsSubmitting(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  const isLoading = isSubmitting || isLoadingMutation // ⚡️ Order matter

  return (
    <BaseTemplate
      testID="LoanTilaDisclosure"
      isError={!!queryError || !selectedData}
      isLoading={isQueryLoading}
      pageTitle={t('TilaDisclosure')}
      showTileBorder={false}
    >
      <LoanTilaDisclosureTemplate
        loanTilaContentData={loanTilaContentData}
        onPressContinue={(): Promise<void> => onPressContinue()}
        isLoading={isLoading}
      />
    </BaseTemplate>
  )
}

export {LoanTilaDisclosureGQLContainer}
