import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {SelectPaymentToRescheduleGQLContainer} from 'src/products/loans/Reschedule/SelectPaymentToReschedule/SelectPaymentToRescheduleGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents from 'src/lib/Analytics/app_events'

type SelectPaymentToRescheduleProps = StackScreenProps<
  MainStackParamList,
  'SelectPaymentToReschedule'
>

const SelectPaymentToReschedule: FC<SelectPaymentToRescheduleProps> = (props) => {
  const {navigation} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.reschedule_payments_screen_viewed,
    eventCategory: AppEvents.Category.ManageActiveLoan,
  })

  const handleOnContinue = (): void => {
    navigation.navigate('Dashboard')
  }

  return (
    <SelectPaymentToRescheduleGQLContainer onContinue={handleOnContinue} navigation={navigation} />
  )
}

export {SelectPaymentToReschedule}
