import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {URAUIUpdateAction} from 'src/lib/ura/actions/uraActions'
import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {PushPage} from 'src/navigation/NavHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import AppEvents from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {completeUra, getUserEnvSelector} from 'src/cassandra'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {
  launchAlternateAggregator,
  launchAggregator,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'

type Props = URAProps & {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const BankAddressMismatch: React.FunctionComponent<Props> = (props: Props) => {
  const {componentId, ura_id, close} = props
  const [busy, setBusy] = useState(false)
  const {t} = useTranslation('UserRequestedAction')
  const dispatch = usePfDispatch()

  const env = usePfSelector(getUserEnvSelector)
  const ura = usePfSelector((state) => get_ura_by_id_from_redux(ura_id, state))

  useEffect(() => {
    dispatch(UserStateRefresh()).catch((e) => {
      Log.error(e, 'UserStateRefresh failed')
    })
    dispatch(URAUIUpdateAction([{id: ura_id, componentId, close}], 'MergeRecord'))
  }, [dispatch, ura_id, componentId, close])

  const onBankComplete = async (success): Promise<void> => {
    try {
      if (success) {
        await completeUra(props.ura_id)
        props.close()
      }
    } catch (e) {
      ShowException(e)
    }
  }

  const bankAggregatorSwitchCallback = (alternateId, accountId): void => {
    launchAlternateAggregator(alternateId, accountId, props.navigation, (success) =>
      onBankComplete(success),
    )
  }

  const onLinkAccount = async (): Promise<void> => {
    setBusy(true)
    await dispatch(UserStateRefresh())
    setBusy(false)
    launchAggregator(
      env,
      props.navigation,
      null,
      (success) => onBankComplete(success),
      (alternateId, accountId) => bankAggregatorSwitchCallback(alternateId, accountId),
    )
  }

  const onComplete = async (): Promise<void> => {
    try {
      TrackAppEvent(
        AppEvents.Name.provide_proof_of_residency_completed,
        AppEvents.Category.Application,
      )
      setBusy(true)
      await completeUra(props.ura_id)
      props.close()
    } catch (e) {
      ShowException(e)
    }
  }

  const onRetake = (): void => {
    PushPage(props.navigation, 'ProofOfResidence', {
      noAddressFill: true,
      uraActionType: ura?.actionType,
      onProofComplete: () => onComplete(),
    })
  }

  const primary = {
    text: t('VerifyTheAddress'),
    disabled: busy,
    onPress: onRetake,
  }

  const secondary = {
    text: t('LinkANewAccount'),
    disabled: busy,
    onPress: onLinkAccount,
  }

  return (
    <URAModalContent
      uraId={props.ura_id}
      ura={ura}
      primary={primary}
      secondary={secondary}
      close={props.close}
    />
  )
}

export default BankAddressMismatch
