import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {logOfferApplicationErrorAndShowException} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {LoanApplicationSubmissionGQLContainer} from 'src/products/loans/LoanApplicationSubmission/LoanApplicationSubmissionGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

type LoanInitialApplicationSubmissionWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to integrate the LoanInitialApplicationSubmission screen into Workflows
 * using onPreReqFulfilled().
 */
const LoanApplicationSubmissionWorkflowContainer: React.FC<
  LoanInitialApplicationSubmissionWorkflowContainerProps
> = (props: LoanInitialApplicationSubmissionWorkflowContainerProps) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.completed_application_submission_screen_viewed,
    eventCategory: AppEvents.Category.Application,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleLoanApplicationSubmitted = async (): Promise<void> => {
    try {
      TrackAppEvent(AppEvents.Name.completed_application_submitted, AppEvents.Category.Application)
      await onPreReqFulfilled()
    } catch (e) {
      void logOfferApplicationErrorAndShowException(
        e,
        'LoanSubmission - LoanInitialApplicationSubmission failed to submit initial loan application in Workflows', // the "LoanSubmission" text is required for a DataDog monitor
      )
    }
  }

  return (
    <LoanApplicationSubmissionGQLContainer
      onSubmitLoanApplication={handleLoanApplicationSubmitted}
    />
  )
}

export {LoanApplicationSubmissionWorkflowContainer}
