import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {QueryStatus, StateBase} from '../types'
import {User, UserEnv, ProcessorNetwork, FundingDirection} from '../types/types.mobile.generated'
import {NextQueryStatus} from '../utils'
import {GetMe, getUserEnvMethod, getUserNextAvailablePaymentDate} from './methods'

export type UserState = StateBase & {
  token?: string
  me?: User

  getUserEnvQueryStatus: QueryStatus
  userEnv?: UserEnv
}

const initialState: UserState = {
  queryStatus: QueryStatus.Initial,
  applyForCardQueryStatus: QueryStatus.Initial,
  getUserEnvQueryStatus: QueryStatus.Initial,
  getUserNextAvailablePaymentDateQueryStatus: QueryStatus.Initial,
  getUserNextAvailablePaymentDate: undefined,
}

/**
 * Do not use! Make a co-located query instead.
 */
export const GetMeAction = createAsyncThunk('User/GetMe', async () => {
  return GetMe()
})

type GetUserNextAvailablePaymentDateActionType = {
  network: ProcessorNetwork
  direction: FundingDirection
  desiredSettlementTime: any
}

/**
 * Do not use! Make a co-located query instead. See: https://possible.atlassian.net/browse/ENG-21330
 */
export const GetUserEnv = createAsyncThunk('User/UpdateUserEnv', async () => {
  return getUserEnvMethod()
})

/**
 * Do not use! Make a co-located query instead.
 */
export const GetUserNextAvailablePaymentDateAction = createAsyncThunk(
  'User/GetUserNextAvailablePaymentDate',
  async (args: GetUserNextAvailablePaymentDateActionType, {getState, dispatch}): Promise<any> => {
    try {
      const {network, direction, desiredSettlementTime} = args

      return await getUserNextAvailablePaymentDate(network, direction, desiredSettlementTime)
    } catch (e) {
      console.log('[debug] error', e)
      const err = e as any
      if (err?.length > 0) {
        throw err[0]
      } else {
        throw err
      }
    }
  },
)

export const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase('CLEAR_USER_ACTION', () => initialState)
      .addCase(GetMeAction.pending, (state, action) => {
        state.queryStatus = NextQueryStatus(state.queryStatus, QueryStatus.Pending)
        state.error = undefined
      })
      .addCase(GetMeAction.fulfilled, (state, action) => {
        state.queryStatus = NextQueryStatus(state.queryStatus, QueryStatus.Fulfilled)
        state.me = action.payload
      })
      .addCase(GetMeAction.rejected, (state, action) => {
        state.queryStatus = NextQueryStatus(state.queryStatus, QueryStatus.Rejected)
        state.error = action.error
      })
      .addCase(GetUserEnv.pending, (state) => {
        state.getUserEnvQueryStatus = NextQueryStatus(
          state.getUserEnvQueryStatus,
          QueryStatus.Pending,
        )
        state.error = undefined
      })
      .addCase(GetUserEnv.fulfilled, (state, action) => {
        state.getUserEnvQueryStatus = NextQueryStatus(
          state.getUserEnvQueryStatus,
          QueryStatus.Fulfilled,
        )
        state.userEnv = action?.payload?.getUserEnv
      })
      .addCase(GetUserEnv.rejected, (state, action) => {
        state.getUserEnvQueryStatus = NextQueryStatus(
          state.getUserEnvQueryStatus,
          QueryStatus.Rejected,
        )
        state.error = action.error
      })
      .addCase(GetUserNextAvailablePaymentDateAction.pending, (state, action) => {
        state.error = undefined
        state.getUserNextAvailablePaymentDateQueryStatus = QueryStatus.Pending
      })
      .addCase(GetUserNextAvailablePaymentDateAction.fulfilled, (state, action) => {
        state.getUserNextAvailablePaymentDateQueryStatus = QueryStatus.Fulfilled
        state.getUserNextAvailablePaymentDate = action.payload?.getUserNextAvailablePaymentDate
      })
      .addCase(GetUserNextAvailablePaymentDateAction.rejected, (state, action) => {
        state.error = action.error
        state.getUserNextAvailablePaymentDateQueryStatus = QueryStatus.Rejected
      })
  },
})

export const {actions} = UserSlice

export default UserSlice.reducer
