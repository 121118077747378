import {StackScreenProps} from '@react-navigation/stack'

import {banking} from '@possible/generated/proto'
import {getUserEnvSelector, LinkedAccountType} from 'src/cassandra'
import {
  BankAggregatorCompletedStatus,
  convertAggregatorStringToType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {usePfSelector} from 'src/store/utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'
import {getFilteredLinkedBankAccountsGql} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {hasValidFundableAccountGql} from 'src/lib/user/utils'
import {showUnsupportedAccountsPopup} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedAccount'
import {BankInfoAdded, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {AchPaymentMethod} from '@possible/cassandra/src/types/types.mobile.generated'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {BankCreateLinkedAccountsMutation} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/mutations/BankCreateLinkedAccounts.gqls'
import {BankAggregatorAccountSelectionLinkedAccountSubset} from 'src/products/general/GeneralPaymentMethods/BankAggregatorAccountSelection/BankAggregatorAccountSelection.types'

/**
 * Subset of fields on AchPaymentMethod that will be provided when a new ACH payment method
 * has been created via useBankAggregator().
 */
export type UseBankAggregatorNewAchPaymentMethod = {
  id: AchPaymentMethod['id']
  status: Pick<AchPaymentMethod['status'], 'code'>
  bankAccount: Pick<AchPaymentMethod['bankAccount'], 'friendlyName' | 'mask' | 'institution'>
  account?: Pick<NonNullable<AchPaymentMethod['account']>, 'id'> | null
}

export type LinkedAccountSubsetAfterPlaid =
  BankCreateLinkedAccountsMutation['bankCreateLinkedAccounts']['linkedAccounts'][0]

export type UseBankAggregatorOnCompleteCallback = (args: {
  bankName: string
  linkedAccounts?: LinkedAccountSubsetAfterPlaid[]
  newAchPaymentMethod?: UseBankAggregatorNewAchPaymentMethod
  aggregatorId: aggregatorsId
}) => void | Promise<void>

export type SelectAndVerifyAccountMode = 'ForAdhocPayment' | 'ForPrimaryAccount' | null

export type UseBankAggregatorReturnValue = {
  openBankAggregator: (args: {
    /**
     * If this is set the newly linked plaid account is guaranteed to have routing + account
     * numbers verified by the time onBankLinkComplete() is called. The user will be taken to the
     * account selection screen after plaid linking to pick which account to use and we will make sure
     * routing + account number are confirmed.
     */
    selectAndVerifyAccountMode?: SelectAndVerifyAccountMode
    onBankLinkComplete?: UseBankAggregatorOnCompleteCallback
    account?: banking.ILinkedAccount
  }) => void
}

/**
 * Hook that allows a component to send users to a banking aggregator to link a bank account.
 */
export const useBankAggregator = (
  navigation: StackScreenProps<MainStackParamList>['navigation'],
): UseBankAggregatorReturnValue => {
  const env = usePfSelector(getUserEnvSelector)

  /**
   * The function used by components to launch the bank aggregator.
   */
  const openBankAggregator: UseBankAggregatorReturnValue['openBankAggregator'] = (args: {
    selectAndVerifyAccountMode?: SelectAndVerifyAccountMode
    account?: banking.ILinkedAccount
    onBankLinkComplete?: UseBankAggregatorOnCompleteCallback
  }) => {
    const {
      onBankLinkComplete = (): void => {},
      account,
      selectAndVerifyAccountMode = null, // default to not select account + verify routing + account numbers
    } = args

    // Get the default aggregator from environment, or use Plaid if not set
    // env.bankingAggregatorId is set for a user in IAM under banking
    let aggregatorId: aggregatorsId =
      convertAggregatorStringToType(env?.bankingAggregatorId) ?? aggregatorsId.plaid

    // If relinking an account, use aggregator associated with that account
    if (account?.aggregatorId) {
      aggregatorId = convertAggregatorStringToType(account.aggregatorId) ?? aggregatorId
    }

    switch (aggregatorId) {
      case aggregatorsId.plaid:
      case aggregatorsId.mocked:
        navigateToPlaid({
          navigation,
          account,
          onBankLinkComplete,
          selectAndVerifyAccountMode,
        })
        break
      case aggregatorsId.yodlee:
        navigateToYodlee({
          navigation,
          account,
          onBankLinkComplete,
          selectAndVerifyAccountMode,
        })
        break
    }
  }
  return {
    openBankAggregator,
  }
}

/**
 * Navigates to Yodlee aggregator route.
 */
const navigateToYodlee = (args: {
  navigation: StackScreenProps<MainStackParamList>['navigation']
  account?: banking.ILinkedAccount
  onBankLinkComplete: UseBankAggregatorOnCompleteCallback
  selectAndVerifyAccountMode: SelectAndVerifyAccountMode
}): void => {
  const {navigation, account, onBankLinkComplete, selectAndVerifyAccountMode} = args
  navigation.navigate('AggregatorYodlee', {
    onComplete: async (success: boolean): Promise<void> => {
      // Do not call onBankLinkComplete if the aggregator failed, similarly to Plaid
      if (!success) {
        return
      }

      let linkedAccounts: Awaited<ReturnType<typeof getFilteredLinkedBankAccountsGql>> = []
      try {
        linkedAccounts = (await getFilteredLinkedBankAccountsGql()) ?? undefined
      } catch (e) {
        logAddPaymentMethodError(
          e,
          'useBankAggregator() navigateToYodlee() onComplete() failed to get linked accounts',
        )
      }
      void handleAggregatorOnComplete({
        navigation,
        bankName: 'yodlee', // no bank name from Yodlee
        linkedAccounts,
        onBankLinkComplete,
        selectAndVerifyAccountMode,
        aggregatorId: aggregatorsId.yodlee,
      })
    },
    account, // Account is passed to relink an existing account
  })
}

/**
 * Navigates to Plaid aggregator route.
 */
const navigateToPlaid = (args: {
  navigation: StackScreenProps<MainStackParamList>['navigation']
  account?: banking.ILinkedAccount
  onBankLinkComplete: UseBankAggregatorOnCompleteCallback
  selectAndVerifyAccountMode: SelectAndVerifyAccountMode
}): void => {
  const {navigation, account, onBankLinkComplete, selectAndVerifyAccountMode} = args
  navigation.navigate('AggregatorPlaid', {
    onComplete: (
      _: BankAggregatorCompletedStatus, // ignore status since it is only called with success
      bank: string,
      linkedAccounts?: LinkedAccountSubsetAfterPlaid[],
    ) =>
      void handleAggregatorOnComplete({
        navigation,
        bankName: bank,
        linkedAccounts,
        onBankLinkComplete,
        selectAndVerifyAccountMode,
        aggregatorId: aggregatorsId.plaid,
      }),
    account, // Account is passed to relink an existing account

    /**
     * onSwitch will pass a different aggregatorId that can be switched to if Plaid doesn't
     * support a selected institution. Only Yodlee is supported as an alternate aggregatorId.
     * If Yodlee is passed, navigate to Yodlee, otherwise do nothing.
     *
     * However, this list in firebase is now empty for dev and prod so this will never be called.
     * @param alternateId
     */
    onSwitch: (alternateId) => {
      if (convertAggregatorStringToType(alternateId) === aggregatorsId.yodlee) {
        navigateToYodlee({
          navigation,
          account,
          onBankLinkComplete,
          selectAndVerifyAccountMode,
        })
      } else {
        logAddPaymentMethodError(
          new Error(
            `useBankAggregator() navigateToPlaid() encountered an invalid alternateId=${alternateId}`,
          ),
        )
      }
    },
  })
}

/**
 * This callback is called by the aggregator when it's done linking the account.
 */
const handleAggregatorOnComplete = (args: {
  navigation: StackScreenProps<MainStackParamList>['navigation']
  bankName: string
  linkedAccounts?: LinkedAccountSubsetAfterPlaid[]
  onBankLinkComplete: UseBankAggregatorOnCompleteCallback
  selectAndVerifyAccountMode: SelectAndVerifyAccountMode
  aggregatorId: aggregatorsId
}): void => {
  const {
    navigation,
    bankName,
    linkedAccounts,
    onBankLinkComplete,
    selectAndVerifyAccountMode,
    aggregatorId,
  } = args
  BankInfoAdded()

  TrackAppEvent(AppEvents.Name.link_bank_account_successful, AppEvents.Category.Application, {
    bank: bankName,
  })

  // Do not call onBankLinkComplete if no valid fundable linked accounts returned.
  // For example, an investment account will fail this validation.
  // This really should be moved to the back-end to validate somehow.
  // NOTE: In Dev this will never be false, mocked accounts always seem to be fundable.
  const isValidFundableAccount = hasValidFundableAccountGql(linkedAccounts)
  if (!isValidFundableAccount || !linkedAccounts) {
    logAddPaymentMethodError(
      new Error('useBankAggregator() handleAggregatorOnComplete() no valid fundable accounts'),
    )
    showUnsupportedAccountsPopup()
    return
  }
  if (
    selectAndVerifyAccountMode === 'ForAdhocPayment' ||
    selectAndVerifyAccountMode === 'ForPrimaryAccount'
  ) {
    // if this is for an adhoc payment or primary account selection we will have the user select which new LinkedAccount from plaid they
    // want to use and we will make sure we've verified routing + account number
    navigateToAccountSelection({
      navigation,
      bankName,
      linkedAccounts,
      onBankLinkComplete,
      accountSelectionRouteName:
        selectAndVerifyAccountMode === 'ForAdhocPayment'
          ? 'BankAggregatorAccountSelectionForAdhocPayment'
          : 'BankAggregatorAccountSelectionForPrimaryAccount',
      aggregatorId,
    })
    return
  }
  // if this is not for an adhoc payment / primary account we're done as soon as linking with the aggregator is done
  void onBankLinkComplete?.({
    bankName,
    // Caveat, if this was Yodlee it will be all linked accounts, not just the newly linked ones
    linkedAccounts,
    aggregatorId,
  })
}

/**
 * Navigates to a route for users to select which bank account from the aggregator they want to use.
 * If the user's account needs routing + account numbers verified they will
 * be sent to BankVerifyRoutingAndAccount to ensure the account is ready for payment.
 */
const navigateToAccountSelection = (args: {
  accountSelectionRouteName:
    | 'BankAggregatorAccountSelectionForAdhocPayment'
    | 'BankAggregatorAccountSelectionForPrimaryAccount'
  navigation: StackScreenProps<MainStackParamList>['navigation']
  bankName: string
  linkedAccounts: LinkedAccountSubsetAfterPlaid[]
  onBankLinkComplete: UseBankAggregatorOnCompleteCallback
  aggregatorId: aggregatorsId
}): void => {
  const {
    accountSelectionRouteName,
    navigation,
    bankName,
    onBankLinkComplete,
    linkedAccounts,
    aggregatorId,
  } = args

  const linkedAccountsForSelection = linkedAccounts.filter((account) => {
    return account.type === LinkedAccountType.Checking || account.type === LinkedAccountType.Savings
  })
  if (linkedAccountsForSelection.length === 0) {
    TrackAppEvent(
      BankAccountManagementEvents.account_management_plaid_error,
      AppEvents.Category.BankAccountManagement,
      {
        reason: 'no_checking_savings',
      },
    )
    return
  }
  // add a property isNewAccount to indicate this is a new account so the UI can show that.
  // eventually we should be able to remove this and use a BE property, see ENG-17885
  const accountsFromAggregatorForSelection: BankAggregatorAccountSelectionLinkedAccountSubset[] =
    // currently our Yodlee implementation doesn't know which accounts are new. this will happen after ENG-17488 and ENG-17490
    // so for now we can't tell the account selection screen which accounts are new from Yodlee
    aggregatorId === aggregatorsId.yodlee
      ? linkedAccountsForSelection
      : linkedAccountsForSelection.map(
          (
            account: LinkedAccountSubsetAfterPlaid,
          ): BankAggregatorAccountSelectionLinkedAccountSubset => {
            return {
              ...account,
              isNewAccount: true,
            }
          },
        )

  navigation.navigate(accountSelectionRouteName, {
    accountsFromBankAggregator: accountsFromAggregatorForSelection,
    // if we successfully verified routing + account and created a new AchPaymentMethod
    // we're all done
    onVerifyRoutingAndAccountSucceeded: ({achPaymentMethod}) => {
      void onBankLinkComplete?.({
        bankName,
        linkedAccounts,
        newAchPaymentMethod: achPaymentMethod,
        aggregatorId,
      })
    },
    onVerifyRoutingAndAccountFailed: ({linkedAccountId, accountNumberMask}) => {
      // if the user chose an account to pay with but we failed to verify the
      // routing and account number we'll need to ask them to verify it manually
      navigation.navigate('BankVerifyRoutingAndAccount', {
        linkedAccountIdToAssociateWith: linkedAccountId,
        accountNumberMask,
        onSuccess: ({newAchPaymentMethod}) => {
          void onBankLinkComplete?.({
            bankName,
            linkedAccounts,
            newAchPaymentMethod,
            aggregatorId,
          })
        },
      })
    },
  })
}
