import {DecisioniongLoanDashboardAggregateStatusQueryHookResult} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {DashboardLoanDecisioningProps} from 'src/products/loans/Dashboard/DashboardLoanDecisioning/DashboardLoanDecisioning.types'

/**
 * Constructs complete props for DashboardLoanDecisioning given GraphQL response data for DashboardLoanAggregateStatusQuery.
 */
export const getDashboardLoanDecisioningProps = (config: {
  decisioningLoanDashboardData: Pick<
    DecisioniongLoanDashboardAggregateStatusQueryHookResult,
    'decisioningLoanAggregateStatus' | 'userProfile'
  >
}): DashboardLoanDecisioningProps => {
  const {decisioningLoanDashboardData} = config
  const {decisioningLoanAggregateStatus} = decisioningLoanDashboardData

  const props: DashboardLoanDecisioningProps = {
    delayed: decisioningLoanAggregateStatus.delayed,
  }
  return props
}
