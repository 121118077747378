import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {EmailVerificationGQLContainer} from 'src/products/loans/EmailVerification/EmailVerificationGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'

export type EmailVerificationRouteContainerProps = StackScreenProps<
  MainStackParamList,
  'EmailVerification'
>

/**
 * Integrates the EmailVerification screen into a typical react-navigation route using navigation.push().
 */
export const EmailVerificationRouteContainer: FC<EmailVerificationRouteContainerProps> = (
  props,
) => {
  const {navigation, route} = props

  const onSuccess = route.params?.onSuccess

  usePageViewedAnalytics({
    eventName: AppEvents.Name.enter_email_verification_code_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  const handleOnResendCodeTracking = (): void => {
    TrackAppEvent(
      AppEvents.Name.enter_email_verification_code_resend_selected,
      AppEvents.Category.Activation,
    )
  }

  const handleOnCodeSubmitted = (): void => {
    TrackAppEvent(
      AppEvents.Name.enter_email_verification_code_submitted,
      AppEvents.Category.Activation,
    )
    onSuccess(navigation)
  }

  return (
    <EmailVerificationGQLContainer
      onResendCodeTracking={handleOnResendCodeTracking}
      onCodeSubmitted={handleOnCodeSubmitted}
      withoutSendCodeOverlay
    />
  )
}
