import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppNav from 'src/nav/AppNavActions'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {
  useGetCardType,
  CardAccountType,
} from 'src/products/card/PhysicalCardActivation/useGetCardType'
import Image from 'src/products/general/Image/Image'
import {ImageSourcePropType} from 'react-native'

/* eslint-disable @typescript-eslint/no-var-requires */

type ImagesMap = Record<CardAccountType, JSX.Element>
const cardImages: ImagesMap = {
  [CardAccountType.Card400]: (
    <Image
      source={require('src/assets/images/CardFront400.png') as ImageSourcePropType}
      resizeMode={'contain'}
    />
  ),
  [CardAccountType.Card800]: (
    <Image
      source={require('src/assets/images/CardFront800.png') as ImageSourcePropType}
      resizeMode={'contain'}
    />
  ),
}

type Props = StackScreenProps<MainStackParamList, 'CardPhysicalCardActivated'>
const CardPhysicalCardActivated: FC<Props> = (props) => {
  const {navigation} = props
  usePageViewedAnalytics({
    eventName: CardEvents.card_physical_card_activated_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardPhysicalCardActivated', 'Common'])
  const cardType = useGetCardType()

  return (
    <Page
      variant={'generic'}
      title={t('YourPossibleCardIsReady')}
      smallTopGap={true}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: (): void => {
            TrackAppEvent(CardEvents.card_physical_card_activated_page_cta, AppEvents.Category.Card)
            AppNav.PopToName(navigation, 'CardDashboard')
          },
        },
      }}
    >
      <Box>
        <Box marginVertical="little" align={'center'}>
          {cardImages[cardType]}
        </Box>
        <Box marginTop={'medium'} marginBottom={'little'}>
          <PFText variant="p_lg_semibold">{t('Subtitle')}</PFText>
        </Box>
        <PFText variant="p">{t('Description')}</PFText>
      </Box>
    </Page>
  )
}

export default CardPhysicalCardActivated
