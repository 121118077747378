import React, {FC} from 'react'
import {View} from 'react-native'
import {useTranslation} from 'react-i18next'

import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {useCards} from 'src/products/card/hooks/useCards'
import {LedgerItem} from 'src/products/card/components/molecules/LedgerItem/LedgerItem'
import {
  CardDashboardBalanceLedgerItem,
  CardDashboardBalanceLedgerKind,
  getCardDashboardBalanceLedger,
} from 'src/products/card/Dashboard/CardDashboardHeader/CardDashboardBalanceLedger/CardDashboardBalanceLedger.utils'
import {PendingPaymentHelpOverlay} from 'src/products/card/Dashboard/CardDashboardHeader/CardDashboardBalanceLedger/PendingPaymentHelpOverlay'
import {PendingTransactionsHelpOverlay} from 'src/products/card/Dashboard/CardDashboardHeader/CardDashboardBalanceLedger/PendingTransactionsHelpOverlay'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {CardEventProperties} from 'src/lib/Analytics/eventProperties'

const CardDashboardBalanceLedger: FC = () => {
  const {t} = useTranslation(['CardDashboardHeader', 'Common'])
  const {accountStatus, availableBalance, totalPendingPayments, totalPendingTransactions} =
    useCards()
  const [isPendingPaymentOverlayVisible, setIsPendingPaymentOverlayVisible] = React.useState(false)
  const [isPendingTransactionsOverlayVisible, setIsPendingTransactionsOverlayVisible] =
    React.useState(false)

  const handleOnPressTogglePendingPaymentOverlay = (): void =>
    setIsPendingPaymentOverlayVisible((prevState) => !prevState)

  const handleOnPressPendingPaymentOverlay = (trackingLocation: string) => (): void => {
    handleOnPressTogglePendingPaymentOverlay()
    TrackAppEvent(CardEvents.card_pending_payment_help_clicked, AppEvents.Category.Card, {
      location: trackingLocation,
    })
  }

  const handleOnPressTogglePendingTransactionsOverlay = (): void =>
    setIsPendingTransactionsOverlayVisible((prevState) => !prevState)

  const handleOnPressPendingTransactionsOverlay = (): void => {
    handleOnPressTogglePendingTransactionsOverlay()
    TrackAppEvent(CardEvents.card_pending_transaction_help_clicked, AppEvents.Category.Card)
  }

  const ledgerItems: CardDashboardBalanceLedgerItem[] = [
    {
      kind: CardDashboardBalanceLedgerKind.PendingPayment,
      title: t('PendingPayment'),
      amount: convertToDollarAmt(totalPendingPayments),
      hideIfZero: true,
      onPress: handleOnPressPendingPaymentOverlay(
        CardEventProperties.card_pending_payment_help_locations.card_dashboard_header,
      ),
      overlay: (
        <PendingPaymentHelpOverlay
          isVisible={isPendingPaymentOverlayVisible}
          onDismiss={handleOnPressTogglePendingPaymentOverlay}
        />
      ),
    },
    {
      kind: CardDashboardBalanceLedgerKind.PendingTransactions,
      title: t('PendingTransactions'),
      amount: convertToDollarAmt(totalPendingTransactions * -1),
      hideIfZero: true,
      onPress: handleOnPressPendingTransactionsOverlay,
      overlay: (
        <PendingTransactionsHelpOverlay
          isVisible={isPendingTransactionsOverlayVisible}
          onDismiss={handleOnPressTogglePendingTransactionsOverlay}
        />
      ),
    },
    {
      kind: CardDashboardBalanceLedgerKind.AvailableToSpend,
      title: t('AvailableToSpend'),
      amount: availableBalance,
      separator: true,
    },
  ]

  return getCardDashboardBalanceLedger(accountStatus, ledgerItems).map(
    ({
      title,
      amount,
      separator: hasSeparator = false,
      onPress: handleOnPress,
      overlay,
    }: CardDashboardBalanceLedgerItem): JSX.Element => (
      <View key={title}>
        <LedgerItem
          title={title}
          amount={amount}
          separator={hasSeparator}
          onPress={handleOnPress}
        />
        {overlay ?? null}
      </View>
    ),
  )
}

export {CardDashboardBalanceLedger}
