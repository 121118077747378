import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import Alert from 'src/designSystem/components/molecules/Alert/Alert'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {CardLevel} from 'src/products/loans/ProductChoice/types'
import {CardApplicationTile} from 'src/products/loans/ProductChoice/CardApplicationTile'

export type PossibleCardSelectionProps = {
  onTileClick: () => void
  cardLevels: CardLevel[]
  buttonDisabled?: boolean
  handleOnPressExitAndCancelCardApplication: () => Promise<void>
  isInCardBureauFlow: boolean
  isUserLoanGrad: boolean
}

export const PossibleCardSelection: React.FC<PossibleCardSelectionProps> = (props) => {
  const {
    onTileClick: handleOnTileClick,
    cardLevels,
    buttonDisabled: isButtonDisabled,
    handleOnPressExitAndCancelCardApplication,
    isInCardBureauFlow,
    isUserLoanGrad,
  } = props
  const {t} = useTranslation(['PossibleCard', 'Common'])

  usePageViewedAnalytics({
    eventName: CardEvents.card_selection_page_viewed,
    eventCategory: AppEvents.Category.Card,
    logEventToSingular: true,
  })

  const renderCardOptions: () => JSX.Element[] = () => {
    const sortedCardLevels = [...cardLevels].sort((a, b) => a.creditAmount - b.creditAmount)
    return sortedCardLevels.map((level) => (
      <CardApplicationTile
        key={level.creditAmount}
        creditAmount={level.creditAmount}
        monthlyFee={level.monthlyFee}
        onApplyClick={handleOnTileClick}
        buttonDisabled={isButtonDisabled}
        isInCardBureauFlow={isInCardBureauFlow}
      />
    ))
  }

  return (
    <Box>
      {isUserLoanGrad ? (
        <Box width={'100%'}>
          <Alert
            level="info"
            title={'Apply For A Loan Instead'}
            onPress={handleOnPressExitAndCancelCardApplication}
          />
        </Box>
      ) : null}
      {renderCardOptions()}
      <Box marginTop="small">
        <PFText variant={'p_sm'} color={'textDisabled'} textAlign="center">
          {t('MasterCardDisclaimer')}
        </PFText>
      </Box>
    </Box>
  )
}
