import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {darkGrayText} from 'src/designSystem/semanticColors'

type SelectAmountTileHeaderProps = {
  nextAmount: number
  prequalificationAmount?: number
  requestLimit?: number
}

const toFixedNumber = 2

const SelectAmountTileHeader: FC<SelectAmountTileHeaderProps> = (props) => {
  const {nextAmount, prequalificationAmount, requestLimit} = props
  const {t} = useTranslation('LoanSelection')

  return (
    <Box>
      {prequalificationAmount ? (
        <Box align={'center'} paddingBottom={'small'}>
          <PFText variant={'label_md'} color={darkGrayText}>
            {t('PrequalifiedAmount', {
              prequalificationAmount: prequalificationAmount?.toFixed(toFixedNumber),
            })}
          </PFText>
        </Box>
      ) : null}
      {requestLimit ? (
        <Box align={'center'} paddingBottom={'small'}>
          <PFText variant="label_md">Request Up to ${requestLimit.toFixed(toFixedNumber)}</PFText>
        </Box>
      ) : null}
      <PFText variant={'d2'} textAlign={'center'} testID="LoanAmountSelection_AmountSelected">
        ${nextAmount.toFixed(toFixedNumber)}
      </PFText>
      <PFText variant={'p'} textAlign={'center'}>
        {t('RequestedAmount')}
      </PFText>
    </Box>
  )
}

export default SelectAmountTileHeader
