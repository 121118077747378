import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import Group1573 from 'src/assets/illustrations/Group1573.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import {disabledBackground, primaryBackgroundColor} from 'src/designSystem/semanticColors'

type LoanLengthProps = {
  loanDuration: number
}

const LoanLength: FC<LoanLengthProps> = (props) => {
  const {loanDuration} = props
  const {t} = useTranslation('LoanSelection')

  return (
    <Box
      direction={'row'}
      paddingHorizontal={'small'}
      paddingVertical={'little'}
      width={'100%'}
      background={primaryBackgroundColor}
      boxStyle={styles.boxStyle}
    >
      <Box direction={'row'} gap={'little'} align={'center'} paddingVertical={'little'}>
        <PFSvgContain svg={Group1573} width={24} />
        <PFText variant={'label_sm'}>{t('LoanLengthStatement', {duration: loanDuration})}</PFText>
      </Box>
    </Box>
  )
}

export default LoanLength

const styles = StyleSheet.create({
  boxStyle: {
    borderTopColor: disabledBackground,
    borderTopWidth: 1,
  },
})
