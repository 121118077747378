import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {ApplyMutation} from '@possible/cassandra/src/utils/operations'
import * as user from 'src/api/actions/user/userActions'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import Snackbar from 'src/lib/Snackbar'
import {queryPlaidLinkToken} from 'src/lib/bank/queryPlaidLinkToken'
import {ShowException} from 'src/lib/errors'
import i18n from 'src/lib/localization/i18n'
import Log from 'src/lib/loggingUtil'
import {PopPage} from 'src/navigation/NavHelper'
import {
  AggregatorPlaidProps,
  handleInstitutionsFlowType,
  onExitType,
  onLinkedEventType,
  onSuccessEventType,
  onSuccessType,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidOld/AggregatorPlaid.types'
import AggregatorPlaidView from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidOld/AggregatorPlaidView'
import {CALLBACK_STATUS} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import PlaidMaintenance from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/PlaidMaintenancePage'
import {
  aggregatorSwitchFromPlaid,
  showSwitchAggregatorPopup,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/PlaidUnsupportedInstitutions'
import {
  institutionIsSupported,
  showUnsupportedInstitutionPopup,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedInstitutions/UnsupportedInstitutions'
import {
  BankCreateLinkedAccountsDocument,
  BankCreateLinkedAccountsMutation,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/mutations/BankCreateLinkedAccounts.gqls'
import {
  getNormalizedInstitutionIdFromMetadata,
  getNormalizedInstitutionNameFromMetadata,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/plaidUtils'
import {useExecuteOnce} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/useExecuteOnce'
import {logAddPaymentMethodError} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {PfDispatch} from 'src/store/types'
import {usePfDispatch} from 'src/store/utils'
import {LinkError, LinkErrorCode, LinkExitMetadata} from 'react-native-plaid-link-sdk/dist/Types'

// The link error codes in Plaid's sdk don't always match what the Plaid server actually returns
enum HonestLinkErrorCode {
  ITEM_NO_ERROR = 'item-no-error',
}

type popType = () => void
type setBusyType = (busy: boolean) => void
type OnCompleteType = (
  status: string,
  bankName?: string,
  linkedAccounts?: BankCreateLinkedAccountsMutation['bankCreateLinkedAccounts']['linkedAccounts'],
) => void
type onLinkedType = (metadata: onLinkedEventType) => Promise<void>
type onAlreadyLinkedType = (metadata: onExitEventType) => Promise<void>
type lastSelectedInstitutionType = {id: string; name: string} | null
type onExitEventType = LinkExitMetadata &
  Partial<LinkError> & {
    // web uses error_code, ios/android use errorCode
    error_code?: LinkErrorCode | HonestLinkErrorCode
    errorCode?: LinkErrorCode | HonestLinkErrorCode
  }

const onExitComplete = (pop: popType, exit_status?: string) => {
  switch (exit_status) {
    case 'requires_credentials':
      Log.log('Left Plaid stage without creating account')
      pop()
      return
    case 'institution_not_found':
      Log.log('User failed to find institution of choice')
      pop()
      return
    default:
      pop()
      return
  }
}

const onLinkedCallback = async (
  metadata: onLinkedEventType,
  setBusy: setBusyType,
  pop: popType,
  dispatch: PfDispatch,
  onComplete: OnCompleteType,
) => {
  try {
    const institutionId = getNormalizedInstitutionIdFromMetadata(metadata)
    const institutionName = getNormalizedInstitutionNameFromMetadata(metadata)
    const isInstitutionSupported = await institutionIsSupported(institutionId.toString())
    const hasToSwitch = !!aggregatorSwitchFromPlaid(institutionId.toString())

    if (isInstitutionSupported && !hasToSwitch) {
      setBusy(true)

      const response = await ApplyMutation(BankCreateLinkedAccountsDocument, {
        input: {publicToken: metadata.public_token ?? ''},
      })

      const linkedAcccounts = response?.bankCreateLinkedAccounts.linkedAccounts

      await dispatch(user.UserStateRefresh())

      pop() //this has to happen before calling onComplete or it breaks the reapply flow

      if (onComplete) {
        const bankName = institutionName ?? ''
        onComplete(CALLBACK_STATUS.SUCCESS, bankName, linkedAcccounts)
      }
    }
  } catch (e) {
    pop()
    ShowException(e)
  }
}

const onSuccessCallback = async (
  data: onSuccessEventType,
  onComplete: OnCompleteType,
  onLinked: onLinkedType,
  verifySelectedInstitution: handleInstitutionsFlowType,
  pop: popType,
) => {
  try {
    if (data.public_token) {
      const institutionId = getNormalizedInstitutionIdFromMetadata(data)
      const institutionName = getNormalizedInstitutionNameFromMetadata(data)

      await onLinked(data)

      verifySelectedInstitution(institutionId, institutionName)
      onExitComplete(pop, data.status)
    } else {
      logAddPaymentMethodError(
        new Error('onSuccessCallback(): Plaid did not return a public token.'),
      )
      Snackbar.error({
        title: i18n.t('AccountManagement:errorLinkingAccount'),
        duration: Snackbar.LENGTH_LONG,
      })
      pop()
    }
  } catch (e) {
    logAddPaymentMethodError(e, 'onSuccessCallback() error')
    Snackbar.error({
      title: i18n.t('AccountManagement:errorLinkingAccount'),
      duration: Snackbar.LENGTH_LONG,
    })
    pop()
  }
}

const onExitCallback = (
  data: onExitEventType,
  dispatch: PfDispatch,
  pop: popType,
  onAlreadyLinked: onAlreadyLinkedType,
  lastSelectedInstitution: lastSelectedInstitutionType,
  verifySelectedInstitution: handleInstitutionsFlowType,
) => {
  try {
    const errorCode = data?.error_code ?? data?.errorCode
    if (errorCode) {
      try {
        // This log is used by the backend, do not remove
        Log.warn(`Plaid link error ${JSON.stringify(data)}`)
      } catch (e) {
        // ignore
      }
    }
    // A link token can become invalidated if it expires, has already been used
    // for a link session, or is associated with too many invalid logins.
    if (errorCode === LinkErrorCode.INVALID_LINK_TOKEN) {
      onExitComplete(pop)
    } else if (
      errorCode === LinkErrorCode.ITEM_NO_ERROR ||
      errorCode === HonestLinkErrorCode.ITEM_NO_ERROR
    ) {
      onAlreadyLinked(data)
    } else {
      if (lastSelectedInstitution) {
        verifySelectedInstitution(lastSelectedInstitution.id, lastSelectedInstitution.name)
      }
      onExitComplete(pop, data?.status)
    }
  } catch (e) {
    Log.log('Plaid error: ' + e)
  }
}

const onInstitutionSelectedCallback = (
  institution_id: string,
  institution_name: string,
  setLastSelectedInstitution: (param: lastSelectedInstitutionType) => void,
) => {
  if (institution_id) {
    setLastSelectedInstitution({id: institution_id, name: institution_name})
    TrackAppEvent(AppEvents.Name.link_bank_account_bank_selected, AppEvents.Category.Application, {
      value: institution_name,
    })
  }
}

const AggregatorPlaid: FC<AggregatorPlaidProps> = (props) => {
  const dispatch = usePfDispatch()
  const [busy, setBusy] = useState(false)
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | undefined>()
  const [showMaintenanceScreen, setShowMaintenanceScreen] = useState(false)
  const hasVerifiedSelectedInstitution = useRef<boolean>(false)
  const lastSelectedInstitution = useRef<lastSelectedInstitutionType>(null)

  const {navigation, onComplete, account, onSwitch} = props

  // The widget throws exit a couple times, to prevent double pops we limit to just one
  const pop = useExecuteOnce<null>(() => {
    PopPage(navigation)
  })

  useEffect(() => {
    async function getPlaidLinkToken() {
      const linkToken = await queryPlaidLinkToken(account?.id)
      if (!linkToken) {
        setShowMaintenanceScreen(true)
      } else {
        setPlaidLinkToken(linkToken)
      }
    }
    getPlaidLinkToken()
  }, [account])

  const onLinked: onLinkedType = useCallback(
    async (metadata: onLinkedEventType) =>
      onLinkedCallback(metadata, setBusy, pop, dispatch, onComplete),
    [setBusy, pop, dispatch, onComplete],
  )

  const handleSupportedInstitutionsFlow: handleInstitutionsFlowType = useCallback(
    (institution_id: string, institution_name: string) => {
      const switchTo = aggregatorSwitchFromPlaid(institution_id.toString())
      if (switchTo) {
        pop()
        showSwitchAggregatorPopup(institution_name, switchTo, account?.id, onSwitch)
      }
    },
    [pop, account, onSwitch],
  )

  const handleUnsupportedInstitutionsFlow: handleInstitutionsFlowType = useCallback(
    (institution_id: string, institution_name: string) => {
      pop()
      showUnsupportedInstitutionPopup(institution_name)
    },
    [pop],
  )

  const verifySelectedInstitution: handleInstitutionsFlowType = useCallback(
    async (institutionId: string, institutionName: string) => {
      if (hasVerifiedSelectedInstitution.current) {
        return
      }

      if (await institutionIsSupported(institutionId.toString())) {
        handleSupportedInstitutionsFlow(institutionId, institutionName)
      } else {
        handleUnsupportedInstitutionsFlow(institutionId, institutionName)
      }
      hasVerifiedSelectedInstitution.current = true
    },
    [handleUnsupportedInstitutionsFlow, handleSupportedInstitutionsFlow],
  )

  const onAlreadyLinked: onAlreadyLinkedType = useCallback(
    async (metadata: onExitEventType) =>
      //Trigger relink, this handles when pf server and plaid account status gets out of sync
      onLinked({...metadata, public_token: plaidLinkToken}),
    [onLinked, plaidLinkToken],
  )

  const onSuccess: onSuccessType = useCallback(
    async (data: onSuccessEventType) =>
      onSuccessCallback(data, onComplete, onLinked, verifySelectedInstitution, pop),
    [onComplete, onLinked, verifySelectedInstitution, pop],
  )

  const onExit: onExitType = useCallback(
    (data: onExitEventType) => {
      onExitCallback(
        data,
        dispatch,
        pop,
        onAlreadyLinked,
        lastSelectedInstitution?.current,
        verifySelectedInstitution,
      )
    },
    [dispatch, pop, onAlreadyLinked, lastSelectedInstitution, verifySelectedInstitution],
  )

  const onInstitutionSelected: handleInstitutionsFlowType = useCallback(
    (institution_id, institution_name) => {
      const setLastSelectedInstitution = (inst: lastSelectedInstitutionType) =>
        (lastSelectedInstitution.current = inst)
      onInstitutionSelectedCallback(institution_id, institution_name, setLastSelectedInstitution)
    },
    [lastSelectedInstitution],
  )

  if (showMaintenanceScreen) {
    return <PlaidMaintenance />
  }

  if (!plaidLinkToken || busy) {
    return <Loading type={'loader0'} size={'large'} />
  }

  return (
    <AggregatorPlaidView
      plaidLinkToken={plaidLinkToken}
      onInstitutionSelected={onInstitutionSelected}
      onSuccess={onSuccess}
      onExit={onExit}
    />
  )
}

export default withForwardedNavigationParams<AggregatorPlaidProps>()(AggregatorPlaid)
