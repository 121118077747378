import {useFocusEffect} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {LayoutChangeEvent, StyleSheet} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'

import Money from 'src/assets/illustrations/Money.svg'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import ButtonLockup, {
  ButtonLockupProps,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents from 'src/lib/Analytics/app_events'
import {clearDeeplink, getDeeplink} from 'src/lib/singular/utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {partnerSelectOffer} from '@possible/cassandra/src/user/authPublicMethods'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {contentMaxWidth} from 'src/designSystem/layout'

const minHeightForSVG = 700

type ListItemProps = {
  iconName: SvgIconProps['name']
  iconSize?: SvgIconProps['size']
  text: string
  testIDFragment: string
}
const ListItem: React.FC<ListItemProps> = (props) => {
  const {iconName, iconSize, text, testIDFragment} = props

  return (
    <Box direction="row" gap="medium" align="center">
      <Box width={24} height={24} align="center" justify="center">
        <SvgIcon name={iconName} size={iconSize ?? 'medium'} colorVariant={'success'} />
      </Box>
      <PFText variant="p" testID={`partner-landing-list-item-${testIDFragment}`}>
        {text}
      </PFText>
    </Box>
  )
}

type Props = StackScreenProps<MainStackParamList, 'Landing'>
const PartnerLanding: React.FC<Props> = (props) => {
  const {navigation} = props

  const {t} = useTranslation('OpeningLandingPage')

  const [height, setHeight] = useState(0.0)

  usePageViewedAnalytics({
    eventName: AppEvents.Name.partner_landing_screen_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  const focusEffect = useCallback(() => {
    async function asyncFocusEffect(): Promise<void> {
      const offer = getDeeplink('offer')
      if (offer) {
        try {
          await partnerSelectOffer(offer.params.offer_id)
        } catch (e) {
          // ignore, error is already logged by Cassandra
        }
        await clearDeeplink(offer)
      }
    }
    asyncFocusEffect()
  }, [])

  useFocusEffect(focusEffect)

  const handleLayout = (event: LayoutChangeEvent): void => {
    setHeight(event.nativeEvent.layout.height)
  }

  const buttonProps: ButtonLockupProps = {
    type: 'doubleButton',
    primary: {
      text: t('PrimaryButton'),
      onPress: () => {
        navigation.navigate('Registration')
      },
      testID: 'partner-landing-sign-up-button',
    },
    secondary: {
      buttonText: t('SecondaryButtonAlt'),
      onPress: () => {
        navigation.navigate('Login')
      },
      testID: 'partner-landing-sign-in-button',
    },
  }

  const tinyScreen = height < minHeightForSVG

  return (
    <Box
      padding="medium"
      fill
      boxStyle={{maxWidth: contentMaxWidth}}
      alignSelf="center"
      onLayout={handleLayout}
    >
      <SafeAreaView edges={['top', 'right', 'left']} style={styles.flexStyle}>
        <Box boxStyle={styles.flexStyle} justify="center" paddingVertical="medium">
          <Box gap="medium" align="start" justify="center" padding="small">
            {!tinyScreen ? (
              <Box
                paddingHorizontal="enormous"
                boxStyle={{height: height * 0.25}}
                alignSelf="center"
                marginBottom="small"
                fill="horizontal"
                align="center"
              >
                <PFSvgContain svg={Money} height="100%" />
              </Box>
            ) : null}
            <PFText variant="h3" testID="partner-landing-title">
              {t('PartnerLandingTitle')}
            </PFText>
            <ListItem iconName={'money'} text={t('PartnerLandingItem1')} testIDFragment="1" />
            <ListItem iconName={'scale'} text={t('PartnerLandingItem2')} testIDFragment="2" />
            <ListItem iconName={'clock'} text={t('PartnerLandingItem3')} testIDFragment="3" />
            <ListItem
              iconName={'largeArrowUpRight'}
              iconSize={'small'}
              text={t('PartnerLandingItem4')}
              testIDFragment="4"
            />
          </Box>
        </Box>
      </SafeAreaView>
      <ButtonLockup {...buttonProps} />
    </Box>
  )
}

export default PartnerLanding

const styles = StyleSheet.create({
  flexStyle: {
    flex: 1,
  },
})
