import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {smallGap} from 'src/designSystem/layout'
import AppEvents from 'src/lib/Analytics/app_events'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import TileListView from 'src/products/loans/components/molecules/TileListView'
import Documents from 'src/products/loans/LoanHistory/Documents'
import {statusList} from 'src/lib/loans/consts'
import {formatShortNumericDate} from 'src/lib/time_util'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {PfReduxState} from 'src/reducers/types'
import {usePfSelector} from 'src/store/utils'
import {
  LoanHistoryDocument,
  LoanHistoryQuery,
} from 'src/products/loans/LoanHistory/LoanHistory.gqls'
import {Transfer} from 'src/lib/loans/reducers/types'
import {LoanSubstatusCode} from '@possible/cassandra/src/types/types.mobile.generated'

const VISIBLE_LOANS = [
  statusList.ACTIVE,
  statusList.PENDING,
  statusList.PAIDOFF,
  statusList.CHARGED_OFF,
  statusList.LATE,
  statusList.DEFAULT,
  statusList.APPROVED,
]

const NO_DOC_LOANS = [
  statusList.REJECTED,
  statusList.PENDING,
  statusList.APPROVED,
  statusList.EXPIRED,
  statusList.CANCELLED,
]

const TRANSFER_STATUS_DUE = [statusList.PENDING, statusList.FAILED]

type LoanItem = {
  title: string
  date: string
  status: string
}

export type LoanHistoryType = NonNullable<LoanHistoryQuery['me']['loans']['all']>['0']

const LoanHistory: FC = () => {
  const {t} = useTranslation('LoanHistory')
  const allTransfers: Record<string, Transfer[]> =
    usePfSelector((state: PfReduxState) => state?.lib?.loans?.transfers) ?? {}
  const navigation: StackNavigationProp<MainStackParamList, 'LoanHistory', undefined> =
    useNavigation()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.lefthand_drawer_loan_history_screen_viewed,
    eventCategory: AppEvents.Category.Admin,
  })

  const {selectedData} = useCassandraQuery(
    LoanHistoryDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data: LoanHistoryQuery) => data.me.loans,
  )
  const getProps = (loan: LoanHistoryType): LoanItem => {
    const transfers = loan?.id ? allTransfers?.[loan.id] : undefined
    const count =
      transfers?.reduce(
        (sum, transfer) =>
          sum + (TRANSFER_STATUS_DUE.includes(transfer.status as statusList) ? 1 : 0),
        0,
      ) ?? 0
    const data = {
      title: t('CurrentLoan'),
      date: t('CurrentLoanDate', {date: formatShortNumericDate(loan?.originationAt)}),
      status: t('CurrentLoanStatus', {count: count}),
    }
    const createdAt = formatShortNumericDate(loan.createdAt)
    const loanStatus = loan.status.code.toLowerCase() as statusList
    switch (loanStatus) {
      case statusList.PENDING:
        {
          data.title = t('LoanSubmittedTitle')
          data.date = t('LoanSubmittedDate', {date: createdAt})
          data.status = t('LoanSubmittedStatus')
        }
        break
      case statusList.APPROVED:
        {
          data.title = t('LoanApprovedTitle')
          data.date = t('LoanApproveDate', {date: createdAt})
          data.status = t('LoanApprovedStatus')
        }
        break
      case statusList.CHARGED_OFF:
        {
          const isChargedOffCollected =
            'substatusCode' in loan.status &&
            loan.status.substatusCode === LoanSubstatusCode.ChargedoffCollectedFull
          data.title = isChargedOffCollected ? t('PaidOffStatus') : t('ChargedOffStatus')
        }
        break
      case statusList.PAIDOFF:
        data.title = t('PaidOffStatus')
    }

    return data
  }

  function renderLoan(loan: LoanHistoryType, showDocs: boolean, showStatus: boolean): JSX.Element {
    const {title, date, status} = getProps(loan)
    return (
      <View key={loan.id} style={styles.loan_card_view}>
        <View style={[styles.postextPadding, styles.loan_card_header_view]}>
          <View style={styles.loanState}>
            <PFText variant={'p_lg_semibold'}>{title}</PFText>
          </View>
          <View style={styles.amount_view}>
            <PFText variant={'p_lg_semibold'}>${loan.amountBorrowed}</PFText>
          </View>
        </View>
        <View style={styles.postextPadding}>
          <PFText variant={'p_sm'}>{date}</PFText>
        </View>
        {showStatus ? (
          <View style={styles.postextPadding}>
            <PFText variant={'p_sm'}>{status}</PFText>
          </View>
        ) : null}
        {showDocs ? <Documents navigation={navigation} loan={loan} /> : null}
      </View>
    )
  }

  function renderLoans(): (JSX.Element | null)[] | undefined {
    return selectedData?.all?.map((loan) => {
      let showDocs = true
      let showStatus = false
      if (!loan) return null
      const loanStatus =
        'code' in loan.status ? (loan.status.code.toLowerCase() as statusList) : null
      if (!loanStatus) return null
      if (!VISIBLE_LOANS.includes(loanStatus)) return null

      if (NO_DOC_LOANS.includes(loanStatus)) showDocs = false

      if (loan.id === selectedData?.latestActionableLoan?.id) {
        showStatus = true
      }
      return renderLoan(loan, showDocs, showStatus)
    })
  }

  return (
    <Page title={t('LoanHistoryTitle')} variant="generic">
      <View style={styles.tileListView}>
        <TileListView>
          <View />
          {renderLoans()}
          <View />
        </TileListView>
      </View>
    </Page>
  )
}

export {LoanHistory}

const styles = StyleSheet.create({
  amount_view: {
    alignSelf: 'flex-end',
  },
  loanState: {
    alignSelf: 'flex-start',
  },
  loan_card_header_view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loan_card_view: {
    marginBottom: smallGap,
    paddingTop: smallGap,
  },
  postextPadding: {
    flex: 1,
    marginBottom: smallGap / 2,
  },
  tileListView: {
    flex: 1,
    marginBottom: smallGap / 2,
    width: '100%',
  },
})
