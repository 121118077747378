import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  InterstitialList,
  InterstitialListItem,
} from 'src/designSystem/components/molecules/InterstitialList/InterstitialList'
import Calendar from 'src/assets/illustrations/Calendar.svg'
import CardLocked from 'src/assets/illustrations/CardLocked.svg'
import CardPayTime from 'src/assets/illustrations/CardPayTime.svg'
import CardStatement from 'src/assets/illustrations/CardStatement.svg'
import {PushPage} from 'src/navigation/NavHelper'
import {PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardHowPayOverTimeWorksManualDocument} from 'src/products/card/PayOverTime/CardHowPayOverTimeWorksManualQuery.gqls'

type Props = StackScreenProps<MainStackParamList, 'CardHowPayOverTimeWorksManual'>

const CardHowPayOverTimeWorksManual: React.FC<Props> = (props) => {
  const {t} = useTranslation(['CardHowPayOverTimeWorks', 'Common'])

  const {selectedData: payments} = useCassandraQuery(
    CardHowPayOverTimeWorksManualDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
    (data) => data.me.cardAccounts.active?.installmentPlans.potential?.payments,
  )

  const numberOfPayments = payments?.length ?? 0

  const graphicalItems: InterstitialListItem[] = [
    {
      id: 1,
      title: t('YoullNeedToEnroll'),
      subtext: t('ToUsePayOverTime'),
      illustration: Calendar,
    },
    {
      id: 2,
      title: t('YourCardWillBeLocked'),
      subtext: t('DoNotForgetToUpdateAnyAutomatic'),
      illustration: CardLocked,
    },
    {
      id: 3,
      title: t('YouPayYourFullBalance', {numberOfPayments}),
      subtext: t('AnyPendingChargesOrCredits'),
      illustration: CardPayTime,
    },
    {
      id: 4,
      title: t('OnceYourBalanceIsPaid'),
      subtext: t('AfterYourFinalPayment'),
      illustration: CardStatement,
    },
  ]

  return (
    <Page
      testID="how_pot_works_page"
      variant="generic"
      title={t('HowPaymentsWorkPossibleCard')}
      smallTopGap
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('HowDoesAutopayWork'),
          onPress: () => {
            PushPage(props.navigation, 'HowDoesAutopayWork', {
              flow: PaymentFlow.PayOverTime,
            })
          },
        },
      }}
    >
      <InterstitialList items={graphicalItems} illustrationJustify={'flex-start'} />
    </Page>
  )
}

export default CardHowPayOverTimeWorksManual
