import {MutationResult} from '@apollo/client'
import {useCallback, useState} from 'react'
import {GraphQLFormattedError} from 'graphql'

import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {Consumer} from '@possible/cassandra'
import {LoanAccepted, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {UseAcceptLoanDocument} from 'src/products/loans/LoanApprovedActivation/useAcceptLoan/useAcceptLoan.gqls'

export type UseAcceptLoanArguments = Consumer.types.LoanAcceptInput

const trackError = (
  exception: Error | GraphQLFormattedError,
  service: 'submitLoan' | 'retrieveLoan' | 'exception' | 'getNextAvailableDisbursementDate',
  overrideMessage?: string,
): void => {
  const message = exception.message
  Log.error(exception, `${service} error`)
  TrackAppEvent(AppEvents.Name.final_loan_acceptance_failed, AppEvents.Category.Activation, {
    service: service,
    error: message,
  })
  if (overrideMessage) {
    ShowException(overrideMessage ?? message)
  }
}

export const useAcceptLoan = (): [
  (input: UseAcceptLoanArguments) => Promise<void | boolean>,
  MutationResult<Consumer.types.LoanAcceptMutation>,
  boolean,
] => {
  const [fetchLoanCount] = useCassandraLazyQuery(UseAcceptLoanDocument)

  const [acceptLoan, requestInfo] = Consumer.hooks.useLoanAcceptMutation()
  const [hasAchMaskAccountError, setHasAchMaskAccountError] = useState(false)

  const handleAcceptLoan = useCallback(
    async (input: UseAcceptLoanArguments) => {
      const result = await acceptLoan({variables: {acceptInput: input}})
      if (result.errors) {
        if (
          Consumer.errors.containsError(result.errors, Consumer.errors.ErrorCodes.AchMaskMatchFail)
        ) {
          setHasAchMaskAccountError(true)
        }
        trackError(result.errors[0], 'submitLoan', 'Unable to submit loan')
        return false
      }

      Log.info('acceptLoan results', result.data)

      let hasSentPurchaseEvent = false
      const loanUserId = result.data?.loanAccept?.borrower?.user?.id
      if (!loanUserId) {
        trackError(
          new Error('No user id found on newly activated loan'),
          'submitLoan',
          'Activated loan but unable to track userId since it was not found on the new loan',
        )
      }
      //Fire Branch/FB/Firebase purchase event only for first loan OA1
      const loanCountResult = await fetchLoanCount()
      const oaCount = loanCountResult?.data?.me?.loans?.countAccepted
      if (oaCount === undefined) {
        trackError(
          new Error('Unable to retrieve countAccepted'),
          'submitLoan',
          'Activated loan but unable to retrieve loan count for OA tracking',
        )
      }
      if (loanUserId && oaCount === 1) {
        LoanAccepted({userId: loanUserId})
        hasSentPurchaseEvent = true
      }
      TrackAppEvent(AppEvents.Name.final_loan_acceptance_completed, AppEvents.Category.Activation, {
        oaCount,
        purchaseEventCreated: hasSentPurchaseEvent,
      })

      return true
    },
    [acceptLoan, fetchLoanCount],
  )

  return [handleAcceptLoan, requestInfo, hasAchMaskAccountError]
}
