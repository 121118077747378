import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {View} from 'react-native'
import {MaterialIndicator} from 'react-native-indicators'

import {primaryLink} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {Destinations, EventEmitter} from 'src/lib/utils/events'

type RedirectingProps = {
  visible: boolean
}

const RedirectingOverlay: React.FC<RedirectingProps> = (props) => {
  const {t} = useTranslation('Redirection')

  const {visible} = props

  const content = (
    <Box gap="large" padding="small" align="center">
      <View style={{height: 40, width: 40}}>
        <MaterialIndicator color={primaryLink} size={40} />
      </View>

      <Box gap="small">
        <PFText variant="h3" textAlign="center">
          {t('RedirectionLoading')}
        </PFText>
      </Box>
    </Box>
  )

  return <Overlay visible={visible} content={content} testID="RedirectionOverlayModal" />
}

const RedirectionListener: React.FC = () => {
  const [overlayVisible, setOverlayVisible] = useState(false)

  useEffect(() => {
    const handleLoading = () => {
      setOverlayVisible(true)
    }

    const handleLoadingComplete = ({destination}: {destination: Destinations}) => {
      TrackAppEvent(AppEvents.Name.redirect_to_webapp_complete, AppEvents.Category.Functional, {
        destination,
      })
      setTimeout(() => {
        setOverlayVisible(false)
      }, 1000)
    }

    const loadingId = EventEmitter.subscribe('Loading', handleLoading)
    const loadingCompleteId = EventEmitter.subscribe('LoadingComplete', handleLoadingComplete)

    return () => {
      EventEmitter.unsubscribe('Loading', loadingId)
      EventEmitter.unsubscribe('LoadingComplete', loadingCompleteId)
      setOverlayVisible(false)
    }
  }, [])

  return <RedirectingOverlay visible={overlayVisible} />
}

export default RedirectionListener
