import {banking} from '@possible/generated/proto'

export function HasAccountAndRouting(
  account: banking.ILinkedAccount | undefined,
): boolean | undefined {
  return account?.isFullNumbersAvailable
}

export function HasDebitAccountNumber(
  account: banking.ILinkedAccount | undefined,
): boolean | undefined {
  return account?.isInterchangeNumbersAvailable
}

export function routingNumberFilter(input: string): string {
  try {
    const val = input.replace(/\D/g, '')

    return val.substring(0, 9)
  } catch (e) {
    return input
  }
}

export function accountNumberFilter(input: string): string {
  try {
    return input.replace(/\D/g, '')
  } catch (e) {
    return input
  }
}

export function accountMatchesMask(mask: string, accountNumber: string) {
  if (accountNumber.length < mask.length) {
    return false
  }

  const accountNumberMask = accountNumber.slice(-mask.length)
  return mask === accountNumberMask
}
