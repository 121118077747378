import React, {useCallback, useContext, useEffect} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useFocusEffect} from '@react-navigation/native'

import AppEvents, {ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {LoanPayNavigatorStack} from 'src/products/loans/AdhocPayment/LoanPayNavigatorStack'
import {
  AdhocPaymentAmountEntryContainer,
  AdhocPaymentAmountEntryContainerProps,
} from 'src/products/general/AdhocPayment/AdhocPaymentAmountEntry/AdhocPaymentAmountEntryContainer'
import {
  OnPressContinueProps,
  onPressContinue,
} from 'src/products/general/AdhocPayment/AdhocPaymentAmountEntry/AdhocPaymentAmountEntry.utils'
import {PaymentAction} from 'src/products/general/AdhocPayment/PaymentInput/PaymentInput.types'
import {
  convertPaymentIntentionStringToEnum,
  loanAdhocPaymentLogError,
  trackLoansAdhocScreenRenderFailure,
} from 'src/products/loans/AdhocPayment/LoanPay.utils'
import {ErrorTemplate} from 'src/designSystem/components/templates/ErrorTemplate/ErrorTemplate'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  getDefaultLoanPaymentOption,
  getInfoNoticeText,
  getLoanPaymentOptionButtons,
} from 'src/products/loans/AdhocPayment/LoanPayAmountEntry/LoanPayAmountEntry.utils'
import {LoanPayContext, LoanPaySetterContext} from 'src/products/loans/AdhocPayment/LoanPayProvider'
import {LoanPayAmountEntryDocument} from 'src/products/loans/AdhocPayment/LoanPayAmountEntry/LoanPayAmountEntry.gqls'

type LoanPayAmountEntryProps = Pick<
  StackScreenProps<LoanPayNavigatorStack, 'LoanPayAmountEntry'>,
  'navigation'
>

/**
 * Screen that allows user to select which custom payment option they want to use (full balance, missed installment, etc).
 */
const LoanPayAmountEntry = ({navigation}: LoanPayAmountEntryProps): JSX.Element => {
  const queryResult = useCassandraQuery(LoanPayAmountEntryDocument, undefined, (data) => {
    return data.me.loans.latestActionableLoan?.aggregateStatus.__typename ===
      'ActiveLoanAggregateStatus'
      ? {
          customPaymentOptions:
            data.me.loans.latestActionableLoan.aggregateStatus.customPaymentOptions.options,
          payments: data.me.loans.latestActionableLoan.aggregateStatus.payments.payments,
          amountOutstanding: data.me.loans.latestActionableLoan.aggregateStatus.amountOutstanding,
        }
      : undefined
  })

  const {selectedPaymentOption} = useContext(LoanPayContext)
  const {setSelectedPaymentOption} = useContext(LoanPaySetterContext)

  const {t} = useTranslation('AdhocPaymentAmountEntry')

  const {buttonActions, selectedButton} = getLoanPaymentOptionButtons({
    selectedLoanCustomPaymentOption: selectedPaymentOption,
    customPaymentOptions: queryResult.selectedData?.customPaymentOptions ?? [],
  })

  useFocusEffect(
    useCallback(() => {
      if (
        !queryResult.loading &&
        queryResult.selectedData?.amountOutstanding !== undefined &&
        buttonActions?.length === 0
      ) {
        const noOptionsError = new Error(
          'user is on the LoanPayAmountEntry screen but they have no payment options, that should not be possible',
        )
        loanAdhocPaymentLogError(noOptionsError)
      }
    }, [queryResult.loading, queryResult.selectedData?.amountOutstanding, buttonActions]),
  )

  useEffect(() => {
    if (queryResult.selectedData?.customPaymentOptions && !selectedButton) {
      const defaultSelectedPaymentOption = getDefaultLoanPaymentOption({
        customPaymentOptions: queryResult.selectedData?.customPaymentOptions,
      })
      if (defaultSelectedPaymentOption) {
        setSelectedPaymentOption(defaultSelectedPaymentOption)
      }
    }
  }, [queryResult.selectedData?.customPaymentOptions, setSelectedPaymentOption, selectedButton])

  if (queryResult.error) {
    const handleOnRetry = queryResult.refetch
    TrackAppEvent(
      ManageActiveLoanEvents.loans_adhoc_amount_entry_error_viewed,
      AppEvents.Category.ManageActiveLoan,
    )
    return (
      <ErrorTemplate
        testID="LoanPayAmountEntry-ErrorPage"
        errorTitle={t('YourInfoCantBeDisplayed')}
        onRetry={handleOnRetry}
        transparentBackground={true}
      />
    )
  }

  const pageViewedAnalytics = {
    eventCategory: AppEvents.Category.ManageActiveLoan,
    eventName: ManageActiveLoanEvents.loans_adhoc_amount_entry_screen_viewed,
    isLoading: queryResult.loading,
  }

  const handleOnChangeSelectedButton = (action: PaymentAction): void => {
    const intention = convertPaymentIntentionStringToEnum(action.trackingId)
    if (!intention) return
    const thisSelectedPaymentOption = queryResult.selectedData?.customPaymentOptions.find(
      (thisPaymentOption) => thisPaymentOption.intention === intention,
    )
    if (!thisSelectedPaymentOption?.installmentCount) {
      return
    }

    setSelectedPaymentOption(thisSelectedPaymentOption)
  }

  const goToPaymentConfirm = (): void => {
    navigation.navigate('LoanPayConfirm')
  }

  const paymentValue = selectedButton?.amount ?? ''
  const paymentIntention = selectedButton?.label ?? ''

  const onPressContinueProps: OnPressContinueProps = {
    eventCategory: AppEvents.Category.ManageActiveLoan,
    eventName: ManageActiveLoanEvents.loans_adhoc_amount_entry_cta,
    eventArgs: {
      paymentAmount: paymentValue,
      paymentIntention,
    },
    goToPaymentConfirm: goToPaymentConfirm,
    paymentValue: paymentValue,
  }

  const handleOnErrorBoundary = (e: Error): void => {
    trackLoansAdhocScreenRenderFailure(e, 'LoanPayAmountEntry')
  }

  const amountEntryContainerProps: AdhocPaymentAmountEntryContainerProps =
    queryResult.loading ||
    queryResult.selectedData?.amountOutstanding === undefined ||
    (queryResult.selectedData.customPaymentOptions.length > 0 && !selectedButton)
      ? {
          testID: 'LoanPayAmountEntry',
          onErrorBoundary: handleOnErrorBoundary,
          isLoading: true,
          pageViewedAnalytics,
        }
      : {
          testID: 'LoanPayAmountEntry',
          onErrorBoundary: handleOnErrorBoundary,
          buttonActions,
          handleOnChangeSelectedButton,
          isAmountInputEditable: false,
          isLoading: false,
          onPressContinue: (): void => onPressContinue(onPressContinueProps),
          pageViewedAnalytics,
          paymentAmount: paymentValue,
          paymentTrackingId: selectedButton?.trackingId ?? '',
          infoNoticeText: getInfoNoticeText({
            selectedButton,
            payments: queryResult.selectedData.payments,
          }),
          totalOwed: queryResult.selectedData?.amountOutstanding,
        }

  return <AdhocPaymentAmountEntryContainer {...amountEntryContainerProps} />
}

export {LoanPayAmountEntry}
