import React, {FC} from 'react'

import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'

export const CardDeactivatedTile: FC<BaseCardProps> = (props) => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_deactivated_dashboard_card_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  return <CardTileBase titleText="YourPossibleCardIsClosed" content="KeepAnEyeOut" {...props} />
}
