import React, {useCallback, useRef, useEffect, FC} from 'react'
import {LinkEventName} from 'react-native-plaid-link-sdk'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEventName, PlaidSDKEvents} from 'src/lib/Analytics/app_events'

import {AggregatorPlaidViewProps} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidOld/AggregatorPlaid.types'
import WebAppConfig from 'src/WebAppConfig'

const AggregatorPlaidView: FC<AggregatorPlaidViewProps> = (props) => {
  const {plaidLinkToken} = props

  const successData = useRef({
    accounts: [],
    linkSessionId: '',
  })

  const onSuccess = useCallback((_, data) => {
    successData.current = data
  }, [])

  const onExit = useCallback(
    (data) => {
      props.onExit(data)
    },
    [props],
  )

  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access*/

  const onEvent = useCallback(
    (eventName: LinkEventName, data) => {
      const lowerCasePlaidEventNameWithPrefix = `plaid_${eventName.toLowerCase()}`
      TrackAppEvent(
        (PlaidSDKEvents[lowerCasePlaidEventNameWithPrefix] as AppEventName) ??
          'plaid_undefined_event',
        'PlaidSDK',
        {
          view_name: data.view_name,
          error_code: data.error_code,
          error_message: data.error_message,
          error_type: data.error_type,
          institution_name: data.institution_name,
        },
      )
      if (eventName === 'SELECT_INSTITUTION' || eventName === 'SUBMIT_CREDENTIALS') {
        const {institution_id, institution_name} = data
        props.onInstitutionSelected(institution_id, institution_name)
      }

      if (eventName === 'HANDOFF') {
        successData.current !== null && props.onSuccess(successData.current)
      }
    },
    [props],
  )
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access*/

  useEffect(() => {
    // Save the callbacks
    window['onSuccessMessage'] = onSuccess
    window['onExitMessage'] = onExit
    window['onEventMessage'] = onEvent
  }, [onSuccess, onEvent, onExit, plaidLinkToken])

  let plaidLinkURI = `${WebAppConfig.REACT_APP_URI}plaid-widget.html?link_token=${plaidLinkToken}`
  if (plaidLinkURI.includes('test.possiblesasquatch.com')) {
    plaidLinkURI = plaidLinkURI.replace('index.html', '')
  }

  return <iframe src={plaidLinkURI} width="100%" height="100%" />
}

export default AggregatorPlaidView
