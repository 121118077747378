import React from 'react'
import {useTranslation} from 'react-i18next'

import {TransactionTileItem} from 'src/designSystem/components/organisms/TransactionsTile/TransactionsTile.utils'
import {
  TransactionsTile,
  TransactionsTileProps,
} from 'src/designSystem/components/organisms/TransactionsTile/TransactionsTile'
import {convertLoanPaymentsAndDisbursementToTransactionsItems} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.utils'
import {LoanHistoryTileProps} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.types'
import {useChaosModeRenderFailure} from '@possible/chaos'
import {usePfDispatch} from 'src/store/utils'

/**
 * Tile to display transactions history (deposit & payments) of a loan.
 * @example <LoanHistoryTile loanPayments={[]} loanDisbursement={...} hasPreviousLoan={false} onViewLoanHistory=(() => {}}/>
 */
export const LoanHistoryTile: React.FC<LoanHistoryTileProps> = (props: LoanHistoryTileProps) => {
  const {t} = useTranslation('DashboardLoanHistoryTile')
  const dispatch = usePfDispatch()

  useChaosModeRenderFailure({
    componentName: 'LoanHistoryTile',
    dispatch,
  })
  const {hasPreviousLoan, loanPayments, loanDisbursement, onViewLoanHistory, testID} = props
  const transactionItems: TransactionTileItem[] =
    convertLoanPaymentsAndDisbursementToTransactionsItems({
      loanPayments,
      loanDisbursement,
      t,
    })
  const transactionsTileProps: TransactionsTileProps = {
    title: t('HistoryTitle'),
    transactions: transactionItems,
    showSeeMoreBtn: hasPreviousLoan,
    onViewMore: onViewLoanHistory,
    onViewMoreBtnText: t('SeeFullLoanHistory'),
    testID,
  }
  return <TransactionsTile {...transactionsTileProps} />
}
