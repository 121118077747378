import React, {FC} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type LoanSummaryRowProps = {
  label: string
  value: string
  bold?: boolean
}

const LoanSummaryRow: FC<LoanSummaryRowProps> = (props) => {
  const {label, value, bold} = props

  return (
    <Box direction={'row'} justify={'between'}>
      <Box width={'70%'}>
        <PFText variant={bold ? 'p_lg_semibold' : 'p_lg'}>{label}</PFText>
      </Box>
      <Box width={'30%'}>
        <PFText variant={bold ? 'p_lg_semibold' : 'p_lg'} textAlign={'right'}>
          {value}
        </PFText>
      </Box>
    </Box>
  )
}

export default LoanSummaryRow
