import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanAmountSelectionTermsQueryVariables = Types.Exact<{
  state: Types.Scalars['String']['input']
}>

export type LoanAmountSelectionTermsQuery = {
  __typename?: 'Query'
  getPrimaryLoanTypeByState?: {
    __typename?: 'LoanType'
    id: string
    state?: string | null
    terms: {
      __typename?: 'LoanTerms'
      collectionWeekInterval: number
      defaultAmount: string
      estimatedApr: string
      lenderApr?: string | null
      loanDurationWeeks: number
      maximumAmount: string
      maximumAmountRepeatLoan: string
      minimumAmount: string
      rate?: string | null
      step: number
      title?: string | null
    }
  } | null
}

export const LoanAmountSelectionTermsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanAmountSelectionTerms'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPrimaryLoanTypeByState'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'state'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'terms'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'collectionWeekInterval'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'defaultAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'estimatedApr'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'lenderApr'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'loanDurationWeeks'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'maximumAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'maximumAmountRepeatLoan'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'minimumAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'rate'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'step'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanAmountSelectionTermsQuery, LoanAmountSelectionTermsQueryVariables>
