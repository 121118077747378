import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import React, {ReactElement} from 'react'

import {
  StateDisclosureAcceptDocument,
  StateDisclosureDocument,
} from 'src/products/loans/StateDisclosure/StateDisclosure.gqls'
import {StateDisclosureScreenProps} from 'src/products/loans/StateDisclosure/StateDisclosure.types'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {usePromise} from 'src/lib/usePromise/usePromise'

export type StateDisclosureGQLContainerProps = Omit<
  StateDisclosureScreenProps,
  // these two will be provided by the GQL container and given to the child template
  'isLoading' | 'isSubmitting'
> & {
  /**
   * Allow the GQL container to fetch the necessary data and pass it to a child using a render() prop.
   * That way we can use one data container for multiple child templates.
   */
  render: (args: StateDisclosureScreenProps) => ReactElement
}

/**
 * GQL container to record when a user accepts a state disclosure. Accepts a render prop
 * so that it can be re-used for any state disclosure screen template that accepts StateDisclosureScreenProps.
 */
export const StateDisclosureGQLContainer: React.FC<StateDisclosureGQLContainerProps> = (props) => {
  const {render, onExit, onContinue} = props
  const {selectedData, loading: isQueryLoading} = useCassandraQuery(
    StateDisclosureDocument,
    undefined,
    (from) => {
      return {
        loanId: from.me.loans.latestActionableLoan?.id,
      }
    },
  )
  const [submitAcceptDisclosure] = useCassandraMutation(StateDisclosureAcceptDocument)
  const [handleOnContinue, {isLoading: isHandleOnContinueExecuting}] = usePromise(
    async (): Promise<void> => {
      try {
        if (!selectedData?.loanId) {
          throw new Error('No loanId found')
        }
        await submitAcceptDisclosure({
          variables: {
            loanId: selectedData?.loanId,
          },
        })
        await onContinue()
      } catch (e) {
        logOfferActivationErrorAndShowException(
          e,
          'StateDisclosureAccept failed to accept disclosure',
        )
      }
    },
  )
  // render the child using a render prop. this GQL container intercepts onContinue and will
  // submit the correct mutation to record their response
  return render({
    onExit,
    onContinue: async (): Promise<void> => {
      await handleOnContinue()
    },
    isLoading: isQueryLoading,
    isSubmitting: isHandleOnContinueExecuting,
  })
}
