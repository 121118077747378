import React from 'react'
import {Modal, StyleSheet} from 'react-native'
import {Trans, useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFElevation from 'src/designSystem/components/atoms/PFElevation/PFElevation'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Button from 'src/designSystem/components/atoms/Button/Button'
import {NamedColors} from 'src/designSystem/colors'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePayOverTimeNavigation} from 'src/products/card/hooks/usePayOverTimeNavigation'

type Props = {
  onPress: (callback: () => void) => void
}

export const POTDelinquencyModal = (props: Props): JSX.Element => {
  const {t} = useTranslation(['CardDashboardPayment', 'Common'])
  const handleOnPressPayOverTime = usePayOverTimeNavigation()
  const {onPress} = props

  const handleOnPress = (): void =>
    onPress(() => {
      TrackAppEvent(CardEvents.card_pot_delinquency_policy_acknowledged, AppEvents.Category.Card)
      handleOnPressPayOverTime()
    })

  return (
    <Modal transparent>
      <Box align="center" justify="center" height="100%" boxStyle={styles.modalBackground}>
        <PFElevation level={3} backgroundColor="white" borderRadius={4}>
          <Box margin="large" gap="medium" boxStyle={styles.modalContainer}>
            <PFText variant="h2">{t('POTDelinquencyTitle')}</PFText>

            <PFText variant="p">
              <Trans
                t={t}
                i18nKey="POTDelinquencyExplination"
                components={{bold: <PFText variant="p_semibold" />}}
              />
            </PFText>

            <Button size="large" mode="primary" onPress={handleOnPress}>
              {t('Common:Understand')}
            </Button>
          </Box>
        </PFElevation>
      </Box>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalBackground: {
    backgroundColor: NamedColors.SEMITRANSPARENT,
  },
  modalContainer: {
    maxWidth: 300,
  },
})
