import React, {FC} from 'react'
import {HeaderBackButtonProps} from '@react-navigation/elements'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {HeaderBackButton} from 'src/nav/Header/HeaderBackButton/HeaderBackButton'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCards} from 'src/products/card/hooks/useCards'
import {CardAutopayOverviewTemplate} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewTemplate/CardAutopayOverviewTemplate'
import {WorkflowScreenProps} from 'src/workflows/types'
import {useMainNavigator} from 'src/nav/AppNavHelper'

const CardAutopayOverviewWorkflowContainer = ({
  onScreenComplete,
}: WorkflowScreenProps): JSX.Element => {
  const navigation = useMainNavigator()
  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_join_autopay_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {hasPendingPhysicalCard} = useCards()

  const handleOnPress = (): void => {
    TrackAppEvent(AppEvents.Name.card_autopay_overview_page_cta, AppEvents.Category.Card)

    onScreenComplete()
  }

  const LeftButton: FC<HeaderBackButtonProps> = () => (
    <Box paddingHorizontal="medium" paddingVertical="small">
      <HeaderBackButton
        navigation={navigation}
        size="medium"
        onPress={() => navigation.navigate('ProductHub', {})}
      />
    </Box>
  )

  navigation.setOptions({
    headerLeft: LeftButton,
  })

  return (
    <CardAutopayOverviewTemplate
      onPress={handleOnPress}
      hasPendingPhysicalCard={hasPendingPhysicalCard}
    />
  )
}

export {CardAutopayOverviewWorkflowContainer}
