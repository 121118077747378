import * as Types from '../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanAmountSelectionEstimatedCostNewQueryVariables = Types.Exact<{
  state: Types.StateCodes
  amount: Types.Scalars['Decimal']['input']
}>

export type LoanAmountSelectionEstimatedCostNewQuery = {
  __typename?: 'Query'
  loanEstimatedCosts: {
    __typename?: 'LoanEstimatedCostsResponse'
    installmentCount: number
    collectionWeekInterval: number
    interest: string
    fees: string
    totalCost: string
    totalOwed: string
    installmentAmount: string
    apr: string
  }
}

export type LoanAmountSelectionTermsNewQueryVariables = Types.Exact<{
  state: Types.Scalars['String']['input']
}>

export type LoanAmountSelectionTermsNewQuery = {
  __typename?: 'Query'
  getPrimaryLoanTypeByState?: {
    __typename?: 'LoanType'
    id: string
    state?: string | null
    terms: {
      __typename?: 'LoanTerms'
      maximumAmount: string
      minimumAmount: string
      defaultAmount: string
    }
  } | null
}

export type LoanAmountSelectionNewQueryVariables = Types.Exact<{
  assessmentDate: Types.Scalars['DateTime']['input']
}>

export type LoanAmountSelectionNewQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    profile?: {
      __typename?: 'UserProfile'
      home?: {
        __typename?: 'Location'
        address?: {__typename?: 'Address'; state?: string | null} | null
      } | null
    } | null
    onboarding?: {
      __typename?: 'OnboardingInfo'
      loan?: {__typename?: 'LoanOnboardingInfo'; stateSelected?: string | null} | null
    } | null
    loans: {
      __typename?: 'UserLoanCollection'
      all?: Array<{
        __typename?: 'Loan'
        id: string
        status:
          | {__typename: 'ActiveLoanStatus'; code: Types.LoanStatusCode}
          | {__typename: 'ApprovedLoanStatus'}
          | {__typename: 'CancelledLoanStatus'; code: Types.LoanStatusCode}
          | {__typename: 'ChargedOffLoanStatus'; code: Types.LoanStatusCode}
          | {__typename: 'ExpiredLoanStatus'}
          | {__typename: 'PaidOffLoanStatus'; code: Types.LoanStatusCode}
          | {__typename: 'PendingLoanStatus'}
          | {__typename: 'RejectedLoanStatus'}
          | {__typename: 'ReplacedLoanStatus'}
      }> | null
    }
  }
  loanGetPrequalification: {__typename?: 'LoanPrequalification'; amount: string}
}

export type LoanAmountSelectionSetLoanAmountNewMutationVariables = Types.Exact<{
  amount: Types.Scalars['Decimal']['input']
}>

export type LoanAmountSelectionSetLoanAmountNewMutation = {
  __typename?: 'Mutation'
  productApplicationUpdateRequirements: {
    __typename: 'ProductApplicationRequirementsResponse'
    success: boolean
  }
}

export const LoanAmountSelectionEstimatedCostNewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanAmountSelectionEstimatedCostNew'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'StateCodes'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Decimal'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanEstimatedCosts'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'state'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'installmentCount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'collectionWeekInterval'}},
                {kind: 'Field', name: {kind: 'Name', value: 'interest'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fees'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalCost'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalOwed'}},
                {kind: 'Field', name: {kind: 'Name', value: 'installmentAmount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'apr'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanAmountSelectionEstimatedCostNewQuery,
  LoanAmountSelectionEstimatedCostNewQueryVariables
>
export const LoanAmountSelectionTermsNewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanAmountSelectionTermsNew'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPrimaryLoanTypeByState'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'state'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'terms'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'maximumAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'minimumAmount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'defaultAmount'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanAmountSelectionTermsNewQuery,
  LoanAmountSelectionTermsNewQueryVariables
>
export const LoanAmountSelectionNewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanAmountSelectionNew'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'assessmentDate'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{kind: 'Field', name: {kind: 'Name', value: 'state'}}],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'onboarding'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'stateSelected'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'status'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'PaidOffLoanStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ChargedOffLoanStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'CancelledLoanStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'ActiveLoanStatus'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'code'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'loanGetPrequalification'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'assessmentDate'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'assessmentDate'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'amount'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanAmountSelectionNewQuery, LoanAmountSelectionNewQueryVariables>
export const LoanAmountSelectionSetLoanAmountNewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'LoanAmountSelectionSetLoanAmountNew'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Decimal'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'productApplicationUpdateRequirements'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'loanAmount'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'success'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoanAmountSelectionSetLoanAmountNewMutation,
  LoanAmountSelectionSetLoanAmountNewMutationVariables
>
