import React, {useCallback, useEffect, useState} from 'react'
import {View} from 'react-native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {
  LoanDocumentType,
  LoanDocumentWithDownloadUrl,
  PresignedUrl,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {AppEventName} from 'src/lib/Analytics/app_events'
import {MainStackNavigationProp, MainStackParamList} from 'src/nav/MainStackParamsList'
import {DocumentLink} from 'src/products/loans/LoanHistory/DocumentLink'
import {Consumer} from '@possible/cassandra'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {isInstallmentLoan} from 'src/lib/loans/utils'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {LoanHistoryQuery} from 'src/products/loans/LoanHistory/LoanHistory.gqls'
type DocumentAttributes = {
  name: string
  type: LoanDocumentType
  event: AppEventName
  isReplacedLoan?: boolean
}
type Props = {
  navigation: StackNavigationProp<MainStackParamList, 'LoanHistory', undefined>
  loan: NonNullable<LoanHistoryQuery['me']['loans']['all']>['0']
}

const getDocs = async (loanId: string | undefined) => {
  if (!loanId) {
    return []
  }
  try {
    return await Consumer.methods.loanDocumentsDownload(loanId)
  } catch (e) {
    Log.error(e)
    ShowException(e)
    return []
  }
}

const filterDoc = (
  docs: Array<LoanDocumentWithDownloadUrl>,
  type: LoanDocumentType,
): PresignedUrl | undefined => {
  return docs?.find((doc) => doc?.type === type)?.download
}

const pushPdf = (
  uri?: string,
  navigation?: MainStackNavigationProp,
  event?: AppEventName,
): void => {
  if (uri && navigation) {
    try {
      if (event) {
        TrackAppEvent(event, AppEvents.Category.ManageActiveLoan)
      }
      displayPdf(uri, navigation)
    } catch (e) {
      Log.error(e)
      ShowException(e)
    }
  }
}

const Documents: React.FC<Props> = (props) => {
  const {navigation, loan} = props
  const {t} = useTranslation('LoanHistory')

  const [loanDocuments, setLoanDocuments] = useState<Array<LoanDocumentWithDownloadUrl>>([])

  const [replacedLoanDocuments, setReplacedLoanDocuments] = useState<
    Array<LoanDocumentWithDownloadUrl>
  >([])

  const loadDocs = useCallback(async () => {
    if (loan) {
      const docs = await getDocs(loan.id)
      setLoanDocuments(docs ? docs : [])

      // If this is an installment loan, load the original loan documents
      if (isInstallmentLoan(loan)) {
        const originalDocs = await getDocs(loan.originalLoan?.id)
        setReplacedLoanDocuments(originalDocs ? originalDocs : [])
      }
    }
  }, [loan])

  useEffect(() => {
    loadDocs()
  }, [loadDocs])

  const RenderDoc = (docAttributes: DocumentAttributes): JSX.Element | null => {
    const {name, type, event, isReplacedLoan} = docAttributes

    const doc = filterDoc(isReplacedLoan ? replacedLoanDocuments : loanDocuments, type)

    if (!doc) {
      return null
    }

    const handleOnClick = (): void => pushPdf(doc.url, navigation, event)

    return <DocumentLink linkText={name} onClick={handleOnClick} />
  }

  return (
    <View>
      <RenderDoc
        name={t('DocumentSinglePaymentLoanAgreement')}
        type={LoanDocumentType.SinglePaymentLoanAgreement}
        event={AppEvents.Name.single_payment_loan_agreement_selected}
      />
      <RenderDoc
        name={t('DocumentLoanAgreement')}
        type={LoanDocumentType.LoanAgreement}
        event={AppEvents.Name.loan_agreement_selected}
      />
      <RenderDoc
        name={t('DocumentInstallmentLoanAgreement')}
        type={LoanDocumentType.InstallmentLoanAgreement}
        event={AppEvents.Name.installment_loan_agreement_selected}
      />
      <RenderDoc
        name={t('DocumentPaymentAuthorization')}
        type={LoanDocumentType.AchAgreement}
        event={AppEvents.Name.ach_agreement_selected}
      />

      {isInstallmentLoan(loan) && (
        <>
          <RenderDoc
            name={t('OriginalLoanAgreement')}
            type={LoanDocumentType.LoanAgreement}
            event={AppEvents.Name.replacement_loan_agreement_selected}
            isReplacedLoan={true}
          />
          <RenderDoc
            name={t('OriginalPaymentAuthorization')}
            type={LoanDocumentType.AchAgreement}
            event={AppEvents.Name.replacement_loan_ach_agreement_selected}
            isReplacedLoan={true}
          />
        </>
      )}

      <RenderDoc
        name={t('ArbitrationAgreement')}
        type={LoanDocumentType.Arbitration}
        event={AppEvents.Name.arbitration_agreement_selected}
      />
      <RenderDoc
        name={t('CreditService')}
        type={LoanDocumentType.CreditService}
        event={AppEvents.Name.credit_service_agreement_selected}
      />
      <RenderDoc
        name={t('CreditServiceDisclosure')}
        type={LoanDocumentType.CreditServiceDisclosure}
        event={AppEvents.Name.credit_service_disclosure_selected}
      />
      <RenderDoc
        name={t('PayOffConfirmation')}
        type={LoanDocumentType.PayOffConfirmation}
        event={AppEvents.Name.pay_off_confirmation_doc_selected}
      />
    </View>
  )
}

export default Documents
