import React, {FC} from 'react'

import {LoanFinalAcceptGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import AppEvents from 'src/lib/Analytics/app_events'

export type LoanFinalAcceptWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

const LoanFinalAcceptWorkflowContainer: FC<LoanFinalAcceptWorkflowContainerProps> = (props) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.final_loan_acceptance_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  const handleOnContinue = async (): Promise<void> => {
    await onPreReqFulfilled?.()
  }

  return <LoanFinalAcceptGQLContainer onContinue={handleOnContinue} />
}

export {LoanFinalAcceptWorkflowContainer}
