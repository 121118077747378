import React, {FC, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import Page from 'src/designSystem/components/organisms/Page/Page'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {enormousGap, largeGap, mediumGap, smallGap, tinyGap} from 'src/designSystem/layout'
import {AddPaymentMethodButtons} from 'src/products/MCU/AccountManagementV2/AddNewAccount/AddPaymentMethodButtons'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import {PaymentMethodTypesEnum} from 'src/products/general/GeneralPaymentMethods/types'

export type AddNewAccountToPossibleTemplateProps = {
  onAddNewLinkedAccount: () => void
  onAddAccountRoutingNumber: ((accoundId: string) => void) | undefined //TODO: change to the correct function format
  onAddNewDebitCard: () => void
  useYodleeAggregator?: boolean
}

const AddNewAccountToPossibleTemplate: FC<AddNewAccountToPossibleTemplateProps> = (
  props: AddNewAccountToPossibleTemplateProps,
) => {
  const {
    onAddNewLinkedAccount,
    onAddAccountRoutingNumber,
    onAddNewDebitCard,
    useYodleeAggregator: isYodleeAggregator,
  } = props

  const {t} = useTranslation(['AccountManagement', 'Common'])
  const [accountType, setAccountType] = useState<PaymentMethodTypesEnum | null>(
    PaymentMethodTypesEnum.Plaid,
  )

  const handleOnSelectAccountType = (accountType: PaymentMethodTypesEnum): void => {
    setAccountType(accountType)
  }

  const getContinueAction = (): void => {
    if (accountType === PaymentMethodTypesEnum.Plaid) {
      sendAnalyticEvent(ampli.accountManagementLinkWithPlaid.bind(ampli))
      onAddNewLinkedAccount()
    }

    if (accountType === PaymentMethodTypesEnum.AccountRouting) {
      sendAnalyticEvent(ampli.accountManagementAccountRouting.bind(ampli))
      onAddAccountRoutingNumber?.('')
    }

    if (accountType === PaymentMethodTypesEnum.DebitCard) {
      sendAnalyticEvent(ampli.accountManagementAddDebitCard.bind(ampli))
      onAddNewDebitCard?.()
    }
  }

  sendAnalyticEvent(ampli.accountManagementAddNewAccount.bind(ampli))

  const title = (
    <PFText>
      <Trans
        t={t}
        i18nKey="AddAccountToPossible"
        components={{
          productBlue: <PFText color={NamedColors.PRODUCT_BLUE} />,
        }}
      />
    </PFText>
  )

  const content = (
    <Box>
      <Box marginBottom={smallGap}>
        <PFStatusPill
          text={t('Common:Recommended')}
          color={NamedColors.ASH}
          fontColor={NamedColors.BLACK}
        />
      </Box>
      <Box paddingRight={mediumGap} marginBottom={mediumGap}>
        <PFText color={NamedColors.PRODUCT_BLUE} variant={'p_lg_semibold'}>
          {isYodleeAggregator ? t('LogInToYourBankWithYodlee') : t('LogInToYourBankWithPlaid')}
        </PFText>
        <PFText variant="p" color={NamedColors.BLACK}>
          <Trans
            t={t}
            i18nKey="UseYourCredentialsToSecurelyLinkYourBank"
            components={{
              iconBtn: (
                <PFStatusPill
                  text="Primary"
                  color={NamedColors.PRODUCT_BLUE}
                  fontColor={NamedColors.WHITE}
                />
              ),
            }}
          />
        </PFText>
      </Box>
      <Box paddingRight={mediumGap} marginBottom={mediumGap}>
        <PFText color={NamedColors.PRODUCT_BLUE} variant={'p_lg_semibold'}>
          {t('OrAddManually')}
        </PFText>
        <PFText variant="p" color={NamedColors.BLACK}>
          {t('JustEnterYourBankDetails')}
        </PFText>
      </Box>
    </Box>
  )

  const subContent = (
    <PFText variant={'p_sm'} color={NamedColors.SILVER} textAlign={'center'}>
      {t('PlaidIsTrusted')}
    </PFText>
  )

  return (
    <Page
      variant="generic"
      title={title}
      smallTopGap
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Continue'),
          onPress: getContinueAction,
          testID: 'Add-New-Account-Screen-Continue',
          disabled: !accountType,
        },
      }}
    >
      <Box>{content}</Box>

      <AddPaymentMethodButtons
        selectedAccountType={accountType}
        onSelectAccountType={handleOnSelectAccountType}
        useYodleeAggregator={isYodleeAggregator}
      />
      {isYodleeAggregator ? null : (
        <Box marginTop={largeGap} paddingTop={tinyGap} paddingHorizontal={enormousGap}>
          {subContent}
        </Box>
      )}
    </Page>
  )
}

export {AddNewAccountToPossibleTemplate}
