import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

import {
  CollectDebitCardNumbersForLoanGQLContainer,
  CollectDebitCardNumbersForLoanGQLContainerProps,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanGQLContainer'

export type CollectDebitCardNumbersForLoanNavPageStateContainerProps = Pick<
  StackScreenProps<MainStackParamList, 'CollectDebitCardNumbersForLoansProduct'>,
  'navigation' | 'route'
>

/**
 * Container for the CollectDebitCardNumbersForLoan screen to integrate it into
 * the NavPageState navigation system.
 */
const CollectDebitCardNumbersForLoanNavPageStateContainer: React.FC<
  CollectDebitCardNumbersForLoanNavPageStateContainerProps
> = (props) => {
  const {navigation, route} = props
  const {onCompleteFromURA} = route.params

  const handleOnDebitCardSaved = (): void => {
    if (onCompleteFromURA) {
      TrackAppEvent(
        AppEvents.Name.disbursement_method_debit_link_success,
        AppEvents.Category.Activation,
      )

      // this user was sent here from a URA
      TrackAppEvent(AppEvents.Name.add_debit_card_confirmed, AppEvents.Category.Activation)
      navigation.pop()
      void onCompleteFromURA()
    } else {
      // this user was sent here from the loan approval flow which should not currently happen
      // this container should only be used by the URA
      // this path cannot be removed until all of nav page state is removed
      // https://possible.atlassian.net/browse/ENG-21203
      throw new Error('CollectDebitCardNumbersForLoanNavPageStateContainer: not coming from URA')
    }
  }

  const handleOnDebitCardSaveFailed: CollectDebitCardNumbersForLoanGQLContainerProps['onDebitCardSaveFailed'] =
    (errorReason) => {
      TrackAppEvent(
        AppEvents.Name.disbursement_method_debit_link_fail,
        AppEvents.Category.Activation,
        {error: errorReason},
      )
    }
  return (
    <CollectDebitCardNumbersForLoanGQLContainer
      onDebitCardSaved={handleOnDebitCardSaved}
      onDebitCardSaveFailed={handleOnDebitCardSaveFailed}
    />
  )
}

export {CollectDebitCardNumbersForLoanNavPageStateContainer}
