import {
  CardAccountAcceptDocument,
  CardAccountAgreementsAcceptDocument,
  CardAccountAgreementsDownloadDocument,
  CardAccountDeclineDocument,
  CardAccountEnableAutomaticPaymentsDocument,
  CardAccountLinkPaymentMethodDocument,
  CardAccountMarkPaymentMethodPrimaryDocument,
  CardAccountSchedulePaymentDocument,
  CardAccountStatementGenerateDownloadLinkDocument,
  CardAccountSubmitApplicationV2Document,
  CardAccountToggleApplicationPropertiesRequestDocument,
  CardActivateDocument,
  CardActivatePhysicalDocument,
  CardActivationApprovedDocument,
  CardAdhocPaymentDocument,
  CardsAccountTypeDocument,
  CardsQueryDocument,
  CardsQueryPolledDocument,
  GetCurrentOfferDocument,
  GetUserEnvDocument,
  LoanAcceptDocument,
  LoanApplyDocument,
  LoanCancelDocument,
  LoanGetDocumentDocument,
  LoanSetPaymentDatesDocument,
  MeActiveCardIdDocument,
  MeAddressDocument,
  MeDocument,
  ModifySsnDocument,
  PhoneVerificationCodeSendDocument,
  PhoneVerificationCodeValidationDocument,
  PrequalificationStatusDocument,
  ProductsDocument,
  ProfileDocument,
  RequestMfaDocument,
  UserModifyAddressComponentsDocument,
  UserModifyProfileDocument,
  UserSsnDocument,
  ValidateMfaDocument,
} from '../../types/types.mobile.generated'
import {useMutationFactory, useQueryFactory} from '../../utils'

// User
export const useMeQuery = useQueryFactory(MeDocument)
export const useMeProfileQuery = useQueryFactory(ProfileDocument)
export const useGetUserEnvQuery = useQueryFactory(GetUserEnvDocument)
export const usePhoneVerificationCodeSendMutation = useMutationFactory(
  PhoneVerificationCodeSendDocument,
)
export const usePhoneVerificationCodeValidationMutation = useMutationFactory(
  PhoneVerificationCodeValidationDocument,
)

// User Profile
export const useUserModifyProfileMutation = useMutationFactory(UserModifyProfileDocument)
export const useUserModifyAddressComponentsMutation = useMutationFactory(
  UserModifyAddressComponentsDocument,
)
export const useMeAddressQuery = useQueryFactory(MeAddressDocument)

/// SSN
export const useUserSSNQuery = useQueryFactory(
  UserSsnDocument,
  (from) => from.me.identification?.ssn,
)
export const useModifySSNMutation = useMutationFactory(ModifySsnDocument)

// Cards
export const useCardsQuery = useQueryFactory(CardsQueryDocument)
export const useCardsQueryPolled = useQueryFactory(CardsQueryPolledDocument)
export const useCardAccountSubmitApplicationMutation = useMutationFactory(
  CardAccountSubmitApplicationV2Document,
)
export const useCardAccountAcceptMutation = useMutationFactory(CardAccountAcceptDocument)
export const useCardAccountDeclineMutation = useMutationFactory(CardAccountDeclineDocument)
export const useCardAccountAgreementsAcceptMutation = useMutationFactory(
  CardAccountAgreementsAcceptDocument,
)
export const useCardAccountSchedulePaymentMutation = useMutationFactory(
  CardAccountSchedulePaymentDocument,
)
export const useCardActivateMutation = useMutationFactory(CardActivateDocument)
export const useCardAccountLinkPaymentMethodMutation = useMutationFactory(
  CardAccountLinkPaymentMethodDocument,
)
export const useCardAccountMarkPaymentMethodPrimaryMutation = useMutationFactory(
  CardAccountMarkPaymentMethodPrimaryDocument,
)
export const useMeActiveCardIdQuery = useQueryFactory(MeActiveCardIdDocument)
export const useCardActivatePhysicalMutation = useMutationFactory(CardActivatePhysicalDocument)
export const useCardEnableAutopayMutation = useMutationFactory(
  CardAccountEnableAutomaticPaymentsDocument,
)
export const useCardAccountStatementGenerateDownloadLinkMutation = useMutationFactory(
  CardAccountStatementGenerateDownloadLinkDocument,
)
export const useCardAccountAgreementsDownloadLinkMutation = useMutationFactory(
  CardAccountAgreementsDownloadDocument,
)
export const useRequestMfaMutation = useMutationFactory(RequestMfaDocument)
export const useValidateMfaMutation = useMutationFactory(ValidateMfaDocument)
export const useCardAccountToggleApplicationPropertiesMutation = useMutationFactory(
  CardAccountToggleApplicationPropertiesRequestDocument,
)
export const useCardAdhocPaymentQuery = useQueryFactory(CardAdhocPaymentDocument)
export const useCardsAccountTypeQuery = useQueryFactory(CardsAccountTypeDocument)
export const useCardActivationApprovedQuery = useQueryFactory(CardActivationApprovedDocument)

// Loans
export const useLoanCancelMutation = useMutationFactory(LoanCancelDocument)
export const useLoanApplyMutation = useMutationFactory(LoanApplyDocument)
export const useLoanAcceptMutation = useMutationFactory(LoanAcceptDocument)
export const useLoanSetPaymentDatesMutation = useMutationFactory(LoanSetPaymentDatesDocument)
export const useLoanGetDocumentMutation = useMutationFactory(LoanGetDocumentDocument)

// Partner API
export const useGetCurrentOffer = useQueryFactory(
  GetCurrentOfferDocument,
  (from) => from.getCurrentOffer,
)

// Prequal
export const usePrequalificationStatusQuery = useQueryFactory(PrequalificationStatusDocument)

// Workflows
export const useProductsQuery = useQueryFactory(ProductsDocument, (from) => from.me.products)
