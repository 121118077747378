import {capitalize} from 'lodash'

import {Consumer} from '@possible/cassandra'
import Log from 'src/lib/loggingUtil'

import {stateAbvToFull} from 'src/lib/us_states'

export type UpdateAddressMethod = (
  data: Consumer.types.ApplicantPhysicalAddressComponentsInput,
) => Promise<Consumer.types.Address>

export const useUpdateUserAddress = (
  addressType: Consumer.types.AddressType = Consumer.types.AddressType.Home,
): [
  UpdateAddressMethod,
  {
    loading: boolean
  },
] => {
  const [updateUserAddress, {loading}] = Consumer.hooks.useUserModifyAddressComponentsMutation()

  const updateAddress = async (data: Consumer.types.ApplicantPhysicalAddressComponentsInput) => {
    data.country = 'US'

    const response = await updateUserAddress({
      variables: {
        addressType,
        physicalAddress: data,
      },
    })
    if (response.errors) {
      throw response.errors[0]
    }

    const address = response.data?.userModifyAddressComponents?.address
    if (!address) {
      throw new Error('Unable to save address')
    }

    return address
  }

  return [updateAddress, {loading}]
}

export const stateMismatch = (stateField: string, selectedState: string | undefined): boolean => {
  //State must match selected state
  return !!(selectedState && stateField !== selectedState)
}

export const stateFull = (stateAbv: string): string => {
  let val = ''
  try {
    if (stateAbv.startsWith('USA-')) {
      stateAbv = stateAbv.replace('USA-', '')
    }
    val = stateAbvToFull.get(stateAbv) ?? ''
  } catch (e) {
    Log.log(e)
  }

  return val
}

export const stateFromLoanObject = (stateAbv: string): string => {
  let val = ''
  try {
    if (stateAbv.startsWith('STATE_')) {
      val = stateAbv.replace('STATE_', '')
    }
  } catch (e) {
    Log.log(e)
  }

  return capitalize(val)
}
