import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {ReapplicationBankGQLContainer} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBankGQLContainer'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {ReapplicationConfirmBankDocument} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBank.gqls'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'

export const ReapplicationBankWorkflowContainer: React.FC<WorkflowPreReqFulfillScreenProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.link_bank_account_primer_viewed,
    eventCategory: AppEvents.Category.Application,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const [confirmBank] = useCassandraMutation(ReapplicationConfirmBankDocument)

  const handleOnContinue = async (accountId: string): Promise<void> => {
    try {
      const response = await confirmBank({variables: {accountId}})
      if (response.errors) {
        throw new Error(`GraphQL Error: ${response.errors[0].message}`)
      }

      if (!response.data?.productApplicationUpdateRequirements.success) {
        throw new Error('productApplicationUpdateRequirements failed')
      }

      await onPreReqFulfilled()
    } catch (e) {
      logOfferApplicationError(e, 'ReapplicationBankWorkflowContainer handleOnContinue() error')
    }
  }

  const bankLinkAnalyticEvent = (): void =>
    TrackAppEvent(
      AppEvents.Name.link_bank_account_primer_completed,
      AppEvents.Category.Application,
      {
        navContainerType: 'Workflows',
      },
    )

  return (
    <ReapplicationBankGQLContainer
      onContinue={handleOnContinue}
      bankLinkAnalyticEvent={bankLinkAnalyticEvent}
    />
  )
}
