import React, {FC, useEffect, useState, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {PaymentInfoType} from 'src/products/loans/LoanSelection/FeeCalc'
import {usePfDispatch} from 'src/store/utils'
import {getNumberOrZero, areNumbersInequal} from 'src/lib/utils/numberUtil'
import Log from 'src/lib/loggingUtil'
import Box from 'src/designSystem/components/atoms/Box/Box'
import LoanSummary from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanSummary/LoanSummary'
import {SelectAmount} from 'src/products/loans/SelectAmount/SelectAmount'
import {
  LoanAmountSelectionContentStateLoanInfoCapsule,
  LoanAmountSelectionContentLoanTermsFields,
  OnSelectedLoanAmountChangedCallback,
} from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanAmountSelectionContent.types'
import {
  StateLoanInfoCapsulePropsType,
  applyLoanTermsMinMaxToAmount,
  geStateLoanInfoCapsule,
  getInitialLoanAmount,
  getLoanDurationWeeks,
  getMaxLoanAmountFromLoanTerms,
  getPaymentInfoFeesAndTotal,
} from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanAmountSelectionContent.utils'
import {
  PFInfoCapsule,
  PFInfoCapsuleVariant,
} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'
import {WorkflowsStackParams} from 'src/workflows/types'

export type LoanAmountSelectionContentProps = {
  navigation: StackNavigationProp<WorkflowsStackParams, 'LoanAmountSelection'>
  prequalificationAmount: number | undefined
  isDisabled: boolean
  loanUsStateAbv: string
  isRepeatLoan: boolean
  loanTerms: LoanAmountSelectionContentLoanTermsFields
  onSelectedAmountChanged: OnSelectedLoanAmountChangedCallback
}

/**
 * Contents of the LoanAmountSelection screen. Contains slider to select loan amount and summary
 * of amount borrowed + fees.
 */
const LoanAmountSelectionContent: FC<LoanAmountSelectionContentProps> = (props) => {
  const [selectedLoanAmount, setSelectedLoanAmount] = useState<number>(0)
  const [paymentInfoFeesAndTotal, setPaymentInfoFeesAndTotal] = useState<PaymentInfoType>()
  const [stateLoanInfoCapsule, setStateLoanInfoCapsule] =
    useState<LoanAmountSelectionContentStateLoanInfoCapsule>()

  const dispatch = usePfDispatch()
  const {t} = useTranslation('LoanSelection')

  const {
    navigation,
    prequalificationAmount,
    isDisabled,
    loanUsStateAbv,
    isRepeatLoan,
    loanTerms,
    onSelectedAmountChanged,
  } = props
  const showPrequalAmount = !!prequalificationAmount

  const getMaximumAmount = useCallback(() => {
    return getMaxLoanAmountFromLoanTerms(isRepeatLoan, loanTerms)
  }, [loanTerms, isRepeatLoan])

  const minimumAmount = useMemo(() => {
    if (loanTerms.minimumAmount < 50 || undefined) {
      Log.error(
        'LoanAmountSelectionContent -- loanTerms.minimumAmount is undefined or less than 50',
      )
      return 50
    }
    return loanTerms.minimumAmount
  }, [loanTerms])

  useEffect(() => {
    if (!isDisabled) {
      let amt = getInitialLoanAmount({
        loanTerms,
        showPrequalAmount,
        prequalAmount: prequalificationAmount,
        selectedLoanAmount: selectedLoanAmount,
      })
      const maximumAmount = getMaximumAmount() || amt

      if (amt) {
        amt = applyLoanTermsMinMaxToAmount(amt, loanTerms, maximumAmount)
        // notify parent that initial amount has been set
        onSelectedAmountChanged({
          amount: amt,
        })
        setSelectedLoanAmount(amt)
      } else {
        setSelectedLoanAmount(0)
        onSelectedAmountChanged({
          amount: 0,
        })
      }
    }
  }, [
    dispatch,
    loanTerms,
    getMaximumAmount,
    showPrequalAmount,
    prequalificationAmount,
    isDisabled,
    selectedLoanAmount,
    onSelectedAmountChanged,
  ])

  useEffect(() => {
    if (prequalificationAmount && prequalificationAmount < selectedLoanAmount) {
      setSelectedLoanAmount(prequalificationAmount)
    }
    // we only want this to run when prequalificationAmount changes
    // (which should only happen once), not when selectedLoanAmount changes
    // so we can ignore the warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prequalificationAmount])

  const setInfoCapsule = useCallback(() => {
    if (loanTerms && loanUsStateAbv) {
      const newPaymentInfo = getPaymentInfoFeesAndTotal(
        loanTerms,
        selectedLoanAmount,
        loanUsStateAbv,
      )
      const stateLoanInfoCapsuleProps: StateLoanInfoCapsulePropsType = {
        t,
        paymentInformation: newPaymentInfo,
        loanState: loanUsStateAbv,
        loanTerms,
        navigation,
      }
      setPaymentInfoFeesAndTotal(newPaymentInfo)
      setStateLoanInfoCapsule(geStateLoanInfoCapsule(stateLoanInfoCapsuleProps))
    }
  }, [loanTerms, loanUsStateAbv, navigation, selectedLoanAmount, t])

  useEffect(() => {
    if (!isDisabled) {
      setInfoCapsule()
    }
  }, [isDisabled, setInfoCapsule])

  const onSlidingComplete = (): void => {
    // provide updated amount to callback if provided
    onSelectedAmountChanged({
      amount: selectedLoanAmount,
    })
  }

  const onValueChange = (loanAmount: number): void => {
    if (areNumbersInequal(selectedLoanAmount, loanAmount)) {
      setSelectedLoanAmount(loanAmount)
    }
  }

  if (!loanTerms || !paymentInfoFeesAndTotal) {
    return <Loading type={'loader0'} size={'large'} />
  }

  if (!selectedLoanAmount || !loanUsStateAbv) {
    return null
  }

  const duration = getLoanDurationWeeks({
    loanTermsDurationWeeks: loanTerms.loanDurationWeeks,
    loanUsStateAbv,
  })

  const getStateLoanInfoCapsule = (): JSX.Element | null => {
    const hasStateSpecificInfoCapsule =
      stateLoanInfoCapsule?.variant === PFInfoCapsuleVariant.Element
    let infoCapsule: JSX.Element | null = null

    if (hasStateSpecificInfoCapsule) {
      const handleOnPress = stateLoanInfoCapsule?.onPress
      infoCapsule = (
        <PFInfoCapsule
          variant={PFInfoCapsuleVariant.Element}
          svgIcon={{name: 'copy', colorVariant: 'active', isFilled: true}}
          element={stateLoanInfoCapsule?.element ?? <></>}
          onPress={handleOnPress}
        />
      )
    } else {
      infoCapsule = (
        <PFInfoCapsule
          variant={PFInfoCapsuleVariant.Text}
          svgIcon={{name: 'copy', colorVariant: 'active', isFilled: true}}
          text={stateLoanInfoCapsule?.text ?? ''}
        />
      )
    }
    return infoCapsule
  }

  const getLowerBlock = (): JSX.Element => {
    return (
      <Box gap={'large'}>
        <LoanSummary
          amountBorrowed={selectedLoanAmount}
          totalFees={paymentInfoFeesAndTotal.interest_and_fees}
          totalAmount={paymentInfoFeesAndTotal.total_owed}
        />
        {getStateLoanInfoCapsule()}
      </Box>
    )
  }

  return (
    <SelectAmount
      testID={'Loan-Amount-Selection-Content'}
      nextAmount={getNumberOrZero(selectedLoanAmount)}
      onSlidingComplete={(): void => onSlidingComplete()}
      onValueChange={(value: number): void => onValueChange(value)}
      step={loanTerms.step}
      minimumAmount={minimumAmount}
      maximumAmount={getMaximumAmount()}
      prequalificationAmount={prequalificationAmount}
      lowerBlock={getLowerBlock()}
      loanDuration={duration}
    />
  )
}

export {LoanAmountSelectionContent}
