import {StackNavigationProp} from '@react-navigation/stack'

import {getPfStore} from 'src/store'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {isDeviceNotWeb} from 'src/lib/utils/platform'
import {userProfileSelector} from 'src/lib/user/selector'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {PushWebPageOnStack, OpenWebPage} from 'src/navigation/NavHelper'
import AppNav from 'src/nav/AppNavActions'
import {getIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

export function buildZendeskUrl(firstName?: string, lastName?: string, email?: string) {
  const zendeskSupportTicketBaseUrl =
    'https://support.possiblefinance.com/hc/en-us/requests/new?appNewReq=true'

  let zendeskSupportTicketUrl = zendeskSupportTicketBaseUrl

  if ((firstName || lastName) && email) {
    const searchParams = new URLSearchParams()
    const usersEmail = email
    let usersName

    if (firstName && lastName) {
      usersName = [firstName, lastName].join(' ')
    }

    if (usersName) searchParams.append('first_last_name', usersName)
    if (usersEmail) searchParams.append('email', usersEmail)

    const userParam = searchParams.toString()

    if (userParam) {
      zendeskSupportTicketUrl = `${zendeskSupportTicketBaseUrl}&${userParam}`
    }
  }

  return zendeskSupportTicketUrl
}

export function openContactUsForm(
  navigator: StackNavigationProp<MainStackParamList, keyof MainStackParamList>,
): void {
  const isSecureMessagingFlagEnabled = getIsFeatureFlagEnabled('secure-message-center')
  if (isSecureMessagingFlagEnabled) {
    AppNav.push(navigator, 'ZendeskHelpCenter')
  } else {
    TrackAppEvent(AppEvents.Name.contact_us_screen_viewed, AppEvents.Category.Admin)

    const state = getPfStore().getState()

    const user = userProfileSelector(state)
    const zendeskSupportTicketUrl = buildZendeskUrl(
      user?.name?.firstName ?? undefined,
      user?.name?.lastName ?? undefined,
      user?.email?.address ?? undefined,
    )
    if (isDeviceNotWeb()) {
      OpenWebPage({uri: zendeskSupportTicketUrl})
    } else {
      PushWebPageOnStack(navigator, {uri: zendeskSupportTicketUrl})
    }
  }
}
