import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import AppNavActions from 'src/nav/AppNavActions'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {callNow} from 'src/products/card/Dashboard/util'

type Props = StackScreenProps<MainStackParamList, 'CardPhysicalCardActivationFailed'>
const CardPhysicalCardActivationFailed: FC<Props> = (props) => {
  const {navigation} = props
  usePageViewedAnalytics({
    eventName: CardEvents.card_physical_card_failed_activation_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardPhysicalCardActivationFailed', 'Common'])

  return (
    <Page
      variant={'generic'}
      title={t('YourCardWasNotActivated')}
      description={t('FailureText')}
      smallTopGap={true}
      buttonProps={{
        type: 'doubleButton',
        primary: {
          text: t('TryAgain'),
          onPress: (): void => {
            TrackAppEvent(
              CardEvents.card_physical_card_failed_activation_page_retry_cta,
              AppEvents.Category.Card,
            )
            AppNavActions.PopToName(navigation, 'CardActivatePhysicalCard')
          },
        },
        secondary: {
          buttonText: t('ActivateLater'),
          onPress: (): void => {
            TrackAppEvent(
              CardEvents.card_physical_card_failed_activation_page_later_cta,
              AppEvents.Category.Card,
            )
            PushPage(navigation, 'CardDashboard')
          },
        },
      }}
    >
      <Box align={'center'} marginTop={'enormous'}>
        <Box align={'center'} gap={'enormous'}>
          <Button mode="secondary" size="medium" width="100%" onPress={callNow}>
            {t('CallCustomerService')}
          </Button>
          <PFText variant={'p_semibold'}>{t('CustomerServicePhoneNumber')}</PFText>
        </Box>
      </Box>
    </Page>
  )
}

export default CardPhysicalCardActivationFailed
