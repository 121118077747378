import React, {FC, useState} from 'react'
import {View, StyleSheet} from 'react-native'
import {Document, Page, pdfjs} from 'react-pdf'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {useTranslation} from 'react-i18next'

import ScrollViewBase from 'src/designSystem/components/molecules/ScrollViewBase/ScrollViewBase'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import Button from 'src/designSystem/components/atoms/Button/Button'

//see https://github.com/wojtekmaj/react-pdf/wiki/Known-issues
//It's preferred we CopyWebpackPlugin to copy pdf.worker.js during build. But we're doing this for simplicity
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

type Props = {
  uri: string
}

const PDFViewer: FC<Props> = (props) => {
  const [numPages, setNumPages] = useState(0)
  const [width, setWidth] = useState(0)
  const {uri} = props
  const {t} = useTranslation('Documents')

  function onDocumentLoadSuccess(evt) {
    setNumPages(evt.numPages)
  }

  function renderPages() {
    const pages: JSX.Element[] = []
    for (let i = 0; i < numPages; i++) {
      pages.push(<Page key={`PDFPage${i}`} pageNumber={i + 1} width={width} />)
    }
    return pages
  }

  const onLayout = (evt) => setWidth(evt.nativeEvent.layout.width)

  const onPress = () => {
    fetch(uri)
      .then((res) => res.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob)
        window.open(file)
      })
  }

  return (
    <View onLayout={onLayout} style={styles.container}>
      <ScrollViewBase style={{marginTop: pfHeaderHeight}}>
        <Document file={uri} onLoadSuccess={onDocumentLoadSuccess}>
          {renderPages()}
        </Document>
      </ScrollViewBase>
      <View style={styles.floatingButton}>
        <Button
          mode={'secondary'}
          size={'large'}
          icon={{name: 'download', colorVariant: 'default'}}
          onPress={onPress}
        >
          {t('Download')}
        </Button>
      </View>
    </View>
  )
}

export default withForwardedNavigationParams<Props>()(PDFViewer)
const styles = StyleSheet.create({
  container: {
    backgroundColor: genericPageBackground,
    flex: 1,
    width: '100%',
  },
  floatingButton: {
    alignItems: 'center',
    bottom: 24,
    position: 'absolute',
    width: '100%',
  },
})
