import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {WorkflowLoader} from 'src/products/general/Workflows/WorkflowLoader'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import AppEvents from 'src/lib/Analytics/app_events'
import {ApplicationActivationWorkflowReady} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflowReady'
import {WorkflowsReadyType} from 'src/workflows/types'

export const ApplicationActivationWorkflow: React.FC<
  StackScreenProps<MainStackParamList, 'ApplicationActivationWorkflow'>
> = (props) => {
  const {navigation, route} = props

  const handleOnReady = (workflowsData: WorkflowsReadyType): JSX.Element => {
    return (
      <ApplicationActivationWorkflowReady
        navigation={navigation}
        route={route}
        {...workflowsData}
      />
    )
  }

  return (
    <WorkflowLoader
      errorEvent={AppEvents.Name.application_activation_workflow_error}
      errorContactUsEvent={AppEvents.Name.application_activation_workflow_error_contact_us_pressed}
      onReady={handleOnReady}
    />
  )
}
