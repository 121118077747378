import {createSelector} from 'reselect'

import {CardAccount, CardPaymentMethodType, LinkedAccount, Maybe, User} from 'src/cassandra'
import {
  findPaymentAccountType,
  PaymentTypes,
  getDefaultPaymentMethodUtil,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {getActiveAccount, getAllPlaidLinkedBankAccounts, getUser} from 'src/lib/card/selectors'
import {PfReduxState} from 'src/reducers/types'
import {PaymentAccount} from 'src/products/card/PaymentMethods/types'

/**
 * Given a PaymentAccount, does this PaymentAccount have a Payment Instrument?
 */

/*
Normally the type of the method can be inferred automatically. With the upgrade to Typescript 4.1.2 it seems like
in this specific situation it has broken, as the type works fine on 3.9.7. Explicitly setting the type appears to
get past this issue
 */
export const hasPaymentInstrument: (state: PfReduxState, id: string | undefined) => boolean =
  createSelector(
    [
      getUser,
      (state: PfReduxState, paymentAccountId?: string): string | undefined => paymentAccountId,
    ],
    (user: Maybe<User>, paymentAccountId?: string): boolean => {
      if (!user || !paymentAccountId) {
        return false
      }
      const paymentType: PaymentTypes | undefined = findPaymentAccountType(
        paymentAccountId,
        user,
      )[1]
      return paymentType !== undefined && paymentType !== PaymentTypes.LinkedAccount
    },
  )

export const getAllCardEligiblePaymentMethods = (state: PfReduxState): CardPaymentMethodType[] =>
  state.cassandra.user.me?.paymentMethods?.cardEligible ?? []

export const getDefaultPaymentMethod = createSelector<
  PfReduxState,
  Maybe<CardAccount>,
  CardPaymentMethodType[],
  LinkedAccount[],
  Maybe<PaymentAccount>
>(
  getActiveAccount,
  getAllCardEligiblePaymentMethods,
  getAllPlaidLinkedBankAccounts,
  (activeAccount, allCardEligiblePaymentMethods, allLinkedBankAccounts) => {
    return getDefaultPaymentMethodUtil(
      activeAccount,
      allCardEligiblePaymentMethods,
      allLinkedBankAccounts,
    )
  },
)

export const getSelectedPayNowPaymentMethod = (
  state: PfReduxState,
): CardPaymentMethodType | undefined => state.lib.card?.selectedPayNowPaymentMethod

/**
 * If a payment method has been selected for Pay Now, return it
 * Otherwise return the default payment method
 */
export const getPayNowPaymentMethod = createSelector(
  getDefaultPaymentMethod,
  getSelectedPayNowPaymentMethod,
  (
    defaultPaymentMethod: Maybe<PaymentAccount>,
    selectedPayNowPaymentMethod?: CardPaymentMethodType,
  ): PaymentAccount => {
    if (selectedPayNowPaymentMethod) {
      return selectedPayNowPaymentMethod
    } else if (defaultPaymentMethod) {
      return defaultPaymentMethod
    } else {
      return null
    }
  },
)
