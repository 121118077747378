import React from 'react'
import {CompositeScreenProps} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import InfoWarning from 'src/assets/illustrations/descriptors/infoWarning.svg'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {CardAdhocPaymentStack} from 'src/products/card/AdhocPayment/CardAdhocPaymentStack'
import {useCardAdhocPaymentAnalytics} from 'src/products/card/AdhocPayment/useCardAdhocPaymentAnalytics'

type CardPaymentFailureModalProps = CompositeScreenProps<
  StackScreenProps<CardAdhocPaymentStack, 'CardAdhocPaymentFailure'>,
  StackScreenProps<MainStackParamList>
>

export const CardAdhocPaymentFailure = ({
  navigation,
}: CardPaymentFailureModalProps): JSX.Element => {
  const {isLoading, eventArgs} = useCardAdhocPaymentAnalytics()
  usePageViewedAnalytics({
    eventName: CardEvents.card_adhoc_payment_failure_modal,
    eventCategory: AppEvents.Category.Card,
    eventArgs,
    isLoading,
  })
  const {t} = useTranslation(['CardAdhocPayment', 'Common'])

  return (
    <Overlay
      visible
      title={t('SomethingWentWrong')}
      image={<PFSvgContain svg={InfoWarning} width={'50%'} />}
      text={t('UnableToProcessPayment')}
      okayButtonText={t('Common:TryAgain')}
      okayButtonSize={'medium'}
      onOkay={(): void => navigation.goBack()}
      dismissButtonText={t('Common:Cancel')}
      dismissButtonSize="medium"
      onDismiss={(): void => {
        navigation.navigate('CardDashboard')
      }}
      testID="CardAdhocPaymentFailureOverlayModal"
    />
  )
}
