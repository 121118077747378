import {useQueryFactory} from '@possible/cassandra/src/utils'
import {CreateCassandraSelector} from '@possible/cassandra/src/utils/selectors'
import {
  DashboardLoanAggregateStatusDocument,
  DashboardLoanAggregateStatusQuery,
} from 'src/products/loans/Dashboard/DashboardLoan/queries/DashboardLoanAggregateStatusQuery.gqls'

export const DashboardLoanAggregateStatusQuerySelector = CreateCassandraSelector(
  DashboardLoanAggregateStatusDocument,
  (from: DashboardLoanAggregateStatusQuery) => {
    let decisioningLoanAggregateStatus: undefined | DecisioningLoanDashboardAggregateStatus
    let approvedLoanAggregateStatus: undefined | ApprovedLoanDashboardAggregateStatus
    let activeLoanAggregateStatus: undefined | ActiveLoanDashboardAggregateStatus
    let closedLoanAggregateStatus: undefined | ClosedLoanDashboardAggregateStatus
    let undefinedLoanAggregateStatus: undefined | UndefinedLoanDashboardAggregateStatus
    const loanOffers: DashboardLoanAggregateStatusQueryLoanOfferInfo[] = []
    from.me.products.eligible.all.forEach((offerInfo) => {
      if (offerInfo.__typename === 'LoanOfferInfo') {
        loanOffers.push(offerInfo)
      }
    })
    switch (from.me.loans.latestActionableLoan?.aggregateStatus?.__typename) {
      case 'DecisioningLoanAggregateStatus':
        decisioningLoanAggregateStatus = from.me.loans.latestActionableLoan?.aggregateStatus
        break
      case 'ApprovedLoanAggregateStatus':
        approvedLoanAggregateStatus = from.me.loans.latestActionableLoan?.aggregateStatus
        break
      case 'ActiveLoanAggregateStatus':
        activeLoanAggregateStatus = from.me.loans.latestActionableLoan?.aggregateStatus
        break
      case 'ClosedLoanAggregateStatus':
        closedLoanAggregateStatus = from.me.loans.latestActionableLoan?.aggregateStatus
        break
      case 'UndefinedLoanAggregateStatus':
        undefinedLoanAggregateStatus = from.me.loans.latestActionableLoan?.aggregateStatus
        break
      case undefined:
        break
    }
    return {
      userProfile: from.me.profile,
      decisioningLoanAggregateStatus,
      approvedLoanAggregateStatus,
      activeLoanAggregateStatus,
      closedLoanAggregateStatus,
      undefinedLoanAggregateStatus,
      loanOffers,
    }
  },
)

/**
 * Main query hook for the v2 loan dashboard. Queries for the latest actionable loan and any data specific to that loan's aggregaet
 * status, which can be "decisioning", "approved", "active", or "closed".
 */
// eslint-disable-next-line react-hooks/rules-of-hooks -- this creates a new hook
export const useDashboardLoanAggregateStatusQuery = useQueryFactory(
  DashboardLoanAggregateStatusDocument,
  DashboardLoanAggregateStatusQuerySelector,
)

// loan offer info
export type DashboardLoanAggregateStatusQueryOfferInfo = NonNullable<
  DashboardLoanAggregateStatusQuery['me']['products']['eligible']['all'][number]
>
export type DashboardLoanAggregateStatusQueryLoanOfferInfo =
  DashboardLoanAggregateStatusQueryOfferInfo & {
    __typename: 'LoanOfferInfo'
  }

// approved loan aggregate status
export type ApprovedLoanDashboardAggregateStatus = NonNullable<
  NonNullable<
    DashboardLoanAggregateStatusQuery['me']['loans']['latestActionableLoan']
  >['aggregateStatus']
> & {
  __typename: 'ApprovedLoanAggregateStatus'
}
export type ApprovedLoanDashboardAggregateStatusQueryHookResult = {
  approvedLoanAggregateStatus: ApprovedLoanDashboardAggregateStatus
} & NonNullable<ReturnType<typeof useDashboardLoanAggregateStatusQuery>['selectedData']>

// closed loan aggregate status
export type ClosedLoanDashboardAggregateStatus = NonNullable<
  DashboardLoanAggregateStatusQuery['me']['loans']['latestActionableLoan']
>['aggregateStatus'] & {
  __typename: 'ClosedLoanAggregateStatus'
}
export type ClosedLoanDashboardAggregateStatusQueryHookResult = {
  closedLoanAggregateStatus: ClosedLoanDashboardAggregateStatus
} & NonNullable<ReturnType<typeof useDashboardLoanAggregateStatusQuery>['selectedData']>

// active loan aggregate status
export type ActiveLoanDashboardAggregateStatus = NonNullable<
  DashboardLoanAggregateStatusQuery['me']['loans']['latestActionableLoan']
>['aggregateStatus'] & {
  __typename: 'ActiveLoanAggregateStatus'
}
export type ActiveLoanDashboardAggregateStatusQueryHookResult = {
  activeLoanAggregateStatus: ActiveLoanDashboardAggregateStatus
} & NonNullable<ReturnType<typeof useDashboardLoanAggregateStatusQuery>['selectedData']>

// decisioning loan aggregate status
export type DecisioningLoanDashboardAggregateStatus = NonNullable<
  DashboardLoanAggregateStatusQuery['me']['loans']['latestActionableLoan']
>['aggregateStatus'] & {
  __typename: 'DecisioningLoanAggregateStatus'
}
export type DecisioniongLoanDashboardAggregateStatusQueryHookResult = {
  decisioningLoanAggregateStatus: DecisioningLoanDashboardAggregateStatus
} & NonNullable<ReturnType<typeof useDashboardLoanAggregateStatusQuery>['selectedData']>

// undefined loan aggregate status from the API
export type UndefinedLoanDashboardAggregateStatus = NonNullable<
  DashboardLoanAggregateStatusQuery['me']['loans']['latestActionableLoan']
>['aggregateStatus'] & {
  __typename: 'UndefinedLoanAggregateStatus'
}
// undefined loan aggregate status return value from the query hook
export type UndefinedLoanDashboardAggregateStatusQueryHookResult = {
  decisioningLoanAggregateStatus: DecisioningLoanDashboardAggregateStatus
} & NonNullable<ReturnType<typeof useDashboardLoanAggregateStatusQuery>['selectedData']>
