import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {LoanProcessingApprovedTemplate} from 'src/products/loans/LoanProcessing/LoanProcessingApproved/LoanProcessingApprovedTemplate'
import {usePfDispatch} from 'src/store/utils'
import {getLoanExpirationDateStr} from 'src/lib/loans/utils'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {LoanProcessingQueryDocument} from 'src/products/loans/LoanProcessing/LoanProcessingQuery.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  ApprovedLoanAggregateStatus,
  LoanAggregateStatus,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {monthDay} from 'src/lib/utils/date'

const isApprovedLoanAggregateStatus = (
  loanAggregateStatus?: Partial<LoanAggregateStatus>,
): loanAggregateStatus is ApprovedLoanAggregateStatus => {
  return (
    loanAggregateStatus !== undefined &&
    'amountApproved' in loanAggregateStatus &&
    'expirationDateTime' in loanAggregateStatus
  )
}

export type Props = {
  onComplete: () => void
}

export const LoanProcessingApprovedGQLContainer: FC<Props> = ({onComplete}) => {
  const {t} = useTranslation(['Onboarding'])
  const dispatch = usePfDispatch()
  let loanAmountBorrowed: string = ''
  let expirationDateStr: string = ''

  const {
    selectedData: loanAggregateStatus,
    loading: isLoading,
    error,
  } = useCassandraQuery(
    LoanProcessingQueryDocument,
    {fetchPolicy: 'cache-first'},
    (data) => data.me.loans.latestActionableLoan?.aggregateStatus,
  )

  if (isApprovedLoanAggregateStatus(loanAggregateStatus)) {
    loanAmountBorrowed = loanAggregateStatus.amountApproved
    expirationDateStr =
      getLoanExpirationDateStr(loanAggregateStatus.expirationDateTime, monthDay) ?? ''
  }

  const title = t('OnboardingLoanProcessingApprovedTitle', {
    amountBorrowed: loanAmountBorrowed ? `$${loanAmountBorrowed}` : undefined,
  })

  const handleOnAcceptOffer = async (): Promise<void> => {
    onComplete?.()
    await dispatch(UserStateRefresh())
  }

  return (
    <BaseTemplate
      isLoading={isLoading}
      isError={!!error || !loanAggregateStatus}
      pageTitle={title}
      showTileBorder={false}
    >
      <LoanProcessingApprovedTemplate
        expirationDate={expirationDateStr}
        onAcceptOffer={handleOnAcceptOffer}
        title={title}
      />
    </BaseTemplate>
  )
}
