import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {Consumer} from '@possible/cassandra'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {userIdSelector} from 'src/lib/user/selector'
import {uploadAndCreateDocument} from 'src/lib/utils/files'
import PhotoInstructionsCard from 'src/products/general/components/molecules/PhotoInstructionsCard/PhotoInstructionsCard'
import CameraPage, {
  CameraPageProps,
  OnCameraCompleteCallback,
} from 'src/products/general/components/organisms/CameraPage/CameraPage'
import {CameraAnalyticEvents} from 'src/products/general/components/organisms/CameraPage/CameraPage.types'
import {usePfSelector} from 'src/store/utils'
import AppEvents from 'src/lib/Analytics/app_events'

type IDCameraPageProps = Partial<CameraPageProps> & {
  onComplete: CameraPageProps['onComplete']
}

const IDCameraPage: React.FC<IDCameraPageProps> = (props) => {
  const {onComplete, ...rest} = props

  const {t} = useTranslation(['CameraPage', 'Common'])

  const userId = usePfSelector(userIdSelector)

  const infoCard = useMemo(
    () => (
      <Box marginBottom="little" fill="horizontal">
        <PhotoInstructionsCard />
      </Box>
    ),
    [],
  )

  const handleSubmission: OnCameraCompleteCallback = async (image, orientation): Promise<void> => {
    try {
      const response = await uploadAndCreateDocument(
        image,
        Consumer.types.UserDocumentType.Identity,
        Consumer.types.UserDocumentSubType.DriversLicenseFront,
        userId,
      )

      if (response) {
        await onComplete(image, orientation)
      }
    } catch (e) {
      Log.error({e}, 'Unable to upload or create document')
      ShowException(e)
    }
  }

  const events: CameraAnalyticEvents = {
    takePhotoViewedEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.front_id_photo_screen_viewed,
    },
    confirmPhotoViewedEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.front_id_photo_confirmation_screen_viewed,
    },
    photoTakenEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.front_id_photo_taken,
    },
    photoSubmittedEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.front_id_photo_submitted,
    },
    photoRetakeEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.front_id_photo_retaken,
    },
    orientationChangedEvent: {
      category: AppEvents.Category.Application,
      name: AppEvents.Name.front_id_photo_orientation_changed,
    },
    ...rest.events,
  }

  return (
    <CameraPage
      orientations={['portrait']}
      position="back"
      captureTitle={t('IDCapturePhaseTitle')}
      captureMessage={infoCard}
      confirmTitle={t('IDConfirmPhaseTitle')}
      confirmMessage={infoCard}
      turnCameraButtonText={{landscape: t('VerticalID'), portrait: t('HorizontalID')}}
      submitButtonText={t('IDSubmitButton')}
      {...rest}
      onComplete={handleSubmission}
      events={events}
    />
  )
}

export default IDCameraPage
