import {useTranslation} from 'react-i18next'

import {
  TransactionItemAccountType,
  TransactionItemType,
  TransactionTileItem,
} from 'src/designSystem/components/organisms/TransactionsTile/TransactionsTile.utils'

import {
  FundingAuthorizationProcessorStatus,
  LoanPaymentMethod,
  LoanPaymentStatusCode,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {
  LoanHistoryTileLoanDisbursementFields,
  LoanHistoryTileLoanPaymentFields,
} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.types'

/**
 * Convert an array of LoanPayments and a LoanDisbursement to TransactionTileItems so that it can be used by the <TransacationsTile> component.
 */
export const convertLoanPaymentsAndDisbursementToTransactionsItems = (params: {
  loanPayments: LoanHistoryTileLoanPaymentFields[]
  loanDisbursement: LoanHistoryTileLoanDisbursementFields
  t: ReturnType<typeof useTranslation>['t']
}): TransactionTileItem[] => {
  const {loanPayments, loanDisbursement, t} = params
  const disbursementTransaction: TransactionTileItem | undefined =
    convertLoanDisbursementsToTransactionItem({
      loanDisbursement,
      t,
    })

  // make a copy so we can sort
  const loanPaymentsSortedByOrdinal = loanPayments.map((thisPayment) => ({...thisPayment}))
  // sort from ordinal high to low so newest payment is first
  loanPaymentsSortedByOrdinal.sort(
    (a: LoanHistoryTileLoanPaymentFields, b: LoanHistoryTileLoanPaymentFields) => {
      return b.ordinal - a.ordinal
    },
  )
  const paymentTransactions: TransactionTileItem[] = convertLoanPaymentsToTransactionItems({
    loanPayments: loanPaymentsSortedByOrdinal,
    t,
  })

  const paymentTransactionsUpcoming: TransactionTileItem[] = paymentTransactions.filter(
    (thisPayment) => {
      return thisPayment.type === TransactionItemType.PaymentUpcoming
    },
  )
  const paymentTransactionsPendingInProgress: TransactionTileItem[] = paymentTransactions.filter(
    (thisPayment) => {
      return thisPayment.type === TransactionItemType.PaymentPending
    },
  )
  const paymentTransactionsAlreadyHappened = paymentTransactions.filter((thisPayment) => {
    return (
      thisPayment.type !== TransactionItemType.PaymentUpcoming &&
      thisPayment.type !== TransactionItemType.PaymentPending
    )
  })

  // always show the newest upcoming transaction first, then all pending in progress, then show the rest sorted by ordinal
  const paymentTransactionsSorted = paymentTransactionsUpcoming
    .slice(-1)
    .concat(paymentTransactionsPendingInProgress)
    .concat(paymentTransactionsAlreadyHappened)
  const allTransactions: TransactionTileItem[] = paymentTransactionsSorted
  if (disbursementTransaction) {
    allTransactions.push(disbursementTransaction)
  }
  return allTransactions
}

const convertPaymentMethodCodeToAccountType = (
  paymentMethodCode: LoanPaymentMethod,
): TransactionItemAccountType => {
  let accountType: TransactionItemAccountType
  switch (paymentMethodCode) {
    case LoanPaymentMethod.Ach:
    case LoanPaymentMethod.Check:
    case LoanPaymentMethod.None:
      accountType = TransactionItemAccountType.Bank
      break
    case LoanPaymentMethod.DebitCard:
      accountType = TransactionItemAccountType.Card
      break
  }
  return accountType
}
/**
 * Convert an array of LoanPayments to TransactionTileItems so that it can be used by the <TransacationsTile> component.
 */
const convertLoanPaymentsToTransactionItems = (params: {
  loanPayments: LoanHistoryTileLoanPaymentFields[]
  t: ReturnType<typeof useTranslation>['t']
}): TransactionTileItem[] => {
  const {loanPayments, t} = params
  const transactions: TransactionTileItem[] = []
  loanPayments.forEach((thisPayment: LoanHistoryTileLoanPaymentFields) => {
    // if it's undefined it will not be included in the list of displayed transactions
    let type: TransactionItemType | undefined
    switch (thisPayment.statusCode) {
      case LoanPaymentStatusCode.Cancelled:
        type = TransactionItemType.PaymentCancelled
        break
      case LoanPaymentStatusCode.Completed:
        type = TransactionItemType.PaymentPaid
        break
      case LoanPaymentStatusCode.Failed:
        type = TransactionItemType.PaymentFailed
        break
      case LoanPaymentStatusCode.InProgress:
        type = TransactionItemType.PaymentPending
        break
      case LoanPaymentStatusCode.Pending:
        type = TransactionItemType.PaymentUpcoming
        break
      case LoanPaymentStatusCode.Replaced:
        // we don't show replaced tx in this component
        type = undefined
        break
      case LoanPaymentStatusCode.Suspended:
        type = TransactionItemType.PaymentFailed
        break
    }
    const ordinalToLabelMap: {[key: number]: string} = {
      1: t('First'),
      2: t('Second'),
      3: t('Third'),
      4: t('Fourth'),
      5: t('Fifth'),
      6: t('Sixth'),
      7: t('Seventh'),
      8: t('Eigth'),
      9: t('Ninth'),
      10: t('Tenth'),
      11: t('Eleventh'),
      12: t('Twelfth'),
    }
    const isThisFinalPayment = isFinalPayment({
      allPayments: loanPayments,
      thisPayment,
    })
    const ordinalText: string = isThisFinalPayment
      ? t('Final')
      : ordinalToLabelMap[thisPayment.ordinal]
    const installmentTitle = t('LoanInstallment', {installment: ordinalText})
    const accountDescriptor = getAccountDescriptor({
      accountFriendlyName: thisPayment.lastTransaction?.accountFriendlyName ?? undefined,
      accountMask: thisPayment.lastTransaction?.accountMask ?? undefined,
    })
    const accountType: TransactionItemAccountType | undefined =
      convertPaymentMethodCodeToAccountType(thisPayment.methodCode)

    if (type !== undefined) {
      transactions.push({
        type,
        title: installmentTitle,
        accountDescriptor,
        amount: thisPayment.amount,
        accountType,
        dateTime: thisPayment.rescheduledDate ?? thisPayment.originalDate,
      })
    }
  })
  return transactions
}

/**
 * Convert a LoanDisbursement to a TransactionTileItem so that it can be used by the <TransacationsTile> component.
 */
const convertLoanDisbursementsToTransactionItem = (params: {
  loanDisbursement: LoanHistoryTileLoanDisbursementFields
  t: ReturnType<typeof useTranslation>['t']
}): TransactionTileItem | undefined => {
  const {loanDisbursement, t} = params
  let type: TransactionItemType
  if (loanDisbursement.status === FundingAuthorizationProcessorStatus.Completed) {
    type = TransactionItemType.DepositCompleted
  } else if (loanDisbursement.status === FundingAuthorizationProcessorStatus.Failed) {
    type = TransactionItemType.DepositFailed
  } else {
    // this shouldnt happen but if it does we don't include it
    return undefined
  }
  const accountDescriptor = getAccountDescriptor({
    accountFriendlyName: loanDisbursement.accountFriendlyName,
    accountMask: loanDisbursement.accountMask,
  })
  let accountType: TransactionItemAccountType
  switch (loanDisbursement.method) {
    case LoanPaymentMethod.Ach:
    case LoanPaymentMethod.Check:
    case LoanPaymentMethod.None:
      accountType = TransactionItemAccountType.Bank
      break
    case LoanPaymentMethod.DebitCard:
      accountType = TransactionItemAccountType.Card
      break
  }
  return {
    type,
    title: t('LoanDisbursement'),
    accountDescriptor,
    amount: loanDisbursement.amount,
    accountType,
    dateTime: loanDisbursement.completedOn ?? loanDisbursement.updatedOn,
  }
}

const getAccountDescriptor = (params: {
  accountFriendlyName?: string
  accountMask?: string
}): string => {
  const {accountFriendlyName, accountMask} = params
  let accountDescriptor = accountFriendlyName ? `${accountFriendlyName} ` : ''
  if (accountMask) {
    accountDescriptor = `${accountDescriptor}${accountMask}`
  }
  return accountDescriptor
}

/**
 * Determine the highest ordinal number given a list of loan payments. Helpful to determine if a loan paayme
 */
const getHighestOrdinalNumber = (params: {
  loanPayments: LoanHistoryTileLoanPaymentFields[]
}): number => {
  const {loanPayments} = params
  let highestOrdinalFound = loanPayments[0].ordinal
  loanPayments.forEach((thisPayment) => {
    if (!highestOrdinalFound || thisPayment.ordinal > highestOrdinalFound) {
      highestOrdinalFound = thisPayment.ordinal
    }
  })
  return highestOrdinalFound
}
/**
 * Determine if a given payment is the final payment per the ordinal.
 */
const isFinalPayment = (params: {
  allPayments: LoanHistoryTileLoanPaymentFields[]
  thisPayment: LoanHistoryTileLoanPaymentFields
}): boolean => {
  const {allPayments, thisPayment} = params
  const highestOrdinal = getHighestOrdinalNumber({loanPayments: allPayments})
  return thisPayment.ordinal === highestOrdinal
}
