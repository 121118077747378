import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {gutterSize} from 'src/designSystem/layout'
import {ExpandableSection} from 'src/products/loans/LoanAmountSelectionNew/ExpandableSection'

type EstimatedTotalProps = {
  estimatedTotal: string
  installmentAmount: string
  installmentCount: number
  totalInterestAndFees: string
  APRPercent: string
  isLoadingEstimatedCosts: boolean
}

export const EstimatedTotal: React.FC<EstimatedTotalProps> = (props) => {
  const {
    estimatedTotal,
    installmentAmount,
    installmentCount,
    totalInterestAndFees,
    APRPercent,
    isLoadingEstimatedCosts,
  } = props

  const [showAPRInfo, setShowAPRInfo] = React.useState<boolean>(false)
  const {t} = useTranslation('LoanAmountSelectionTemplateNew')

  return (
    <ExpandableSection
      showLoading={isLoadingEstimatedCosts}
      testID={'LoanAmountSelectionNew-ExpandableSection'}
      title={t('EstimatedTotal', {estimatedTotal})}
    >
      {installmentCount < 2 ? (
        <Box>
          <PFText variant={'p_sm'}>
            {t('PaymentExplanation', {installmentAmount: parseInt(installmentAmount) / 4})}
          </PFText>
        </Box>
      ) : null}
      <Box direction="row" justify="between" marginTop={gutterSize} align="center">
        <Box>
          <PFText variant={'p_semibold'} textProps={{style: styles.smallLineHeight}}>
            {t('InstallmentOf', {count: installmentCount})}
          </PFText>
          <PFText variant={'p_sm'}>{t('EveryTwoWeeks', {count: installmentCount})}</PFText>
        </Box>
        <Box boxStyle={styles.amountBox}>
          <PFText variant="p_semibold" color="primary">
            {t('InstallmentAmountWithCount', {installmentAmount, installmentCount})}
          </PFText>
        </Box>
      </Box>

      <Box direction="row" justify="between" marginTop={gutterSize} align="center">
        <Box>
          <PFText variant={'p_semibold'} textProps={{style: styles.smallLineHeight}}>
            {t('TotalInterest')}
          </PFText>
          <PFText variant={'p_sm'}>
            <Trans
              t={t}
              i18nKey="TranslatesToAPR"
              values={{APRPercent}}
              components={{
                underline: (
                  <PFText
                    variant={'p_sm'}
                    onPress={(): void => setShowAPRInfo(true)}
                    textProps={{style: styles.link, role: 'link'}}
                  />
                ),
              }}
            />
          </PFText>
        </Box>

        <Box boxStyle={styles.amountBox}>
          <PFText variant="p_semibold" color="primary">{`$${totalInterestAndFees}`}</PFText>
        </Box>
      </Box>

      <Box
        direction="row"
        justify="between"
        marginTop={gutterSize}
        align="center"
        boxStyle={styles.amountBox}
      >
        <PFText variant="p_semibold">{t('Total')}</PFText>
        <PFText variant="p_semibold">{`$${estimatedTotal}`}</PFText>
      </Box>
      <BottomSheet
        visible={showAPRInfo}
        title={t('AprExplanationTitle')}
        titleAlign="left"
        titleVariant="h3"
        onDismiss={(): void => setShowAPRInfo(false)}
        showDots={false}
        showCloseButton
        dismissButton={{text: t('DismissButtonLabel')}}
        fullWidthTitle={false}
      >
        <PFText variant="p">{t('AprExplanation')}</PFText>
      </BottomSheet>
    </ExpandableSection>
  )
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
  },
  smallLineHeight: {
    lineHeight: 18,
  },
  amountBox: {
    backgroundColor: NamedColors.WHITE,
    borderRadius: 8,
    paddingLeft: gutterSize,
    paddingRight: gutterSize,
    paddingTop: gutterSize / 2,
    paddingBottom: gutterSize / 2,

    marginRight: -gutterSize,
    marginLeft: -gutterSize,
  },
})
